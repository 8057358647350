import { Button, useTheme } from '@mui/material';


export default function PillButton({ children, onClick, disabled }) {
  const theme = useTheme();
  return (
    <Button variant='contained' size='small' onClick={onClick} disabled={disabled}
      sx={{
        fontSize: 14,
        padding: '4px 10px 5px 10px',
        borderRadius: '20px',
        border: disabled ? '2px solid rgba(0, 0, 0, 0.12)' : `2px solid ${theme.palette.primary.main}`,
      }}
    >{children}</Button>
  );
}