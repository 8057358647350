import { Grid, Typography } from '@mui/material';

export interface ICustomerDetailsProps {
  customer: any;
}
export default function CustomerDetails({ customer }:ICustomerDetailsProps) {
  return (
    <Grid container item spacing={1}>
      <Grid item xs={12} textAlign='left'>
        <Typography component='div'>Name: {customer.fullName}</Typography>
      </Grid>
      <Grid item xs={12} textAlign='left'>
        <Typography component='div'>Address: {customer.streetAddress}</Typography>
      </Grid>
      <Grid item xs={12} textAlign='left'>
        <Typography component='div'>ZipCode: {customer.zipCode}</Typography>
      </Grid>
      <Grid item xs={12} textAlign='left'>
        <Typography component='div'>Phone: {customer.phone}</Typography>
      </Grid>
      <Grid item xs={12} textAlign='left'>
        <Typography component='div'>Email: {customer.email}</Typography>
      </Grid>
      <Grid item xs={12} textAlign='left'>
        <Typography component='div'>Neighborhood: {customer.neighborhood}</Typography>
      </Grid>
      <Grid item xs={12} textAlign='left'>
        <Typography component='div'>Status: {customer.status}</Typography>
      </Grid>
    </Grid>
  );
}