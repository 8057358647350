import useCustomerDetail from '../hookStore/useCustomerDetail';
import { markRewardsAsPaid } from 'api/customerApi';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useToast from 'components/toast/useToast';

export function MarkCustomerRewardsAsPaidModal() {
  const { createSuccessToast } = useToast();
  const { customer } = useCustomerDetail();

  return (
    <HookConfirmationModal
      modalKey={'markRewardsPaidModal'}
      title={() => (<div>Mark rewards as paid?</div>)}
      message={() => 'This will clear the customer off Open Credits table'}
      onConfirm={async () => {
        try {
          await markRewardsAsPaid(customer.id);
          console.log('marking rewards as paid');
          createSuccessToast('Customer rewards marked as paid.');
        } catch (e:any) {

        }
      }}
      sx={{
        '& .MuiCardContent-root': {
          padding: 3,
        },
      }}
    />
  );
}