import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { createEmptyNote } from 'model/operations';

export default function AddNewNoteButton() {
  const { getValues } = useFormContext();

  const { fields: notes, append, replace, remove } = useFieldArray({
    name: 'notes',
  });
  return (
    <Button
      type='button'
      variant='outlined'
      onClick={() => {
        append(createEmptyNote());
      }}
    >
      <AddIcon/> New Note
    </Button>
  );
}