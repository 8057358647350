import { Grid, Alert } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import useGroupDeal from '../hookStore/useGroupDeal';
import { HookIntegerField } from 'components/reactHookForm';

export interface IMaxCustomerCountFieldProps {
  className?:string;
}

export default function MaxCustomerCountField({ className }:IMaxCustomerCountFieldProps) {
  const formCtx = useFormContext();
  const { editingClicked } = useGroupDeal();
  const neighborhoodName = formCtx.getValues().neighborhoodName;
  const watchMaxCustomerCount = useWatch({ control: formCtx.control, name: 'maxCustomerCount' });
  const watchCustomers = useWatch({ control: formCtx.control, name: 'customers' });

  return (
    <Grid container item xs={12} className={className || ''}>
      <>
        <HookIntegerField
          // disabled={!editingClicked}
          required
          name='maxCustomerCount'
          label='Max # of Customers'
          helperText={`Max number of customers in ${neighborhoodName} that can join in on this service visit`}
          validationRules={{
            validate: function(value) {
              if (value < watchCustomers.length) {
                return 'The max customer count must be at least the current number of customers';
              }
              return true;
            },
          }}
        />
        {watchMaxCustomerCount && parseInt(watchMaxCustomerCount) === 1 &&
                    <Alert style={{ width: '100%', margin: '20px 0px 10px 0px' }} severity="warning">
                      <span>Setting max to 1 means that no additional neighbors will be able to join in. Only one customer will be on this service visit.</span>
                    </Alert>
        }
      </>
    </Grid>
  );
}
