import { useEffect, useState } from 'react';
import store from './customerDetailDataStore';
import { useLoading } from 'components/layout/Loading';
import { createOperationsForm } from 'model/operations';

const { get, update, registerListener, unregisterListener } = store;

const loadingKey = 'operationsStore';

export default function useOperationsStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function init() {
    const { customerServiceDetails } = get();
    const form = createOperationsForm(customerServiceDetails);
    update({
      ...get(),
      operationsForm: form,
    });
  }


  function onCancel() {
    const { customerServiceDetails } = get();
    const form = createOperationsForm(customerServiceDetails);
    update({
      ...get(),
      operationsForm: form,
    });
  }

  return {
    ...get(),
    onCancel,
    init,
  };
}