export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function getRandomFromArray(data:any[]) {
  if (!data || data.length === 0) {
    return null;
  }
  const random = Math.floor(Math.random() * data.length);
  return data[random];
}