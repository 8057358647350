import Axios, { AxiosResponse } from 'axios';
import { IAddressAutocompleteRequestDto, IAddressAutocompleteResponse, IFindNeighborhoodByPlaceRequestDTO, IGeospatialDataFromPlaceResponseDTO } from 'model/google';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function getAutocompleteForAddress(serviceProviderId:string, data:IAddressAutocompleteRequestDto):Promise<AxiosResponse<IAddressAutocompleteResponse>> {
  return Axios.post(`${apiUrl}/sfgapi/v2/serviceProviders/${serviceProviderId}/autocompleteAddress`, data);
}

export function findNeighborhoodByPlace(data: IFindNeighborhoodByPlaceRequestDTO):Promise<AxiosResponse<IGeospatialDataFromPlaceResponseDTO>> {
  return Axios.post(
    `${apiUrl}/sfgapi/v2/public/findNeighborhoodByPlace`,
    data,
  );
}