import { Typography } from '@mui/material';
import styles from '../postConfirmModal.module.css';

export default function SlideSubtitle({ children }:any) {

  return (
    <Typography
      variant='h6'
      className={styles.slideSubtitle}
      sx={{
        textAlign: {
          xs: 'center',
          sm: 'left',
        },
      }}
    >{children}</Typography>
  );
}