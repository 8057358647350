import Axios, { AxiosResponse } from 'axios';
import { IDiscountSchedule } from 'model/discount';
import { IDiscountTableRequest } from 'model/groupDeal';

export function findDiscountSchedulesByProvider(providerId:string):Promise<AxiosResponse<any>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/findByProvider/${providerId}`);
}
export function getDiscountTable(serviceProviderId:string, serviceOfferingId:string, data:IDiscountTableRequest):Promise<AxiosResponse<any>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/serviceProviders/${serviceProviderId}/serviceOfferings/${serviceOfferingId}`, data);
}

export function findDiscountScheduleByServiceInstance(serviceProviderId:string, neighborhoodServiceOfferingInstanceId:string):Promise<AxiosResponse<IDiscountSchedule>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/discountSchedule/v2/serviceProviders/${serviceProviderId}/nsois/${neighborhoodServiceOfferingInstanceId}/findByServiceInstance`);
}
