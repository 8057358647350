import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Grid, Breadcrumbs, Typography, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSwitchProvider from './hookStore/useSwitchProvider';
import styles from './switchProvider.module.css';
import Footer from 'components/footer';
import QuicksearchToolbar from 'components/grid/QuicksearchToolbar';
import useTableSearch from 'components/grid/useTableSearch';
import Loading from 'components/layout/Loading';
import Status from 'components/Status';

const desktopColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 150,
    renderCell: (params: any) => {
      return <Typography variant='body2' component='div' className={styles.nameCell}>{params.row.name}</Typography>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (<Status status={params.row.status} size={12} marginRight={5} fontSize={12}/>);
    },
  },
];

const mobileColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 150,
    renderCell: (params: any) => {
      return <Typography variant='body2' component='div' className={styles.nameCell}>{params.row.name}</Typography>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (<Status status={params.row.status} size={10} marginRight={5} fontSize={11}/>);
    },
  },
];

function getColumnData(breakpoint):any {
  let columns;
  let columnTotalWidth = 0;
  if (breakpoint) {
    columns = mobileColumns;
    columnTotalWidth = mobileColumns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
  } else {
    columns = desktopColumns;
    columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0) +1;
  }
  return { columns, columnTotalWidth };
}

export default function SwitchProvider() {
  const { serviceProviderId } = useParams();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    loadingKey,
    quickSearchStorageKey,
    init,
    serviceProviders,
    sortModel,
    onSortModelChange,
    switchProvider,
  } = useSwitchProvider();
  const {
    rows,
    searchText,
    setRowsWithStoredFilter,
    requestSearch,
    clearStoredSearch,
  } = useTableSearch(quickSearchStorageKey);
  const { columns, columnTotalWidth } = getColumnData(smDown);

  useEffect(() => {
    setRowsWithStoredFilter(serviceProviders);
  }, [serviceProviders]);

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={8} className={styles.pageContainer}>
          <Grid className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} container item xs='auto' alignItems='center' wrap="nowrap">
            <HomeIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}`}>Home</Link>
              <Link to={`/v1/${serviceProviderId}/switchProvider`} className={styles.lastLink}>Switch Service Provider</Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} >
            <Typography variant='h5' component='div' className={smDown ? styles.mobileHeader : styles.header} textAlign='left'>Service Providers</Typography>
          </Grid>
          <Grid container item xs={12} >
            <DataGrid
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              rows={rows}
              components={{ Toolbar: QuicksearchToolbar }}
              componentsProps={{
                toolbar: {
                  quickSearchStorageKey,
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(serviceProviders, event.target.value),
                  clearSearch: () => requestSearch(serviceProviders, ''),
                },
              }}
              columns={columns}
              autoHeight={true}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              onRowClick={switchProvider}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  background: '#DBDBDB',
                  color: '#666',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
              }}
            />
          </Grid>
          <Footer className={styles.footer}/>
        </Grid>
      </Grid>
    </Loading>
  );
}