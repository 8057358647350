import { ICustomerServiceDetails } from './customer';
import { IMinimalUserDTO } from 'api/userApi';


export interface IOperationsFormDto {
  customerId:string;
  subscriberId:string;
  recurring:boolean;
  serviceRelationshipOnus: string;
  notes:INote[];
  snoozeDate:string | null;
}

export interface IOperationsForm {
  serviceRelationshipOnus: string;
  notes:IFormNote[];
  snoozeDate:string | null;
}

export enum NoteType {
  OPERATIONS = 'OPERATIONS'
}

export interface INote {
  userId:string;
  userName:string;
  timestamp:string;
  note:string;
  noteType:NoteType;
}

export interface IFormNote extends INote {
  isExpanded:boolean;
  wasAdded:boolean;
}

export function convertFormNote(note:IFormNote, userInfo:IMinimalUserDTO):INote {
  if (!note.userId || !note.userName ) {
    note.userId = userInfo.userId;
    note.userName = userInfo.userName;
  }
  return {
    ...note,
  };
}

export function convertNote(note:INote):IFormNote {
  return {
    ...note,
    isExpanded: false,
    wasAdded: false,
  };
}

export function createEmptyNote():IFormNote {
  return {
    userId: '',
    userName: '',
    timestamp: new Date().toISOString(),
    note: '',
    noteType: NoteType.OPERATIONS,
    isExpanded: true,
    wasAdded: true,
  };
}

export function createEmptyOperationsForm():IOperationsForm {
  return {
    serviceRelationshipOnus: '',
    notes: [],
    snoozeDate: null,
  };
}

export function createOperationsForm(data:ICustomerServiceDetails):IOperationsForm {
  return {
    serviceRelationshipOnus: data.serviceRelationshipOnus ?? '',
    notes: (data.notes ?? []).map(x => convertNote(x)),
    snoozeDate: data.snoozeDate,
  };
}