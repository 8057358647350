import { ThemeProvider } from '@mui/material';
import Userfront from '@userfront/react';
import LogoHeader from 'components/Header/logoHeader';
import Toast from 'components/toast';
import useSelf from 'hooks/useSelf';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import mainTheme from 'theme';

function Layout () {
  const navigate = useNavigate();
  const { serviceProviderId, signUpLinkToken } = useParams();
  const { onGetSelfServiceProvider, onGetAnonymousServiceProvider, serviceProvider } = useSelf();


  async function init(_serviceProviderId) {
    if (!Userfront.tokens.accessToken && !signUpLinkToken) {
      navigate('/sign-in');
      return;
    } else if (!signUpLinkToken || (signUpLinkToken && Userfront.tokens.accessToken)) {
      await onGetSelfServiceProvider(_serviceProviderId);
    } else if (signUpLinkToken) {
      await onGetAnonymousServiceProvider(_serviceProviderId);
    }
  }
  useEffect(() => {
    void init(serviceProviderId);
  }, [serviceProviderId]);

  return (
    <ThemeProvider theme={mainTheme}>
      <LogoHeader />
      <main>
        <Outlet />
      </main>
      <Toast/>
    </ThemeProvider>
  );
}

export default Layout;