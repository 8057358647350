import { Grid, TextField } from '@mui/material';
import { requestSupport } from 'api/serviceProviderApi';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useToast from 'components/toast/useToast';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { IProviderSupportRequest, ProviderSupportRequestTypes } from 'model/serviceProvider';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatPhoneNumber } from 'util/formatUtil';
import styles from './requestSupport.module.css';

export default function RequestSupportModal() {
  const { serviceProviderId, customerId } = useParams();
  const [message, setMessage] = useState<string>('');
  const { createSuccessToast } = useToast();
  const { trackButtonClick } = useStreetfairAnalytics();
  return (
    <HookConfirmationModal
      modalKey={'requestSupportModal'}
      title={() => (<div className={styles.confirmDeleteModalTitle}>Submit Request for Support</div>)}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.requestSupportModalBody}>
          <Grid item xs={12}>
                        Contact StreetFair operations for support? Alternatively, call or text: {formatPhoneNumber('704-686-8070')}
          </Grid>
          <Grid item xs={12} className={styles.requestSupportMessage}>
            <TextField
              fullWidth
              multiline
              minRows={3}
              label='Message'
              value={message}
              error={message.length > 2000}
              helperText={message.length > 2000 ? 'Max 2000 characters' : '' }
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      )}
      onConfirm={async () => {
        if (serviceProviderId && customerId && message.length <= 2000) {
          let data:IProviderSupportRequest = {
            supportRequestType: ProviderSupportRequestTypes.CUSTOMER_DETAIL_REQUEST,
            serviceProviderId,
            customerId,
            message,
          };
          try {
            await requestSupport(data);
            void trackButtonClick('Customer_Detail_Request_Support_Button');
            createSuccessToast('Request for support received.');
          } catch (e:any) {

          }

        }
      }}
    />
  );
}