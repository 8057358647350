import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Status from 'components/Status';
import { ParticipantStatus } from 'model/ancillary';
import { formatDateRangeLocalDate, formatLongMonthDayYear } from 'util/dateUtil';
import { formatPhoneNumber } from 'util/formatUtil';
import styles from './customerTable.module.css';

export const desktopColumns: GridColDef[] = [
  {
    field: 'nameAndNeighborhood',
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName} ${params.row.neighborhoodName}`;
    },
    headerName: 'Name',
    filterable: true,
    flex: 1,
    minWidth: 150,
    renderCell: (params: any) => {
      return (
        <div className={styles.nameNeighborhoodCell}>
          <Typography variant='body2' component='div'>{params.row.firstName} {params.row.lastName}</Typography>
          <Typography variant='caption' component='div'>{params.row.neighborhoodName}</Typography>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: (params) => {
      if (params.row.status === ParticipantStatus.ACTIVE) {
        return 'Confirmed';
      }
      return `${params.row.status}`;
    },
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (<Status status={params.row.status} size={12} marginRight={5} fontSize={12}/>);
    },
  },
  { field: 'neighborhoodName', headerName: 'Neighborhood', minWidth: 100, flex: 1, hide: true },
  { field: 'communicationPreference', headerName: 'Pref. Contact', minWidth: 100, flex: 1, hide: true },
  { field: 'email', headerName: 'Email', minWidth: 100, flex: 1.5, hide: true },
  {
    field: 'phone',
    headerName: 'phone',
    minWidth: 100,
    flex: 1.5,
    renderCell: (params:any) => {
      if (!params.row.phone) {
        return <Typography variant='body2' component='div'>No phone number</Typography>;
      }
      return <span>{formatPhoneNumber(params.row.phone)}</span>;
    },
  },
  { field: 'streetAddress', headerName: 'Address', minWidth: 100, flex: 1 },
  { field: 'zipCode', headerName: 'ZIP', minWidth: 100, flex: 1, hide: true },
  {
    field: 'serviceDate',
    valueGetter: (params) => {
      if (params.row.recurring) {
        return 'No service date recurring';
      }
      if (!params.row.serviceDate) {
        return 'No service date';
      }

      if (params.row.endServiceDate) {
        return `${params.row.serviceDate} ${params.row.endServiceDate} ${formatDateRangeLocalDate(params.row.serviceDate, params.row.endServiceDate)}`;
      }
      return `${params.row.serviceDate} ${formatLongMonthDayYear(params.row.serviceDate)} `;
    },
    headerName: 'Service Date',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      if (params.row.recurring) {
        return (
          <div className={styles.nameNeighborhoodCell}>
            <Typography variant='body2' component='div'>No service date</Typography>
            <Typography variant='caption' component='div'>Recurring</Typography>
          </div>
        );
      }
      if (!params.row.serviceDate) {
        return <Typography variant='body2' component='div' sx={{ fontWeight: 'bold' }}>No service date</Typography>;
      }
      if (params.row.endServiceDate) {
        return (
          <div style={{ textAlign: 'left' }}>
            <Typography variant='caption' component='div'>{formatLongMonthDayYear(params.row.serviceDate)}</Typography>
            <Typography variant='caption' component='div'>{formatLongMonthDayYear(params.row.endServiceDate)}</Typography>
          </div>
        );
      }
      return (<span>{formatLongMonthDayYear(params.row.serviceDate)}</span>);
    },
  },
];

export const mobileColumns: GridColDef[] = [
  {
    field: 'nameAndNeighborhood',
    headerName: 'Name',
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName} ${params.row.neighborhoodName}`;
    },
    filterable: true,
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <div className={styles.mobileNameNeighborhoodCell}>
          <Typography variant='body2' component='div'>{params.row.firstName} {params.row.lastName}</Typography>
          <Typography variant='caption' component='div'>{params.row.neighborhoodName}</Typography>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: (params) => {
      if (params.row.status === ParticipantStatus.ACTIVE) {
        return 'Confirmed';
      }
      return `${params.row.status}`;
    },
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (<Status status={params.row.status} size={10} marginRight={5} fontSize={11}/>);
    },
  },
  {
    field: 'serviceDate',
    headerName: 'Service Date',
    valueGetter: (params) => {
      if (params.row.recurring) {
        return 'No service date recurring';
      }
      if (!params.row.serviceDate) {
        return 'No service date';
      }
      if (params.row.endServiceDate) {
        return `${formatDateRangeLocalDate(params.row.serviceDate, params.row.endServiceDate)}`;
      }
      return `${formatLongMonthDayYear(params.row.serviceDate)} ${params.row.serviceDate}`;
    },
    minWidth: 100,
    flex: 1,
    renderCell: (params:any) => {
      if (params.row.recurring) {
        return (
          <div className={styles.mobileNameNeighborhoodCell}>
            <Typography variant='body2' component='div'>No service date</Typography>
            <Typography variant='caption' component='div'>Recurring</Typography>
          </div>
        );
      }
      if (params.row.endServiceDate) {
        return (
          <div style={{ textAlign: 'left' }}>
            <Typography variant='caption' component='div'>{formatLongMonthDayYear(params.row.serviceDate)}</Typography>
            <Typography variant='caption' component='div'>{formatLongMonthDayYear(params.row.endServiceDate)}</Typography>
          </div>
        );
      }
      return (<Typography variant='body2' component='div' className={styles.mobileServiceDate}>{formatLongMonthDayYear(params.row.serviceDate)}</Typography>);
    },
  },
];

export function getColumnData(breakpoint):any {
  let columns;
  let columnTotalWidth = 0;
  if (breakpoint) {
    columns = mobileColumns;
    columnTotalWidth = mobileColumns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
  } else {
    columns = desktopColumns;
    columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0) +1;
  }
  return { columns, columnTotalWidth };
}
