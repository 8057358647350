import Axios, { AxiosResponse } from 'axios';
import { IProviderSupportRequest, IServiceProvider } from 'model/serviceProvider';
import { ITermsAndConditionsData } from 'model/termsAndConditions';

export function getServiceProviderById(providerId:string) {
  return Axios.get(`${window.REACT_APP_PAYMENTS_URI}/serviceProvider/${providerId}`);
}

export function findServiceProviderById(providerId:string):Promise<AxiosResponse<any, any>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/serviceProvider/v2/find/${providerId}`);
}

export function anonymousFindServiceProviderById(providerId:string):Promise<AxiosResponse<IServiceProvider>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/serviceProvider/v2/public/find/${providerId}`);
}

export function findServiceProviders() {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/serviceProvider/v2/findAll`);
}

export function findOwned():Promise<AxiosResponse<IServiceProvider[]>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/serviceProvider/v2/findOwned`);
}

export function requestSupport(data:IProviderSupportRequest) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/serviceProvider/v2/requestSupport`, data);
}

export function getTermsAndConditions(providerId:string):Promise<AxiosResponse<ITermsAndConditionsData>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/serviceProvider/v2/${providerId}/termsAndConditions`);
}

export function saveTermsAndConditions(serviceProviderId:string, data:ITermsAndConditionsData) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/serviceProvider/v2/${serviceProviderId}/saveTermsAndConditions`, data);
}