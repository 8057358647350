import { Grid } from '@mui/material';
import DiscountTableRow from './DiscountTableRow';
import styles from './groupDiscountTable.module.css';
import { DiscountScheduleTypes } from 'model/ancillary';

const getTableColumnWidth = (isTwoColumnTable:boolean) => {
  if (isTwoColumnTable) {
    return '50%';
  } else {
    return '33%';
  }
};

function getDiscountColumnHeaderName(discountScheduleType:string) {
  if (!discountScheduleType) {
    return 'Discount';
  }
  if (discountScheduleType === DiscountScheduleTypes.DollarPerCustomer.value) {
    return 'Price';
  } else if (discountScheduleType === DiscountScheduleTypes.DollarSavePerCustomer.value) {
    return 'Discount';
  } else if (discountScheduleType === DiscountScheduleTypes.PercentSavePerCustomer.value) {
    return 'Discount';
  }
  return '';
}

export default function DiscountTable({ neighborhoodOffering, pendingCustomers, confirmedCustomers, newServiceInstanceFlag, fullWidth }: any) {

  const discountSchedule = neighborhoodOffering.discountSchedule;
  const discountScheduleTable = neighborhoodOffering.discountTable;
  const discountScheduleType = neighborhoodOffering.discountSchedule.type;
  const discountColumnHeaderName = getDiscountColumnHeaderName(discountScheduleType);

  if (discountScheduleTable && discountScheduleTable.length > 0) {
    const rowCount = discountScheduleTable.length - 1;
    if (discountScheduleTable[rowCount].count.toString().indexOf('+') < 0 ) {
      discountScheduleTable[rowCount].displayCount = discountScheduleTable[rowCount].count + '+';
    }

    if (discountScheduleTable[0].count > 1) {
      discountScheduleTable[0].displayCount = discountScheduleTable[0].count + ' (or fewer)';
    }
  }

  const tableSize = discountScheduleTable.length;
  if (tableSize == 0) {
    return null;
  }
  const maxDiscountCount = discountScheduleTable[tableSize-1].key;
  const totalCustomerCount = pendingCustomers.length + confirmedCustomers.length;

  let isTwoColumnTable = newServiceInstanceFlag || totalCustomerCount === 0;

  return (
    <Grid item xs={12} md={12} lg={fullWidth ? 12 : 8}>
      <table className={`discountScheduleTable ${styles.discountScheduleTable}`}>
        <tbody>
          <tr className={styles.discountScheduleTableHeaderRow}>
            <th className={styles.discountAmtTableHeader}># of Neighbors</th>
            <th className={styles.discountAmtTableHeader}>{discountColumnHeaderName} ({discountSchedule.priceSuffix})</th>
            {!isTwoColumnTable &&
                <th className={styles.discountAmtTableHeader}>Neighbors</th>
            }
          </tr>

          { discountScheduleTable.map((x:any, i) => {
            return (
              <DiscountTableRow
                key={i}
                discountTableRow={x}
                index={i}
                isNewServiceInstance={newServiceInstanceFlag}
                tableColumnWidth={getTableColumnWidth(isTwoColumnTable)}
                isTwoColumnTable={isTwoColumnTable}
                maxDiscountCount={maxDiscountCount}
                pendingCustomers={pendingCustomers}
                confirmedCustomers={confirmedCustomers}
              />
            );
          })
          }
        </tbody>
      </table>
    </Grid>
  );
}