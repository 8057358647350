import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export interface IHookRadioFieldProps {
  name:string;
  horizontal?:boolean;
  defaultValue?:string;
  shouldRender?:boolean;
  required?:boolean;
  validationRules?: any;
  children:any;
}

export default function HookRadioField({ name, required, validationRules, horizontal, defaultValue, shouldRender, children }:IHookRadioFieldProps) {
  const methods = useFormContext();
  const { control } = methods;
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    ...validationRules,
  };
  const { isError, getErrorMessage } = useErrorState(methods);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => {
        if (shouldRender === false) {
          return null as any;
        }
        return (
          <RadioGroup
            row={horizontal}
            name={name}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
          >
            {children}
          </RadioGroup>
        );
      }
      }/>

  );
}