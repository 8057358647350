import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import PageHeader from '../components/SectionHeaders/PageHeader';
import { findAllNeighborhoods } from 'api/neighborhoodApi';

function Neighborhoods () {
  const [neighborhoods, setNeighborhoods] = useState<any[]>([]);

  useEffect(() => {
    findAllNeighborhoods().then((res: any) => {
      setNeighborhoods(res.data);
    }).catch((reason) => console.error(reason));;
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params: any) => {
        return <a href={`https://www.streetfair.app/${params.row.id}`} >{params.value}</a>;
      },
    },
    { field: 'status', headerName: 'Status', width: 250 },
  ];

  const sortModel = [{
    field: 'name',
    sort: 'asc' as any,
  }];

  return (
    <>
      <Container style={{
        marginLeft: '10px',
      }}>
        <Row style={{
          borderBottom: '1px solid #26995F',
          padding: '10px',
          textAlign: 'left',
        }}>
          <PageHeader headerText={'NEIGHBORHOODS'} />
        </Row>
        <DataGrid rows={neighborhoods}
          columns={columns}
          autoHeight={true}

          sortModel={sortModel}
          style={{
            minWidth: '500px',
            maxWidth: '500px',
            marginTop: '16px',
          }}
        />
      </Container>
    </>
  );
}

export default Neighborhoods;
