
export interface IDropdownOption {
  key:string;
  optionValue:string;
  optionSk?:string;
  optionText:string;
  ancillary?:any;
}

export function createDefaultDropdownOption() {
  return { key: 'UNSELECTED', optionValue: 'UNSELECTED', optionText: 'All' };
}

export function createUnselectedDropdownOption() {
  return { key: 'UNSELECTED', optionValue: 'UNSELECTED', optionText: 'Unselected' };
}


export function createPlaceholderDropdownOption() {
  return { key: 'PLACEHOLDER', optionValue: 'PLACEHOLDER', optionText: 'Type for address autocomplete' };
}

export const UNSELECTED_OPTION = 'UNSELECTED';