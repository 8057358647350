import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import styles from './layout.module.css';

const loadingStateMap:Map<string, LoadingState> = new Map();

let listenerMap:Map<string, any[]> = new Map();

interface LoadingState {
  isLoading:boolean;
}

export default function Loading({ children, loadingKey, size, absolute }:any) {
  const el = useRef(null);
  const { getLoadingState } = useLoading(loadingKey);
  const loadingState = getLoadingState();
  return (
    <div className={loadingState.isLoading ? styles.loadingContent : ''}>
      {children}
      {loadingState.isLoading && (

        <CircularProgress
          className={absolute ? styles.loadingAbsolute : styles.loading}
          size={size || 200}
          style={{
            top: `calc(50% - ${(size || 200) / 2}px`,
            left: `calc(50% - ${(size || 200) / 2}px`,
          }}
        />

      )}
    </div>
  );
}

export function useLoading(loadingKey: string) {
  const setState = useState(loadingStateMap)[1];

  useEffect(() => {
    if (listenerMap.has(loadingKey)) {
      listenerMap.get(loadingKey)?.push(setState);
    } else {
      listenerMap.set(loadingKey, [setState]);
    }
    return () => {
      const nextListeners = listenerMap.get(loadingKey)?.filter(li => li !== setState);
      if (nextListeners) {
        listenerMap.set(loadingKey, nextListeners);
      }
    };
  }, []);

  function onLoading() {
    const loadingState = { isLoading: true };
    _loadingStateChange(loadingState);
  }

  function doneLoading(gracePeriod?:number) {
    if (gracePeriod) {
      setTimeout(() => {
        const loadingState = { isLoading: false };
        _loadingStateChange(loadingState);
      }, gracePeriod);
    } else {
      const loadingState = { isLoading: false };
      _loadingStateChange(loadingState);
    }
  }

  function _loadingStateChange(loadingState:LoadingState) {
    if (!loadingKey) {
      console.error('Must supply a loading state.');
    }
    loadingStateMap.set(loadingKey, loadingState);
    const listeners = listenerMap.get(loadingKey);
    if (listeners) {
      for (const li of listeners) {
        li(loadingState);
      }
    }
  }

  function getLoadingState() {
    const loadingState = loadingStateMap.get(loadingKey);
    if (!loadingState) {
      return { isLoading: false, notFound: true };
    }
    return loadingState;
  }

  return {
    getLoadingState, onLoading, doneLoading,
  };
}