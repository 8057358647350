import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { Box, Checkbox, FormControlLabel, Grid, TextField, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isEqual, isValid } from 'date-fns';
import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';
import styles from './customerAccordion.module.css';
import { IMoveGroupDealError, IMoveToGroupDealForm } from 'model/groupDeal';
import useGroupDeal from 'pages/groupDeal/hookStore/useGroupDeal';
import { formatLongMonthDayYear } from 'util/dateUtil';
import { DemoUtil } from 'util/demoUtil';

interface CustomProps {
  onChange: (event:any) => void;
  name: string;
}


const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref:any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='[#]n'
        scale={2}
        signed={false}
        radix=','
        mapToRadix={['.']}
        definitions={{
          '#': /[1-9]/,
          'n': /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any, mask:any) => {
          return onChange({ target: { name: props.name, value: mask._unmaskedValue } });
        }
        }
        overwrite
      />
    );
  },
);
export interface ICustomerAccordionFieldsProps{
  shouldShowMaxCustomerCount:boolean;
  data: IMoveToGroupDealForm;
  error:IMoveGroupDealError;
  setData:Function;
  setError:Function;
}

function renderValue(serviceDate:Date[]) {
  if (serviceDate.length > 1) {
    let startDate = serviceDate[0];
    let endDate = serviceDate[1];
    if (startDate && endDate && isValid(startDate) && isValid(endDate) && !isEqual(startDate, endDate)) {
      return `${formatLongMonthDayYear(startDate)} - ${formatLongMonthDayYear(endDate)}`;
    } else if (startDate && isValid(startDate)) {
      return `${formatLongMonthDayYear(startDate)}`;
    }
  } else if (serviceDate.length > 0) {
    let startDate = serviceDate[0];
    if (isValid(startDate)) {
      return `${formatLongMonthDayYear(startDate)}`;
    }
  }
  return '';
}

export default function CustomerAccordionFields({ shouldShowMaxCustomerCount, data, setData, error, setError }:ICustomerAccordionFieldsProps) {
  const theme = useTheme();
  const { isProviderAdmin } = useGroupDeal();
  const [serviceDate, setServiceDate] = useState<[any, any]>([null, null]);
  const [maxCustomerCount, setMaxCustomerCount] = useState<string>(`${data.maxCustomerCount ?? ''}`);
  const [shouldSendPriceDropAlert, setShouldSendPriceDropAlert] = useState<boolean>(!isProviderAdmin);
  const [shouldSendHappeningSoonAlert, setShouldSendHappeningSoonAlert] = useState<boolean>(!isProviderAdmin);
  function handleDateChange(value) {
    setServiceDate(value);
    if (value.length > 1) {
      setData({
        ...data,
        startDate: value[0],
        endDate: value[1],
      });
    } else if (value.length === 1) {
      setData({
        ...data,
        startDate: value[0],
        endDate: null,
      });
    } else {
      setData({
        ...data,
        startDate: null,
        endDate: null,
      });
    }
  }
  function handleMaxCustomerCountChange(value) {
    let parsedValue = parseInt(value);
    if (isNaN(parsedValue) || parsedValue <= 0) {
      setError({
        ...error,
        maxCustomerCountError: 'Must supply a max customer count greater than 0',
      });
      setMaxCustomerCount('');
    } else {
      setMaxCustomerCount(value);
      setError({
        ...error,
        maxCustomerCountError: null,
      });
      setData({
        ...data,
        maxCustomerCount: parsedValue,
      });
    }
  }
  return (
    <Grid container item sx={{ padding: '4px' }}>
      <Grid item xs className={styles.dateRangePicker}>
        <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: 'Service Dates', end: 'End Date' }}>
          <MobileDateRangePicker
            DialogProps={{
              PaperProps: { className: 'newServiceDateModalBody' },
            }}
            value={serviceDate}
            onChange={(newValue) => {
              handleDateChange(newValue);
            }}
            renderInput={({ inputProps, ...startProps }, endProps) => {
              const startValue = inputProps?.value;
              delete inputProps?.value;
              return (
                <>
                  <TextField
                    sx={{
                      '& .MuiFilledInput-root': {
                        padding: '0',
                      },
                      '& .MuiFilledInput-input': {
                        paddingLeft: '0',
                      },
                      '& .MuiInputLabel-root': {
                        transform: 'translate(0px, 7px) scale(0.75)',
                      },
                      '& .MuiFormHelperText-root.Mui-error': {
                        marginLeft: '0',
                      },
                    }}
                    variant='filled'
                    fullWidth
                    InputProps={{
                      endAdornment: <InsertInvitationIcon sx={{ color: theme.palette.primary.main }}/>,
                    }}
                    {...startProps}
                    inputProps={inputProps}
                    error={error.serviceDatesError !== null}
                    helperText={error.serviceDatesError}
                    value={renderValue(serviceDate)}

                  />
                </>
              );
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        {shouldShowMaxCustomerCount && (
          <TextField
            fullWidth
            required
            variant='standard'
            label={'Max Customer Count'}
            error={error.maxCustomerCountError !== null}
            helperText={error.maxCustomerCountError}
            value={maxCustomerCount}
            onChange={(e) => handleMaxCustomerCountChange(e.target.value)}
            InputProps={{
              inputComponent: TextMaskCustom as any,
            }}
          />
        )}
      </Grid>
      <Grid container item xs={12} justifyContent='flex-start' sx={{ padding: '10px' }}>
        {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
          <>
            {/* <FormControlLabel control={
              <Checkbox

                checked={!!shouldSendPriceDropAlert}
                onChange={(e) => {
                  setShouldSendPriceDropAlert(e.target.checked);
                }}
              />
            }
            label='Send Price Drop Alert'
            /> */}
            <FormControlLabel control={
              <Checkbox

                checked={!!shouldSendHappeningSoonAlert}
                onChange={(e) => {
                  setShouldSendHappeningSoonAlert(e.target.checked);
                }}
              />
            }
            label='Send Happening Soon Alert'
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}