import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid, Typography } from '@mui/material';
import SecondaryColorButton from 'components/buttons/SecondaryColorButton';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import styles from './groupDealShare.module.css';

export interface IDirectLinkProps {
  nsoiId: string;
  nsoId: string;
  isActiveNeighborhood: boolean;
}

function getMessageText({ nsoId, nsoiId, isActiveNeighborhood }:IDirectLinkProps) {
  return `${window.REACT_APP_STREETFAIR_URL}/neighborhood-service-offering/${nsoId}/service-instance/${nsoiId}${isActiveNeighborhood ? '' : '?preview=true'}`;
}

export default function DirectLink({ nsoiId, nsoId, isActiveNeighborhood }:IDirectLinkProps) {
  const { trackButtonClick } = useStreetfairAnalytics();
  return (
    <Grid container textAlign='left'>
      <Grid item xs={12}>
        <Typography variant='subtitle1' className={styles.directLinkTitle}>Direct Link to Service Date</Typography>
      </Grid>
      <Grid container item xs={12} className={styles.directLink} alignItems='center' justifyContent='space-between'>
        <Grid item xs={12}>
          <Typography variant='body1'>{window.REACT_APP_STREETFAIR_URL}/neighborhood-service-offering/{nsoId}/service-instance/{nsoiId}</Typography>
        </Grid>
        <Grid item xs textAlign='right'>
          <SecondaryColorButton
            variant='contained'
            type='button'
            onClick={() => {
              void trackButtonClick(ButtonClickTypes.GROUP_DEAL_CONFIRMATION_COPY_LINK);
              void navigator.clipboard.writeText(getMessageText({ nsoId, nsoiId, isActiveNeighborhood }));
            }}
          >
            <ContentCopyIcon className={styles.copyIcon}/>Copy URL
          </SecondaryColorButton>
        </Grid>
      </Grid>
    </Grid>
  );
}