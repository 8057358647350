import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePostConfirmModal from '../hookStore/usePostConfirmModal';
import PostConfirmStepper from './components/PostConfirmStepper';
import SlideDescription from './components/SlideDescription';
import SlideTitle from './components/SlideTitle';


export default function LoadingSlide() {


  return (
    <Grid container justifyContent='center' alignItems='center' sx={{
      width: {
        xs: '100%',
        sm: '600px',
      },
      height: {
        xs: '100%',
        sm: '400px',
      },
    }}>
      <Grid container item
        justifyContent='center'
        alignItems='center'
      >
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </Grid>
    </Grid>
  );
}