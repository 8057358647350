import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './formComponents.module.css';
import { HookTextField } from 'components/reactHookForm';

export default function ZipCodeField() {
  const { isEditing } = useGroupDeal();
  if (isEditing) {
    return null;
  }
  return (
    <HookTextField
      disabled={isEditing}
      className={styles.zipCodeField}
      name='zipCode'
      label='Zip Code'
      maxLength={5}
      validationRules={{
        validate: function(value) {
          if (isEditing) {
            //can't select a new neighborhood while editing so normal validators do not apply
            return true;
          }
          if (value.toString().length != 5) {
            return 'Zip code must be 5 digits';
          }
          const matched = !!value.toString().match(/^[0-9]{1,10}$/g);
          if (!matched) {
            return 'Zip code may only have numbers';
          }

          return true;
        },
      }}
    />
  );
}