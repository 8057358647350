import { Typography } from '@mui/material';
import styles from '../postConfirmModal.module.css';

export default function SlideDescription({ children }:any) {

  return (
    <Typography
      variant='body1'
      className={styles.slideDescription}
      component='div'
      sx={{
        '&&': {
          padding: {
            xs: '0 20px 20px 20px',
            sm: '0 0px 15px 0px',
          },
          textAlign: {
            xs: 'center',
            sm: 'left',
          },
          maxWidth: '600px',
        },
      }}
    >
      {children}
    </Typography>
  );
}

