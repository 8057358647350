import ServiceCard from './ServiceCard';
import { IAvatarIconStyleOverride } from 'components/cardAvatar';
import { createEmptyServiceProvider, IServiceProvider } from 'model/serviceProvider';


export interface IServiceCardProps {
  id:string;
  altColor?:boolean;
  customers?: IServiceCardCustomer[];
  customerCount?:number;
  beginServiceDate?:string;
  endServiceDate?:string;
  cutoffDayCount?:number;
  serviceProvider?:IServiceProvider;
  serviceType?:string;
  maxCustomerCount?:number;
  maxAvatarIcons?:number;
  avatarIconStyleOverride?: IAvatarIconStyleOverride;
  onClick?:any;
  className?:string;
}


export interface IServiceCardCustomer {
  id?:string;
  firstName?:string;
  lastName?:string;
}

export function createEmptyServiceCardProps():IServiceCardProps {
  return {
    id: '',
    altColor: false,
    customers: [],
    customerCount: 0,
    beginServiceDate: '',
    endServiceDate: '',
    cutoffDayCount: 0,
    serviceProvider: createEmptyServiceProvider(),
    serviceType: '',
    maxCustomerCount: 0,
    maxAvatarIcons: 0,
    onClick: () => ({}),
    className: '',
  };
}

export default ServiceCard ;