import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IPageBoxProps } from '../PageBox';

type HomeData = {
  pageBoxes: IPageBoxProps[];
}

const { get, update, registerListener, unregisterListener } = createStore<HomeData>('home', {
  pageBoxes: [],
});

const loadingKey = 'home';

export default function useHome() {
  const setState = useState(get())[1];
  const { serviceProviderId } = useParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();
  const { reviewsManagement } = useFlags();
  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    await createPageBoxes();
  }

  async function createPageBoxes() {
    const pageBoxes:IPageBoxProps[] = [];
    pageBoxes.push(createCustomerPageBox());
    pageBoxes.push(createGroupDealsPageBox());
    if (reviewsManagement) {
      pageBoxes.push(createReviewsPageBox());
    }
    update({
      ...get(),
      pageBoxes,
    });
  }

  function createCustomerPageBox():IPageBoxProps {
    return {
      title: 'Customers',
      buttonText: 'Manage Customers',
      description: 'Manage customers, contact, and service information.',
      navigation: () => navigate(`/v1/${serviceProviderId}/customers`),
    };
  }

  function createGroupDealsPageBox():IPageBoxProps {
    return {
      title: 'Group Deals',
      buttonText: 'Manage Group Deals',
      description: 'Create a neighborhood group deal or manage existing group deals.',
      navigation: () => navigate(`/v1/${serviceProviderId}/groupDeals`),
    };
  }

  function createReviewsPageBox():IPageBoxProps {
    return {
      title: 'Reviews',
      buttonText: 'See Reviews',
      description: 'View reviews left by your customers.',
      navigation: () => navigate(`/v1/${serviceProviderId}/reviews`),
    };
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    init,
    createPageBoxes,
  };
}