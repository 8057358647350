import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, Rating, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SelectPillFilter } from 'components/PillFilter';
import ElementPillFilter from 'components/PillFilter/ElementPillFilter';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IDropdownOption } from 'model/dropdown';
import useReviews from './hookStore/useReviews';
import styles from './reviews.module.css';

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface MultiToolbarProps {
  quickSearchStorageKey?:string;
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  placeholder:string;
}

export default function MultiToolbar({ quickSearchStorageKey, value, placeholder, onChange, clearSearch }: MultiToolbarProps) {
  const {
    refresh,
    filteredReviews,
    filter,
    neighborhoodOptions,
    ratingOptions,
    resetFiltersToDefault,
    onTableFilterChange,
  } = useReviews();
  const { reviewsManagement } = useFlags();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { trackButtonClick } = useStreetfairAnalytics();

  const filteredNeighborhoods = neighborhoodOptions.filter(x => x.key === filter.neighborhoodId);
  const filteredRating = ratingOptions.filter(x => x.key === filter.rating?.toString());

  let filterText = 'Reviews';
  let groupDealCount = filteredReviews.length;

  return (
    <Grid container alignItems='center' justifyContent='space-between' sx={{ padding: '12px' }}>
      <Grid container item
        flexShrink={2}
        xs={12} sm={12} md lg
        order={{ xs: 1, md: 0 }}
        alignItems='center'
        justifyContent={{ xs: 'left', sm: 'right', md: 'left' }}
        className={mdDown ? styles.mobilePillFilterWrapper : styles.pillFilterWrapper}
        spacing={1}>
        <Grid item xs='auto' className={styles.pillFilter}>
          <SelectPillFilter
            id="neighborhood-pill-filter"
            handleSelection={async (e) => {
              try {
                void trackButtonClick(ButtonClickTypes.REVIEWS_TABLE_NEIGHBORHOOD_DROPDOWN);
              } catch (err) {
                console.log(err);
              }
              await onTableFilterChange('neighborhoodId', e.target.value);
            }}
            options={neighborhoodOptions}
            selection={filter.neighborhoodId ?? ''}
            renderValue={() => `Neighborhood: ${filteredNeighborhoods[0].optionText}`}
          />
        </Grid>
        <Grid item xs='auto' className={styles.pillFilter}>
          <ElementPillFilter
            id="rating-pill-filter"
            handleSelection={async (e) => {
              try {
                void trackButtonClick(ButtonClickTypes.REVIEWS_TABLE_RATING_FILTER);
              } catch (err) {
                console.log(err);
              }
              await onTableFilterChange('rating', e.target.value);
            }}
            options={ratingOptions}
            selection={filter.rating?.toString() ?? ''}
            renderValue={(val) => {
              return (
                <Grid container alignItems='center'>
                  <Rating name="read-only" size={'small'} value={parseFloat(val)} readOnly /> &amp; Down
                </Grid>
              );
            }}
            renderOption={(o:IDropdownOption) => (
              <Grid container alignItems='center'>
                <Rating name="read-only" size={'small'} value={parseFloat(o.optionValue)} readOnly /> &amp; Down
              </Grid>
            )}
          />
        </Grid>
      </Grid>
      <Grid container item xs md='auto' alignItems='center' justifyContent='end'>
        <TextField
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={placeholder || 'Search...'}
          InputProps={{
            endAdornment: (
              !value ? <SearchIcon fontSize="small" /> :
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: value ? 'visible' : 'hidden' }}
                  onClick={clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
            ),
          }}
          sx={{
            'width': {
              xs: 1,
              sm: 'auto',
            },
            'minWidth': {
              xs: 1,
              sm: '400px',
            },
            'background': '#F1F1F1',
            'borderRadius': '4px',
            'm': (mTheme) => mTheme.spacing(1, 0.5, 1.5),
            '& .MuiOutlinedInput-input': {
              padding: '12px',
            },
            '&&&&': {
              margin: 0,
            },
          }}
        />

      </Grid>
      {filterText && (
        <Grid container order={2} justifyContent={{ xs: 'left', sm: 'right', md: 'left' }}>
          <Typography variant='body1' component='div' className={styles.groupDealCount}><span style={{ fontWeight: 'bold' }}>{groupDealCount} </span>{filterText}</Typography>
        </Grid>
      )}
    </Grid>
  );
}