import { DateRange } from '@mui/x-date-pickers-pro';
import { findByServiceProviderAndServiceInstance } from 'api/customerApi';
import { findDiscountSchedulesByProvider, getDiscountTable } from 'api/discountScheduleApi';
import { getSelfRoles } from 'api/individualApi';
import { findAllNeighborhoods } from 'api/neighborhoodApi';
import { findNSOs, findNeighborhoodServiceOfferingDetails } from 'api/neighborhoodServiceOfferingApi';
import { findForProviderByNeighborhoodServiceOffering, findServiceInstanceById, saveGroupDeal } from 'api/serviceInstanceApi';
import { findServiceOfferingsByServiceProvider } from 'api/serviceOfferingApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import { isEqual, isPast, parseISO } from 'date-fns';
import useServiceCategoryTypeDisplay from 'hooks/useServiceCategoryTypeDisplay';
import { useFlags } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash.debounce';
import { CadenceType, ParticipantStatus, ServiceInstanceType } from 'model/ancillary';
import { ICustomer, IGroupDealCustomer } from 'model/customer';
import { IDiscountSchedule } from 'model/discount';
import { IDropdownOption } from 'model/dropdown';
import { ICustomerAccordionState, IDiscountTableRequest, IGroupDealForm, NO_MAX_CUSTOMER_COUNT_DEFAULT_VALUE, createEmptyCustomerAccordionState, createEmptyGroupDealForm } from 'model/groupDeal';
import { INeighborhood, NeighborhoodStatus } from 'model/neighborhood';
import { IMovableGroupDealsDto, INeighborhoodServiceOfferingInstance, createEmptyNeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import { IServiceOffering } from 'model/serviceOffering';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { findFirstMatch } from 'util/arrayUtil';
import { createDateIgnoreTimezoneNullPlaceholder } from 'util/dateUtil';
import { DemoUtil } from 'util/demoUtil';
import { containsProviderAdmin } from 'util/role';
import { sortByPropertyAsc } from 'util/sortFunctions';
import { IMatchingNsoData, store } from './groupDealStore';
import useAddressAutocomplete from './useAddressAutocomplete';

const debounceGetDiscountTable = debounce(async (serviceProviderId, serviceOfferingId, data, onComplete: (data: any) => void): Promise<any> => {
  const discountTableRes = await getDiscountTable(serviceProviderId, serviceOfferingId, data);
  onComplete(discountTableRes);
}, 300);

const { get, update, registerListener, unregisterListener } = store;

const loadingKey = 'groupDeal';
const neighborhoodLoadingKey = 'neighborhoodLoading';

export default function useGroupDeal() {
  const setState = useState(get())[1];
  const { serviceProviderId, groupDealId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { onLoading: onNeighborhoodLoading, doneLoading: doneNeighborhoodLoading } = useLoading(neighborhoodLoadingKey);
  const navigate = useNavigate();
  const { createSuccessToast, createErrorToast } = useToast();
  const { typedAddresses } = useAddressAutocomplete();
  const { groupDealMgmtV2 } = useFlags();
  const { getServiceTypeDisplay } = useServiceCategoryTypeDisplay();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    const promises: any[] = [];
    onLoading();
    if (!serviceProviderId) {
      console.error('service provider id did not exist');
      return;
    }
    if (searchParams.has('discountOpen')) {
      const discountOpen = searchParams.get('discountOpen') as string;
      if (discountOpen === 'true') {
        setCustomersToggled(false);
      }
    }
    const rolesRes = await getSelfRoles();
    const isProviderAdmin = containsProviderAdmin(rolesRes.data);

    promises.push(findServiceOfferingsByServiceProvider(serviceProviderId));
    promises.push(findDiscountSchedulesByProvider(serviceProviderId));
    if (groupDealId && groupDealId !== 'new') {
      promises.push(findServiceInstanceById(serviceProviderId, groupDealId));
      promises.push(findByServiceProviderAndServiceInstance(serviceProviderId, groupDealId));
      const [serviceOfferingsRes, discountScheduleRes, nsoiRes, customersRes] = await Promise.all(promises);
      const nsoDetailsRes = await findNeighborhoodServiceOfferingDetails(nsoiRes.data.neighborhoodServiceOfferingId, groupDealId);
      let customers = customersRes.data;
      const _customerAccordionStateMap = new Map<string, ICustomerAccordionState>();
      for (let i = 0; i < customers.length; i++) {
        _customerAccordionStateMap.set(customers[i].id, createEmptyCustomerAccordionState());
      }
      let placeholderCustomers = [];
      if (!isProviderAdmin) {
        placeholderCustomers = customers.filter(x => x.isPlaceholderCustomer);
        customers = customers.filter(x => !x.isPlaceholderCustomer);
      }

      update({
        ...get(),
        isProviderAdmin,
        customerAccordionStateMap: _customerAccordionStateMap,
        isTerritoryServiceOfferingInstance: nsoiRes.data.zipCodeTerritory != null,
        pendingCustomers: customers.filter(x => x.status === ParticipantStatus.PENDING),
        placeholderCustomers,
        discountSchedules: discountScheduleRes.data,
        discountScheduleOptions: convertForDiscountScheduleSelect(discountScheduleRes.data),
        activeOrCompleteCustomers: customers.filter(x => x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE),
        formData: createFormData(nsoiRes.data, nsoDetailsRes.data, customers),
        nsoisWithSameNso: [],
        nsoDetails: nsoDetailsRes.data,
        nsoi: nsoiRes.data,
        serviceOfferings: serviceOfferingsRes.data,
        serviceOfferingToServiceTypeOptions: convertForServiceOfferingSelect(serviceOfferingsRes.data, isProviderAdmin),
        neighborhoodOptions: [{
          key: nsoiRes.data.neighborhoodId,
          optionValue: nsoiRes.data.neighborhoodId,
          optionText: nsoiRes.data.neighborhoodName,
        }],
        isEditing: true,
        editingClicked: false,
        warningMessage: null,
      });
    } else {
      const [serviceOfferingsRes, discountScheduleRes] = await Promise.all(promises);
      update({
        ...get(),
        isProviderAdmin,
        isTerritoryServiceOfferingInstance: false,
        editingClicked: true,
        pendingCustomers: [],
        placeholderCustomers: [],
        activeOrCompleteCustomers: [],
        formData: createEmptyGroupDealForm(groupDealMgmtV2 ? 5 : 10),
        nsoDetails: null,
        nsoisWithSameNso: [],
        nsoi: createEmptyNeighborhoodServiceOfferingInstance(),
        serviceOfferings: serviceOfferingsRes.data,
        discountSchedules: discountScheduleRes.data,
        discountScheduleOptions: convertForDiscountScheduleSelect(discountScheduleRes.data),
        serviceOfferingToServiceTypeOptions: convertForServiceOfferingSelect(serviceOfferingsRes.data, isProviderAdmin),
        neighborhoodOptions: [],
        isEditing: false,
        warningMessage: null,
      });
    }
    doneLoading();
  }

  function filterCurrentServiceInstances(currentNsoi: INeighborhoodServiceOfferingInstance, nsois: INeighborhoodServiceOfferingInstance[]): INeighborhoodServiceOfferingInstance[] {
    if (!nsois || nsois.length === 0) {
      return [];
    }
    return nsois.filter(x => {
      if (x.serviceInstanceType === ServiceInstanceType.PLACEHOLDER) {
        return true;
      }
      if (x.id === currentNsoi.id) {
        return false;
      }
      if (x.serviceDate && x.endServiceDate) {
        return !isPast(parseISO(x.endServiceDate));
      } else if (x.serviceDate) {
        return !isPast(parseISO(x.serviceDate));
      }
      return false;
    });
  }

  function createFormData(nsoi: INeighborhoodServiceOfferingInstance, nsoDetails:any, customers: ICustomer[]): IGroupDealForm {
    let serviceDate = createDateIgnoreTimezoneNullPlaceholder(nsoi.serviceDate);
    let endServiceDate = nsoi.endServiceDate !== null ? createDateIgnoreTimezoneNullPlaceholder(nsoi.endServiceDate) : null;
    let serviceDates = nsoi.endServiceDate !== null ? [serviceDate, endServiceDate] : [serviceDate, null];

    return {
      id: nsoi.id,
      customers,
      maxCustomerCount: nsoi.maxCustomerCount || customers.length || 10,
      neighborhoodId: nsoi.neighborhoodId,
      neighborhoodName: nsoi.neighborhoodName,
      serviceDates: serviceDates as DateRange<Date>,
      serviceDate: serviceDate,
      endServiceDate: endServiceDate,
      serviceOfferingId: nsoi.serviceOfferingId,
      serviceTypeName: nsoi.serviceTypeName,
      discountScheduleKey: `${nsoi.discountScheduleId}#${nsoi.discountScheduleVersion}`,
      discountScheduleId: nsoi.discountScheduleId,
      discountScheduleVersion: nsoi.discountScheduleVersion,
      nsoPrice: nsoDetails.price !== null && nsoDetails.price !== undefined ? nsoDetails.price.toString() : '',
      vendorDriven: nsoi.origin === 'GROUP_DEAL_MANAGEMENT_ORIGIN',
      territoryEnabled: nsoi.zipCodeTerritory != null,
      superDeal: nsoi.serviceInstanceType === ServiceInstanceType.SUPER_DEAL,
      maxCustomerSwitchToggled: nsoi.maxCustomerCount === NO_MAX_CUSTOMER_COUNT_DEFAULT_VALUE,
      shouldSendHappeningSoonAlert: false,
      shouldSendPriceDropAlert: false,
      shouldCloseGroupDeal: nsoi.closedOffDate !== null,
    };
  }

  async function save(formData: IGroupDealForm) {
    update({
      ...get(),
      warningMessage: null,
    });
    const { serviceOfferings, isEditing } = get();
    let matchingServiceOfferings = serviceOfferings.filter(x => x.id === formData.serviceOfferingId);
    if (!isEditing && (matchingServiceOfferings.length === 0 || !matchingServiceOfferings[0].defaultDiscountScheduleId)) {
      createErrorToast('Unable to save. Please contact support.');
      return Promise.resolve();
    }
    let matchingServiceOffering = matchingServiceOfferings[0];
    const dataToSave = convertDataForSave(formData, matchingServiceOffering);
    onLoading();
    try {
      let savedNsoiId = null;
      if (serviceProviderId) {
        let saveGroupDealRes = await saveGroupDeal(dataToSave, serviceProviderId);
        savedNsoiId = saveGroupDealRes.data?.id;
      }
      doneLoading(300);
      if (savedNsoiId && !isEditing) {
        navigate(`/v1/${serviceProviderId}/groupDeals/${savedNsoiId}/groupDealShare/new`);
      } else {
        navigate(`/v1/${serviceProviderId}/groupDeals`);
      }
    } catch (err: any) {
      if (err?.response?.data?.message?.indexOf('You already have a group deal available') > -1) {
        update({
          ...get(),
          warningMessage: err?.response?.data?.message,
        });
      }
      console.error(err);
      doneLoading();
    }
  }

  function convertDataForSave(formData: IGroupDealForm, matchingServiceOffering: IServiceOffering) {
    const { isEditing, isProviderAdmin, placeholderCustomers } = get();
    let dataToSave = { ...formData };
    if (!isEditing && isProviderAdmin && formData.discountScheduleKey) {
      dataToSave.discountScheduleId = formData.discountScheduleKey.split('#')[0];
    } else if (!isEditing && matchingServiceOffering?.defaultDiscountScheduleId) {
      dataToSave.discountScheduleId = matchingServiceOffering?.defaultDiscountScheduleId;
    }
    dataToSave.serviceDate = formData.serviceDates[0];
    if (_shouldSetEndServiceDate(formData)) {
      dataToSave.endServiceDate = formData.serviceDates[1];
    } else {
      // clicked the same date on the calendar and so we should delete the end service date
      dataToSave.endServiceDate = null;
    }

    dataToSave.customers = dataToSave.customers.map((x, i) => {
      if ((x.streetAddress as any)?.key) {
        return {
          ...x,
          streetAddress: (x.streetAddress as any).optionValue,
        };
      } else if (typedAddresses.has(`customers.${i}.streetAddress`) && typedAddresses.get(`customers.${i}.streetAddress`)) {
        return {
          ...x,
          streetAddress: typedAddresses.get(`customers.${i}.streetAddress`),
        };
      } else {
        return x;
      }
    });
    if (!isProviderAdmin) {
      dataToSave.customers.push(...placeholderCustomers);
    }
    return dataToSave;
  }

  //if there are two service dates that aren't equal then we should set the end service date. Otherwise clear it.
  function _shouldSetEndServiceDate(formData: IGroupDealForm) {
    return formData.serviceDates.length > 1 &&
      formData.serviceDates[0] &&
      formData.serviceDates[1] &&
      !isEqual(formData.serviceDates[0], formData.serviceDates[1]);
  }

  async function searchNeighborhoods(zipCode: string) {
    onNeighborhoodLoading();
    const neighborhoodRes = await findAllNeighborhoods();
    const filtered = neighborhoodRes.data
      .filter(x => x.status != NeighborhoodStatus.APPLICATION_RECEIVED && x.status != NeighborhoodStatus.PENDING)
      .filter(x => x.zipCodes.indexOf(zipCode) > -1)
      .sort(sortByPropertyAsc('name'));
    update({
      ...get(),
      neighborhoodOptions: convertForNeighborhoodSelect(filtered),
    });
    doneNeighborhoodLoading(300);
  }


  async function refreshAvailableNsoisToMove(customer:IGroupDealCustomer) {
    const { nsoi, customerAccordionStateMap } = get();
    if (serviceProviderId) {
      const data:IMovableGroupDealsDto = {
        neighborhoodId: customer.neighborhoodId,
        zipCodeTerritory: nsoi.zipCodeTerritory,
        shouldShowPastDeals: false,
      };
      const nsoisWithSameNsoRes = await findForProviderByNeighborhoodServiceOffering(serviceProviderId, nsoi.neighborhoodServiceOfferingId, data );
      if (serviceProviderId && groupDealId && groupDealId !== 'new') {
        const customersRes = await findByServiceProviderAndServiceInstance(serviceProviderId, groupDealId);
        const pendingCustomers = customersRes.data.filter(x => x.status === ParticipantStatus.PENDING);
        const activeOrCompleteCustomers = customersRes.data.filter(x => x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE);
        update({
          ...get(),
          nsoisWithSameNso: filterCurrentServiceInstances(nsoi, nsoisWithSameNsoRes.data),
          pendingCustomers,
          activeOrCompleteCustomers,
        });

        const currentState = customerAccordionStateMap.get(customer.id);
        if (currentState) {
          updateCustomerAccordion(customer.id, { ...currentState, loading: false });
        }
      } else {
        update({
          ...get(),
          nsoisWithSameNso: filterCurrentServiceInstances(nsoi, nsoisWithSameNsoRes.data),
        });
        const currentState = customerAccordionStateMap.get(customer.id);
        if (currentState) {
          updateCustomerAccordion(customer.id, { ...currentState, loading: false });
        }
      }
    }
  }

  async function getProspectiveDiscountTable(serviceOfferingId: string, data: IDiscountTableRequest) {
    if (serviceProviderId) {
      await debounceGetDiscountTable(serviceProviderId, serviceOfferingId, data, (discountTableRes) => {
        update({
          ...get(),
          prospectiveDiscountTable: discountTableRes.data,
        });
      });

    }
  }

  function convertForServiceOfferingSelect(data: IServiceOffering[], isProviderAdmin:boolean): IDropdownOption[] {
    if (!data || data.length === 0) {
      return [];
    }
    let _isProviderAdmin = isProviderAdmin && !DemoUtil.isDemoMode(isProviderAdmin);
    return data
      .filter(x => {
        if (groupDealId !== 'new') {
          return x.cadenceType !== CadenceType.SUBSCRIPTION_ONLY;
        }
        if (_isProviderAdmin) {
          return x.defaultDiscountScheduleId && x.cadenceType !== CadenceType.SUBSCRIPTION_ONLY;
        } else {
          return x.defaultForServiceType && x.defaultDiscountScheduleId && x.cadenceType !== CadenceType.SUBSCRIPTION_ONLY;
        }
      })
      .map(x => {
        var serviceTypeDisplay = getServiceTypeDisplay(x.serviceCategory, x.serviceType);
        if (_isProviderAdmin) {
          return {
            key: x.id ?? 'UNDEFINED',
            optionValue: x.id ?? 'UNDEFINED',
            optionText: `${x.serviceCategory}:${serviceTypeDisplay} (name:"${x.name}")`,
            tsoiEnabled: x.territoryServiceOfferingEnabled,
          };
        } else {
          return {
            key: x.id ?? 'UNDEFINED',
            optionValue: x.id ?? 'UNDEFINED',
            optionText: `${x.serviceCategory}:${serviceTypeDisplay}`,
            tsoiEnabled: x.territoryServiceOfferingEnabled,
          };
        }
      });
  }

  function convertForDiscountScheduleSelect(data: IDiscountSchedule[]):IDropdownOption[] {
    if (!data || data.length === 0) {
      return [];
    }
    return data.filter(x => x.id && x.sk).map(x => {
      return {
        key: `${x.id ?? ''}#${x.sk ?? ''}`,
        optionValue: `${x.id ?? ''}#${x.sk ?? ''}`,
        optionText: x.name ?? 'UNDEFINED',
      };
    });
  }

  function convertForNeighborhoodSelect(data: INeighborhood[]): IDropdownOption[] {
    if (!data || data.length === 0) {
      return [];
    }
    return data.map(x => {
      return {
        key: x.id,
        optionValue: x.id,
        optionText: x.name,
        ancillary: {
          neighborhood: x,
        },
      };
    });
  }

  function cancel() {
    navigate(`/v1/${serviceProviderId}/groupDeals`);
  }

  function setCustomersToggled(val: boolean) {
    update({
      ...get(),
      customersToggled: val,
    });
  }

  function toggleEditing() {
    const { editingClicked } = get();
    update({
      ...get(),
      editingClicked: !editingClicked,
    });
  }

  /**
   * Only called in case of creating new group deal.
   * Will have discount information when editing a group deal.
   * If the user is creating a super deal, then the discount schedule selected will be used
   * */
  async function findMatchingNsoPricingData(neighborhoodId:string, serviceOfferingId:string, discountScheduleKey:string | null):Promise<IMatchingNsoData> {
    var request = {
      neighborhoodId, serviceOfferingId,
    };
    if (!neighborhoodId || !serviceOfferingId || !serviceProviderId) {
      return {
        discountSchedule: null,
        neighborhoodPrice: null,
      };
    }
    try {
      let nsosRes = await findNSOs(serviceProviderId!, request);
      let nsosWithNeighborhoodPricing = nsosRes.data.filter(x => x.price !== null && x.price !== '');
      let defaultServiceOffering = findDefaultServiceOffering(serviceOfferingId);
      let selectedDiscountScheduleId = discountScheduleKey ? discountScheduleKey.split('#')[0] : null;
      let selectedDiscountSchedule = findCurrentDiscountSchedule(selectedDiscountScheduleId);
      if (nsosWithNeighborhoodPricing.length > 0) {
      //TODO: is this even still needed? don't think we show this nor do many service providers use neighborhood level pricing.
      //take the first nso that has a neighborhood level price
        var nso = nsosWithNeighborhoodPricing[0];
        //price on nso takes precedent as it is the already set neighborhood price. Otherwise we look for the discount schedule set on the NSO and use the price amount from there.
        let nsoDiscountSchedule = findCurrentDiscountSchedule(nso.discountScheduleId);
        return {
          discountSchedule: selectedDiscountSchedule ?? nsoDiscountSchedule,
          neighborhoodPrice: nso.price,
        };
      } else
      if (defaultServiceOffering) {
      // if we couldn't find a matching nso (i.e. NSO does not exist in neighborhood yet), then look at discount schedule for service offering selected
        let defaultDiscountSchedule = findCurrentDiscountSchedule(defaultServiceOffering.defaultDiscountScheduleId ?? null);
        return {
          discountSchedule: selectedDiscountSchedule ?? defaultDiscountSchedule,
          neighborhoodPrice: null,
        };
      }
    } catch (err:any) {
      console.error(err);
    }
    return {
      discountSchedule: null,
      neighborhoodPrice: null,
    };
  }

  function findDefaultServiceOffering(serviceOfferingId:string | null):IServiceOffering | null {
    const { serviceOfferings } = get();
    let matchingServiceOfferings = serviceOfferings.filter(x => x.id === serviceOfferingId && x.defaultForServiceType);
    if (matchingServiceOfferings.length > 0) {
      return matchingServiceOfferings[0];
    }
    return null;
  }

  function findServiceOffering(serviceOfferingId:string | null):IServiceOffering | null {
    const { serviceOfferings } = get();
    return findFirstMatch(serviceOfferings, 'id', serviceOfferingId);
  }

  function doesNeighborhoodHaveTerritoryEnabled(serviceOffering: IServiceOffering | null, neighborhoodId:string) {
    const { neighborhoodOptions } = get();
    if (!serviceOffering?.territoryServiceOfferingEnabled) {
      return false;
    }
    const matching = neighborhoodOptions.filter(x => x.optionValue === neighborhoodId);
    if (matching.length > 0) {
      return matching[0]?.ancillary?.zipCodeTerritory !== null;
    }
    return false;
  }

  function findCurrentDiscountSchedule(discountScheduleId:string | null):IDiscountSchedule | null {
    const { discountSchedules } = get();
    if (discountScheduleId === null) {
      return null;
    }
    let matching = discountSchedules.filter(x => x.id === discountScheduleId && x.gsiSK1 === 'CURRENT');
    if (matching.length > 0) {
      return matching[0];
    }
    return null;
  }

  function updateCustomerAccordion(customerId:string, data:ICustomerAccordionState) {
    const { customerAccordionStateMap } = get();
    const _customerAccordionStateMap = new Map<string, ICustomerAccordionState>(customerAccordionStateMap);
    _customerAccordionStateMap.set(customerId, data);
    update({ ...get(), customerAccordionStateMap: _customerAccordionStateMap });
  }


  function reinitializeCustomerAccordionState(customers:IGroupDealCustomer[]) {
    const _customerAccordionStateMap = new Map<string, ICustomerAccordionState>();
    for (let i = 0; i < customers.length; i++) {
      _customerAccordionStateMap.set(
        customers[i].id,
        {
          expanded: false,
          loading: false,
          shouldShowMoveToGroupDeal: false,
          shouldShowMoveToPlaceholderGroupDeal: false,
        },
      );
    }
    update({ ...get(), customerAccordionStateMap: _customerAccordionStateMap });
  }

  return {
    loadingKey: loadingKey,
    neighborhoodLoadingKey,
    ...get(),
    init,
    save,
    cancel,
    searchNeighborhoods,
    setCustomersToggled,
    toggleEditing,
    refreshAvailableNsoisToMove,
    getProspectiveDiscountTable,
    findMatchingNsoPricingData,
    findServiceOffering,
    doesNeighborhoodHaveTerritoryEnabled,
    updateCustomerAccordion,
    reinitializeCustomerAccordionState,
  };
}