import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { ICustomerServiceDetails, createEmptyServiceItem } from 'model/customer';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import CustomerServiceItemCard from './CustomerServiceItemCard';
import styles from './confirmCustomerDrawer.module.css';
import OneTimeChargeServiceItemCard from './subscriptionSpecific/OneTimeChargeServiceItemCard';

export default function ServiceItemCardList() {
  const formCtx = useFormContext();
  const { fields: serviceItems, append, replace, remove } = useFieldArray({
    control: formCtx.control,
    name: 'serviceItems',
  });
  const {
    fields: oneTimeChargeServiceItems,
    append: appendOneTimeCharge,
    replace: replaceOneTimeCharge,
    remove: removeOneTimeCharge,
  } = useFieldArray({
    control: formCtx.control,
    name: 'oneTimeChargeServiceItems',
  });
  const { customer } = useCustomerDetail();
  const { calculatePriceClientside } = usePriceCalculator();
  const { trackButtonClick } = useStreetfairAnalytics();
  const watchServiceItems = useWatch({ control: formCtx.control, name: 'serviceItems' });
  const watchOneTimeChargeServiceItems = useWatch({ control: formCtx.control, name: 'oneTimeChargeServiceItems' });
  const watchConvertingToCustomer = useWatch({ control: formCtx.control, name: 'convertingToCustomer' });
  const watchProviderAdminConvertToCustomer = useWatch({ control: formCtx.control, name: 'providerAdminConvertToCustomer' });
  const convertingToCustomer = watchConvertingToCustomer || watchProviderAdminConvertToCustomer;
  useEffect(() => {
    //this call is debounced so really not that bad
    void calculatePriceClientside(formCtx.getValues() as ICustomerServiceDetails);
    if (serviceItems.length !== watchServiceItems.length) {
      replace(watchServiceItems);

    }
  }, [watchServiceItems]);
  useEffect(() => {
    if ((!watchConvertingToCustomer && !watchProviderAdminConvertToCustomer) && customer.recurring) {
      replace([watchServiceItems[0]]);
    } else if (watchConvertingToCustomer || watchProviderAdminConvertToCustomer) {
      replaceOneTimeCharge([]);
    }
  }, [watchConvertingToCustomer, watchProviderAdminConvertToCustomer]);

  return (
    <>
      <Grid container item xs={12} justifyContent='center' className={styles.customerServiceCardList}>
        {serviceItems.map((x, i)=> {
          return (
            <CustomerServiceItemCard key={x.id} index={i} remove={remove} watchConvertingToCustomer={convertingToCustomer}/>
          );
        })}
        {oneTimeChargeServiceItems.map((x, i)=> {
          return (
            <OneTimeChargeServiceItemCard
              key={x.id}
              index={i}
              remove={removeOneTimeCharge}
            />
          );
        })}
      </Grid>
      <Grid container item xs={12} sm={12} justifyContent='center'>
        {(!customer.recurring || convertingToCustomer) && (
          <Button
            variant='outlined'
            type='button'
            onClick={() => {
              void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_DRAWER_ADD_SERVICE_ITEM);
              append(createEmptyServiceItem());
            }}
            fullWidth
            className={styles.serviceItem}
            style={{ margin: '0 20px' }}
          >
            <AddIcon/>
            <span> Service Item</span>
          </Button>
        )}
        {(customer.recurring && !convertingToCustomer && oneTimeChargeServiceItems.length === 0) && (
          <Button
            variant='outlined'
            type='button'
            onClick={() => {
              void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_DRAWER_ADD_ONE_TIME_SERVICE_ITEM);
              appendOneTimeCharge(createEmptyServiceItem());
            }}
            fullWidth
            className={styles.serviceItem}
            style={{ margin: '0 20px' }}
          >
            <AddIcon/>
            <span> Add One Time Charge</span>
          </Button>
        )}
      </Grid>
    </>
  );
}