
export const DiscountScheduleType = {
  DollarPerCustomer: 'DollarPerCustomer',
  DollarSavePerCustomer: 'DollarSavePerCustomer',
  PercentPerCustomer: 'PercentPerCustomer',
  PercentSavePerCustomer: 'PercentSavePerCustomer',
  NoDiscount: 'NoDiscount',
};

export interface IDiscountTableRow {
  key?:number;
  count?:number;
  amount?:string;
  unformattedAmount?:number;
  currentRow:boolean;
}

export interface IDiscountSchedule {
  id?:string;
  sk:string;
  type?:string;
  name?:string;
  priceLabel?:string;
  priceAmount?:number;
  priceSuffix?:string;
  priceSubText?:string;
  annualBillingCycleCount?:number;
  discountSchedule?:IGroupDiscountScheduleDetails ;
  pricingSchedule?:IPricingSchedule;
  providerId?:string;
  serviceTypeId?:string;
  unitBasedPricingFlag?:boolean;
  noPriceTextField?:string;
}

export interface IGroupDiscountScheduleDetails {
  startingAt?:number;
  discountIncrement?:number;
  maxDiscount?:number;
  startingCountOffset?:number;
}

export interface IPricingSchedule {
  startingAt?:number;
  decrement?:number;
  priceFloor?:number;
  startingCountOffset?:number;
}


export function createEmptyDiscountSchedule():IDiscountSchedule {
  return {
    id: '',
    sk: '',
    type: '',
    name: '',
    priceLabel: '',
    priceAmount: -1,
    priceSuffix: '',
    priceSubText: '',
    annualBillingCycleCount: -1,
    discountSchedule: emptyGroupDiscountScheduleDetails(),
    pricingSchedule: emptyPricingSchedule(),
    providerId: '',
    serviceTypeId: '',
    unitBasedPricingFlag: false,
  };
}

export function emptyGroupDiscountScheduleDetails():IGroupDiscountScheduleDetails {
  return {
    startingAt: -1,
    discountIncrement: -1,
    maxDiscount: -1,
    startingCountOffset: -1,
  };
}

export function emptyPricingSchedule():IPricingSchedule {
  return {
    startingAt: -1,
    decrement: -1,
    priceFloor: -1,
    startingCountOffset: -1,
  };
}