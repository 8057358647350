import { Grid, useTheme } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import ServiceCard from 'components/serviceCard';
import { useNavigate, useParams } from 'react-router-dom';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePostConfirmModal from '../hookStore/usePostConfirmModal';
import PostConfirmStepper from './components/PostConfirmStepper';
import SlideButtons from './components/SlideButtons';
import SlideDescription from './components/SlideDescription';
import SlideSubtitle from './components/SlideSubtitle';
import SlideTitle from './components/SlideTitle';
import { IHappeningSoonSlideProps } from './modalSlide';

const buttonOverrideStyle ={
  padding: '0',
  border: 'none',
  background: 'none',
  textDecoration: 'underline',
  color: '#2E3A82',
  fontWeight: 400,
  fontSize: '16px',
  cursor: 'pointer',
};

export default function HappeningSoonSlide({ title, subtitle, description, description2, showUpdateMaxCap, showPleaseContactSupport }: IHappeningSoonSlideProps) {
  const { customer } = useCustomerDetail();
  const navigate = useNavigate();
  const theme = useTheme();
  const { serviceProviderId } = useParams();
  const { happeningSoonCompatibleNsoi, closeModal } = usePostConfirmModal();
  const { openModal } = useConfirmationModal('requestSupportModal');

  function openRequestSupport() {
    closeModal({});
    openModal({});
  }
  function goToGroupDealManagement() {
    closeModal({});
    navigate(`/v1/${serviceProviderId}/groupDeals/${customer.neighborhoodServiceOfferingInstanceId}`);
  }
  return (
    <Grid container justifyContent='center' >
      <Grid container item xs={12} justifyContent='center'>
        <SlideTitle>
          {title}
        </SlideTitle>
      </Grid>
      <Grid item xs='auto' sx={{ padding: '10px 0 20px 0' }}>
        <a style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noreferrer noopener"
          href={`${window.REACT_APP_STREETFAIR_URL}/provider-view/neighborhood-service-offering/${customer.neighborhoodServiceOfferingId}/service-instance/${customer.neighborhoodServiceOfferingInstanceId}?mc=ppconf`}>
          <ServiceCard
            {...happeningSoonCompatibleNsoi}
            onClick={() => {}}
          />
        </a>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs='auto' >
        <PostConfirmStepper/>
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <SlideSubtitle>{subtitle}</SlideSubtitle>
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <SlideDescription>{description}</SlideDescription>
      </Grid>
      {showUpdateMaxCap && (
        <Grid container item xs={12} justifyContent='center'>
          <SlideDescription>
            If you have room for more customers,
            <button
              type='button'
              onClick={goToGroupDealManagement}
              style={{
                background: 'none',
                border: 'none',
                textDecoration: 'underline',
                color: theme.palette.primary.main,
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              <>Update Your Max Customer Cap Here</>
            </button>
          </SlideDescription>
        </Grid>
      )}
      {showPleaseContactSupport && (
        <Grid container item xs={12} justifyContent='center'>
          <SlideDescription>
            If you could still schedule more customers for this date, <button type='button' onClick={openRequestSupport} style={buttonOverrideStyle}>Please Contact Support</button> to change settings
          </SlideDescription>
        </Grid>
      )}
      <SlideButtons/>
    </Grid>
  );
}