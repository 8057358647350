
import { Grid, Typography, useTheme } from '@mui/material';
import styles from './customerAccordion.module.css';

import { INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import { formatLongMonthDay, createDateIgnoreTimezone } from 'util/dateUtil';

export interface IOtherGroupDealProps {
  isSelected:boolean;
  nsoi: INeighborhoodServiceOfferingInstance;
  onClick:any;
  overrideButtonText?:string;
}
export default function OtherGroupDeal({ nsoi, isSelected, onClick, overrideButtonText }:IOtherGroupDealProps) {
  const theme = useTheme();
  const secondaryColor = theme.palette.secondary.main;
  const secondaryColorLight = theme.palette.secondary.light;
  const extraLight = theme.palette.tertiary.altExtraLight;
  return (
    <Grid
      container
      className={styles.otherGroupDeal}
      justifyContent='center'
      sx={{
        'borderBottom': `1px solid ${secondaryColor}`,
        'backgroundColor': isSelected ? secondaryColor : '',
        '&:hover': {
          background: secondaryColorLight,
        },
        '&:hover span': {
          color: extraLight,
        },
      }}
      onClick={onClick}
    >
      <Grid item>
        <Typography
          variant='subtitle2'
          component='span'
          color={isSelected ? extraLight : secondaryColor}
        >
          <>{formatLongMonthDay(createDateIgnoreTimezone(nsoi.serviceDate)) || overrideButtonText}</>
        </Typography>
        {nsoi.endServiceDate && (
          <Typography
            variant='subtitle2'
            component='span'
            color={isSelected ? extraLight : secondaryColor}
          >
            <> - {formatLongMonthDay(createDateIgnoreTimezone(nsoi.endServiceDate))}</>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}