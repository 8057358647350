import { FormControl, FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import { HookDatePicker } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';


export default function ConsulationDate() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { onInitialConfirmation } = useCustomerDetail();

  return (
    <FormControl variant='standard' fullWidth error={isError('consultationDate')}>
      <HookDatePicker
        label='Consultation Date'
        name='consultationDate'
        textFieldProps={{ fullWidth: true, variant: 'standard' }}
        required
        datePickerProps={{
          showTodayButton: true,
          clearable: true,
          onAccept: () => onInitialConfirmation(formCtx.getValues),
        }}
      />
      <FormHelperText>{getErrorMessage('consultationDate')}</FormHelperText>
    </FormControl>
  );
}