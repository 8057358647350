export namespace DemoUtil {


  export function isDemoMode(isProviderAdmin:boolean):boolean {
    return getDemoMode() === 'true' && isProviderAdmin;
  }

  export function shouldShowProviderAdminFeature(isProviderAdmin:boolean):boolean {
    return getDemoMode() !== 'true' && isProviderAdmin;
  }

  function getDemoMode() {
    return localStorage.getItem('isDemoMode');
  }

  export function turnDemoModeOn(isProviderAdmin:boolean) {
    if (isProviderAdmin) {
      localStorage.setItem('isDemoMode', 'true');
    } else {
      localStorage.setItem('isDemoMode', 'false');
    }
  }

  export function turnDemoModeOff(isProviderAdmin:boolean) {
    if (isProviderAdmin) {
      localStorage.setItem('isDemoMode', 'false');
    } else {
      localStorage.setItem('isDemoMode', 'false');
    }
  }
}