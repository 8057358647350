import { Button, Grid, LinearProgress } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MoveToGroupDeal from './MoveToGroupDeal';
import MoveToPlaceholderGroupDealButton from './MoveToPlaceholderDealButton';
import MoveToPlaceholderGroupDeal from './MoveToPlaceholderGroupDeal';
import { ICustomer, IGroupDealCustomer } from 'model/customer';
import { createEmptyCustomerAccordionState } from 'model/groupDeal';
import { INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import useGroupDeal from 'pages/groupDeal/hookStore/useGroupDeal';
import { DemoUtil } from 'util/demoUtil';

export interface ICustomerActionsProps {
  onlyOneCustomer: boolean;
  shouldShowMaxCustomerCount:boolean;
  customer: IGroupDealCustomer;
  onRemove: () => void;
  neighborhoodId: string;
}

function shouldEnableMoveToGroupDeal(
  isCustomerInRootNeighborhood: boolean,
  isTerritoryServiceOfferingInstance:boolean,
  nsoisWithSameNso: INeighborhoodServiceOfferingInstance[],
  onlyOneCustomer:boolean ):boolean {
  return ((nsoisWithSameNso.length > 0 && onlyOneCustomer) || !onlyOneCustomer);
}

export default function CustomerActions({ customer, onlyOneCustomer, shouldShowMaxCustomerCount, onRemove, neighborhoodId }: ICustomerActionsProps) {
  const { nsoisWithSameNso, isTerritoryServiceOfferingInstance, customerAccordionStateMap, updateCustomerAccordion, isProviderAdmin } = useGroupDeal();
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  const isCustomerInRootNeighborhood = customer.neighborhoodId === neighborhoodId;
  const _shouldEnableMoveToGroupDeal = shouldEnableMoveToGroupDeal(isCustomerInRootNeighborhood, isTerritoryServiceOfferingInstance, nsoisWithSameNso, onlyOneCustomer);
  const { loading, shouldShowMoveToGroupDeal, shouldShowMoveToPlaceholderGroupDeal } = customerAccordionStateMap.get(customer.id) ?? createEmptyCustomerAccordionState();
  return (
    <Grid container alignItems={'center'} justifyContent='center' alignContent='center' flexDirection={'column'}>
      {!shouldShowMoveToGroupDeal && (
        <>
          {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
            <Grid item xs='auto' sx={{ marginBottom: '12px' }}>
              <MoveToPlaceholderGroupDealButton
                customer={customer}
              />
            </Grid>
          )}
          <Grid item xs='auto'>
            <Button
              disabled={!_shouldEnableMoveToGroupDeal}
              type='button'
              variant='outlined'
              fullWidth
              sx={{
                padding: '16px',
                width: { xs: '100%', sm: '300px' },
              }}
              onClick={async () => {
                const currentState = customerAccordionStateMap.get(customer.id);
                if (currentState) {
                  updateCustomerAccordion(customer.id, { ...currentState, shouldShowMoveToGroupDeal: true, shouldShowMoveToPlaceholderGroupDeal: false });
                }
              }}
            >
              {loading ? <LinearProgress variant='indeterminate' sx={{ width: '100%', minWidth: '220px' }}/> : (<span>{'Move to a Different Group Deal'}</span>)}
            </Button>
          </Grid>
          <Grid item xs='auto'>
            <Button
              type='button'
              variant='text'
              sx={{ padding: '16px', marginTop: '12px', minWidth: '240px' }}
              onClick={() => {
                navigate(`/v1/${serviceProviderId}/customers/${customer.id}`);
              }}>
              <span>View/Edit Customer Details</span>
            </Button>
          </Grid>

        </>
      )}
      {shouldShowMoveToGroupDeal && (
        <MoveToGroupDeal
          shouldShowMaxCustomerCount={shouldShowMaxCustomerCount}
          onRemove={onRemove}
          onlyOneCustomer={onlyOneCustomer}
          customer={customer}
        />
      )}
      {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && shouldShowMoveToPlaceholderGroupDeal && (
        <MoveToPlaceholderGroupDeal
          shouldShowMaxCustomerCount={false}
          onRemove={onRemove}
          onlyOneCustomer={onlyOneCustomer}
          customer={customer}
        />
      )}

    </Grid>
  );
}