import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import useOperationsStore from '../hookStore/useOperationsStore';
import AddNewNoteButton from './AddNewNoteButton';
import styles from './operationsForm.module.css';
import OperationsNotes from './OperationsNotes';
import ServiceRelationshipOnusField from './ServiceRelationshipOnusField';
import SnoozeDate from './SnoozeDate';
import Shake, { useShake } from 'components/layout/Shake';
import Spacer from 'components/layout/Spacer';
import HookRadioField from 'components/reactHookForm/HookRadioField';
import { IOperationsForm, createEmptyOperationsForm, createOperationsForm } from 'model/operations';

export interface IOperationsFormInternalProps {
  initialFormData: IOperationsForm;
}
const shakeStoreKey = 'saveOperations';

function OperationsFormInternal({ initialFormData }:IOperationsFormInternalProps) {
  const theme = useTheme();
  const { setAnimate } = useShake(shakeStoreKey);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const formContext = useForm<IOperationsForm>({ mode: 'onTouched', defaultValues: initialFormData });
  const { customer, customerServiceDetails, onSubmitOperations } = useCustomerDetail();
  const { onCancel } = useOperationsStore();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<IOperationsForm>= data => {
    void onSubmitOperations(data);
  };

  const onInvalid : any = data => {
    setAnimate(true);
  };


  const { isSubmitting, isValid } = formContext.formState;
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit, onInvalid)} className={styles.operationsForm}>
        <Card sx={{ marginTop: '10px', marginLeft: '8px' }}>
          <CardContent>
            <Grid container sx={{ marginTop: '20px' }}>
              <Grid container item xs={12} alignItems='center'>
                <Grid item xs='auto'>
                  <Typography variant='subtitle2'>{customer.firstName} {customer.lastName}</Typography>
                </Grid>
                <Spacer></Spacer>
                <Grid item xs={12} sm={'auto'} order={{ xs: 0, sm: 1 }}>
                  <Shake
                    storeKey={shakeStoreKey}
                    timeout={900}
                    renderProps={(animatedClassName) => (
                      <Button
                        className={`${styles.saveButton} ${animatedClassName}`}
                        disabled={isSubmitting}
                        variant='contained'
                        type='button'
                        onClick={async (e) => {
                          await formContext.handleSubmit(onSubmit, onInvalid)(e);
                        }}
                      >
                        Save
                      </Button>
                    )}/>
                </Grid>

                <Grid item xs={12} sm='auto' sx={{ marginRight: { xs: 0, sm: '20px' } }}>
                  <Button
                    className={smDown ? styles.mobileCancelButton : styles.cancelButton}
                    type='button'
                    variant='outlined'
                    onClick={() => onCancel()}
                  >
                Cancel
                  </Button>
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: '10px', marginTop: '10px' }}>
                <Grid item xs={12} sm={6} >
                  <ServiceRelationshipOnusField/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SnoozeDate/>
                </Grid>
              </Grid>
              <Grid item xs={12} justifyContent={'flex-start'} order={0} textAlign={'left'} sx={{ marginBottom: '12px' }}>
                <AddNewNoteButton/>
              </Grid>
              <Grid item xs={12}>
                <OperationsNotes/>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
}

export default function OperationsForm() {
  const { customerServiceDetails } = useCustomerDetail();
  const { operationsForm, init } = useOperationsStore();

  useEffect(() => {
    void init();
  }, [customerServiceDetails]);
  return (
    <OperationsFormInternal initialFormData={operationsForm}/>
  );
}