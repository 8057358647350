import { Box, Button, Drawer, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Loading, { useLoading } from 'components/layout/Loading';
import Shake, { useShake } from 'components/layout/Shake';
import { HookCheckbox } from 'components/reactHookForm';
import { ICustomerServiceDetails } from 'model/customer';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { DemoUtil } from 'util/demoUtil';
import useConfirmCustomerDrawer, { COMPLETION_DRAWER_VARIANT, CONFIRMATION_DRAWER_VARIANT } from '../hookStore/useConfirmCustomerDrawer';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import AdditionalServicesFollowUp from './AdditionalServicesFollowUp';
import EditServiceDateAndMaxCustomerCount from './EditServiceDateAndMaxCustomerCount';
import Header from './Header';
import ServiceItemCardList from './ServiceItemCardList';
import TotalPrice from './TotalPrice';
import styles from './confirmCustomerDrawer.module.css';

function Divider() {
  return (
    <div className={styles.confirmationDivider}></div>
  );
}

function PriceSpace() {
  return (
    <div className={styles.priceSpace}></div>
  );
}

const shakeStoreKey = 'confirmCustomerDrawerSubmit';
function SaveButton() {
  const formContext = useFormContext<ICustomerServiceDetails>();
  const { setAnimate } = useShake(shakeStoreKey);
  const {
    onSubmitError,
    onSubmitDrawer,
  } = useCustomerDetail();
  const { formState: { isSubmitting } } = formContext;
  const { loadingKey: drawerLoadingKey, variant } = useConfirmCustomerDrawer();
  const { getLoadingState } = useLoading(drawerLoadingKey);
  const { isLoading } = getLoadingState();

  const {
    isCalculating,
  } = usePriceCalculator();
  const onError : any = (errors, e) => {
    setAnimate(true);
    onSubmitError(errors);
  };

  return (
    <Shake
      storeKey={shakeStoreKey}
      timeout={900}
      renderProps={(animatedClassName) => (
        <Button
          variant='contained'
          type='button'
          disabled={isCalculating || isLoading || isSubmitting}
          onClick={(e) => {
            void formContext.handleSubmit(onSubmitDrawer, onError)(e);
          }}
          className={`${styles.saveAndCloseButton} ${animatedClassName}`}
        >
          <span>{variant === CONFIRMATION_DRAWER_VARIANT ? 'Save': 'Mark Complete'}</span>
        </Button>
      )}/>
  );
}

const saveAsPendingShakeStoreKey = 'saveAsPendingDrawerSubmit';
function SaveAsPendingButton() {
  const {
    onSubmitError,
    onSubmitDrawerAsPending,
  } = useCustomerDetail();
  const { loadingKey: drawerLoadingKey } = useConfirmCustomerDrawer();
  const formContext = useFormContext<ICustomerServiceDetails>();
  const { formState: { isSubmitting } } = formContext;
  const { getLoadingState } = useLoading(drawerLoadingKey);
  const { isLoading } = getLoadingState();
  const { setAnimate } = useShake(saveAsPendingShakeStoreKey);

  const {
    isCalculating,
  } = usePriceCalculator();
  const onError : any = (errors, e) => {
    setAnimate(true);
    onSubmitError(errors);
  };
  return (
    <Shake
      storeKey={saveAsPendingShakeStoreKey}
      timeout={900}
      renderProps={(animatedClassName) => (
        <Button
          variant='contained'
          type='button'
          disabled={isCalculating || isLoading || isSubmitting}
          onClick={(e) => {
            void formContext.handleSubmit(onSubmitDrawerAsPending, onError)(e);
          }}
          className={`${styles.saveAndCloseButton} ${animatedClassName}`}
        >
          <span>Save as Pending</span>
        </Button>
      )}/>
  );
}

function CancelButton() {
  const formContext = useFormContext<ICustomerServiceDetails>();
  const {
    closeDrawer,
  } = useConfirmCustomerDrawer();

  const {
    refresh,
    customerServiceDetails,
  } = useCustomerDetail();

  const resetCustomerServiceDetails = () => {
    formContext.reset(customerServiceDetails);
    void refresh();
    closeDrawer();
  };

  return (
    <Button
      variant='outlined'
      type='button'
      onClick={resetCustomerServiceDetails}
      className={styles.cancelConfirmCustomerButton}
    >
      <span>Cancel</span>
    </Button>
  );
}

export default function ConfirmCustomerDrawer() {
  const theme = useTheme();
  const xsBrk = useMediaQuery(theme.breakpoints.only('xs'));
  const {
    anchor,
    open,
    variant,
    loadingKey,
    closeDrawer,
  } = useConfirmCustomerDrawer();
  const {
    customer,
    customerServiceDetails,
    isProviderAdmin,
    isSelectedOptionOneTime,
    refresh,
  } = useCustomerDetail();
  const { calculatePriceClientside } = usePriceCalculator();

  const formContext = useForm<ICustomerServiceDetails>({ mode: 'onTouched', defaultValues: customerServiceDetails });
  function onClickOutsideDrawer() {
    void refresh();
    closeDrawer();
  }

  useEffect(() => {
    formContext.reset(customerServiceDetails);
    if (isSelectedOptionOneTime(customerServiceDetails.subscriptionSelection, customer)) {
      formContext.setValue('convertingToCustomer', true);
    } else {
      formContext.setValue('convertingToCustomer', false);
    }
    if (customerServiceDetails.customerId || customerServiceDetails.subscriberId) {
      void calculatePriceClientside(customerServiceDetails);
    }
  }, [customerServiceDetails]);


  const topRef = useCallback(node => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  const watchCustomerStatus = useWatch({ control: formContext.control, name: 'customerStatus' });
  const watchConvertingToCustomer = useWatch({ control: formContext.control, name: 'convertingToCustomer' });
  const watchProviderAdminConvertToCustomer = useWatch({ control: formContext.control, name: 'providerAdminConvertToCustomer' });
  const convertingToCustomer = watchConvertingToCustomer || watchProviderAdminConvertToCustomer;

  return (
    <Drawer
      anchor={anchor}
      open={open}
      classes={{
        paper: xsBrk ? 'fullWidthDrawerRoot' : '',
      }}
      onClose={(ev, reason) => {
        onClickOutsideDrawer();
      } }
    >
      <Box
        className={xsBrk ? styles.wrapperMobile : styles.wrapper}
      >
        <Header ref={topRef}/>
        <FormProvider {...formContext}>
          <form className={styles.finalConfirmationForm} >
            <Loading loadingKey={loadingKey}>
              <Grid container justifyContent='center' >
                <Grid container item xs={12} style={{ margin: '0 20px' }} >
                  <EditServiceDateAndMaxCustomerCount/>
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <Divider/>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: '20px', marginLeft: '16px', marginRight: '16px' }}>
                  <Typography variant='h6' sx={{ color: '#000' }} >
                    {variant === COMPLETION_DRAWER_VARIANT ? 'Confirm final price' : 'Set Price'}
                  </Typography>
                </Grid>
                <ServiceItemCardList/>
                <Grid container item xs={12} justifyContent='center'>
                  <Divider/>
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  {(!customer.recurring || convertingToCustomer) && <TotalPrice/>}
                  {(customer.recurring && !convertingToCustomer) && <PriceSpace/>}
                </Grid>
                <Grid container item xs={12}>
                  <AdditionalServicesFollowUp/>
                </Grid>
                <Grid container item xs={12} style={{ marginLeft: '16px', marginRight: '16px' }} >
                  {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
                    <>
                      <Grid container item xs={12}>
                        <HookCheckbox
                          name="disableConfirmationEmail"
                          label="Disable customer communications"
                        />
                      </Grid>
                      <Grid container item xs={12}>
                        <HookCheckbox
                          name="providerAdminConvertToCustomer"
                          label="Convert to customer manually"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid container item xs={12} justifyContent='center'>
                    <SaveButton/>
                  </Grid>
                  {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
                    <Grid container item xs={12} justifyContent='center'>
                      <SaveAsPendingButton/>
                    </Grid>
                  )}
                  <Grid container item xs={12} justifyContent='center'>
                    <CancelButton/>
                  </Grid>
                </Grid>
              </Grid>
            </Loading>
          </form>
        </FormProvider>
      </Box>

    </Drawer>
  );
}