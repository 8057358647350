import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MultiSelectPillFilter, SelectPillFilter } from 'components/PillFilter';
import PillButton from 'components/buttons/PillButton';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { ParticipantStatus } from 'model/ancillary';
import { IDropdownOption, UNSELECTED_OPTION } from 'model/dropdown';
import styles from './customerTable.module.css';
import useCustomerTable from './hookStore/useCustomerTable';

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface MultiToolbarProps {
  quickSearchStorageKey?:string;
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  placeholder:string;
}

export default function MultiToolbar({ quickSearchStorageKey, value, placeholder, onChange, clearSearch }: MultiToolbarProps) {
  const {
    refresh,
    filteredCustomers,
    filter,
    filterModel,
    customerStatusSelectionText,
    customerStatusOptions,
    neighborhoodOptions,
    actionItemOptions,
    resetFiltersToDefault,
    onStatusChange,
    onStatusClose,
    onTableFilterChange,
  } = useCustomerTable();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { trackButtonClick } = useStreetfairAnalytics();
  let filteredStatuses = customerStatusOptions.filter(x => x.key === filter.customerStatus);

  filteredStatuses = customerStatusOptions.filter(x => {
    return filter.customerStatuses.indexOf(x.optionValue) > -1;
  });

  const filteredNeighborhoods = neighborhoodOptions.filter(x => x.key === filter.neighborhoodId);
  let filteredActionItems:IDropdownOption[] = [];
  filteredActionItems = actionItemOptions.filter(x => x.key === filter.actionItem);

  let filterText = '';
  let customerWithStatusCount = 0;
  if (filteredStatuses.length > 0 && filteredStatuses[0].key !== UNSELECTED_OPTION) {
    customerWithStatusCount = filteredCustomers.length;
    filterText = `${filteredStatuses.map(x => x.optionText).join(',')} Customers`;
  }
  const hasDefaultStatusSelections = filter.customerStatuses.filter(x => x !== ParticipantStatus.PENDING && x !== ParticipantStatus.ACTIVE).length === 0;
  const hasFilterSelections = (
    !hasDefaultStatusSelections ||
    (filter.neighborhoodId && filter.neighborhoodId !== UNSELECTED_OPTION) ||
    (filter.actionItem && filter.actionItem !== UNSELECTED_OPTION) ||
    filterModel.items.length > 0
  );
  return (
    <Grid container alignItems='center' justifyContent='space-between' sx={{ padding: '12px' }}>
      <Grid container item
        flexShrink={2}
        xs={12} sm={12} md lg
        order={{ xs: 1, md: 0 }}
        justifyContent={{ xs: 'left', sm: 'right', md: 'left' }}
        className={mdDown ? styles.mobileStatusPillFilterWrapper : styles.statusPillFilterWrapper}
        spacing={1}>
        <Grid item xs='auto' className={styles.statusPillFilter}>
          <MultiSelectPillFilter
            id="status-pill-filter"
            handleSelection={(e) => {
              void trackButtonClick(ButtonClickTypes.CUSTOMERS_TABLE_STATUS_FILTER);
              onStatusChange(e.target.value);
            }}
            handleClose={() => onStatusClose()}
            options={customerStatusOptions}
            selections={filter.customerStatuses}
            renderValue={() => `Status: ${customerStatusSelectionText}`}
          />
        </Grid>
        <Grid item xs='auto' className={styles.statusPillFilter}>
          <SelectPillFilter
            id="neighborhood-pill-filter"
            handleSelection={(e) => {
              void trackButtonClick(ButtonClickTypes.CUSTOMERS_TABLE_NEIGHBORHOOD_FILTER);
              onTableFilterChange('neighborhoodId', e.target.value);
            }}
            options={neighborhoodOptions}
            selection={filter.neighborhoodId}
            renderValue={() => `Neighborhood: ${filteredNeighborhoods[0].optionText}`}
          />
        </Grid>
        <>
          <Grid item xs='auto'>
            <SelectPillFilter
              id="action-item-pill-filter"
              handleSelection={(e) => {onTableFilterChange('actionItem', e.target.value);}}
              options={actionItemOptions}
              selection={filter.actionItem}
              renderValue={() => `${filteredActionItems[0].optionText}`}
            />
          </Grid>
          <Grid item xs='auto'>
            <PillButton onClick={resetFiltersToDefault} disabled={!hasFilterSelections}>
                Reset filters
            </PillButton>
          </Grid>
        </>
      </Grid>
      <Grid container item xs md='auto' alignItems='center' justifyContent='end'>
        <TextField
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={placeholder || 'Search...'}
          InputProps={{
            endAdornment: (
              !value ? <SearchIcon fontSize="small" /> :
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: value ? 'visible' : 'hidden' }}
                  onClick={clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
            ),
          }}
          sx={{
            'width': {
              xs: 1,
              sm: 'auto',
            },
            'minWidth': {
              xs: 1,
              sm: '400px',
            },
            'background': '#F1F1F1',
            'borderRadius': '4px',
            'm': (t) => t.spacing(1, 0.5, 1.5),
            '& .MuiOutlinedInput-input': {
              padding: '12px',
            },
            '&&&&': {
              margin: 0,
            },
          }}
        />

      </Grid>
      {filterText && (
        <Grid container order={2} justifyContent={{ xs: 'left', sm: 'right', md: 'left' }}>
          <Typography variant='body1' component='div' className={styles.numberOfCustomersWithStatus}><span style={{ fontWeight: 'bold' }}>{customerWithStatusCount} </span>{filterText}</Typography>
        </Grid>
      )}
    </Grid>
  );
}