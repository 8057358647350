import { Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/SectionHeaders/PageHeader';
import CustomerDetails from './customerDetails';
import CustomerPrice from './customerPrice';
import ServiceDateSelect from './serviceDateSelect';
import { findNeighborhoodServiceOfferingDetails } from 'api/neighborhoodServiceOfferingApi';
import { approveCustomerForServiceOffering, deleteCustomer, findCustomerDetails, markCustomerCompleteForServiceOffering, sendCustomerEmailCommunication, updateCustomerBilling, updateCustomerServiceInstance } from 'api/participantApi';
import { findServiceInstancesByProviderAndNeighborhoodServiceOffering } from 'api/serviceInstanceApi';
import PaymentDetails from 'components/paymentDetails/paymentDetails';

function SectionHeader({ headerText }:any) {
  return (
    <Grid item xs={12} style={{ borderBottom: '1px solid green', textAlign: 'left', paddingBottom: '4px', marginBottom: '8px' }}>
      <PageHeader headerText={headerText} style={{ borderBottom: '1px solid green' }}/>
    </Grid>
  );
}

export default function Customer () {
  const [customer, setCustomer] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [serviceInstances, setServiceInstances] = useState<any[]>([]);
  const [selectedServiceInstance, setSelectedServiceInstance] = useState<string>('');
  const [neighborhoodServiceOffering, setNeighborhoodServiceOffering] = useState<any>();
  const [snackPack, setSnackPack] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<any>({ message: '', key: '' });
  const [emailTemplateSelected, setEmailTemplateSelected] = useState<string>('');
  const [rewardAmountHasError, setRewardAmountHasError] = useState(false);
  const [rewardAmount, setRewardAmount] = useState<string>('');
  const [emailOverride, setEmailOverride] = useState<string>('');
  const [emailOverrideHasError, setEmailOverrideHasError] = useState(false);
  async function onChange(e:any) {
    console.log(e);
    setSelectedServiceInstance(e.target.value);
  }

  useEffect(() => {
    findCustomerDetails(params.customerId).then((res: any) => {
      setCustomer(res.data);
      if (params.serviceProviderId) {
        findServiceInstancesByProviderAndNeighborhoodServiceOffering(params.serviceProviderId, res.data.neighborhoodServiceOfferingId).then((nsoiRes:any) => {
          setServiceInstances(nsoiRes.data);
          setSelectedServiceInstance(res.data.neighborhoodServiceOfferingInstanceId);
        }).catch((reason) => console.error(reason));;
      }
      findNeighborhoodServiceOfferingDetails(res.data.neighborhoodServiceOfferingId).then((nsoDetails:any) => {
        setNeighborhoodServiceOffering(nsoDetails.data);
      }).catch((reason) => console.error(reason));;
    }).catch((reason) => console.error(reason));;

  }, []);

  const approveCustomer = () => {
    approveCustomerForServiceOffering(customer.neighborhoodServiceOfferingId, customer.id)
      .then(function (response) {
        navigate(`/v0/${params.serviceProviderId}/customers`);
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  const markCustomerComplete = () => {
    markCustomerCompleteForServiceOffering(customer.neighborhoodServiceOfferingId, customer.id)
      .then(function (response) {
        navigate(`/v0/${params.serviceProviderId}/customers`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendEmailCommunication = () => {
    sendCustomerEmailCommunication(customer.id, emailTemplateSelected, rewardAmount, emailOverride)
      .then(function(response) {
        navigate(`/v0/${params.serviceProviderId}/customers`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function _updateCustomerServiceInstance(neighborhoodServiceOfferingInstanceId:string) {
    const data = {
      neighborhoodServiceOfferingInstanceId,
    };
    if (params.serviceProviderId && params.customerId) {
      const res = await updateCustomerServiceInstance(params.serviceProviderId, params.customerId, data);
      const nextMessage = { message: 'Updated customer service date.', toastType: 'info', key: new Date().getTime() };
      setSnackPack((prev) => [...prev, nextMessage]);
    } else {
      console.error('Must define service provider id and customer id');
    }
  }


  async function _updateCustomerBilling(customerPrice, unitQuantity) {
    const data = {
      price: customerPrice,
      unitQuantity: unitQuantity,
    };
    if (params.serviceProviderId && params.customerId) {
      const res = await updateCustomerBilling(params.serviceProviderId, params.customerId, data);
      const nextMessage = { message: 'Updated customer price.', toastType: 'info', key: new Date().getTime() };
      setSnackPack((prev) => [...prev, nextMessage]);
    } else {
      console.error('Must define service provider id and customer id');
    }
  }

  function notifyOfError(message:string) {
    const nextMessage = { message: `${message}`, toastType: 'error', key: new Date().getTime() };
    setSnackPack((prev) => [...prev, nextMessage]);
  }

  const removeCustomer = () => {
    deleteCustomer(customer.id).then(function (response) {
      navigate(`/v0/${params.serviceProviderId}/customers`);
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (snackPack.length && !messageInfo.message) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo({ message: '', key: '' });
  };

  function canSetPrice() {
    return !editMode && customer;
  }
  return (
    <Grid container style={{ padding: '15px' }}>
      <Grid container item >
        <Button variant="contained" onClick={approveCustomer} style={{ marginRight: '15px' }}>Approve</Button>
        <Button variant="contained" onClick={removeCustomer} style={{ marginRight: '15px' }}>Remove</Button>
        <Button variant="contained" onClick={markCustomerComplete} style={{ marginRight: '15px' }}>Complete</Button>
      </Grid>
      <Grid container item>
        <SectionHeader
          headerText='CUSTOMER DETAILS'
        />
        {!editMode && customer && (
          <CustomerDetails customer={customer}/>
        )}
      </Grid>
      <Grid container item>
        <SectionHeader
          headerText='SERVICE DETAILS'
        />
        {!editMode && customer &&
          <ServiceDateSelect
            customer={customer}
            serviceInstances={serviceInstances}
            selectedServiceInstance={selectedServiceInstance}
            onChange={onChange}
            updateCustomerServiceInstance={_updateCustomerServiceInstance}
          />
        }
        {
          canSetPrice() && (
            <CustomerPrice
              customer={customer}
              updateCustomerPrice={_updateCustomerBilling}
              notifyOfError={notifyOfError}
              neighborhoodServiceOffering={neighborhoodServiceOffering}
            />
          )
        }
      </Grid>
      <Grid container item>
        <SectionHeader
          headerText='CUSTOMER COMMUNICATIONS'
        />
        <Grid container xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 200 }}>
            <InputLabel>Email Template</InputLabel>
            <Select
              id="service-instance-select"
              name="serviceInstanceId"
              autoWidth
              onChange={(e) => setEmailTemplateSelected(e.target.value)}
              value={emailTemplateSelected}
            >
              <MenuItem key={'d-b6fb30d2181d403091e184305bad650f'} value={'d-b6fb30d2181d403091e184305bad650f'}>{'Post to Facebook Request'}</MenuItem>
              <MenuItem key='REVIEW_PROVIDER' value='REVIEW_PROVIDER'>Review Provider Email</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={'auto'}>
            <TextField
              error={rewardAmountHasError}
              variant='standard'
              size='small'
              label="Reward Amount"
              name="rewardAmount"
              defaultValue={rewardAmount}
              onChange={(e) => setRewardAmount(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={'auto'}>
            <TextField
              error={emailOverrideHasError}
              variant='standard'
              size='small'
              label="Email Override (optional)"
              name="emailOverride"
              defaultValue={emailOverride}
              onChange={(e) => setEmailOverride(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Button variant="contained" onClick={sendEmailCommunication} style={{ marginTop: '15px' }}>Send Email</Button>
        </Grid>
      </Grid>
      <Grid container item>
        <>
          {customer && customer.stripeCustomerId && <PaymentDetails customer={customer} serviceProviderId={params.serviceProviderId} />}
        </>
      </Grid>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        <Alert
          severity={messageInfo.toastType}
          variant='filled'
          onClose={(e) => handleClose(e, null)}
        >
          {messageInfo ? messageInfo.message : undefined}
        </Alert>
      </Snackbar>
    </Grid>
  );
}