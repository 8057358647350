import { Avatar } from '@mui/material';

export interface IServiceCardAvatarProps {
  index?:number;
  firstName?:string;
  lastName?:string;
  profilePicUrl?:string;
  avatarIconStyleOverride?:IAvatarIconStyleOverride;
  className?:string;
}

export interface IAvatarIconStyleOverride {
  width?:string;
  height?:string;
  fontSize?:string;
  color?:string;
  fontWeight?:string;
  extraStyling?:any;
}

const indexedColors = [
  '#6023A0',
  '#4657BB',
  '#4F9866',
  '#5C3B5C',
  '#598AAB',
];

function stringToColor(string:any) {
  let stringUniqueHash = [...string].reduce((acc, char) => {
    // eslint-disable-next-line no-bitwise
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 80%, 30%)`;
}

function indexToColor(index:number) {
  return indexedColors[index];
}

function stringAvatar(index:number | undefined, firstName: string, lastName: string, override?: IAvatarIconStyleOverride ) {
  const firstNameCharacter = firstName ? firstName[0] : 'Z';
  const lastNameCharacter = lastName ? lastName[0] : 'Z';
  return {
    sx: {
      bgcolor: index && index > -1 ? indexToColor(index) : stringToColor(`${firstName} ${lastName}`),
      color: (override && override.color) || '#FFF',
      border: '2px solid #eee',
      fontSize: (override && override.fontSize) || '14px',
      fontWeight: (override && override.fontWeight) || 'bold',
      width: (override && override.width) || '32px',
      boxShadow: '0px 0px 1px rgba(0,0,0,0.3)',
      height: (override && override.height) || '32px',
      ...override?.extraStyling,
    },
    children: `${firstNameCharacter}${lastNameCharacter}`,
  };
}

export default function ServiceCardAvatar({ index, firstName, lastName, avatarIconStyleOverride, profilePicUrl, ...other }:IServiceCardAvatarProps) {
  return (
    <Avatar {...other} {...stringAvatar(index, firstName || 'Z', lastName || 'Z', avatarIconStyleOverride)} />
  );
}