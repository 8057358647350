import { Grid, Typography } from '@mui/material';
import { HookTextField } from 'components/reactHookForm';

import { useFlags } from 'launchdarkly-react-client-sdk';

export default function CreateNeighborhood() {
  const { gdmCreateNewNeighborhoodJun2023 } = useFlags();

  if (!gdmCreateNewNeighborhoodJun2023) {
    return null;
  }
  return (
    <Grid container item xs={12} alignItems='center' sx={{ marginTop: '20px' }}>
      <Grid item xs={12} sm={12}>
        <Typography variant='subtitle2' textAlign='left'>Create Neighborhood</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <HookTextField
          required
          label="Neighborhood Name"
          name="createNeighborhoodRequestDTO.neighborhoodName"
        />
      </Grid>
    </Grid>
  );
}