import { findByServiceProviderAndServiceInstance as findCustomersByServiceProviderAndServiceInstance } from 'api/customerApi';
import { findNeighborhoodById } from 'api/neighborhoodApi';
import { findAnalogousNsoRequest } from 'api/neighborhoodServiceOfferingApi';
import { findServiceInstanceById } from 'api/serviceInstanceApi';
import { findServiceOfferingById } from 'api/serviceOfferingApi';
import { findServiceProviderById } from 'api/serviceProviderApi';
import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import useServiceCategoryTypeDisplay from 'hooks/useServiceCategoryTypeDisplay';
import { IProviderCustomerDetail } from 'model/customer';
import { IDropdownOption } from 'model/dropdown';
import { activeOrGreater } from 'model/neighborhood';
import { INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';
import { INeighborhoodServiceOfferingInstance, createEmptyNeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import { IServiceProvider, createEmptyServiceProvider } from 'model/serviceProvider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


export interface IGroupDealShareData {
  neighborhoodName:string;
  serviceDate: string;
  serviceProviderName:string;
  serviceTypeName:string;
  neighborhoodServiceOfferingId:string;
  neighborhoodServiceOfferingInstanceId:string;
}

function createEmptyGroupDealShareData():IGroupDealShareData {
  return {
    neighborhoodName: '',
    serviceDate: '',
    serviceProviderName: '',
    serviceTypeName: '',
    neighborhoodServiceOfferingId: '',
    neighborhoodServiceOfferingInstanceId: '',
  };
}

const { get, update, registerListener, unregisterListener } = createStore<GroupDealShare>('groupDealShare', {
  happeningSoonCompatibleNsoi: null,
  nsoi: createEmptyNeighborhoodServiceOfferingInstance(),
  groupDealShareData: createEmptyGroupDealShareData(),
  serviceProvider: createEmptyServiceProvider(),
  customers: [],
  customersForDropdown: [],
  shouldShowCustomerDropdown: false,
  selectedCustomer: '',
  isActiveNeighborhood: false,
});

type GroupDealShare = {
  happeningSoonCompatibleNsoi:any;
  nsoi:INeighborhoodServiceOfferingInstance;
  groupDealShareData: IGroupDealShareData;
  serviceProvider:IServiceProvider;
  shouldShowCustomerDropdown: boolean;
  customers: IProviderCustomerDetail[];
  customersForDropdown: IDropdownOption[];
  selectedCustomer: string ;
  isActiveNeighborhood: boolean;
}

const loadingKey = 'groupDealShare';

export default function useGroupDealShare() {
  const setState = useState(get())[1];
  const { serviceProviderId, groupDealId } = useParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { getServiceTypeDisplay } = useServiceCategoryTypeDisplay();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    if (serviceProviderId && groupDealId) {
      const promises = [
        findServiceInstanceById(serviceProviderId, groupDealId),
        findServiceProviderById(serviceProviderId),
        findCustomersByServiceProviderAndServiceInstance(serviceProviderId, groupDealId),
      ];
      const [nsoiRes, serviceProviderRes, customerDetailsRes] = await Promise.all(promises);
      const _hasMultipleNeighborhoods = hasMultipleNeighborhoods(customerDetailsRes.data);
      const [neighborhoodRes, serviceOfferingRes] = await Promise.all([
        findNeighborhoodById(nsoiRes.data.neighborhoodId),
        findServiceOfferingById(nsoiRes.data.serviceOfferingId),
      ]);
      var serviceOffering = serviceOfferingRes.data;
      var serviceTypeDisplay = getServiceTypeDisplay(serviceOffering.serviceCategory, serviceOffering.serviceType);
      update({
        ...get(),
        happeningSoonCompatibleNsoi: convertForHappeningSoonCard(nsoiRes.data, serviceProviderRes.data, customerDetailsRes.data, serviceTypeDisplay),
        nsoi: nsoiRes.data,
        groupDealShareData: createGroupDealShareData(nsoiRes.data, serviceProviderRes.data, serviceTypeDisplay),
        serviceProvider: serviceProviderRes.data,
        customers: customerDetailsRes.data,
        customersForDropdown: convertForCustomerDropdown(customerDetailsRes.data),
        shouldShowCustomerDropdown: _hasMultipleNeighborhoods,
        isActiveNeighborhood: activeOrGreater(neighborhoodRes.data.status),
      });
    }
  }

  function hasMultipleNeighborhoods(customers:IProviderCustomerDetail[]) {
    const neighborhoodMap = new Map<string, string>();
    customers.map(x => {
      if (!neighborhoodMap.has(x.neighborhoodId)) {
        neighborhoodMap.set(x.neighborhoodId, x.neighborhoodName);
      }
    });

    return neighborhoodMap.size > 1;
  }

  function createGroupDealShareData(nsoi:INeighborhoodServiceOfferingInstance, serviceProvider:IServiceProvider, serviceTypeDisplay?:string):IGroupDealShareData {
    return {
      neighborhoodName: nsoi.neighborhoodName,
      neighborhoodServiceOfferingId: nsoi.neighborhoodServiceOfferingId,
      neighborhoodServiceOfferingInstanceId: nsoi.id,
      serviceDate: nsoi.serviceDate,
      serviceProviderName: serviceProvider.name,
      serviceTypeName: serviceTypeDisplay ?? nsoi.serviceTypeName,
    };
  }

  function createGroupDealShareDataFromCustomer(
    nsoi:INeighborhoodServiceOfferingInstance,
    analogousNso:INeighborhoodServiceOffering,
    serviceProvider:IServiceProvider,
    customer: IProviderCustomerDetail):IGroupDealShareData {
    return {
      neighborhoodName: customer.neighborhoodName,
      neighborhoodServiceOfferingId: analogousNso.id,
      neighborhoodServiceOfferingInstanceId: customer.neighborhoodServiceOfferingInstanceId,
      serviceDate: nsoi.serviceDate,
      serviceProviderName: serviceProvider.name,
      serviceTypeName: nsoi.serviceTypeName,
    };
  }

  function convertForCustomerDropdown(customers:IProviderCustomerDetail[]):IDropdownOption[] {
    const filtered = customers.filter( x => !x.placeholder);
    if (!filtered || filtered.length === 0) {
      return [];
    }
    return filtered.map(x => {
      return {
        key: x.id,
        optionValue: x.id,
        optionText: `${x.firstName} ${x.lastName}`,
      };
    });
  }

  function convertForHappeningSoonCard(nsoi: any, serviceProvider:any, customerDetails:IProviderCustomerDetail[], serviceTypeDisplay?:string) {
    var serviceType = nsoi.serviceTypeName;
    if (nsoi.serviceTypeNameOverride) {
      serviceType = nsoi.serviceTypeNameOverride;
    } else if (serviceTypeDisplay) {
      serviceType = serviceTypeDisplay;
    }

    return {
      nsoId: nsoi.neighborhoodServiceOfferingId,
      maxCustomerCount: nsoi.maxCustomerCount,
      beginServiceDate: nsoi.serviceDate,
      endServiceDate: nsoi.endServiceDate ? nsoi.endServiceDate : nsoi.serviceDate,
      cutoffDayCount: nsoi.cutoffDayCount,
      serviceType: serviceType,
      serviceProvider: { name: serviceProvider.name },
      customers: customerDetails,
      altColor: false,
      serviceInstanceId: nsoi.id,
    };
  }

  async function onCustomerChange(e:any) {
    const { customers, nsoi, serviceProvider } = get();
    const selectedCustomerId = e.target.value;
    const matchingCustomers = customers.filter(x => x.id === selectedCustomerId);
    if (matchingCustomers.length > 0) {
      let selectedCustomer = matchingCustomers[0];
      try {
        const nsoRes = await findAnalogousNsoRequest(serviceProvider.id, {
          neighborhoodId: selectedCustomer.neighborhoodId,
          neighborhoodServiceOfferingId: nsoi.neighborhoodServiceOfferingId,
        });
        update({
          ...get(),
          selectedCustomer: selectedCustomerId,
          groupDealShareData: createGroupDealShareDataFromCustomer(nsoi, nsoRes.data, serviceProvider, selectedCustomer),
        });
      } catch (err:any) {
        console.error(err);
      }
    }
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    init,
    onCustomerChange,
  };
}