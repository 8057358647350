import { Button, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './subscriber.module.css';

export interface ISubscriberPriceProps {
  customer: any;
  updateSubscriberPrice:Function;
  notifyOfError:Function;
}

function isValidNumber(value:any) {
  const isValid = !isNaN(value - parseFloat(value));
  return isValid;
}

export default function SubscriberPrice({ customer, updateSubscriberPrice, notifyOfError }:ISubscriberPriceProps) {

  const [price, setPrice] = useState(null);
  const [hasError, setHasError] = useState(false);
  function changePrice(e) {
    const currentPrice = e.target.value;
    const isValid = isValidNumber(currentPrice) || currentPrice === '';
    if (!isValid) {
      setHasError(true);
    } else {
      setPrice(e.target.value);
      setHasError(false);
    }
  }

  return (
    <Grid container item spacing={1} className={styles.customerPrice} alignItems='end'>
      <Grid item xs='auto'>
        <TextField
          error={hasError}
          variant='standard'
          size='small'
          label="Subscriber Price"
          name="price"
          defaultValue={customer.price}
          onChange={changePrice}
        />
      </Grid>
      <Grid item xs='auto'>
        <Button
          size='small'
          variant='contained'
          type='button'
          onClick={() => {
            if (!hasError) {
              updateSubscriberPrice(price);
            } else {
              notifyOfError('Invalid price');
            }
          }}
        >
            Change Price
        </Button>
      </Grid>
    </Grid>
  );
}