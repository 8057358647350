import Axios from 'axios';
import { IUpdateCustomerBilling, IUpdateCustomerServiceInstance } from 'model/customer';

export function findCustomerDetails(customerId:any) {
  const providerId = localStorage.getItem('providerId');
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${providerId}/findCustomerDetails/${customerId}`);
}

export function approveCustomerForServiceOffering(neighborhoodServiceOfferingId:string, customerId:string) {
  const providerId = localStorage.getItem('providerId');
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${providerId}/approveCustomerForServiceOffering/${neighborhoodServiceOfferingId}/${customerId}`);
}


export function markCustomerCompleteForServiceOffering(neighborhoodServiceOfferingId:string, customerId:string) {
  const providerId = localStorage.getItem('providerId');
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${providerId}/markCustomerAsCompletedForServiceOffering/${neighborhoodServiceOfferingId}/${customerId}`);
}

export function sendCustomerEmailCommunication(customerId:string, emailTemplateId: string, rewardAmount: string, emailOverride: string, isSubscriber?: boolean) {
  const providerId = localStorage.getItem('providerId');
  var rewardAmountString = (rewardAmount != null && rewardAmount.length > 0) ? rewardAmount : 0;
  var url = `${window.REACT_APP_BASE_API_URI}/communications/v2/provider/${providerId}/customer/${customerId}/template/${emailTemplateId}?amount=${rewardAmountString}&isSubscriber=${isSubscriber ? isSubscriber : ''}`;
  if (emailOverride && emailOverride !== '') {
    url = url + '&toEmailOverride=' + emailOverride;
  }
  return Axios.post(url);
}

export function approveSubscriberForServiceOffering(neighborhoodServiceOfferingId:string, subscriberId:string) {
  const providerId = localStorage.getItem('providerId');
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${providerId}/approveSubscriberForServiceOffering/${neighborhoodServiceOfferingId}/${subscriberId}`);
}

export function deleteCustomer(customerId:string) {
  const providerId = localStorage.getItem('providerId');
  return Axios.delete(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${providerId}/deleteCustomer/${customerId}`);
}

export function deleteSubscriber(subscriberId:string) {
  const providerId = localStorage.getItem('providerId');
  return Axios.delete(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${providerId}/deleteSubscriber/${subscriberId}`);
}

export function findCustomersByServiceProviderId(serviceProviderId:string) {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/participant/v2/findCustomersByServiceProviderId/${serviceProviderId}`);
}

export function findSubscribersByServiceProviderId(serviceProviderId:string) {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/participant/v2/findSubscribersByServiceProviderId/${serviceProviderId}`);
}

export function findSubscriberDetails(subscriberId:string) {
  const providerId = localStorage.getItem('providerId');
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${providerId}/findSubscriberDetails/${subscriberId}`);
}

export function updateCustomerServiceInstance(serviceProviderId:string, customerId:string, data:IUpdateCustomerServiceInstance) {
  return Axios.put(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${serviceProviderId}/updateCustomerDetailsServiceInstance/${customerId}`, data);
}

export function updateCustomerBilling(serviceProviderId:string, customerId:string, data:IUpdateCustomerBilling) {
  return Axios.put(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${serviceProviderId}/updateCustomerBilling/${customerId}`, data);
}

export function updateSubscriberBilling(serviceProviderId:string, subscriberId:string, data:IUpdateCustomerBilling) {
  return Axios.put(`${window.REACT_APP_BASE_API_URI}/participant/v2/serviceProviders/${serviceProviderId}/updateSubscriberBilling/${subscriberId}`, data);
}