import { Code } from '@mui/icons-material';
import { Typography, Grid, Rating } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { isEqual, parseISO } from 'date-fns';
import styles from './reviews.module.css';
import { formatLongMonthDayYear, createDateIgnoreTimezone, formatLongMonthDay, formatYearOnly } from 'util/dateUtil';
import { formatDecimalNoPadding } from 'util/numberUtil';

export const desktopColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Customer',
    valueGetter(params) {
      return `${params.row.firstName} ${params.row.lastName}`;
    },
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <div className={styles.nameNeighborhoodCell}>
          <Typography variant='body2' component='div'>{params.row.firstName} {params.row.lastName}</Typography>
        </div>
      );
    },
  },
  {
    field: 'individualId',
    headerName: 'Email',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Typography variant='body2' component='div'>{params.row.individualId}</Typography>
      );
    },
  },
  {
    field: 'neighborhoodId',
    valueGetter(params) {
      return `${params.row.neighborhoodName}`;
    },
    headerName: 'Neighborhood',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Typography variant='body2' component='div'>{params.row.neighborhoodName}</Typography>
      );
    },
  },
  {
    field: 'reviewDate',
    valueGetter(params) {
      return `${params.row.reviewDate} ${formatLongMonthDayYear(createDateIgnoreTimezone(params.row.reviewDate))}`;
    },
    headerName: 'Review Date',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Typography variant='body2' component='div'>{formatLongMonthDayYear(createDateIgnoreTimezone(params.row.reviewDate))}</Typography>
      );
    },
  },
  {
    field: 'rating',
    valueGetter(params) {
      var val = Math.ceil(parseFloat(params.row.rating));
      return val;
    },
    headerName: 'Rating',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Rating name="read-only" value={Math.ceil(parseFloat(params.row.rating))} readOnly precision={1} />
      );
    },
  },
];

export const mobileColumns: GridColDef[] = [
  {
    field: 'nameAndIndividualId',
    headerName: 'Customer',
    valueGetter(params) {
      return `${params.row.firstName} ${params.row.lastName} ${params.row.individualId}`;
    },
    minWidth: 100,
    flex: 2,
    renderCell: (params: any) => {
      return (
        <div className={styles.mobileNameNeighborhoodCell}>
          <Typography variant='body2' component='div'>{params.row.firstName} {params.row.lastName}</Typography>
          <Typography variant='caption' component='div'>{params.row.individualId}</Typography>
        </div>
      );
    },
  },
  {
    field: 'neighborhoodId',
    headerName: 'Neighborhood',
    valueGetter(params) {
      return `${params.row.neighborhoodName}`;
    },
    minWidth: 100,
    flex: 1,
    renderCell: (params:any) => {
      return (
        <div>
          <Typography variant='body2' component='div' sx={{ fontSize: '12px' }}>{params.row.neighborhoodName}</Typography>
        </div>
      );
    },
  },
  {
    field: 'rating',
    headerName: 'Rating/Review Date',
    valueGetter(params) {
      var val = Math.ceil(parseFloat(params.row.rating));
      return `${params.row.reviewDate} ${val} ${formatLongMonthDayYear(createDateIgnoreTimezone(params.row.reviewDate))}`;
    },
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <div className={styles.mobileNameNeighborhoodCell}>
          <Rating name="read-only" size='small' value={Math.ceil(parseFloat(params.row.rating))} readOnly />
          <Typography variant='caption' component='div' sx={{ marginLeft: '5px' }}>{formatLongMonthDayYear(createDateIgnoreTimezone(params.row.reviewDate))}</Typography>
        </div>
      );
    },
  },
];

export function getColumnData(breakpoint):any {
  let columns;
  let columnTotalWidth = 0;
  if (breakpoint) {
    columns = mobileColumns;
    columnTotalWidth = mobileColumns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
  } else {
    columns = desktopColumns;
    columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0) +1;
  }
  return { columns, columnTotalWidth };
}