import { DrawerProps } from '@mui/material';
import Userfront from '@userfront/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import createStore from 'hooks/hookStore';
import useSelf from 'hooks/useSelf';
import { DemoUtil } from 'util/demoUtil';
import { containsProviderMulti, containsProviderAdmin } from 'util/role';

type UserProfileDrawer = {
  isProviderAdmin:boolean;
  isProviderMulti:boolean;
  open:boolean;
  anchor:DrawerProps['anchor'];
}

const { get, update, registerListener, unregisterListener } = createStore<UserProfileDrawer>('userProfileDrawer', {
  isProviderAdmin: false,
  isProviderMulti: false,
  open: false,
  anchor: 'right',
});

export default function useUserProfileDrawer() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  const location = useLocation();
  const { roles } = useSelf();


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    void init();
  }, [roles]);

  async function init() {
    if (Userfront.tokens.accessToken && location.pathname !== '/sign-in') {
      try {
        update({
          ...get(),
          isProviderAdmin: containsProviderAdmin(roles),
          isProviderMulti: containsProviderMulti(roles),
        });
      } catch (err:any) {
        console.log(err);
      }
    } else {
      update({ ...get(), isProviderAdmin: false });
    }
  }

  function openProfile() {
    update({
      ...get(),
      open: true,
    });
  }

  function closeProfile() {
    update({
      ...get(),
      open: false,
    });
  }

  function navigateToProfilePage(pathSuffix) {
    closeProfile();
    navigate(`/user-profile/${pathSuffix}`);
  }
  function navigateToV0() {
    closeProfile();
    navigate(`/v0/${_getServiceProviderId()}/customers`);
  }

  function navigateToSwitchProvider() {
    closeProfile();
    navigate(`/v1/${_getServiceProviderId()}/switchProvider`);
  }

  function navigateToCustomers() {
    closeProfile();
    navigate(`/v1/${_getServiceProviderId()}/customers`);
  }

  function navigateToGroupDeals() {
    closeProfile();
    navigate(`/v1/${_getServiceProviderId()}/groupDeals`);
  }

  function navigateToTermsAndConditions() {
    closeProfile();
    navigate(`/v1/${_getServiceProviderId()}/termsAndConditions`);
  }

  function navigateToReviews() {
    closeProfile();
    navigate(`/v1/${_getServiceProviderId()}/reviews`);
  }

  function toggleDemoMode() {
    const { isProviderAdmin } = get();
    if (DemoUtil.isDemoMode(isProviderAdmin)) {
      DemoUtil.turnDemoModeOff(isProviderAdmin);
      window.location.reload();
    } else {
      DemoUtil.turnDemoModeOn(isProviderAdmin);
      window.location.reload();
    }
  }

  function _getServiceProviderId() {
    let _serviceProviderId = localStorage.getItem('providerId');;
    if (serviceProviderId) {
      _serviceProviderId = serviceProviderId;
    }
    return _serviceProviderId;
  }

  async function signOut() {
    await Userfront.logout();
  }

  return {
    ...get(),
    init,
    openProfile,
    closeProfile,
    navigateToProfilePage,
    navigateToV0,
    navigateToSwitchProvider,
    navigateToCustomers,
    navigateToGroupDeals,
    navigateToTermsAndConditions,
    navigateToReviews,
    signOut,
    toggleDemoMode,
  };
}