import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import useGroupDeal from '../../hookStore/useGroupDeal';
import useGroupDealCreationStore from '../../hookStore/useGroupDealCreationStore';
import styles from '../groupDealCreation.module.css';
import AddCustomerCard from './AddCustomerCard';
import { createEmptyGroupDealCustomer, IGroupDealCustomer } from 'model/customer';


export default function AddCustomerFields() {

  const { control, getValues, formState } = useFormContext();
  const {
    customersToggled,
    nsoDetails,
    nsoi,
    pendingCustomers,
    findServiceOffering,
  } = useGroupDeal();
  const { groupDealCreationState } = useGroupDealCreationStore();

  const { fields: customers, append, replace, remove } = useFieldArray({
    name: 'customers',
  });

  const watchedCustomers = useWatch({ control, name: 'customers' });
  const watchServiceOfferingId = useWatch({ control, name: 'serviceOfferingId' });
  const watchMaxCustomerCount = useWatch({ control, name: 'maxCustomerCount' });
  const watchSuperDeal = useWatch({ control, name: 'superDeal' });
  const watchDiscountScheduleKey = useWatch({ control, name: 'discountScheduleKey' });
  const watchNsoPrice = useWatch({ control, name: 'nsoPrice' });
  const onlyOneCustomer = watchedCustomers.filter(x => !x.wasAdded).length === 1;
  const serviceOffering = findServiceOffering(watchServiceOfferingId);

  const shouldShow = groupDealCreationState.currentActiveStepIndex === 1;
  if (!shouldShow) {
    return null;
  }
  return (
    <Grid container >
      {customers && customers.length > 0 && (
        <Grid container item xs={12} spacing={0.25} sx={{ display: `${customersToggled ? '' : 'none'}` }}>
          {customers.map((x, i) => {
            return (
              <Grid key={x.id} item xs={12} >
                <AddCustomerCard
                  index={i}
                  onRemove={() => {
                    remove(i);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Grid item xs={12} sx={{ paddingBottom: '20px' }}>

      </Grid>
      {/* <Grid item xs={12} sm='auto' sx={{ display: `${customersToggled ? '' : 'none'}` }}>
        <Button
          variant='text'
          type='button'
          onClick={() => {
            append(createEmptyGroupDealCustomer());
          }}
          className={styles.addCustomerButton}
        >
          <AddIcon />
          Add Customer
        </Button>
      </Grid> */}
    </Grid>
  );
}