import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import { Grid, Menu, MenuItem, Select, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { findOwned } from 'api/serviceProviderApi';
import useSelf from 'hooks/useSelf';
import { IServiceProvider } from 'model/serviceProvider';
import { containsProviderMulti } from 'util/role';

export interface IServiceProviderNameToggleProps {
  serviceProviderName:string;
}

function DisplayElement({ serviceProviderName }:any) {

  return (
    <Tooltip title={`${serviceProviderName}`} enterTouchDelay={0} leaveTouchDelay={5000}>
      <Typography variant='subtitle2' sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingLeft: '8px',
        paddingRight: '8px',
      }}>
        <>{serviceProviderName}</>
      </Typography>
    </Tooltip>
  );
}

function SwitchableServiceProviders({ serviceProviderName, ownedServiceProviders, onChange, value }:any) {
  const theme = useTheme();
  const sw600 = useMediaQuery('(max-width:600px)');
  const sw500 = useMediaQuery('(max-width:500px)');
  const sw400 = useMediaQuery('(max-width:400px)');
  const location = useLocation();
  const params = useParams();
  const { serviceProviderId } = params;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const determineHeaderTitleMaxWidth = () => {
    if (sw400) {
      return '140px';
    } else if (sw500) {
      return '230px';
    } else if (sw600) {
      return '270px';
    } else {
      return '600px';
    }

  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onChange(event.currentTarget.value);
  };

  const handleClose = (id:string) => {
    setAnchorEl(null);

    if (serviceProviderId && id) {
      if (
        location.pathname.endsWith('/groupDeals') ||
        location.pathname.endsWith('/customers') ||
        location.pathname.endsWith(`/${serviceProviderId}`)
      ) {
        let nextLocation =location.pathname.replace(serviceProviderId, id);
        navigate(nextLocation);
        window.location.reload();
      } else {
        navigate(`/v1/${id}`);
        window.location.reload();
      }
    }
  };

  return (
    <>
      <Grid container item alignItems='center' justifyContent='center'>
        <Grid item sx={{ maxHeight: '75px', maxWidth: determineHeaderTitleMaxWidth(), cursor: 'pointer' }} flexWrap={'nowrap'}>
          <Typography
            id='service-provider-name-toggle'
            onClick={handleClick}
            variant='subtitle2'
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}>
            <>{serviceProviderName}</>
          </Typography>
        </Grid>
        <Grid item xs='auto'>
          <ArrowDropDownOutlinedIcon/>
        </Grid>
      </Grid>
      <Menu
        id='switchable-service-providers-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose('')}
        MenuListProps={{
          'aria-labelledby': 'service-provider-name-toggle',
        }}

      >{
          ownedServiceProviders.map((x: any, i) => {

            return (
              <MenuItem key={i}
                onClick={() => handleClose(x.id)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{
                    '&': {
                      color: serviceProviderId === x.id ? theme.palette.secondary.main : 'auto',
                      textDecoration: serviceProviderId === x.id ? 'underline': 'none',
                    },
                  }}>
                  <>{x.name}</>
                </Typography>
              </MenuItem>
            );
          })
        }
      </Menu>
    </>
  );
}


export default function ServiceProviderNameToggle({ serviceProviderName }:IServiceProviderNameToggleProps) {
  const { roles } = useSelf();
  const [ownedServiceProviders, setOwnedServiceProviders] = useState<IServiceProvider[]>([]);
  const [selectedServiceProviderId, setSelectedServiceProviderId] = useState<string>('');
  async function populateOwnedServiceProviders() {
    const res = await findOwned();
    setOwnedServiceProviders(res.data);
  }
  useEffect(() => {
    if (roles?.length > 0 && containsProviderMulti(roles)) {
      void populateOwnedServiceProviders();
    }
  }, [roles]);

  if (ownedServiceProviders.length > 0) {
    return (
      <SwitchableServiceProviders
        serviceProviderName={serviceProviderName}
        ownedServiceProviders={ownedServiceProviders}
        onChange={setSelectedServiceProviderId}
        value={selectedServiceProviderId}
      />
    );
  }
  return (
    <DisplayElement serviceProviderName={serviceProviderName}/>
  );
}