import { Button, useTheme } from '@mui/material';
import useCustomerDetail from '../hookStore/useCustomerDetail';

export default function ShowOperationsButton() {
  const theme = useTheme();

  const { showOperations, onHideOperations, onShowOperations } = useCustomerDetail();
  return (
    <Button
      variant='outlined'
      type='button'
      onClick={showOperations ? onHideOperations : onShowOperations}
      sx={{
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        width: {
          xs: '100%',
          sm: 'auto',
        },
      }}
    >
      <span>{showOperations ? 'Hide Operations' : 'Operations'}</span>
    </Button>
  );
}