import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/SectionHeaders/PageHeader';
import { findSubscribersByServiceProviderId } from 'api/participantApi';


function SubscriberList () {
  const [subscribers, setSubscribers] = useState<any[]>([]);
  const [serviceProviderId, setServiceProviderId] = useState<string>('');
  const params = useParams();

  const getFullName = (row:any) => {
    return row.firstName + ' ' + row.lastName;
  };

  useEffect(() => {
    let serviceProvider;
    if (params.serviceProviderId) {
      serviceProvider = params.serviceProviderId;
      setServiceProviderId(serviceProvider);
      localStorage.setItem('providerId', serviceProvider);
    } else {
      serviceProvider = localStorage.getItem('providerId');
      setServiceProviderId(serviceProvider);
    }

    findSubscribersByServiceProviderId(serviceProvider).then((res: any) => {
      setSubscribers(res.data);
    }).catch((reason) => console.error(reason));
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (cellParams: any) => {
        return <a href={`/v0/${serviceProviderId}/subscriber/${cellParams.row.id}`} >{getFullName(cellParams.row)}</a>;
      },
    },
    { field: 'streetAddress', headerName: 'Address', width: 250 },
    { field: 'zipCode', headerName: 'ZipCode', width: 100 },
    { field: 'neighborhood', headerName: 'Neighborhood', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  const sortModel = [{
    field: 'name',
    sort: 'asc' as any,
  }];

  return (
    <>
      <Container style={{
        marginLeft: '10px',
      }}>
        <Row style={{
          borderBottom: '1px solid #26995F',
          padding: '10px',
          textAlign: 'left',
        }}>
          <PageHeader headerText={'CUSTOMERS'} />
        </Row>
        <DataGrid rows={subscribers}
          columns={columns}
          autoHeight={true}
          sortModel={sortModel}
          style={{
            minWidth: '500px',
            maxWidth: '1200px',
            marginTop: '16px',
          }}
        />
      </Container>
    </>
  );
}

export default SubscriberList;
