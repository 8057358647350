
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import useGroupDealShare from './hookStore/useGroupDealShare';

export default function CustomerSelect() {
  const theme = useTheme();
  const { customersForDropdown, selectedCustomer, onCustomerChange } = useGroupDealShare();
  return (
    <FormControl
      variant='standard'
      fullWidth
      disabled={customersForDropdown.length === 0}
    >
      <InputLabel htmlFor="neighborhood-select">Select Existing Customer</InputLabel>
      <Select
        id='customer-select'
        onChange={onCustomerChange}
        value={selectedCustomer}
      >
        {
          customersForDropdown.map((x: any, i) => {
            return <MenuItem key={i} value={x.optionValue}>{x.optionText}</MenuItem>;
          })
        }
      </Select>

    </FormControl>
  );
}