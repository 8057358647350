import { Typography } from '@mui/material';

export default function CaptionDetail({ icon, label, value, sx }:any) {
  return (
    <div style={{ minWidth: '120px', display: 'inline-block' }}>
      <Typography variant='caption' component='div'>{label}</Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{icon}</span>
        <Typography variant='body1' component='span' sx={sx}>{value}</Typography>
      </div>
    </div>
  );
}