import { useTheme } from '@mui/material';
import { ParticipantStatus } from 'model/status';

export interface IStatusBallProps {
  status:string;
  size?:number;
  marginRight?:number;
}
export default function StatusBall({ status, size, marginRight }:IStatusBallProps) {
  const theme = useTheme();
  let ballColor = '#000';

  if (ParticipantStatus.isPending(status)) {
    ballColor = theme.palette.tertiary.dark;
  } else if (ParticipantStatus.isOnHold(status)) {
    ballColor = theme.palette.tertiary.dark;
  } else if (ParticipantStatus.isActive(status)) {
    ballColor = theme.palette.primary.main;
  } else if (ParticipantStatus.isCompleted(status)) {
    ballColor = theme.palette.primary.main;
  } else if (ParticipantStatus.isFormer(status)) {
    ballColor = theme.palette.secondary.main;
  } else if (ParticipantStatus.isInvited(status)) {
    ballColor = theme.palette.secondary.light;
  } else if (ParticipantStatus.isLead(status)) {
    ballColor = theme.palette.secondary.light;
  } else if (ParticipantStatus.isRemoved(status)) {
    ballColor = theme.palette.tertiary.red || '#000';
  }
  return (
    <div style={{
      backgroundColor: ballColor,
      width: `${size || 20}px`,
      height: `${size || 20}px`,
      borderRadius: `${(size || 20) / 2}px`,
      marginRight: `${marginRight || 10}px`,
    }}></div>
  );
}