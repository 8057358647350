
import { Button, Card, CardContent, Grid, Grow, Typography, useMediaQuery, useTheme } from '@mui/material';
import HelpAlert from 'components/Help/HelpAlert';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { useLayoutEffect, useState } from 'react';
import styles from './requestSupport.module.css';

export default function RequestSupportCard() {

  const theme = useTheme();
  const { openModal } = useConfirmationModal('requestSupportModal');

  return (
    <Grid container alignItems='center' justifyContent={'left'}>
      <Grid item xs={12} sm='auto' textAlign={'left'}>
        <Typography
          variant='body1'
          component='div'
          sx={{
            lineHeight: 'initial',
            fontSize: '14px',
            width: 'auto',
            display: 'inline-block',
            marginRight: '4px',
          }}
          textAlign={'left'}>
          <>Having trouble reaching this customer?</>
        </Typography>
        <Button
          variant='text'
          type='button'
          onClick={openModal}
          sx={{
            fontSize: '14px',
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: '2px',
          }}
        >
          <span>Request Support</span>
        </Button>
      </Grid>
    </Grid>
  );
}

//deprecated. too garish
export function RequestSupportCardOld() {
  const timeout = 250;
  const theme = useTheme();
  const { openModal } = useConfirmationModal('requestSupportModal');
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const mdDownBrk = useMediaQuery(theme.breakpoints.down('md')); //down from md not including md
  const [transition, setTransition] = useState(false);
  useLayoutEffect(() => {
    setTransition(true);
  }, []);

  return (
    <Grow in={transition} timeout={timeout} >
      <Card className={styles.requestSupportCard} sx={{ width: '100%' }}>
        <CardContent className={styles.requestSupportCardContent}>
          <Grid container alignItems={{ xs: 'start', lg: 'center' }} >
            <Grid item xs={12} sm md={7} lg={8}>
              <HelpAlert size="md" className={mdDownBrk ? styles.requestSupportCardAlert : ''} verticallyCenter={lgUp}>Having trouble reaching this customer? Let us know!</HelpAlert>
            </Grid>
            <Grid item xs={12} sm md={5} lg={4} justifyContent={{ xs: 'center', sm: 'end', md: 'end' }} textAlign={{ xs: 'center', sm: 'right' }}>
              <Button
                variant='outlined'
                type='button'
                className={styles.escalateButton}
                onClick={openModal}
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                  padding: {
                    xs: 'auto',
                    sm: '5px 20px',
                  },
                }}
              >
                <span>Request Support</span>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grow>
  );
}