import Axios, { AxiosResponse } from 'axios';
import { IPriceCalculatorParameters, IPriceResult, IProviderPortalPriceResult } from 'model/priceCalculator';

export function calculatePriceForCustomer(serviceProviderId:string, customerId:string):Promise<AxiosResponse<IPriceResult>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/priceCalculator/v2/serviceProviders/${serviceProviderId}/customers/${customerId}`);
}

export function calculatePriceForSubscriber(serviceProviderId:string, subscriberId:string):Promise<AxiosResponse<IPriceResult>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/priceCalculator/v2/serviceProviders/${serviceProviderId}/subscribers/${subscriberId}`);
}

export function calculatePrice(data:IPriceCalculatorParameters):Promise<AxiosResponse<IPriceResult>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/priceCalculator/v2/calculatePrice`, data);
}

export function calculatePriceForProviderPortal(data:IPriceCalculatorParameters):Promise<AxiosResponse<IProviderPortalPriceResult>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/priceCalculator/v2/calculatePriceForProviderPortal`, data);
}