import PercentIcon from '@mui/icons-material/Percent';
import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export interface IHookNumberFieldProps {
  name:string;
  label:string;
  required?:boolean | any;
  maxLength?: string | number;
  validationRules?: any;
  textFieldProps?:any;
  shouldRender?:boolean;
  allowNegative?:boolean;
}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  allowNegative?:boolean;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref:any) {
    const { onChange, allowNegative, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={Number}
        scale={1}
        signed={false}
        radix='.'
        mapToRadix={['.']}
        min={0}
        max={10000}
        unmask={true}
        inputRef={ref}
        onAccept={(value: any, mask:any) => {
          let _value = mask._unmaskedValue;
          return onChange({ target: { name: props.name, value: value } });
        }}
        overwrite
      />
    );
  },
);

export default function HookNumberField({ name, label, required, maxLength, validationRules, shouldRender, allowNegative, ...textFieldProps }:IHookNumberFieldProps | TextFieldProps | any) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    maxLength: {
      value: maxLength || 0, message: `Must be ${maxLength} characters or less`,
    },
    ...validationRules,
  };
  if (!maxLength) {
    delete rules.maxLength;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        if (shouldRender === false) {
          return null as any;
        }
        return (
          <TextField
            fullWidth
            id={`${name}-input`}
            variant='standard'
            label={label}
            inputRef={ref}
            error={isError(name)}
            helperText={(isError(name) && getErrorMessage(name)) || ' '}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
              name,
              onChange,
              allowNegative,
            }}
            InputProps={{
              inputComponent: TextMaskCustom as any,
            }}
            {...textFieldProps}
          />
        );
      }
      }
    />
  );
}