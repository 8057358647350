import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { findProviderStreetfairReviews } from 'api/reviewsApi';
import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import { createDefaultDropdownOption, IDropdownOption, UNSELECTED_OPTION } from 'model/dropdown';
import { createEmptyFindReviewsForProviderRequest, IFindReviewsForProviderRequest, IProviderStreetfairReview } from 'model/reviews';
import logPageView from 'util/analyticsUtil';
import { dedupe } from 'util/arrayUtil';
import { sortByString } from 'util/sortFunctions';

const loadingKey = 'reviews';
type ReviewData = {
  filter:IFindReviewsForProviderRequest;
  reviews: IProviderStreetfairReview[];
  filteredReviews: IProviderStreetfairReview[];
  sortModel:any;
  neighborhoodOptions: IDropdownOption[];
  ratingOptions: IDropdownOption[];
}

function createRatingOption(num:string):IDropdownOption {
  return {
    key: num,
    optionValue: num,
    optionText: num,
  };
}

const { get, update, registerListener, unregisterListener } = createStore<ReviewData>('reviews', {
  filter: createEmptyFindReviewsForProviderRequest(),
  reviews: [],
  filteredReviews: [],
  sortModel: [{
    field: 'reviewDate',
    sort: 'desc' as any,
  }],
  neighborhoodOptions: [createDefaultDropdownOption()],
  ratingOptions: [
    createRatingOption('0.0'),
    createRatingOption('1.0'),
    createRatingOption('2.0'),
    createRatingOption('3.0'),
    createRatingOption('4.0'),
    createRatingOption('5.0'),
  ],
});
const quickSearchStorageKey = 'reviewsTable';
export default function useReviews() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    const { filter } = get();
    onLoading();
    if (!serviceProviderId) {
      console.log('service provider id not defined.');
      doneLoading();
      return;
    }
    logPageView(serviceProviderId);
    let initFilter = createEmptyFindReviewsForProviderRequest();
    let _filter:IFindReviewsForProviderRequest = {
      neighborhoodId: initFilter.neighborhoodId && initFilter.neighborhoodId !== UNSELECTED_OPTION ? initFilter.neighborhoodId : null,
      rating: initFilter.rating && initFilter.rating !== UNSELECTED_OPTION ? initFilter.rating : null,
    };
    const res = await findProviderStreetfairReviews(serviceProviderId, _filter);
    const neighborhoodOptions = extractNeighborhoodOptions(res.data);
    update({
      ...get(),
      reviews: res.data,
      filteredReviews: res.data,
      filter: initFilter,
      neighborhoodOptions,
    });
    doneLoading();
  }

  async function refresh(filter:IFindReviewsForProviderRequest) {
    if (!serviceProviderId) {
      console.log('service provider id not defined.');
      return;
    }
    let _filter:IFindReviewsForProviderRequest = {
      neighborhoodId: filter.neighborhoodId && filter.neighborhoodId !== UNSELECTED_OPTION ? filter.neighborhoodId : null,
      rating: filter.rating && filter.rating !== UNSELECTED_OPTION ? filter.rating : null,
    };
    const res = await findProviderStreetfairReviews(serviceProviderId, _filter);
    update({
      ...get(),
      filteredReviews: res.data,
      filter,
    });
  }

  function extractNeighborhoodOptions(data:IProviderStreetfairReview[]):IDropdownOption[] {
    const optionsWithDupes = data
      .map( x=> ({
        key: x.neighborhoodId,
        optionValue: x.neighborhoodId,
        optionText: x.neighborhoodName,

      }))
      .filter( x=> x.key && x.optionText);
    const deduped = dedupe(optionsWithDupes, 'key');
    let result = Array.from(deduped);
    result.unshift(createDefaultDropdownOption());
    result.sort(sortByString('optionText'));
    return result as IDropdownOption[];
  }

  async function onTableFilterChange(propertyName, propertyValue) {
    const { filter } = get();
    const nextFilter = { ...filter };
    nextFilter[propertyName] = propertyValue;
    await refresh(nextFilter);
  }

  function onSortModelChange(sortModel) {
    update({
      ...get(),
      sortModel,
    });
  }

  async function resetFiltersToDefault() {
    let nextFilter = createEmptyFindReviewsForProviderRequest();
    await refresh(nextFilter);
  }

  return {
    loadingKey,
    quickSearchStorageKey,
    ...get(),
    init,
    refresh,
    onTableFilterChange,
    onSortModelChange,
    resetFiltersToDefault,
  };
}