

import get from 'lodash.get';
import { useState } from 'react';
import { getStoredSaveQuickSearch, getStoredSearchValue, setStoredSearchValue } from './storage';

export interface ICustomSearch {
  map:Map<string, (searchString:string, fieldValue:any, row?:any) => boolean>;
}

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default function useTableSearch(storageKey?:string, customSearch?:ICustomSearch, searchablePropertyKeys?:string[]) {

  let defaultSearchText ='';
  if (storageKey) {
    const saveQuickSearch = getStoredSaveQuickSearch(storageKey);
    if (saveQuickSearch) {
      const storedSearchValue = getStoredSearchValue(storageKey);
      defaultSearchText = storedSearchValue || '';
    }
  }
  const [rows, setRows] = useState<any[]>([]);
  const [searchText, setSearchText] = useState(defaultSearchText);

  function setRowsWithStoredFilter(allRows) {

    if (searchText) {
      const filteredRows = filterRows(allRows, searchText);
      setRows(filteredRows);
    } else {
      setRows(allRows);
    }
  }

  function clearStoredSearch() {
    if (storageKey) {
      setStoredSearchValue(storageKey, '');
      setSearchText('');
    }
  }

  function requestSearch(allRows, searchValue: string) {
    setSearchText(searchValue);
    if (storageKey) {
      const saveQuickSearch = getStoredSaveQuickSearch(storageKey);
      if (saveQuickSearch) {
        setStoredSearchValue(storageKey, searchValue);
      }
    }
    const filteredRows = filterRows(allRows, searchValue);
    setRows(filteredRows);
  };

  function filterRows(allRows:any[], searchValue:string) {

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

    return allRows.filter((row: any) => {
      if (searchablePropertyKeys) {
        return searchablePropertyKeys.some((field: any) => {
          if (customSearch?.map.has(field)) {
            const searchFunc = customSearch.map.get(field);
            if (searchFunc) {
              return searchFunc(searchValue, get(row, field), row);
            } else {
              console.error('Attempted to use an undefined search function for field: ', field);
              return false;
            }
          }
          if (get(row, field) === null || get(row, field) === undefined || get(row, field) === '') {
            return false;
          }
          return searchRegex.test(get(row, field, '').toString());
        });
      }
      return Object.keys(row).some((field: any) => {
        if (customSearch?.map.has(field)) {
          const searchFunc = customSearch.map.get(field);
          if (searchFunc) {
            return searchFunc(searchValue, row[field]);
          } else {
            console.error('Attempted to use an undefined search function for field: ', field);
            return false;
          }
        }
        if (row[field] === null || row[field] === undefined) {
          return false;
        }
        return searchRegex.test(row[field].toString());
      });
    });
  }

  return {
    rows,
    searchText,
    setRows,
    setRowsWithStoredFilter,
    requestSearch,
    clearStoredSearch,
  };
}