import { TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export interface IHookTextFieldProps {
  name:string;
  label:string;
  required?:boolean | any;
  maxLength?: string | number;
  validationRules?: any;
  textFieldProps?:any;
  helperTextDefault?:string;
  shouldRender?:boolean;
  postOnChange?: Function;
}

export default function HookTextField({ name, label, required, maxLength, validationRules, helperTextDefault, shouldRender, postOnChange, ...textFieldProps }:IHookTextFieldProps | TextFieldProps |any) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    maxLength: {
      value: maxLength || 0, message: `Must be ${maxLength} characters or less`,
    },
    ...validationRules,
  };
  if (!maxLength) {
    delete rules.maxLength;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        if (shouldRender === false) {
          return null as any;
        }
        return (
          <TextField
            fullWidth
            id={`${name}-input`}
            variant='standard'
            label={label}
            inputRef={ref}
            error={isError(name)}
            helperText={(isError(name) && getErrorMessage(name)) || (helperTextDefault !== undefined ? helperTextDefault : ' ')}
            value={value || ''}
            onChange={(e) => {
              onChange(e);
              if (postOnChange) {
                // note that if you are using a postOnChange to call the RHF trigger function for cross field validation
                // that useWatch() is useless and you need to use getValues() within the validate method(s) that is/are triggered
                postOnChange(e);
              }
            }}
            onBlur={onBlur}
            {...textFieldProps}
          />
        );
      }
      }
    />
  );
}