import { Grid, Typography } from '@mui/material';
import useRemoveGroupDealStore from '../hookStore/useRemoveGroupDeal';
import styles from './confirmGroupDealDeleteModal.module.css';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useToast from 'components/toast/useToast';


export default function ConfirmGroupDealDeleteModal() {
  const { createSuccessToast } = useToast();
  const { deleteGroupDeal, modalKey, getFormattedServiceTypeAndServiceDates, canDeleteGroupDeal } = useRemoveGroupDealStore();
  return (
    <HookConfirmationModal
      modalKey={modalKey}
      title={() => (<div className={styles.confirmDeleteModalTitle}>Delete group deal?</div>)}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.requestSupportModalBody}>
          <Grid item xs={12}>
            {canDeleteGroupDeal() && (
              <Typography variant='body1'>The group deal for for {getFormattedServiceTypeAndServiceDates()} will be deleted. All non-placeholder customers must be moved to another group deal or removed from service.
              </Typography>
            )}
            {!canDeleteGroupDeal() && (
              <Typography variant='body1'>You must remove or move existing non-placeholder customers before deleting this deal.</Typography>
            )}
          </Grid>
        </Grid>
      )}
      isDisabled={!canDeleteGroupDeal()}
      onConfirm={async () => {
        await deleteGroupDeal();
      }}
    />
  );
}