import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MapIcon from '@mui/icons-material/Map';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleIcon from '@mui/icons-material/People';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import TaskIcon from '@mui/icons-material/Task';
import { Box, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CloseButton } from 'components/buttons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DemoUtil } from 'util/demoUtil';
import useUserProfileDrawer from './useUserProfileDrawer';
import styles from './userProfileDrawer.module.css';

function ProfileDivider() {
  return (
    <div className={styles.profileDivider}></div>
  );
}

function ProfileListLink({ iconComponent, text, link }) {
  return (
    <ListItemButton onClick={(e) => {

    }} className={styles.profileListItem}>
      <ListItemIcon sx={{
        '&.MuiListItemIcon-root': {
          minWidth: '48px',
        },
      }}>
        {iconComponent}
      </ListItemIcon>
      <ListItemText primary={<a href={link} target="_blank" rel="noreferrer" >{text}</a>}></ListItemText>
      <OpenInNewIcon color='primary'/>
    </ListItemButton>
  );
}

function ProfileListItem({ iconComponent, text, onClick }) {
  return (
    <ListItemButton onClick={onClick} className={styles.profileListItem}>
      <ListItemIcon sx={{
        '&.MuiListItemIcon-root': {
          minWidth: '48px',
        },
      }}>
        {iconComponent}
      </ListItemIcon>
      <ListItemText primary={text}></ListItemText>
      <ArrowForwardIosIcon color='primary'/>
    </ListItemButton>
  );
}

function ProfileHeader() {
  const theme = useTheme();
  const { closeProfile } = useUserProfileDrawer();
  return (
    <div className={styles.closeProfile}>
      <Typography variant='h5' component='div' className={styles.title}>My Profile</Typography>
      <CloseButton
        tooltipTitle="close pane"
        onClick={closeProfile}
        className={styles.closeProfileButton}
        iconButtonProps={{
          color: 'primary',
        }}
      />
    </div>
  );
}

export default function UserProfileDrawer() {
  const theme = useTheme();
  const xsBrk = useMediaQuery(theme.breakpoints.only('xs'));
  const { reviewsManagement } = useFlags();
  const {
    anchor,
    open,
    isProviderAdmin,
    navigateToV0,
    navigateToSwitchProvider,
    navigateToCustomers,
    navigateToGroupDeals,
    navigateToReviews,
    signOut,
    init,
    navigateToTermsAndConditions,
    toggleDemoMode,
  } = useUserProfileDrawer();


  return (
    <Drawer
      anchor={anchor}
      open={open}
      className={xsBrk ? styles.wrapperMobile : styles.wrapper}
      classes={{
        paper: xsBrk ? 'fullWidthDrawerRoot' : '',
      }}
    >
      <Box className={xsBrk ? styles.wrapperMobile : styles.wrapper}>
        <ProfileHeader/>
        <List>
          <ProfileDivider/>
          <ProfileListItem
            iconComponent={<DoorFrontIcon/>}
            text='Sign Out'
            onClick={signOut}
          />
          {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
            <>
              <ProfileDivider/>
              <ProfileListItem
                iconComponent={<BlurCircularIcon/>}
                text='V0 pages'
                onClick={navigateToV0}
              />
              <ProfileDivider/>
              <ProfileListItem
                iconComponent={<SwapHorizontalCircleIcon/>}
                text='Switch Provider'
                onClick={navigateToSwitchProvider}
              />

            </>
          )}
          <ProfileDivider/>
          <ProfileListItem
            iconComponent={<PeopleIcon/>}
            text='Customers'
            onClick={navigateToCustomers}
          />
          {reviewsManagement && (
            <>
              <ProfileDivider/>
              <ProfileListItem
                iconComponent={<ReviewsIcon/>}
                text='Reviews'
                onClick={navigateToReviews}
              />
            </>
          )}

          <>
            <ProfileDivider/>
            <ProfileListItem
              iconComponent={<LocalOfferIcon/>}
              text='Group Deals'
              onClick={navigateToGroupDeals}
            />
          </>
          <ProfileDivider/>
          <ProfileListItem
            iconComponent={<TaskIcon/>}
            text='Partnership Agreement'
            onClick={navigateToTermsAndConditions}
          />
          <ProfileDivider/>
          <ProfileListLink
            iconComponent={<MapIcon/>}
            text='All StreetFair Neighborhoods'
            link={'https://www.google.com/maps/d/u/0/viewer?mid=1ABAsW0YyRej4txNgRM6X-5ucAvc0KX8&ll=35.264473213499436%2C-80.75423602705486&z=10'}
          />
          <ProfileDivider/>
          <ProfileListLink
            iconComponent={<PaymentIcon/>}
            text='Billing'
            link={'https://billing.stripe.com/p/login/14k9CybIHdZpa2YaEE'}
          />
          {isProviderAdmin && (
            <>
              <ProfileDivider/>
              <ProfileListItem
                iconComponent={DemoUtil.isDemoMode(isProviderAdmin) ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                text={DemoUtil.isDemoMode(isProviderAdmin) ? 'Demo Mode On' : 'Demo Mode Off'}
                onClick={toggleDemoMode}
              />
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
}