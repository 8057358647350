import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getChargesForCustomer } from 'api/paymentsApi';
import ChargeCard from 'components/chargeCard/chargeCard';
import PageHeader from 'components/SectionHeaders/PageHeader';

export default function PaymentDetails (props: any) {
  const [chargeList, setChargeList] = useState<any>([]);

  useEffect(() => {
    getCharges();
  }, []);

  const getCharges = () => {
    if (props.customer) {
      void getChargesForCustomer(props.customer.individualId, props.serviceProviderId, props.customer.customerId, props.customer.subscriberId).then((res: any) => {
        const data = (res.data.charges.data as Array<any>).map(x => {
          return {
            id: x.id,
            amount: `$${x.amount / 100}`,
            created: format(new Date(x.created * 1000), 'MM/dd/yyyy'),
            details: `...${x.payment_method_details.card.last4}`,
            status: x.status,
          };
        });
        setChargeList(data);
      });
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', hide: true },
    {
      field: 'amount', headerName: 'Amount', width: 200,
    },
    { field: 'created', headerName: 'Date/Time Charged', width: 250 },
    { field: 'details', headerName: 'Last 4 of Card', width: 250 },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  const sortModel = [{
    field: 'created',
    sort: 'asc' as any,
  }];

  return (
    <Col sm={12} md={6}>
      <Row style={{
        borderBottom: '1px solid #26995F',
        padding: '10px',
      }}>
        <PageHeader headerText={'PAYMENT DETAILS'} />
      </Row>
      <Row>
        {props.customer &&
          <ChargeCard
            providerId={props.serviceProviderId}
            email={props.customer.email}
            individualId={props.customer.individualId}
            customerId={props.customer.customerId}
            subscriberId={props.customer.subscriberId}
            fetchCharges={getCharges}
          />
        }
      </Row>
      <DataGrid rows={chargeList}
        columns={columns}
        autoHeight={true}
        hideFooter={true}
        sortModel={sortModel}
        style={{
          minWidth: '500px',
          maxWidth: '1200px',
          marginTop: '16px',
        }}
      />
    </Col>
  );
}