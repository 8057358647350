import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import HelpIcon from '@mui/icons-material/Help';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import Footer from 'components/footer';
import Loading from 'components/layout/Loading';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createDateIgnoreTimezone, formatMonthDayYear } from 'util/dateUtil';
import BulletPoints from './BulletPoints';
import CustomerSelect from './CustomerSelect';
import DirectLink from './DirectLink';
import HappeningSoonDemo from './HappeningSoonDemo';
import MessageCopy from './MessageCopy';
import styles from './groupDealShare.module.css';
import useGroupDealShare from './hookStore/useGroupDealShare';

const loadingKey = 'groupDealShare';

export default function GroupDealShare() {
  const { serviceProviderId, groupDealId, context } = useParams();
  const navigate = useNavigate();
  const { init, groupDealShareData, serviceProvider, shouldShowCustomerDropdown, isActiveNeighborhood } = useGroupDealShare();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isShare = context === 'share';
  const isNew = context === 'new';
  const { trackPageView } = useStreetfairAnalytics();
  useEffect(() => {
    void init();
    window.scrollTo(0, 0);
    void trackPageView('Group_Deal_Share');
  }, []);
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={10} xl={8} className={styles.pageContainer}>
          <Grid container item xs='auto' alignItems='center' wrap="nowrap" className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} >
            <CalendarTodayIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}/groupDeals`}>Group Deals</Link>
              <Link
                to={`/v1/${serviceProviderId}/groupDeals/${groupDealId}/groupDealShare/${context}`}
                className={styles.lastLink}
              >{context === 'share' ? 'Share Deal' : 'New Group Deal'}</Link>
            </Breadcrumbs>
          </Grid>
          <Grid container item xs={12} justifyContent='center' className={smDown ? styles.mobileMainContent : styles.mainContent}>
            <Grid container item xs={12} lg={10} justifyContent='center'>
              <Grid item xs={12} >
                <Typography
                  variant='h4'
                  component='div'
                  className={smDown ? styles.mobileHeader : styles.header}
                  textAlign='left'
                >
                  <>Group Deal {isShare ? 'Share' : 'Created'}</>
                </Typography>
              </Grid>
              {isNew && <HappeningSoonDemo/> }
              <Grid container item xs={12} textAlign='left' alignItems={{ xs: 'start', sm: 'center' }} className={styles.pageSubHeader}>
                <Grid item xs='auto'>
                  <FacebookRoundedIcon className={styles.facebookIcon}/>
                </Grid>
                <Grid item xs>
                  <Typography variant='h6' component='div' >
                    <>How to get more customers</>
                  </Typography>
                </Grid>
              </Grid>
              {shouldShowCustomerDropdown && (
                <>
                  <Grid container item xs={12} sm={8} md={6} textAlign='left' alignItems='center' className={styles.customerSelect}>
                    <Grid item xs>
                      <CustomerSelect/>
                    </Grid>
                    <Grid item xs='auto' sx={{ marginLeft: 3 }}>
                      <Tooltip title={'Select an existing customer to customize the following message and StreetFair link to their neighborhood.'} enterTouchDelay={0} leaveTouchDelay={5000}>
                        <HelpIcon sx={{ color: theme.textColor.secondary }} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} sm={4} md={6}></Grid>
                </>
              )}
              <Grid item xs={12}>
                <BulletPoints
                  neighborhoodName={groupDealShareData.neighborhoodName}
                />
              </Grid>
              <Grid item xs={12} textAlign='left'>
                <MessageCopy
                  neighborhoodName={groupDealShareData.neighborhoodName}
                  serviceDate={formatMonthDayYear((createDateIgnoreTimezone(groupDealShareData.serviceDate) || new Date().toISOString()))}
                  serviceProviderName={serviceProvider.name}
                  serviceTypeName={groupDealShareData.serviceTypeName}
                  nsoId={groupDealShareData.neighborhoodServiceOfferingId}
                  nsoiId={groupDealShareData.neighborhoodServiceOfferingInstanceId}
                  isActiveNeighborhood={isActiveNeighborhood}
                />
              </Grid>
              <Grid item xs={12} >
                <DirectLink
                  nsoId={groupDealShareData.neighborhoodServiceOfferingId}
                  nsoiId={groupDealShareData.neighborhoodServiceOfferingInstanceId}
                  isActiveNeighborhood={isActiveNeighborhood}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='button'
                  variant='contained'
                  className={styles.viewAllGroupDealsButton}
                  onClick={() => navigate(`/v1/${serviceProviderId}/groupDeals`)}
                >
                  View All Group Deals
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Footer className={smDown ? styles.mobileFooter : styles.footer}/>
        </Grid>
      </Grid>
    </Loading>
  );
}