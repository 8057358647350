import { DateRange } from '@mui/x-date-pickers-pro';
import { createEmptyGroupDealCustomer, ICustomer } from './customer';
import { IDiscountSchedule, IDiscountTableRow } from './discount';

export const NO_MAX_CUSTOMER_COUNT_DEFAULT_VALUE = 999999;
export interface IGroupDealForm {
  id?: string;
  serviceDates: DateRange<Date>;
  serviceDate: Date | null;
  endServiceDate: Date | null;
  serviceTypeName: string;
  serviceOfferingId: string;
  neighborhoodId: string;
  neighborhoodName: string;
  maxCustomerCount: number | null;
  customers: ICustomer[];
  discountScheduleKey: string;
  discountScheduleId: string;
  discountScheduleVersion: string;
  nsoPrice: string | null;
  vendorDriven: boolean;
  territoryEnabled: boolean;
  superDeal:boolean;
  maxCustomerSwitchToggled:boolean;
  shouldSendHappeningSoonAlert:boolean;
  shouldSendPriceDropAlert:boolean;
  shouldCloseGroupDeal: boolean;
  createNeighborhoodRequestDTO?: ICreateNeighborhoodRequest;
}
export interface ICreateNeighborhoodRequest {
  neighborhoodName: string;
  placeId: string;
  zipCode: string;
}
export function createEmptyCreateNeighborhoodRequest(): ICreateNeighborhoodRequest {
  return {
    neighborhoodName: '',
    placeId: '',
    zipCode: '',
  };
}
export function createEmptyGroupDealForm(defaultMaxCustomerCount?: number): IGroupDealForm {

  return {
    id: '',
    serviceDates: [null, null],
    serviceDate: null,
    endServiceDate: null,
    serviceTypeName: '',
    serviceOfferingId: '',
    neighborhoodId: '',
    neighborhoodName: '',
    maxCustomerCount: defaultMaxCustomerCount ?? 10,
    customers: [createEmptyGroupDealCustomer()],
    discountScheduleKey: '',
    discountScheduleId: '',
    discountScheduleVersion: '',
    nsoPrice: '',
    vendorDriven: true,
    territoryEnabled: true,
    superDeal: false,
    maxCustomerSwitchToggled: false,
    shouldSendHappeningSoonAlert: false,
    shouldSendPriceDropAlert: false,
    shouldCloseGroupDeal: false,
    createNeighborhoodRequestDTO: createEmptyCreateNeighborhoodRequest(),
  };
}

export interface IMoveToGroupDealForm {
  originNsoiId: string | null;
  customerId: string;
  nsoiId: string | null;
  startDate: string | null;
  endDate: string | null;
  maxCustomerCount: number | null;
  placeholderMove:boolean;
  shouldSendPriceDropAlert:boolean;
  shouldSendHappeningSoonAlert:boolean;
}

export function createEmptyMoveToGroupDealForm(customerId: string): IMoveToGroupDealForm {
  return {
    originNsoiId: null,
    customerId,
    nsoiId: '',
    startDate: '',
    endDate: '',
    maxCustomerCount: 1,
    shouldSendPriceDropAlert: false,
    shouldSendHappeningSoonAlert: false,
    placeholderMove: false,
  };
}

export interface IMoveGroupDealError {
  serviceDatesError: string | null;
  maxCustomerCountError: string | null;
}

export interface IDiscountTableRequest {
  participantCount: number | null;
  maxCustomerCount: number | null;
  nsoPrice: number | null;
  discountScheduleId: string | null;
  customers: ICustomer[];
}

export interface IDiscountTableData {
  discountTable: IDiscountTableRow[];
  discountSchedule: IDiscountSchedule;
}

export interface ICustomerAccordionState {
  expanded:boolean;
  loading:boolean;
  shouldShowMoveToGroupDeal:boolean;
  shouldShowMoveToPlaceholderGroupDeal:boolean;
}

export function createEmptyCustomerAccordionState():ICustomerAccordionState {
  return {
    expanded: false,
    loading: false,
    shouldShowMoveToGroupDeal: false,
    shouldShowMoveToPlaceholderGroupDeal: false,
  };
}