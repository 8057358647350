

export function isNullOrUndefined(x:any) {
  return x === null || x === undefined;
}

export function isNullOrUndefinedOrEmptyArray(x:any) {
  if (x === null || x === undefined) {
    return true;
  }
  if (!Array.isArray(x)) {
    return true;
  }
  return x.length === 0;
}