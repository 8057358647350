
import { findServiceInstanceById } from 'api/serviceInstanceApi';
import { useLoading } from 'components/layout/Loading';
import useModal from 'components/modals/useModal';
import { IServiceCardProps, createEmptyServiceCardProps } from 'components/serviceCard';
import createStore from 'hooks/hookStore';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { DiscountScheduleTypes, ParticipantStatus } from 'model/ancillary';
import { IProviderCustomerDetail } from 'model/customer';
import { IServiceProvider } from 'model/serviceProvider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IModalSlideProps, createConfirmationEmailInviteMore, createConfirmationEmailMaxCustomers, createHappeningSoonAddedCustomer, createHappeningSoonDefaultVariant, createHappeningSoonFullyBooked, createHappeningSoonPastCutoffDate, createNeighborsNotified, createNeighborsNotifiedSubscriberVariant, createPriceDropAlert } from '../postConfirmModal/modalSlide';

export type PostConfirmModalStore = {
  showConfetti: boolean;
  activeStep: number;
  stepCount: number;
  happeningSoonCompatibleNsoi: IServiceCardProps;
  slides: IModalSlideProps[];
}

const { get, update, registerListener, unregisterListener } = createStore<PostConfirmModalStore>('postConfirmModalStore', {
  showConfetti: false,
  activeStep: 0,
  stepCount: 4,
  happeningSoonCompatibleNsoi: createEmptyServiceCardProps(),
  slides: [],
});

export const wizardModalKey = 'customerConfirmationModalKey';


export default function usePostConfirmModal() {
  const { customerId, subscriberId } = useParams();
  const setState = useState(get())[1];
  const modal = useModal(wizardModalKey);
  const { onLoading, doneLoading } = useLoading(wizardModalKey);
  const { trackPageView } = useStreetfairAnalytics();
  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(customer:IProviderCustomerDetail, provider:IServiceProvider) {
    onLoading();
    if (customerId) {
      await handleModalInitForCustomer(customer, provider);
    } else if (subscriberId) {
      await handleModalInitForSubscriber(customer, provider);
    }
    doneLoading(200);
  }

  async function handleModalInitForCustomer(customer:IProviderCustomerDetail, provider:IServiceProvider) {
    const realActiveOrCompleteCustomers = customer.customers.filter(x => !x.isPlaceholder && (x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE));
    const realCustomerCount = realActiveOrCompleteCustomers.length;
    const otherCustomerCount = realActiveOrCompleteCustomers.filter(x => x.id !== customer.id).length;
    const hasExistingActiveCustomer = otherCustomerCount > 0;
    const isFirstCustomer = !hasExistingActiveCustomer;

    const isAtMaxCustomerCount = customer.maxCustomerCount !== undefined && realCustomerCount >= customer.maxCustomerCount;
    const isBookingPeriodOpen = customer.bookingPeriodOpen;
    const wasLastCustomer = otherCustomerCount === realCustomerCount - 1;
    const hasDiscount = customer.discountSchedule.type !== DiscountScheduleTypes.NoDiscount.value;
    const isAtMaxDiscount = customer.discountTable && customer.discountTable.length > 0 && customer.discountTable[customer.discountTable.length - 1].currentRow;
    const shouldShowPriceDropAlert = hasDiscount && (!isAtMaxDiscount || (isAtMaxDiscount && wasLastCustomer));
    const happeningSoonCompatibleNsoi = await getHappeningSoonData(provider, customer);


    let nextSlides:IModalSlideProps[] = [];
    if (isFirstCustomer && (!isAtMaxCustomerCount && isBookingPeriodOpen)) {
      nextSlides = createSlidesForFirstCustomerConfirmedAndRoomOnServiceVisit(customer);
    } else if (isFirstCustomer && (isAtMaxCustomerCount || !isBookingPeriodOpen)) {
      nextSlides = createSlidesForFirstCustomerConfirmedAndNoRoom(customer, isAtMaxCustomerCount, isBookingPeriodOpen);
    } else if (!isFirstCustomer && (!isAtMaxCustomerCount && isBookingPeriodOpen)) {
      nextSlides = createSlidesForAdditionalCustomerConfirmedAndRoomOnServiceVisit(customer, shouldShowPriceDropAlert);
    } else if (!isFirstCustomer && (isAtMaxCustomerCount || !isBookingPeriodOpen)) {
      nextSlides = createSlidesForAdditionalCustomerConfirmedAndNoRoom(customer, isAtMaxCustomerCount, isBookingPeriodOpen, shouldShowPriceDropAlert);
    }
    update({
      ...get(),
      happeningSoonCompatibleNsoi,
      slides: nextSlides,
      activeStep: 0,
      stepCount: nextSlides.length,
    });
    openModal();
  }

  async function handleModalInitForSubscriber(subscriber:IProviderCustomerDetail, provider:IServiceProvider) {
    let nextSlides:IModalSlideProps[] = [];
    nextSlides = createSlidesForSubscriberConfirmation(subscriber);
    update({
      ...get(),
      slides: nextSlides,
      activeStep: 0,
      stepCount: nextSlides.length,
    });
    openModal();
  }

  function createSlidesForFirstCustomerConfirmedAndRoomOnServiceVisit(customer:IProviderCustomerDetail):IModalSlideProps[] {
    let nextSlides:IModalSlideProps[] = [];
    nextSlides.push(createHappeningSoonDefaultVariant(customer));
    nextSlides.push(createNeighborsNotified());
    nextSlides.push(createConfirmationEmailInviteMore(customer));
    return nextSlides;
  }

  function createSlidesForFirstCustomerConfirmedAndNoRoom(customer:IProviderCustomerDetail, isAtMaxCustomerCount:boolean, isBookingPeriodOpen:boolean):IModalSlideProps[] {
    let nextSlides:IModalSlideProps[] = [];
    if (isAtMaxCustomerCount) {
      nextSlides.push(createHappeningSoonFullyBooked());
    } else if (!isBookingPeriodOpen && !isAtMaxCustomerCount) {
      nextSlides.push(createHappeningSoonPastCutoffDate());
    }
    nextSlides.push(createConfirmationEmailMaxCustomers(customer, 'Customer Scheduled, Great!'));
    return nextSlides;
  }

  function createSlidesForAdditionalCustomerConfirmedAndRoomOnServiceVisit(customer:IProviderCustomerDetail, shouldShowPriceDropAlert:boolean):IModalSlideProps[] {
    let nextSlides:IModalSlideProps[] = [];
    nextSlides.push(createHappeningSoonAddedCustomer());
    if (shouldShowPriceDropAlert) {
      nextSlides.push(createPriceDropAlert());
    }
    nextSlides.push(createConfirmationEmailInviteMore(customer));
    return nextSlides;
  }

  function createSlidesForAdditionalCustomerConfirmedAndNoRoom(customer:IProviderCustomerDetail, isAtMaxCustomerCount:boolean, isBookingPeriodOpen:boolean, shouldShowPriceDropAlert:boolean):IModalSlideProps[] {
    let nextSlides:IModalSlideProps[] = [];
    if (isAtMaxCustomerCount) {
      nextSlides.push(createHappeningSoonFullyBooked());
    } else if (!isBookingPeriodOpen) {
      nextSlides.push(createHappeningSoonPastCutoffDate());
    }
    if (shouldShowPriceDropAlert) {
      nextSlides.push(createPriceDropAlert());
    }
    nextSlides.push(createConfirmationEmailMaxCustomers(customer));
    return nextSlides;
  }

  function createSlidesForSubscriberConfirmation(customer:IProviderCustomerDetail):IModalSlideProps[] {
    return [
      createConfirmationEmailMaxCustomers(customer),
      createNeighborsNotifiedSubscriberVariant(),
    ];
  }

  function handleNext() {
    const { activeStep } = get();
    const nextStep = activeStep + 1;
    update({
      ...get(),
      activeStep: nextStep,
    });
  }

  function handleBack() {
    const { activeStep } = get();
    const nextStep = activeStep - 1;
    update({
      ...get(),
      activeStep: nextStep,
    });

  }

  function openModal() {
    void trackPageView('Customer_Confirmed_Modal');
    modal.openModal({});
    update({
      ...get(),
      showConfetti: true,
    });
  }

  async function getHappeningSoonData(serviceProvider:IServiceProvider, customerDetails:IProviderCustomerDetail):Promise<IServiceCardProps> {
    const nsoiRes = await findServiceInstanceById(serviceProvider.id, customerDetails.neighborhoodServiceOfferingInstanceId);
    return convertForHappeningSoonCard(nsoiRes.data, serviceProvider, customerDetails);
  }

  function convertForHappeningSoonCard(nsoi: any, serviceProvider:IServiceProvider, customerDetails:IProviderCustomerDetail):IServiceCardProps {

    const customers = customerDetails.customers.filter(x => !x.isPlaceholder && (x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE));
    const customerCount = customers.length;
    var serviceType = nsoi.serviceTypeName;
    if (nsoi.serviceTypeNameOverride) {
      serviceType = nsoi.serviceTypeNameOverride;
    }
    return {
      id: `happening-soon-card-${nsoi.neighborhoodServiceOfferingId.toUpperCase()}`,
      maxCustomerCount: nsoi.maxCustomerCount,
      beginServiceDate: nsoi.serviceDate,
      endServiceDate: nsoi.endServiceDate ? nsoi.endServiceDate : nsoi.serviceDate,
      cutoffDayCount: nsoi.cutoffDayCount,
      serviceType: serviceType,
      serviceProvider,
      customers,
      customerCount,
      altColor: false,
      maxAvatarIcons: 5,
    };
  }

  function confettiCallback() {
    update(
      {
        ...get(),
        showConfetti: false,
      },
    );
  }


  return {
    ...get(),
    wizardModalKey,
    ...modal,
    openModal,
    handleNext,
    handleBack,
    init,
    confettiCallback,

  };
}