import { IItemMetadata } from './ancillary';

export interface INeighborhood {
  id: string;
  name: string;
  legacyId: number;
  city: string;
  state: string;
  zipCode: string;
  zipCodes: string[];
  status: string;
  latitude: string;
  longitude: string;
  onboardingCompletion: number;
  waitlistNumber: number;
  applicantName:string;
  applicationComment:string;
  applicantEmail:string;
  itemMetadata?: IItemMetadata;
  forceAuth:boolean;
}

export function createEmptyNeighborhood():INeighborhood {
  return {
    id: '',
    name: '',
    legacyId: -1,
    city: '',
    state: '',
    zipCode: '',
    zipCodes: [],
    status: '',
    latitude: '',
    longitude: '',
    onboardingCompletion: -1,
    waitlistNumber: -1,
    forceAuth: false,
    applicantName: '',
    applicationComment: '',
    applicantEmail: '',
  };
}

export const NeighborhoodStatus = {
  ACTIVE: 'ACTIVE',
  APPLICATION_RECEIVED: 'APPLICATION_RECEIVED',
  BETA: 'BETA',
  ALPHA: 'ALPHA',
  PENDING: 'PENDING', //deprecated
  GLOBAL_ENTRY_FLOW_CREATED: 'GLOBAL_ENTRY_FLOW_CREATED',
  LAUNCHED: 'LAUNCHED',
};

export function alphaOrBeta(status:string):boolean {
  return status === NeighborhoodStatus.ALPHA || status === NeighborhoodStatus.BETA;
}

export function activeOrGreater(status:string):boolean {
  return status === NeighborhoodStatus.ACTIVE || status === NeighborhoodStatus.LAUNCHED;
}