import { Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './requestNeighborhood.module.css';
import { requestSupport } from 'api/serviceProviderApi';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useToast from 'components/toast/useToast';
import { IProviderSupportRequest, ProviderSupportRequestTypes } from 'model/serviceProvider';

export default function RequestNeighborhoodModal() {
  const { serviceProviderId } = useParams();
  const [message, setMessage] = useState<string>('');
  const { createSuccessToast } = useToast();
  return (
    <HookConfirmationModal
      modalKey={'requestNeighborhoodModal'}
      title={() => (<div className={styles.confirmDeleteModalTitle}>Submit Request for Neighborhood?</div>)}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.requestSupportModalBody}>
          <Grid item xs={12}>
                        Streetfair operations will be notified. In addition to the neighborhood name, please add the relevant zip codes.
          </Grid>
          <Grid item xs={12} className={styles.requestSupportMessage}>
            <TextField
              fullWidth
              multiline
              minRows={3}
              label='Message'
              value={message}
              error={message.length > 2000}
              helperText={message.length > 2000 ? 'Max 2000 characters' : '' }
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      )}
      onConfirm={async () => {
        if (serviceProviderId && message.length <= 2000) {
          let data:IProviderSupportRequest = {
            supportRequestType: ProviderSupportRequestTypes.GROUP_DEAL_NEW_NEIGHBORHOOD_REQUEST,
            serviceProviderId,
            message,
          };
          try {
            await requestSupport(data);
            createSuccessToast('Request for support received.');
          } catch (e:any) {

          }
        }
      }}
    />
  );
}