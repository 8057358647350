import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import styles from './home.module.css';

export interface IPageBoxProps {
  title:string;
  description:string;
  buttonText:string;
  navigation:Function;
}

export default function PageBox({ title, description, buttonText, navigation }:IPageBoxProps) {

  return (
    <Card>
      <CardContent >
        <Grid container className={styles.pageBoxContentWrapper}>
          <Grid item xs={12} textAlign='left'>
            <Typography variant='subtitle2' className={styles.pageBoxTitle}>{title}</Typography>
          </Grid>
          <Grid item xs={12} textAlign='left'>
            <Typography variant='body1' className={styles.pageBoxDescription}>{description}</Typography>
          </Grid>
          <Grid item xs={12} textAlign={{ xs: 'center', sm: 'right' }} alignSelf={{ xs: 'center', sm: 'flex-end' }}>
            <Button
              variant='contained'
              type='button'
              onClick={() => navigation()}
              className={styles.pageBoxNavigationButton}
            >
              <>{buttonText}</>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}