import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { moveGroupDeal } from 'api/serviceInstanceApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { IGroupDealCustomer } from 'model/customer';
import { IMoveToGroupDealForm, NO_MAX_CUSTOMER_COUNT_DEFAULT_VALUE } from 'model/groupDeal';
import { INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';

type MoveGroupDealStore = {
  warningMessage: string | null;
}

const { get, update, registerListener, unregisterListener } = createStore<MoveGroupDealStore>('moveGroupDealStore', {
  warningMessage: null,
});

const loadingKey = 'groupDeal';

export default function useMoveGroupDealStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();
  const { createSuccessToast, createInfoToast, createErrorToast } = useToast();
  const { serviceProviderId, groupDealId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function save(
    data:IMoveToGroupDealForm,
    customer: IGroupDealCustomer,
    onlyOneCustomer: boolean,
    shouldShowMaxCustomerCount: boolean,
    onRemove:()=> void,
    setError: (errors:any) => void,
  ) {
    onLoading();
    update({
      ...get(),
      warningMessage: null,
    });
    const dataToSave = { ...data };


    if (!dataToSave.nsoiId && groupDealId) {
      dataToSave.nsoiId = null;
      dataToSave.originNsoiId = groupDealId;
      let maxCustomerCountError:string | null = null;
      let serviceDatesError:string | null = null;
      if (!dataToSave.placeholderMove && !data.maxCustomerCount && shouldShowMaxCustomerCount) {
        maxCustomerCountError = 'Must supply a max customer count greater than 0';
      }
      if (!shouldShowMaxCustomerCount || dataToSave.placeholderMove) {
        //if we're not showing the max customer count then it must have it disabled meaning we need to pass the max value.
        dataToSave.maxCustomerCount = NO_MAX_CUSTOMER_COUNT_DEFAULT_VALUE;
      }
      if (!dataToSave.placeholderMove && (data.startDate === undefined || data.startDate === null)) {
        serviceDatesError = 'Must supply service date(s)';
      }
      if (maxCustomerCountError || serviceDatesError) {
        setError({
          maxCustomerCountError,
          serviceDatesError,
        });
        doneLoading(300);
        return Promise.resolve();
      }
    }
    if (serviceProviderId) {
      try {
        await moveGroupDeal(dataToSave, serviceProviderId);
        onRemove();
        createSuccessToast('Customer moved successfully');
        if (onlyOneCustomer) {
          createInfoToast('Last customer removed. Group Deal deleted. Navigating back to group deals.');
          setTimeout(() => {
            navigate(`/v1/${serviceProviderId}/groupDeals`);
          }, 300);
        }
      } catch (err:any) {
        if (err?.response?.data?.message?.indexOf('You already have a group deal available') > -1) {
          update({
            ...get(),
            warningMessage: err?.response?.data?.message,
          });
        } else {
          console.error(err);
          createErrorToast('Unable to move customer. Please contact support.');
        }
      }
    }
    doneLoading(300);
  }

  function selectGroupDeal(
    nsoi:INeighborhoodServiceOfferingInstance,
    customerId:string,
    setData:Function,
    setShowCreateGroupDeal: Function,
    setSelectedNsoiId:Function,
    setCreateNewSelected:Function) {
    setData({
      customerId,
      nsoiId: nsoi.id,
      startDate: null,
      endDate: null,
      maxCustomerCount: null,
    });
    setSelectedNsoiId(nsoi.id);
    setShowCreateGroupDeal(false);
    setCreateNewSelected(false);
  }

  function createGroupDeal(
    customerId:string,
    setData:Function,
    setShowCreateGroupDeal: Function,
    setSelectedNsoiId:Function,
    setCreateNewSelected:Function) {
    setData({
      customerId,
      nsoiId: '',
      startDate: null,
      endDate: null,
      maxCustomerCount: 1,
    });
    setSelectedNsoiId('');
    setShowCreateGroupDeal(true);
    setCreateNewSelected(true);
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    save,
    selectGroupDeal,
    createGroupDeal,
  };
}