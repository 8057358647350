import { FormControl, FormLabel, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import provider from 'launchdarkly-react-client-sdk/lib/provider';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import HookRadioField from 'components/reactHookForm/HookRadioField';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export const SHOULD_HONOR_GROUP_DEAL = 'yes';
export const NOT_SHOULD_HONOR_GROUP_DEAL = 'no';
export default function HonorGroupDealRadio() {
  const formContext = useFormContext();
  function onChange(e) {
    formContext.setValue('honorGroupDeal', e.target.value);
  }

  const { isError, getErrorMessage } = useErrorState(formContext);

  return (
    <FormControl fullWidth error={isError('honorGroupDeal')}>
      <FormLabel id="honor-group-deal-radio-buttons-label">Would you still like to honor the group discount?</FormLabel>
      <HookRadioField
        horizontal
        name='honorGroupDeal'
        required
        defaultValue={SHOULD_HONOR_GROUP_DEAL}
      >
        <FormControlLabel value={SHOULD_HONOR_GROUP_DEAL} control={<Radio onChange={onChange}/>} label="Yes (recommended)" />
        <FormControlLabel value={NOT_SHOULD_HONOR_GROUP_DEAL} control={<Radio onChange={onChange}/>} label="No" />
      </HookRadioField>
      <FormHelperText>{getErrorMessage('honorGroupDeal')}</FormHelperText>
    </FormControl>
  );
}