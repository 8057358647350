import { Typography } from '@mui/material';
import CustomerAvatarGroup from './customerAvatarGroup';
import { defaultMetadata, confirmedMetadata, pendingMetadata, createDiscountTableMetadata } from './discountTableUtil';
import styles from './groupDiscountTable.module.css';

export interface IDiscountTableRowProps {
  discountTableRow:any;
  index:number;
  isNewServiceInstance:boolean;
  isTwoColumnTable:boolean;
  tableColumnWidth:string;
  maxDiscountCount:number;
  pendingCustomers: any[];
  confirmedCustomers:any[];
}

export default function DiscountTableRow({
  discountTableRow,
  index,
  isNewServiceInstance,
  isTwoColumnTable,
  tableColumnWidth,
  maxDiscountCount,
  pendingCustomers,
  confirmedCustomers,
}:IDiscountTableRowProps) {
  let metadata = createDiscountTableMetadata(isNewServiceInstance, discountTableRow, index, confirmedCustomers, pendingCustomers, maxDiscountCount);

  const {
    rowClass,
    customerArray,
    neighborLabel,
    tableRowBackground,
  } = metadata;
  return (
    <tr key={discountTableRow.key} className={`discountRow${index} ${rowClass}`} style={{ backgroundColor: tableRowBackground }}>
      <td
        className={`discount-row-${index}-neighbor-count ${styles.discountTableCell}`}
        style={{ width: tableColumnWidth }}
      >
        <>{discountTableRow.displayCount || discountTableRow.count}</>
      </td>
      <td
        className={`discount-row-${index}-amount ${styles.discountTableCell}`}
        style={{ width: tableColumnWidth }}
      >
        <>{discountTableRow.amount}</>
      </td>
      {!isTwoColumnTable &&
        <td className={styles.neighborAvatarIconCell}>
          <div>
            <Typography variant="subtitle2" className={styles.neighborLabel}><b>{neighborLabel}</b></Typography>
          </div>
          <div>
            {customerArray &&
            <CustomerAvatarGroup
              customers={customerArray}
              avatarIconStyleOverride={{ width: '25px', height: '25px', fontSize: '10px' }}
              rowIndex={index}
            />
            }
          </div>
        </td>
      }
    </tr>
  );
}