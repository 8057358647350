
const NoPaddingNumberFormatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const PennyPaddingNumberFormatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const TwoDigitPadding = new Intl.NumberFormat('en-us', {
  minimumIntegerDigits: 2,
  minimumFractionDigits: 0,
});

export function formatTwoDigit(num: number | null | undefined, placeholder:string) {
  if (num === undefined || num === null) {
    return placeholder || 'N/A';
  }
  return TwoDigitPadding.format(num);
}

/**
 * Takes a value like 13.2 and converts it to 0.132
 * @param formattedPercent
 */
export function convertFormattedPercentage(formattedPercent:string | number):number | null {
  if (formattedPercent === '') {
    return null;
  }
  if (typeof formattedPercent === 'string') {
    return parseFloat(formattedPercent) / 100.0;
  } else if (typeof formattedPercent === 'number') {
    return formattedPercent / 100.0;
  }
  throw new Error('Cannot parse: '+formattedPercent);
}

/**
 * Takes a value like 0.132 and converts it to 13.2
 * @param percent
 */
export function formatPercent(percent:number | string | null):string {
  if (percent === null) {
    return '';
  }
  if (typeof percent === 'string') {
    return NoPaddingNumberFormatter.format((parseFloat(percent) * 100.0));
  } else if (typeof percent === 'number') {
    return NoPaddingNumberFormatter.format(percent * 100.0);
  }
  throw new Error('Cannot format: '+percent);
}

export function formatDecimalNoPadding(num: number | null, placeholder:string) {
  if (num === undefined || num === null) {
    return placeholder || 'N/A';
  }
  return NoPaddingNumberFormatter.format(num);
}

export function formatDecimalMinTwoDecimals(num: number | null, placeholder:string) {
  if (num === undefined || num === null) {
    return placeholder || 'N/A';
  }
  return PennyPaddingNumberFormatter.format(num);
}

export function hasValue(num?: number | null):boolean {
  return num !== undefined && num !== null;
}