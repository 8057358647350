import { datadogRum } from '@datadog/browser-rum';
import { AnalyticsProvider, createClient } from '@segment/analytics-react';
// eslint-disable-next-line import/order
import useToast from 'components/toast/useToast';
// eslint-disable-next-line import/order
import useInterceptor from 'hooks/interceptorHook/useInterceptor';
import NotFound from 'pages/NotFound';
import CustomersTable from 'pages/customerTable';
import { GroupDealVersionChooser } from 'pages/groupDeal';
import OldGroupDeal from 'pages/groupDeal/v1/GroupDeal';
import { GroupDealShare } from 'pages/groupDealShare';
import { GroupDealsTable } from 'pages/groupDealsTable';
import Home from 'pages/home/Home';
import { Reviews } from 'pages/reviews';
import SignUp from 'pages/signUp';
import SwitchProvider from 'pages/switchProvider';
import { TermsAndConditions } from 'pages/termsAndConditions';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import CustomerDetail from './pages/customer';
import HomePage from './pages/home';
import Layout from './pages/layout';
import PasswordReset from './pages/reset';
import SignIn from './pages/signin/signIn';
import Customer from './pagesV1/customer';
import CustomerList from './pagesV1/customer/customerList';
import LayoutV1 from './pagesV1/layout';
import Neighborhoods from './pagesV1/neighborhoods';
import Payments from './pagesV1/payments';
import Subscriber from './pagesV1/subscriber/subscriber';
import SubscriberList from './pagesV1/subscriberList';
const segmentClient = createClient({ writeKey: 'WTgCkEuyfurEYjvhlBaqzpa4F5K8XHXl' });

function App () {
  const navigate = useNavigate();
  const { createWarnToast } = useToast();
  const { isIntercepting, initializeInterceptor } = useInterceptor(createWarnToast);
  if (!isIntercepting) {
    initializeInterceptor();
  }


  if (window.REACT_APP_NODE_ENV !== 'local') {
    datadogRum.init({
      applicationId: 'ea380099-82db-4797-a696-870cb9692847',
      clientToken: 'pub20d9e35e03b888386dfad84899f956b0',
      site: 'datadoghq.com',
      service: 'provider-front-end',
      env: `${window.REACT_APP_NODE_ENV}`,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [/https:\/\/.*\.streetfair\.app/],
    });
    datadogRum.startSessionReplayRecording();
  }

  return (
    <AnalyticsProvider client={segmentClient}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="/notfound" element={<NotFound/>}/>
          </Route>
          <Route path="/v0" element={<LayoutV1 />}>
            <Route path=":serviceProviderId" element={<HomePage />} />
            <Route path="customers" element={<CustomerList />} />
            <Route path=":serviceProviderId/customers" element={<CustomerList />} />
            <Route path="customer/:customerId" element={<Customer />} />
            <Route path=":serviceProviderId/customer/:customerId" element={<Customer />} />
            <Route path="subscribers" element={<SubscriberList />} />
            <Route path=":serviceProviderId/subscribers" element={<SubscriberList />} />
            <Route path=":serviceProviderId/subscriber/:subscriberId" element={<Subscriber />} />
            <Route path=":serviceProviderId/neighborhoods" element={<Neighborhoods />} />
            <Route path="payments" element={<Payments />} />
            <Route path=":serviceProviderId/payments" element={<Payments />} />
          </Route>
          <Route path="/v1/:serviceProviderId" element={<Layout/>}>
            <Route path="sign-up/:signUpLinkToken" element={<SignUp />} />
            <Route index element={<Home/>}/>
            <Route path="customers" element={<CustomersTable/>}/>
            <Route path="groupDeals" element={<GroupDealsTable/>}/>
            <Route path="groupDeals/:groupDealId/old" element={<OldGroupDeal/>} />
            <Route path="groupDeals/:groupDealId" element={<GroupDealVersionChooser/>}/>
            <Route path="groupDeals/:groupDealId/groupDealShare/:context" element={<GroupDealShare/>}/>
            <Route path="customers/:customerId" element={<CustomerDetail/>}/>
            <Route path="subscribers/:subscriberId" element={<CustomerDetail/>}/>
            <Route path="switchProvider" element={<SwitchProvider/>}/>
            <Route path="reviews" element={<Reviews/>}/>
            <Route path="termsAndConditions" element={<TermsAndConditions/>}/>
          </Route>
          <Route path="/sign-in" element={<SignIn />} />

          <Route path="/reset" element={<PasswordReset />} />
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </div>
    </AnalyticsProvider>
  );
}

export default App;
