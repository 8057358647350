import { Grid } from '@mui/material';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import styles from './holdCustomerModal.module.css';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useToast from 'components/toast/useToast';

export function HoldCustomerModal() {
  const { createSuccessToast } = useToast();
  const { provider, holdCustomer } = useCustomerDetail();

  return (
    <HookConfirmationModal
      modalKey={'holdCustomerModal'}
      title={() => (<div className={styles.confirmDeleteModalTitle}>Place this customer on hold?</div>)}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' className={styles.requestSupportModalBody}>
          <Grid item xs={12}>
                        Click confirm to mark this customer as on hold.
          </Grid>
        </Grid>
      )}
      onConfirm={async () => {
        try {
          await holdCustomer();
          createSuccessToast('Customer marked as "On Hold".');
        } catch (e:any) {

        }
      }}
    />
  );
}