import { Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProviderAccount, setupProviderAccount } from 'api/paymentsApi';
import { getServiceProviderById } from 'api/serviceProviderApi';

function Payments () {
  const [accountId, setAccountId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState<any>();
  const params = useParams();

  const setupAccount = () => {
    if (params.serviceProviderId) {
      setLoading(true);
      setupProviderAccount(params.serviceProviderId).then((res: any) => {
        if (res.status === 200) {
          window.location = res.data.redirect;
        }
      }).catch((reason) => console.error(reason));
    }
  };

  const getAccount = (id) => {
    if (id) {
      getProviderAccount(id).then((res: any) => {
        if (res.status === 200) {
          if (res.data.redirect) {
            setRedirect(res.data.redirect);
          } else {
            setRedirect('https://connect.stripe.com/login');
          }
        }
      }).catch((reason) => console.error(reason));;
    }
  };

  const getProvider = () => {
    const providerId = params.serviceProviderId || '';
    getServiceProviderById(providerId).then((res: any) => {
      if (res.data && res.data.stripeAccountId) {
        setAccountId(res.data.stripeAccountId);
        getAccount(providerId);
      }
    }).catch((reason) => console.error(reason));;
  };

  const goToRedirect = () => {
    if (redirect) {
      window.location = redirect;
    }
  };

  useEffect(() => {
    getProvider();
  }, []);

  return (
    <>
      <h3 style={{
        margin: '20px',
        textAlign: 'left',
      }}>Setup Payments</h3>
      {accountId && (
        <>
          <p>click here to view your account</p>
          <Button variant="contained" onClick={goToRedirect}>Click</Button>
        </>
      )}
      {loading && (
        <CircularProgress></CircularProgress>
      )}
      {!accountId && !loading && (
        <p style={{
          margin: '20px',
          textAlign: 'left',
        }}>Click here to setup an account to begin to recieve payments <Button variant="contained" onClick={setupAccount} style={{
            marginTop: '15px',
            marginRight: '15px',
          }}>Setup</Button></p>
      )}
    </>

  );
}

export default Payments;
