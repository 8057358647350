import { isNullOrUndefined } from './falsyCheckingUtil';

const pluralRules = new Intl.PluralRules('en-us', {
  type: 'ordinal',
});

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);
export const formatOrdinals = (n) => {
  const rule = pluralRules.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export function formatPhoneNumber(phone:string) {
  if (!phone) {
    console.debug('phone number was empty');
    return '';
  }
  let _unformattedPhoneNumber = phone.replace(/[^0-9]/g, '');

  if (!_unformattedPhoneNumber) {
    console.debug('phone number was empty');
    return '';
  }
  if (_unformattedPhoneNumber.length === 11) {
    //this phone number has a country code
    let countryCode = _unformattedPhoneNumber.slice(0, 1);
    let areaCode = _unformattedPhoneNumber.slice(1, 4);
    let firstThree = _unformattedPhoneNumber.slice(4, 7);
    let lastFour = _unformattedPhoneNumber.slice(7, 11);
    return `+${countryCode}-${areaCode}-${firstThree}-${lastFour};`;
  }
  let areaCode = _unformattedPhoneNumber.slice(0, 3);
  let firstThree = _unformattedPhoneNumber.slice(3, 6);
  let lastFour = _unformattedPhoneNumber.slice(6, 10);
  return `${areaCode}-${firstThree}-${lastFour}`;
}

export function formatUSD(val:number) {
  if (isNullOrUndefined(val)) {
    return '';
  }
  if (val < 0) {
    return `-$${Math.abs(val).toFixed(2)}`;
  } else {
    return `$${val.toFixed(2)}`;
  }
}

export function capitalizeFirstLetter(val:string) {
  let lowercase = val.toLocaleLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
}