
import { InputLabel, FormHelperText, FormControl, Select, MenuItem } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { customerRemoveReasons } from 'model/removeCustomerForm';

export interface ICustomerRemoveReasonSelectProps {
  value:string;
  onChange: (e) => void;
  error: {hasError:boolean; message:string};
}
export default function CustomerRemoveReasonSelect({ value, onChange, error }:ICustomerRemoveReasonSelectProps) {


  return (
    <FormControl variant='standard' fullWidth error={error.hasError} >
      <InputLabel htmlFor='customer-remove-reason-select'>Customer Removal Reason</InputLabel>
      <Select
        id='customer-remove-reason-select'
        onChange={onChange}
        value={value}
      >
        {
          customerRemoveReasons.map((x: any, i) => {
            return <MenuItem key={i} value={x.optionValue}>{x.optionText}</MenuItem>;
          })
        }
      </Select>
      <FormHelperText>{error.message || ' '}</FormHelperText>
    </FormControl>
  );
}