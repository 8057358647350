import { Grid, ThemeProvider, Typography } from '@mui/material';
import Userfront from '@userfront/react';
import { initXsrf } from 'api/sessionApi';
import LogoHeader from 'components/Header/logoHeader';
import Toast from 'components/toast';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { useEffect } from 'react';
import mainTheme from 'theme';
import SignUpForm from './SignUpForm';

export default function SignUp() {
  const { trackPageView } = useStreetfairAnalytics();

  useEffect(() => {
    void initXsrf();
    void trackPageView('Sign_Up');
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <LogoHeader hideUserButtons={!Userfront.tokens.accessToken}/>
      <Grid container justifyContent={'center'}>
        <Grid container item xs={10} sm={6} md={4} lg={3}
          justifyContent={'center'}
          sx={{
            'marginTop': '20px',
            'padding': '20px',
            'borderRadius': '4px',
            'boxShadow': '0px 0px 8px rgba(0,0,0,0.3)',
            '&&&': {
              maxWidth: '340px',
              minHeight: '400px',
            },
          }}>
          <Grid item xs='auto' sx={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Typography variant='h6' sx={{ fontWeight: '600' }}>Create Your Account</Typography>
          </Grid>
          <SignUpForm/>
        </Grid>
      </Grid>
      <Toast/>
    </ThemeProvider>
  );
}