import AddCustomerFields from './customerCreation/AddCustomerFields';
import EnterServiceInformation from './serviceInformation/EnterServiceInformation';

export default function GroupDealCreationFields() {
  return (
    <>
      <EnterServiceInformation/>
      <AddCustomerFields/>
    </>
  );
}