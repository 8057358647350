import { MenuItem, Select, SelectProps } from '@mui/material';
import get from 'lodash/get';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

interface IMenuItemKeys {
  key: string;
  value: string;
  displayValue:string;
}

interface IHookSelectProps extends SelectProps{
  control:Control<any>;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' >;
  id:string;
  name:string;
  menuItems:any[];
  menuItemKeys?:IMenuItemKeys | null;
  shouldRender?:boolean;
  extraProps?:any;
  postOnChange?:any;
}

export default function RHFSelect({ control, id, name, menuItems, menuItemKeys, rules, shouldRender, extraProps, postOnChange }:IHookSelectProps) {

  const menuItemKey = menuItemKeys?.key || '';
  const menuItemValue = menuItemKeys?.value || '';
  const menuItemDisplayValue = menuItemKeys?.displayValue || '';
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      rules={rules}
      render={({ field: { onBlur, onChange, ref, value } })=>{
        if (shouldRender === false) {
          return null as any;
        }
        return (
          <Select
            inputProps={{
              inputRef: ref,
              onBlur,
            }}
            onChange={(e) => {
              onChange(e);
              if (postOnChange) {
                postOnChange(e);
              }
            }}
            value={value}
            id={id}
            style={{ textAlign: 'left' }}
            fullWidth
            {...extraProps}>
            {
              menuItems && menuItems.map((x: any, i:number) => {
                return (
                  <MenuItem
                    key={get(x, menuItemKey, i)}
                    value={get(x, menuItemValue, x.id)}>
                    <span>{get(x, menuItemDisplayValue, x.name)}</span>
                  </MenuItem>
                );
              })
            }
          </Select>
        );
      }
      }/>
  );
}