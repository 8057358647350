import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useHardDeleteCustomer from '../hookStore/useHardDeleteCustomer';
import styles from './hardDeleteCustomerModal.module.css';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';

export function HardDeleteCustomerModal() {
  const { onHardDeleteParticipant, customer } = useHardDeleteCustomer();
  const { open } = useConfirmationModal('hardDeleteCustomerModal');
  const { serviceProviderId } = useParams();
  return (
    <HookConfirmationModal
      modalKey={'hardDeleteCustomerModal'}
      title={() => (<div className={styles.confirmDeleteModalTitle}>Remove this customer?</div>)}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent={{ xs: 'center', md: 'left' }} className={styles.requestSupportModalBody}>
          <Grid item xs={12} sx={{ paddingBottom: 1 }}>
              This will hard delete the customer
          </Grid>
          <Grid item md={12}></Grid>
        </Grid>
      )}
      onConfirm={async () => {
        if (serviceProviderId) {
          if (customer.recurring) {
            await onHardDeleteParticipant({ serviceProviderId, customerId: null, subscriberId: customer.id });
          } else {
            await onHardDeleteParticipant({ serviceProviderId, customerId: customer.id, subscriberId: null });
          }
        }
      }}
      sx={{
        '& .MuiCardContent-root': {
          padding: 3,
        },
      }}
    />
  );
}