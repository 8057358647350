import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Alert, Button, Grid, IconButton, Typography } from '@mui/material';
import get from 'lodash.get';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useGroupDeal from '../hookStore/useGroupDeal';
import { CustomerAccordion } from './CustomerAccordion';
import CustomerCard from './CustomerCard';
import CustomerDiscountToggles from './CustomerDiscountToggles';
import styles from './formComponents.module.css';
import DiscountTable from 'components/groupDiscountTable/discountTable';
import { DiscountScheduleTypes, ParticipantStatus } from 'model/ancillary';
import { createEmptyGroupDealCustomer, IGroupDealCustomer } from 'model/customer';
import { IDiscountTableData } from 'model/groupDeal';
import { IServiceOffering } from 'model/serviceOffering';

function shouldShowNoDiscountWarning(nsoDetails:any, prospectiveDiscountTable: IDiscountTableData | null) {
  return (nsoDetails !== null && nsoDetails.discountTable.length === 0) || (
    nsoDetails === null && (!prospectiveDiscountTable?.discountSchedule || !prospectiveDiscountTable?.discountTable || prospectiveDiscountTable.discountSchedule.type === DiscountScheduleTypes.NoDiscount.value)
  );
}

function shouldShowMaxCustomerCount(serviceOffering:IServiceOffering | null) {
  return serviceOffering !== null && serviceOffering.hasDisabledMaxCustomerCount !== true;
}

export default function CustomerFields() {
  const { control, getValues, formState } = useFormContext();
  const {
    customersToggled,
    nsoDetails,
    nsoi,
    pendingCustomers,
    prospectiveDiscountTable,
    findServiceOffering,
    getProspectiveDiscountTable,
    reinitializeCustomerAccordionState,
  } = useGroupDeal();

  const { fields: customers, append, replace, remove } = useFieldArray({
    name: 'customers',
  });

  const watchedCustomers = useWatch({ control, name: 'customers' });
  const watchServiceOfferingId = useWatch({ control, name: 'serviceOfferingId' });
  const watchMaxCustomerCount = useWatch({ control, name: 'maxCustomerCount' });
  const watchSuperDeal = useWatch({ control, name: 'superDeal' });
  const watchDiscountScheduleKey = useWatch({ control, name: 'discountScheduleKey' });
  const watchNsoPrice = useWatch({ control, name: 'nsoPrice' });
  const onlyOneCustomer = watchedCustomers.filter(x => !x.wasAdded).length === 1;
  const serviceOffering = findServiceOffering(watchServiceOfferingId);


  useEffect(() => {
    if (customers.length !== watchedCustomers.length) {
      reinitializeCustomerAccordionState(watchedCustomers);
    }
  }, [customers]);
  useEffect(() => {
    if (!customersToggled) {
      //we only ever want to look at the actual neighborhood level pricing or the override set by the user. Not the default values from the various discount schedules
      let isNsoPriceDirty = get(formState, 'dirtyFields.nsoPrice', false);
      let nsoPrice = nsoDetails?.price;
      if (isNsoPriceDirty) {
        nsoPrice = getValues('nsoPrice');
      }
      if (watchMaxCustomerCount && watchServiceOfferingId && watchDiscountScheduleKey) {
        let discountScheduleId = watchDiscountScheduleKey.split('#')[0];
        void getProspectiveDiscountTable(watchServiceOfferingId, {
          maxCustomerCount: watchMaxCustomerCount,
          nsoPrice: nsoPrice,
          customers: watchedCustomers,
          participantCount: watchedCustomers.filter( x=> !x.placeholder || !nsoi?.dynamicDiscountTable).length,
          discountScheduleId,
        });
      } else if (watchMaxCustomerCount && watchServiceOfferingId) {
        void getProspectiveDiscountTable(watchServiceOfferingId, {
          maxCustomerCount: watchMaxCustomerCount,
          nsoPrice: nsoPrice,
          customers: watchedCustomers,
          participantCount: watchedCustomers.filter( x=> !x.placeholder || !nsoi?.dynamicDiscountTable).length,
          discountScheduleId: null,
        });
      }
    }
  }, [watchServiceOfferingId, watchMaxCustomerCount, watchedCustomers, watchSuperDeal, watchDiscountScheduleKey, watchNsoPrice, customersToggled]);

  let prospectiveConfirmedCustomers = watchedCustomers
    .filter( x => x && (!x.placeholder && !x.isPlaceholderCustomer) && (x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE || x.wasAdded));

  return (
    <Grid container item xs={12} alignContent='flex-start'>
      <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
        <Grid item xs='auto'>
          <Typography variant='subtitle2' textAlign='left'>Customers and Discounts</Typography>
        </Grid>
        <Grid item xs='auto'>
          <IconButton
            onClick={() => {
              window.scrollTo(0, document.body.scrollHeight);
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item xs={12} alignItems='center' justifyContent={{ xs: 'center', md: 'left' }}>
        <CustomerDiscountToggles />
      </Grid>
      {customers && customers.length > 0 && (
        <Grid container item xs={12} className={styles.customerCards} spacing={0.25} sx={{ display: `${customersToggled ? '' : 'none'}` }}>
          {customers.map((x, i) => {
            if ((x as IGroupDealCustomer).wasAdded) {
              return (
                <Grid key={x.id} item xs={12} xl={6}>
                  <CustomerCard
                    index={i}
                    onRemove={() => {
                      remove(i);
                    }} />
                </Grid>
              );
            }
            return (
              <Grid key={x.id} item xs={12} sm={8} md={9} xl={6}>
                <CustomerAccordion
                  shouldShowMaxCustomerCount={shouldShowMaxCustomerCount(serviceOffering)}
                  onlyOneCustomer={onlyOneCustomer}
                  index={i}
                  onRemove={() => {
                    remove(i);
                  }}
                  neighborhoodId={nsoDetails?.neighborhoodId}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Grid item xs={12} sm='auto' sx={{ display: `${customersToggled ? '' : 'none'}` }}>
        <Button
          variant='text'
          type='button'
          onClick={() => {
            append(createEmptyGroupDealCustomer());
          }}
          className={styles.addCustomerButton}
        >
          <AddIcon />
          Add Customer
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ display: `${!customersToggled ? '' : 'none'}` }} className={styles.discountTableWrapper}>
        {nsoDetails !== null && nsoDetails.discountTable != null && nsoDetails.discountTable.length > 0 && (
          <DiscountTable
            neighborhoodOffering={nsoDetails}
            pendingCustomers={pendingCustomers}
            confirmedCustomers={prospectiveConfirmedCustomers}
            newServiceInstanceFlag={false}
            fullWidth
          />
        )}
        {
          nsoDetails === null && prospectiveDiscountTable?.discountSchedule && prospectiveDiscountTable?.discountTable && (
            <DiscountTable
              neighborhoodOffering={prospectiveDiscountTable}
              pendingCustomers={pendingCustomers}
              confirmedCustomers={prospectiveConfirmedCustomers}
              newServiceInstanceFlag={false}
              fullWidth
            />
          )
        }
        {shouldShowNoDiscountWarning(nsoDetails, prospectiveDiscountTable) && (
          <Alert severity='warning'>No discount on this job</Alert>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
}