import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSelfServiceProvider } from 'api/userApi';

export default function NotFound() {
  const params = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  async function init() {

    try {
      let serviceProviderId;
      if (params.serviceProviderId) {
        serviceProviderId = params.serviceProviderId;
        localStorage.setItem('providerId', serviceProviderId);
      } else {
        serviceProviderId = localStorage.getItem('providerId');
        if (!serviceProviderId) {
          const serviceProviderRes = await getSelfServiceProvider();
          serviceProviderId = serviceProviderRes.data;
          if (serviceProviderId) {
            localStorage.setItem('providerId', serviceProviderId);
          }
        }
      }
      if (serviceProviderId) {
        navigate(`/v1/${serviceProviderId}/customers`);
      } else {
        setMessage('Could not find data. Please contact support at support@streetfair.com.');
      }
    } catch (err:any) {
      console.error(err);
    }
  }
  useEffect(() => {
    void init();
  }, []);
  return (
    <div id='not-found'>
            Page not found
    </div>
  );
}