import { Button } from '@mui/material';
import { ParticipantStatus } from 'model/status';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import styles from './requestPaymentModal.module.css';

export default function RequestPaymentButton() {

  const { provider, setRequestPaymentModalOpen, customer } = useCustomerDetail();
  const requestPaymentOnClick = () => {
    setRequestPaymentModalOpen(true);
  };

  const doesProviderHavePaymentsConfigured = (currentProvider) => {
    if (currentProvider.venmoId || currentProvider.cashAppId) {
      return true;
    }
    return false;
  };
  const isEnabled = (!customer.recurring && customer.status === ParticipantStatus.COMPLETE) || (customer.recurring && customer.status === ParticipantStatus.ACTIVE);
  const shouldRender = false;
  // provider && doesProviderHavePaymentsConfigured(provider);
  if (!shouldRender) {
    return null;
  }
  return (
    <Button
      disabled={!isEnabled}
      variant='contained'
      type='button'
      onClick={requestPaymentOnClick}
      className={styles.requestPaymentButton}
      sx={{
        width: {
          xs: '100%',
          sm: 'auto',
        },
      }}
    >
      <span>Request Payment</span>
    </Button>
  );
}