import { FormControl, FormLabel, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import HookRadioField from 'components/reactHookForm/HookRadioField';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export const PROVIDER = 'PROVIDER';
export const CONSUMER = 'CONSUMER';

export default function ServiceRelationshipOnusField() {
  const formContext = useFormContext();
  function onChange(e) {
    formContext.setValue('serviceRelationshipOnus', e.target.value);
  }

  const { isError, getErrorMessage } = useErrorState(formContext);

  return (
    <FormControl fullWidth error={isError('serviceRelationshipOnus')}>
      <FormLabel id="service-relationship-onus-buttons-label" sx={{ textAlign: 'left' }}>Whose got the ball?</FormLabel>
      <HookRadioField
        horizontal
        name='serviceRelationshipOnus'
        required
        defaultValue={PROVIDER}
      >
        <FormControlLabel value={PROVIDER} control={<Radio onChange={onChange}/>} label="Provider" />
        <FormControlLabel value={CONSUMER} control={<Radio onChange={onChange}/>} label="Consumer" />
      </HookRadioField>
      <FormHelperText>{getErrorMessage('serviceRelationshipOnus')}</FormHelperText>
    </FormControl>
  );
}