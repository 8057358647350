import Axios from 'axios';
import { IPaymentRequest } from 'model/paymentRequest';


export function createAccount(providerId: string) {
  return Axios.post(`${window.REACT_APP_PAYMENTS_URI}/account/create/${providerId}`);
}

export function getAccount(accountId: string) {
  return Axios.get(`${window.REACT_APP_PAYMENTS_URI}/account/${accountId}`);
}

export function getHealth() {
  return Axios.get(`${window.REACT_APP_PAYMENTS_URI}/healthcheck`);
}

export function chargeConsumer(individualId: string, paymentData: any) {
  return Axios.post(`${window.REACT_APP_PAYMENTS_URI}/charge/${individualId}`, paymentData);
}

export function requestPaymentFromCustomer(data: IPaymentRequest) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/paymentRequest/v2/serviceProviders/${data.serviceProviderId}/customers/${data.customerId}/requestPayment`, data);
}

export function requestPaymentFromSubscriber(data: IPaymentRequest) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/paymentRequest/v2/serviceProviders/${data.serviceProviderId}/subscribers/${data.subscriberId}/requestPayment`, data);
}

export function setupProviderAccount(serviceProviderId: string) {
  return Axios.post(`${window.REACT_APP_PAYMENTS_URI}/account/provider/create/${serviceProviderId}`);
}
export function getProviderAccount(providerId: string) {
  return Axios.get(`${window.REACT_APP_PAYMENTS_URI}/account/provider/${providerId}`);
}
export function getChargesForCustomer(
  individualId: string,
  providerId: string,
  customerId: string,
  subscriberId: string,
) {
  let uri = `${window.REACT_APP_PAYMENTS_URI}/charge/list/${individualId}?providerId=${providerId}`;
  if (customerId) {
    uri += `&customerId=${customerId}`;
  } else {
    uri += `&subscriberId=${subscriberId}`;
  }
  return Axios.get(uri);
}
