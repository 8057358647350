import { findDiscountScheduleByServiceInstance } from 'api/discountScheduleApi';
import { calculatePriceForCustomer, calculatePriceForProviderPortal as calculatePriceApi, calculatePriceForSubscriber } from 'api/priceCalculatorApi';
import debounce from 'lodash.debounce';
import { ParticipantStatus } from 'model/ancillary';
import { ICustomerServiceDetails, ICustomerServiceLineItem } from 'model/customer';
import { IDiscountSchedule } from 'model/discount';
import { IPriceCalculatorParameters } from 'model/priceCalculator';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NOT_SHOULD_HONOR_GROUP_DEAL } from '../confirmCustomerDrawer/HonorGroupDealRadio';
import store from './customerDetailDataStore';

const debounceCalculatePrice = debounce(async (data:IPriceCalculatorParameters, preCall, callback):Promise<any> => {
  const timeoutId = setTimeout(() => {
    preCall();
  }, 300);
  const res = await calculatePriceApi(data);
  callback(res, timeoutId);
}, 200);

const { get, update, registerListener, unregisterListener } = store;

export default function usePriceCalculator() {
  const setState = useState(get())[1];
  const { serviceProviderId, customerId, subscriberId } = useParams();
  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  /**
     * Calculates the final price as a number before discount. Handles unit based pricing and normal pricing.
     * @param serviceItem
     * @returns
     */
  function calculatePrice(serviceItem:ICustomerServiceLineItem):number {
    const { units, unitBasedPrice, price } = serviceItem;
    if (units && unitBasedPrice) {
      const _units = parseFloat(units);
      const _unitBasedPrice = parseFloat(unitBasedPrice);
      if (!isNaN(_units) && !isNaN(_unitBasedPrice)) {
        return (_units * _unitBasedPrice);
      }
    } else if (unitBasedPrice) {
      const _unitBasedPrice = parseFloat(unitBasedPrice);
      if (!isNaN(_unitBasedPrice)) {
        return _unitBasedPrice;
      }
    } else if (price) {
      const _price = parseFloat(price);
      if (!isNaN(_price)) {
        return _price;
      }
    }
    return 0;
  }

  async function calculateDataDrivenTotalPrice() {
    if (serviceProviderId) {
      if (customerId) {
        const priceResultRes = await calculatePriceForCustomer(serviceProviderId, customerId);
        update({
          ...get(),
          serverSidePriceResult: priceResultRes.data,
        });
      } else if (subscriberId) {
        const priceResultRes = await calculatePriceForSubscriber(serviceProviderId, subscriberId);
        update({
          ...get(),
          serverSidePriceResult: priceResultRes.data,
        });
      }
    }
  }

  function getActiveParticipantCountIncludingCustomer(customerServiceDetails:ICustomerServiceDetails):number {
    const { customer, matchingServiceDateInstance, selectedAlternateServiceDate } = get();
    if (selectedAlternateServiceDate && matchingServiceDateInstance === null && customerServiceDetails.honorGroupDeal === NOT_SHOULD_HONOR_GROUP_DEAL) {
      return 0;
    }
    const activeParticipantCountFilter = (x) => {
      return x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE ||
        (x.id === customer.id && customer.status === ParticipantStatus.PENDING);
    };
    if (customerId) {
      if (matchingServiceDateInstance) {
        const customerCount = matchingServiceDateInstance.customers.filter(activeParticipantCountFilter).length;
        if (selectedAlternateServiceDate) {
          return customerCount + 1; // include current customer in discount as they won't appear in the customer list until after save
        }
        return customerCount;
      } else {
        if (customer.activeParticipantCountAtCompletion) {
          return customer.activeParticipantCountAtCompletion;
        }
        const activeCustomersList = customer.customers.filter(activeParticipantCountFilter);
        return activeCustomersList.length;
      }
    } else if (customerId) {
      return customer.customers.filter(x => {
        return x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE ||
          (x.id === customer.id && customer.status === ParticipantStatus.PENDING);
      }).length;
    }
    return customer.subscribers.filter(x => {
      return x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE ||
        (x.id === customer.id && customer.status === ParticipantStatus.PENDING);
    }).length;
  }

  async function calculatePriceClientside(customerServiceDetails:ICustomerServiceDetails) {
    const { customer, matchingServiceDateInstance, priceSuffix } = get();

    let participant;
    if (customerId) {
      participant = {
        id: customerServiceDetails.customerId,
        neighborhoodServiceOfferingInstanceId: matchingServiceDateInstance?.id ?? customerServiceDetails.neighborhoodServiceOfferingInstanceId,
        neighborhoodServiceOfferingId: customerServiceDetails.neighborhoodServiceOfferingId,
        serviceItems: customerServiceDetails.serviceItems,
      };
    } else if (subscriberId) {
      participant = {
        id: customerServiceDetails.subscriberId,
        neighborhoodServiceOfferingId: customerServiceDetails.neighborhoodServiceOfferingId,
        serviceItems: customerServiceDetails.serviceItems,
      };
    }

    let matchingDiscountSchedule:IDiscountSchedule | null = null;
    if (matchingServiceDateInstance?.id && serviceProviderId && customerServiceDetails.honorGroupDeal === NOT_SHOULD_HONOR_GROUP_DEAL) {
      const discountScheduleRes = await findDiscountScheduleByServiceInstance(serviceProviderId, matchingServiceDateInstance.id);
      matchingDiscountSchedule = discountScheduleRes.data;
    }
    let participantCount = customer.activeParticipantCountAtCompletion ?? getActiveParticipantCountIncludingCustomer(customerServiceDetails);
    let params:IPriceCalculatorParameters = {
      discountSchedule: matchingDiscountSchedule ?? customer.discountSchedule,
      nsoPrice: customer.nsoPrice,
      participantCount,
      customer: customerId ? participant : null,
      subscriber: subscriberId ? participant : null,

      priceSuffix: (priceSuffix === null || priceSuffix === '' || priceSuffix === undefined )? null : priceSuffix,
    };

    if (params.customer || params.subscriber) {
      await debounceCalculatePrice(params, (timeoutId) => {
        update({
          ...get(),
          isCalculating: true,
        });
      }, (priceResultRes, timeoutId) => {
        clearTimeout(timeoutId);
        if (priceResultRes && priceResultRes.data) {
          update({
            ...get(),
            clientSidePriceResult: priceResultRes.data,
            isCalculating: false,
          });
        }
      });
    }
  }


  return {
    isCalculating: get().isCalculating,
    serverSidePriceResult: get().serverSidePriceResult,
    clientSidePriceResult: get().clientSidePriceResult,
    calculatePrice,
    calculateDataDrivenTotalPrice,
    calculatePriceClientside,
    getActiveParticipantCountIncludingCustomer,
  };
}