import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export interface IHookDatePickerProps {
  name:string;
  label:string;
  required?:boolean;
  validationRules?: any;
  datePickerProps?:any;
  textFieldProps?:any;
}

export default function HookDatePicker({ name, label, required, validationRules, datePickerProps, textFieldProps }:IHookDatePickerProps) {
  const methods = useFormContext();
  const [open, setOpen] = useState(false);
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  const rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    ...validationRules,
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        ...params
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              label={label}
              value={value || null}
              onChange={(e:any) => {
                onChange(e);
                void methods.trigger(name);
              }}
              onBlur={onBlur}
              renderInput={(inputParams) => {
                return (
                  <TextField
                    {...inputParams}
                    {...textFieldProps}
                    onKeyDown={(e) => e.preventDefault()}
                    id={`${name}-datepicker-input`}
                    error={isError(name)}
                    onClick={() => {
                      if (!inputParams.disabled) {
                        setOpen(true);
                      }
                    }}
                  />
                );
              }}
              {...datePickerProps}
            />
          </LocalizationProvider>
        );
      }
      }
    />
  );
}