import { DrawerProps } from '@mui/material';
import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { useEffect, useState } from 'react';

export const CONFIRMATION_DRAWER_VARIANT:string = 'CONFIRMATION_DRAWER_VARIANT';
export const COMPLETION_DRAWER_VARIANT:string = 'COMPLETION_DRAWER_VARIANT';

type ConfirmCustomerDrawer = {
  open:boolean;
  anchor:DrawerProps['anchor'];
  suppressDataDrivenCalculation: boolean;
  variant:string;
}

const { get, update, registerListener, unregisterListener } = createStore<ConfirmCustomerDrawer>('confirmCustomerDrawer', {
  open: false,
  anchor: 'right',
  suppressDataDrivenCalculation: false,
  variant: CONFIRMATION_DRAWER_VARIANT,
});
const drawerLoadingKey = 'customerDrawer';
export default function useConfirmCustomerDrawer() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(drawerLoadingKey);
  const { trackPageView } = useStreetfairAnalytics();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);


  function openDrawer(variant?:string) {
    void trackPageView('Customer_Detail_Drawer');
    update({
      ...get(),
      open: true,
      variant: variant ? variant : CONFIRMATION_DRAWER_VARIANT,
    });
  }

  function closeDrawer(suppressDataDrivenCalculation?:boolean) {
    update({
      ...get(),
      open: false,
      suppressDataDrivenCalculation: suppressDataDrivenCalculation ?? false,
      variant: CONFIRMATION_DRAWER_VARIANT,
    });
  }

  return {
    loadingKey: drawerLoadingKey,
    anchor: get().anchor,
    open: get().open,
    suppressDataDrivenCalculation: get().suppressDataDrivenCalculation,
    variant: get().variant,
    openDrawer,
    closeDrawer,
    onDrawerLoading: onLoading,
    doneDrawerLoading: doneLoading,
  };
}