import { Grid, Typography } from '@mui/material';
import styles from './reviewDrawer.module.css';
export interface IReviewSectionProps {
  title:string | JSX.Element;
  content: string | JSX.Element;
}

export default function ReviewSection({ title, content }:IReviewSectionProps) {

  return (
    <Grid container item className={styles.reviewSection}>
      <Grid item xs={12} >
        <Typography variant='subtitle2' className={styles.reviewSectionTitle}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
}