import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IInitialConfirmationForm } from 'model/customer';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DemoUtil } from 'util/demoUtil';
import styles from './customer.module.css';
import { HardDeleteCustomerModal } from './hardDeleteCustomer';
import { HoldCustomerModal } from './holdCustomer';
import useConfirmCustomerDrawer from './hookStore/useConfirmCustomerDrawer';
import useCustomerDetail from './hookStore/useCustomerDetail';
import { OperationsView, ShowOperationsButton } from './operationsView';
import ProviderWorkflow from './providerWorkflow/ProviderWorkflow';
import { RemoveCustomerButton, RemoveCustomerModal } from './removeCustomer';
import { RequestPaymentButton, RequestPaymentModal } from './requestPaymentModal';
import { RequestSupportCard, RequestSupportModal } from './requestSupport';

function Divider() {
  return (
    <div className={styles.divider}></div>
  );
}

export default function InitialConfirmation() {
  const theme = useTheme();
  const { initialConfirmation, isProviderAdmin, clearNsoisWithSameNso } = useCustomerDetail();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  const { openDrawer } = useConfirmCustomerDrawer();

  const formContext = useForm<IInitialConfirmationForm>({ mode: 'onTouched', defaultValues: initialConfirmation });

  const onSubmit : SubmitHandler<IInitialConfirmationForm> = data => {
    clearNsoisWithSameNso();
    openDrawer();
  };

  useEffect(() => {
    formContext.reset(initialConfirmation);
  }, [initialConfirmation]);


  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmit)}>
          <Grid container justifyContent='center'>
            <Grid container item xs={12} >
              <Grid item xs={12} textAlign='left'>
                {/* <CustomerTimeCounter/> */}
                <Typography variant='subtitle2'>Progress Tracker</Typography>
                <Typography variant='caption'>Please mark off the relevant items as soon as they are completed.</Typography>
              </Grid>
              <Grid item xs={12} textAlign='left' className={styles.providerWorkflowWrapper}>
                <ProviderWorkflow/>
              </Grid>
              <>
                <Grid item xs={12} sm='auto'
                  textAlign={{ xs: 'left', sm: 'left' }}
                  sx={{
                    marginTop: {
                      xs: '0px',
                      sm: '10px',
                    },
                  }}>
                  <RequestPaymentButton/>
                </Grid>
                <Grid item xs={12} sm='auto'
                  textAlign={{ xs: 'left', sm: 'left' }}
                  sx={{

                    marginTop: {
                      xs: '10px',
                      sm: '10px',
                    },
                  }}>
                  <RemoveCustomerButton/>
                </Grid>
                <Divider/>
                <RequestSupportCard/>
                {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
                  <Grid item xs={12} sm='auto'
                    alignSelf={'flex-end'}
                    textAlign={{ xs: 'left', sm: 'left' }}
                    sx={{

                      marginTop: {
                        xs: '10px',
                        sm: '10px',
                      },
                    }}>
                    <ShowOperationsButton/>
                  </Grid>
                )}

              </>
              {mdDown && (
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    onClick={() => navigate(`/v1/${serviceProviderId}/customers`)}
                    className={styles.viewAllCustomers}>View All Customers</Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && <OperationsView/>}
      <RequestPaymentModal />
      <RequestSupportModal/>
      <RemoveCustomerModal/>
      <HoldCustomerModal/>
      {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && <HardDeleteCustomerModal/>}
    </>
  );
}
