import PlaceIcon from '@mui/icons-material/Place';
import { Grid, MenuItem, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import StatusBall from 'components/Status/StatusBall';
import { IProviderHistoricalParticipant } from 'model/customer';
import { formatLongMonthDayYear } from 'util/dateUtil';
import { capitalizeFirstLetter } from 'util/formatUtil';

export interface IHistoricalParticipantMenuItemProps {
  historicalParticipant:IProviderHistoricalParticipant;
}

export default function HistoricalParticipantMenuItem({ historicalParticipant }:IHistoricalParticipantMenuItemProps) {
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  function onClick() {
    if (historicalParticipant.id && historicalParticipant.neighborhoodServiceOfferingInstanceId) {
      navigate(`/v1/${serviceProviderId}/customers/${historicalParticipant.id}`);
      location.reload();
    } else {
      navigate(`/v1/${serviceProviderId}/subscribers/${historicalParticipant.id}`);
      location.reload();
    }
  }
  return (
    <MenuItem onClick={onClick} disableRipple
      sx={{
        width: '300px',
      }}
    >
      <Grid container sx={{ padding: '4px' }}>
        <Grid container item xs={6} alignItems='center' flexWrap='nowrap'>
          <StatusBall
            size={10}
            marginRight={5}
            status={historicalParticipant.status}
          />
          <Typography variant='body2' component='span' sx={{ fontSize: '12px', lineHeight: 'initial' }}>{capitalizeFirstLetter(historicalParticipant.status)}</Typography>
        </Grid>
        <Grid container item xs={6} alignItems='center' flexWrap='nowrap'>
          <PlaceIcon />
          <Typography variant='body2' component='span' sx={{ fontSize: '12px', lineHeight: 'initial' }}>{historicalParticipant.neighborhoodName}</Typography>
        </Grid>
        <Grid container item xs={12} sx={{ marginTop: '5px' }} alignItems='center'>
          <Grid item xs={6} >
            <Typography variant='body2' sx={{ color: '#212121', lineHeight: 'initial' }}>
              <>{formatLongMonthDayYear(historicalParticipant.serviceDate || historicalParticipant.firstServiceDate) || 'No date set'}</>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' component='div' sx={{ marginLeft: '2px', color: '#212121', lineHeight: 'initial' }}>({historicalParticipant.serviceType})</Typography>
          </Grid>
        </Grid>
      </Grid>
    </MenuItem>
  );
}