import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { store } from './groupDealStore';
import { deleteGroupDeal as apiDeleteGroupDeal } from 'api/serviceInstanceApi';
import { useLoading } from 'components/layout/Loading';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import useToast from 'components/toast/useToast';
import { createDateIgnoreTimezone, formatLongMonthDayYear } from 'util/dateUtil';
import { isTestNeighborhood } from 'util/testDataUtil';

const { get, update, registerListener, unregisterListener } = store;

const loadingKey = 'groupDeal';
const modalKey = 'confirmGroupDealDeleteModal';
export default function useRemoveGroupDealStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();
  const { createSuccessToast, createInfoToast, createErrorToast } = useToast();
  const { openModal } = useConfirmationModal(modalKey);
  const { serviceProviderId, groupDealId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function openConfirmDeleteModal() {
    openModal({});
  }

  async function deleteGroupDeal() {
    try {
      onLoading();
      if (serviceProviderId && groupDealId) {

        await apiDeleteGroupDeal(serviceProviderId, groupDealId);
      }
      doneLoading();
      createSuccessToast('Group Deal deleted. Navigating to group deals.');
      setTimeout(() => {
        navigate(`/v1/${serviceProviderId}/groupDeals`);
      }, 300);
    } catch (err:any) {
      createErrorToast(err.response.data.message);
      doneLoading();
    }
  }

  function canDeleteGroupDeal() {
    const { pendingCustomers, activeOrCompleteCustomers } = get();
    const hasNonPlaceholderPendingCustomer = pendingCustomers.filter(x => !x.email.includes('@streetfair.com') && !x.placeholder && !isTestNeighborhood(x.neighborhoodId)).length > 0;
    const hasNonPlaceholderActiveOrCompleteCustomer = activeOrCompleteCustomers.filter(x => (!x.email.includes('streetfair') || x.email.includes('group_deal_management') )&& !x.placeholder && !isTestNeighborhood(x.neighborhoodId)).length > 0;
    return !hasNonPlaceholderActiveOrCompleteCustomer && !hasNonPlaceholderPendingCustomer;
  }

  function getFormattedServiceTypeAndServiceDates() {
    const { formData } = get();
    if (formData.endServiceDate) {
      return `${formData.serviceTypeName} between ${formatLongMonthDayYear(createDateIgnoreTimezone(formData.serviceDate))} and ${formatLongMonthDayYear(createDateIgnoreTimezone(formData.endServiceDate))}`;
    } else {
      return `${formData.serviceTypeName} on ${formatLongMonthDayYear(createDateIgnoreTimezone(formData.serviceDate))}`;
    }
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    modalKey,
    deleteGroupDeal,
    openConfirmDeleteModal,
    getFormattedServiceTypeAndServiceDates,
    canDeleteGroupDeal,
  };
}