import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Button, Grid, IconButton, Typography } from '@mui/material';
import Loading from 'components/layout/Loading';
import Shake, { useShake } from 'components/layout/Shake';
import { HookTextField } from 'components/reactHookForm';
import { ISignUpForm, createEmptySignUpForm } from 'model/userfront';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './signUp.module.css';
import useSignUpStore from './useSignUpStore';
export interface ISignUpFormInternalProps {
  initialFormData:ISignUpForm;
}

const shakeStoreKey = 'saveProviderUser';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
function SignUpFormInternal({
  initialFormData,
}:ISignUpFormInternalProps) {
  const { errorMessage, save, setErrorMessage } = useSignUpStore();
  const { setAnimate } = useShake(shakeStoreKey);
  const formContext = useForm<ISignUpForm>({ mode: 'onTouched', defaultValues: createEmptySignUpForm() });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState(false);


  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<ISignUpForm> = data => {
    if (data.password !== data.passwordVerify) {
      onInvalid();
      setErrorMessage('Passwords do not match.');
    } else if (data.email == null || data.email === '' || !emailRegex.test(data.email)) {
      onInvalid();
      setErrorMessage('Please type a valid email address.');
    } else {
      void save(data);
    }
  };
  const onInvalid : any = data => {
    setAnimate(true);
  };

  const { isSubmitting, isValid } = formContext.formState;

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={formContext.handleSubmit(onSubmit, onInvalid)}
        style={{ width: '100%' }}
      >
        <HookTextField
          variant='outlined'
          label='Email'
          autoComplete='off'
          name={'email'}
          inputProps={{
            type: 'email',
          }}
          validationRules={{
            required: {
              value: true, message: 'Please enter your email',
            },
          }}
          size='small'
          sx={{
            '&&& .MuiInputBase-input': {
              fontWeight: '200',
            },

          }}
        />
        <HookTextField
          variant='outlined'
          label='Password'
          name={'password'}
          inputProps={{
            type: showPassword ? 'text' : 'password',
            autoComplete: 'new-password',
          }}
          validationRules={{
            required: {
              value: true, message: 'Please enter your password',
            },
          }}

          size='small'
          sx={{
            '&&& .MuiInputBase-root': {
              fontWeight: '200',
            },
          }}
          InputProps={{

            endAdornment: (
              <IconButton
                title="Show Password"
                aria-label="Show Password"
                size="small"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </IconButton>
            ),
          }}
        />
        <HookTextField
          variant='outlined'
          label='Verify Password'
          name={'passwordVerify'}
          inputProps={{
            type: showPasswordVerify ? 'text' : 'password',
            autoComplete: 'off',
          }}
          validationRules={{
            required: {
              value: true, message: 'Please enter your password',
            },
          }}
          size='small'
          helperText='Password must be at least 8 characters including 1 uppercase letter and 1 number.'
          sx={{
            '&&& .MuiInputBase-root': {
              fontWeight: '200',
            },
            '&&& .MuiFormHelperText-root': {
              marginLeft: '0',
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                title="Show Password"
                aria-label="Show Password"
                size="small"
                onClick={() => {
                  setShowPasswordVerify(!showPasswordVerify);
                }}
              >
                {showPasswordVerify ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </IconButton>
            ),
          }}
        />
        { (errorMessage !== null && errorMessage !== '') && (
          <Alert
            severity='error'
          >
            <Typography variant='body2' textAlign='left'>{errorMessage}</Typography>
          </Alert>
        )}
        <Shake
          storeKey={shakeStoreKey}
          timeout={900}
          renderProps={(animatedClassName) => (
            <Button
              className={`${styles.saveButton} ${animatedClassName}`}
              disabled={isSubmitting}
              variant='contained'
              type='button'
              onClick={async (e) => {
                await formContext.handleSubmit(onSubmit, onInvalid)(e);
              }}
            >
              <div style={{ textTransform: 'initial', fontWeight: '600', fontSize: '16px' }}>Submit</div>
            </Button>
          )}
        />
      </form>
    </FormProvider>
  );
}

export default function SignUpForm() {
  const { showAlreadySignedIn, formData, loadingKey, init } = useSignUpStore();
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  useEffect(() => {
    void init();

  }, []);
  if (showAlreadySignedIn) {
    return (
      <Grid container item xs={12} justifyContent={'center'}>
        <Typography>You are already signed in. If you are unable to access your data please contact us at support@streetfair.com, Otherwise, click the button below to navigate home.</Typography>
        <Grid item xs='auto'>
          <Button
            variant='contained'
            type='button'
            onClick={() => {
              navigate(`/v1/${serviceProviderId}`);
            }}
            sx={{
              width: '200px',
            }}
          >
            <>Home</>
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <SignUpFormInternal initialFormData={formData}/>
      <Grid container item xs={12} alignItems={'flex-start'} justifyContent={'center'} sx={{ paddingBottom: '5px' }}>
        <Grid item xs='auto'>
          <Typography variant='body1' sx={{ fontSize: '14px' }}>Already have an account?</Typography>
        </Grid>
        <Grid item xs='auto' sx={{ paddingLeft: '8px' }}>
          <Link to={'/sign-in'} style={{ fontSize: '14px' }}>Sign In</Link>
        </Grid>
      </Grid>
    </Loading>
  );
}