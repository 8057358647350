import { findNeighborhoodByPlace } from 'api/googleApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IDropdownOption } from 'model/dropdown';
import { IFindNeighborhoodByPlaceRequestDTO } from 'model/google';
import { INeighborhood, createEmptyNeighborhood } from 'model/neighborhood';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GroupDealCreationState, store } from './groupDealStore';

const { get, update, registerListener, unregisterListener } = store;

const loadingKey = 'groupDeal';
const neighborhoodLoadingKey = 'neighborhoodLoading';

export default function useGroupDealCreationStore() {
  const setState = useState(get())[1];
  const { serviceProviderId, groupDealId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { onLoading: onNeighborhoodLoading, doneLoading: doneNeighborhoodLoading } = useLoading(neighborhoodLoadingKey);
  const navigate = useNavigate();
  const { createSuccessToast, createErrorToast } = useToast();
  const { gdmCreateNewNeighborhoodJun2023 } = useFlags();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function init() {
    let _maxCustomerCountOptions = createMaxCustomerOptions();
    update({
      ...get(),
      groupDealCreationState: GroupDealCreationState.createEmpty(),
      maxCustomerCountOptions: _maxCustomerCountOptions,
      customerIndexToNeighborhoodMap: new Map<number, INeighborhood>(),
    });
  }

  function onNext() {
    const { groupDealCreationState } = get();
    groupDealCreationState.nextStep();
    const nextGroupDealCreationState = new GroupDealCreationState(groupDealCreationState.currentActiveStepIndex, groupDealCreationState.steps);
    update({
      ...get(),
      groupDealCreationState: nextGroupDealCreationState,
    });
  }

  async function onFindNeighborhoodByPlace(formContext: any, customerIndex: number, placeId:string) {
    onLoading();
    const { customerIndexToNeighborhoodMap, customerIndexToPossibleNeighborhoodsMap } = get();
    const dto: IFindNeighborhoodByPlaceRequestDTO = {
      placeId,
    };
    try {
      const res = await findNeighborhoodByPlace(dto);
      if (res.data) {
        let showCreateNeighborhood = false;
        //using a map because originally could add more than one customer as part of creation. Potential to add this back so keeping it around.
        let nextMap = new Map<number, INeighborhood>(customerIndexToNeighborhoodMap);
        let nextPossibleNeighborhoodsMap = new Map<number, INeighborhood[]>(customerIndexToPossibleNeighborhoodsMap);
        if (customerIndex === 0 && res.data.neighborhood) {
          nextMap.set(customerIndex, res.data.neighborhood);
          formContext.setValue('neighborhoodId', res.data.neighborhood.id);
          formContext.setValue('createNeighborhoodRequestDTO.placeId', '');
          formContext.setValue('createNeighborhoodRequestDTO.zipCode', '');
        } else if (customerIndex === 0 && res.data.possibleMatchingNeighborhoods.length > 0) {
          //clear any previous selection and show a new list of possible neighborhoods
          nextMap.delete(customerIndex);
          formContext.setValue('neighborhoodId', '');
          nextPossibleNeighborhoodsMap.set(customerIndex, res.data.possibleMatchingNeighborhoods);
          formContext.setValue('createNeighborhoodRequestDTO.placeId', '');
          formContext.setValue('createNeighborhoodRequestDTO.zipCode', '');
        } else {
          //did not find an exact match or multiple matches, we need to create a new neighborhood
          nextMap.set(customerIndex, createEmptyNeighborhood());
          formContext.setValue('neighborhoodId', '');
          if (gdmCreateNewNeighborhoodJun2023) {
            showCreateNeighborhood = true;
            formContext.setValue('createNeighborhoodRequestDTO.placeId', res.data.parsedIndividualDetails.placeId);
            formContext.setValue('createNeighborhoodRequestDTO.zipCode', res.data.parsedIndividualDetails.zipCode);
          }

        }
        update({
          ...get(),
          customerIndexToNeighborhoodMap: nextMap,
          customerIndexToPossibleNeighborhoodsMap: nextPossibleNeighborhoodsMap,
          showCreateNeighborhood,
        });
      }
    } catch (e: any) {
      console.error(e);
    } finally {
      doneLoading();
    }
  }

  function createMaxCustomerOptions():IDropdownOption[] {
    return [
      { key: '1', optionValue: '1', optionText: '1' },
      { key: '2', optionValue: '2', optionText: '2' },
      { key: '3', optionValue: '3', optionText: '3' },
      { key: '4', optionValue: '4', optionText: '4' },
      { key: '5', optionValue: '5', optionText: '5' },
      { key: '6', optionValue: '6', optionText: '6' },
      { key: '7', optionValue: '7', optionText: '7' },
      { key: '8', optionValue: '8', optionText: '8' },
      { key: '9', optionValue: '9', optionText: '9' },
      { key: '10', optionValue: '10', optionText: '10' },
      { key: '11', optionValue: '11', optionText: '11' },
      { key: '12', optionValue: '12', optionText: '12' },
      { key: '13', optionValue: '13', optionText: '13' },
      { key: '14', optionValue: '14', optionText: '14' },
      { key: '15', optionValue: '15', optionText: '15' },
      { key: '16', optionValue: '16', optionText: '16' },
      { key: '17', optionValue: '17', optionText: '17' },
      { key: '18', optionValue: '18', optionText: '18' },
      { key: '19', optionValue: '19', optionText: '19' },
      { key: '20', optionValue: '20', optionText: '20' },
    ];
  }

  /**
   * Need to clear the dropdown options and set the neighborhood for the given customer as well as set the neighborhoodId for the formContext
   * @param formContext
   * @param customerIndex
   * @param neighborhood
   */
  function onSelectPossibleNeighborhood(formContext:any, customerIndex:number, neighborhood:INeighborhood) {
    const { customerIndexToNeighborhoodMap, customerIndexToPossibleNeighborhoodsMap } = get();
    let nextMap = new Map<number, INeighborhood>(customerIndexToNeighborhoodMap);
    let nextPossibleNeighborhoodsMap = new Map<number, INeighborhood[]>(customerIndexToPossibleNeighborhoodsMap);
    nextMap.set(customerIndex, neighborhood);
    nextPossibleNeighborhoodsMap.delete(customerIndex);
    formContext.setValue('neighborhoodId', neighborhood.id);
    update({
      ...get(),
      customerIndexToNeighborhoodMap: nextMap,
      customerIndexToPossibleNeighborhoodsMap: nextPossibleNeighborhoodsMap,
    });
  }

  function clearCustomerNeighborhood(formContext: any, customerIndex: number) {
    const { customerIndexToNeighborhoodMap, customerIndexToPossibleNeighborhoodsMap } = get();
    var nextMap = new Map<number, INeighborhood>(customerIndexToNeighborhoodMap);
    var nextPossibleNeighborhoodsMap = new Map<number, INeighborhood[]>(customerIndexToPossibleNeighborhoodsMap);
    nextMap.delete(customerIndex);
    nextPossibleNeighborhoodsMap.delete(customerIndex);
    formContext.setValue('neighborhoodId', null);

    update({
      ...get(),
      customerIndexToNeighborhoodMap: nextMap,
      customerIndexToPossibleNeighborhoodsMap: nextPossibleNeighborhoodsMap,
    });
  }

  function onShowCreateNeighborhood() {
    update({
      ...get(),
      showCreateNeighborhood: true,
    });
  }

  return {
    loadingKey: loadingKey,
    neighborhoodLoadingKey,
    groupDealCreationState: get().groupDealCreationState,
    maxCustomerCountOptions: get().maxCustomerCountOptions,
    customerIndexToNeighborhoodMap: get().customerIndexToNeighborhoodMap,
    customerIndexToPossibleNeighborhoodsMap: get().customerIndexToPossibleNeighborhoodsMap,
    showCreateNeighborhood: get().showCreateNeighborhood,
    init,
    onNext,
    onFindNeighborhoodByPlace,
    clearCustomerNeighborhood,
    onSelectPossibleNeighborhood,
    onShowCreateNeighborhood,
  };
}