import { Typography } from '@mui/material';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import styles from './confirmCustomerDrawer.module.css';
import { ICustomerServiceLineItem } from 'model/customer';
import { formatUSD } from 'util/formatUtil';

export default function TotalPrice() {
  const {
    clientSidePriceResult,
  } = usePriceCalculator();

  return (
    <>
      <div style={{ width: '100%' }}></div>
      <Typography variant='h6' component='div' className={styles.totalPrice}>Total Price: {formatUSD(clientSidePriceResult.priceResult.totalPriceAfterDiscount)}</Typography>
    </>
  );
}


function hasExtraServiceItems(serviceItems:ICustomerServiceLineItem[]) {
  return serviceItems.length > 0;
}