import { Grid, Stack, Typography } from '@mui/material';
import styles from './customerAvatarGroup.module.css';
import ServiceCardAvatar from 'components/cardAvatar';

interface CustomerAvatarGroupParams {
  customers: any[];
  avatarIconStyleOverride?: any;
  rowIndex?:number;
}
export default function CustomerAvatarGroup({ customers, avatarIconStyleOverride, rowIndex }: CustomerAvatarGroupParams) {
  const _maxAvatarIcons = 5;

  return (
    <Grid container item xs={12} justifyContent="center" alignItems='center' className={`customerAvatarGroup${rowIndex} ${styles.avatarWrapperNoBottom}`}>
      <Grid item>
        <Stack direction='row' className={styles.avatarGroup} alignItems='center'>
          {
            customers.filter((x, i) => i+1 <= _maxAvatarIcons).map((x, i) => {
              return (
                <ServiceCardAvatar
                  index={i}
                  key={x.id || i}
                  firstName={x.firstName}
                  lastName={x.lastName}
                  avatarIconStyleOverride={avatarIconStyleOverride}
                  className='customerServiceAvatar'/>
              );
            })
          }
        </Stack>
      </Grid>
      <Grid item>
        {customers.length > _maxAvatarIcons &&
                    <Typography variant='body2' textAlign='left' className={`customerAvatarGroupExtraCustomers ${styles.extraCustomers}`}>+{customers.length - _maxAvatarIcons}</Typography>
        }
      </Grid>
    </Grid>
  );
}