import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Footer from 'components/footer';
import Loading from 'components/layout/Loading';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatPercent, hasValue } from 'util/numberUtil';
import TermsAndConditionsForm from './TermsAndConditionsForm';
import useTermsAndConditions from './hookStore/useTermsAndConditions';
import styles from './termsAndConditions.module.css';

function TermListItem({ children }:any) {

  return (
    <li style={{ textAlign: 'left' }}>
      {children}
    </li>
  );
}

function valueShare(oneTimePercent, recurringPercent) {
  if (hasValue(oneTimePercent) && hasValue(recurringPercent)) {
    return `I agree to pay StreetFair ${formatPercent(oneTimePercent)}% of the job value for one-time services and ${formatPercent(recurringPercent)}% for recurring services.`;
  } else if (hasValue(oneTimePercent)) {
    return `I agree to pay StreetFair ${formatPercent(oneTimePercent)}% of the job value for one-time services.`;
  } else if (hasValue(recurringPercent)) {
    return `I agree to pay StreetFair ${formatPercent(recurringPercent)}% of the job value for recurring services.`;
  }
  return null;
}

export default function TermsAndConditions() {
  const { serviceProviderId } = useParams();
  const {
    loadingKey,
    init,
    termsAndConditionsData: { agreedToPartnership, oneTimePercent, recurringPercent, customConditions },
  } = useTermsAndConditions();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackPageView } = useStreetfairAnalytics();
  useEffect(() => {
    void init();
    void trackPageView('Terms_and_Conditions');
  }, []);
  if (!oneTimePercent && !recurringPercent && !customConditions) {
    return (
      <Grid container justifyContent='center' alignItems='center' sx={{ height: '100vh' }}>
        <Grid container item xs={12} sm={10} md={10} lg={10} className={styles.pageContainer} justifyContent='center'>
          <Grid item>
            <Typography variant='body1'>Please contact StreetFair support for account setup.</Typography>
          </Grid>
          <Footer className={styles.footer}/>
        </Grid>
      </Grid>
    );
  }
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={10} className={styles.pageContainer} >
          <Grid className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} container item xs='auto' alignItems='center' wrap="nowrap">
            <HomeIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}`}>Home</Link>
              <Link to={`/v1/${serviceProviderId}/termsAndConditions`} className={styles.lastLink}>Partnership Agreement</Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} >
            <Typography variant='h5' component='div' className={smDown ? styles.mobileHeader : styles.header} textAlign='left'>Partnership Agreement</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' textAlign='left'>Owning a business is hard, but working with StreetFair shouldn't be. To ensure we're on the same page, we ask all of our Service Providers to agree to a few basic ground rules:</Typography>
          </Grid>
          <Grid item xs={12} className={styles.section}>
            <Typography variant='subtitle2' textAlign='left'>Communication is Key:</Typography>
            <ul>
              <TermListItem>
                <Typography variant='body1'>I agree to respond to a customer service request within 1 business day.</Typography>
                <ul>
                  <TermListItem>
                    <Typography variant='body1'>If you're out of town or have extenuating circumstances, just text us and let us know.</Typography>
                  </TermListItem>
                </ul>
              </TermListItem>
              <TermListItem>
                <Typography variant='body1'>I will close the loop with StreetFair and update Provider Portal in a timely manner honestly.</Typography>
              </TermListItem>
              <TermListItem>
                <Typography variant='body1'>I consent to receive text messages from StreetFair regarding important customer activity.</Typography>
                <ul>
                  <TermListItem>
                    <Typography variant='body1'>I understand that if I no longer wish to receive text messages, I can disable them at any time.</Typography>
                  </TermListItem>
                </ul>
              </TermListItem>
            </ul>
          </Grid>

          <Grid item xs={12} className={styles.section}>
            <Typography variant='subtitle2' textAlign='left'>Be Respectful:</Typography>
            <Typography variant='body1' textAlign='left'>It's a core principle at StreetFair. We promise to always respect you and your business.</Typography>
            <ul>
              <TermListItem>
                <Typography variant='body1'>I agree to be respectful and professional to StreetFair and its customers/users. I know I can expect the same in return from StreetFair.</Typography>
              </TermListItem>
            </ul>
          </Grid>
          <Grid item xs={12} className={styles.section}>
            <Typography variant='subtitle2' textAlign='left'>Clean breakups only:</Typography>
            <Typography variant='body1' textAlign='left'>We hope StreetFair works really well for your business and you stay active on the platform forever, but we know things may change.</Typography>
            <ul>
              <TermListItem>
                <Typography variant='body1'>I know that I can remove my business from StreetFair at any point and that StreetFair reserves the right to do the same.</Typography>
              </TermListItem>
            </ul>
          </Grid>
          <Grid item xs={12} className={styles.section}>
            <Typography variant='subtitle2' textAlign='left'>We make money together:</Typography>
            <ul>
              {(hasValue(oneTimePercent) || hasValue(recurringPercent)) ? (
                <TermListItem>
                  <Typography variant='body1'>{valueShare(oneTimePercent, recurringPercent)}</Typography>
                </TermListItem>
              ) : null}
              {customConditions && customConditions.trim() && (
                <TermListItem><Typography variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>{customConditions}</Typography></TermListItem>
              )}
            </ul>
          </Grid>
          <Grid item xs={12} className={styles.acceptTitle}>
            <Typography variant='h6' component='div' textAlign='left'>Do you agree to the terms and conditions listed above?</Typography>
          </Grid>
          <Grid item xs={12} textAlign='left'>
            <TermsAndConditionsForm/>
          </Grid>
          <Footer className={styles.footer}/>
        </Grid>
      </Grid>
    </Loading>
  );
}