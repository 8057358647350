import { findAllServiceCategoryTypes } from 'api/serviceCategoryTypeApi';
import { createModuleStore } from 'hooks/hookStore';
import { IServiceCategoryType, IServiceCategoryTypeDisplay, createServiceCategoryTypeDisplay } from 'model/serviceCategoryType';
import { useEffect, useState } from 'react';

type ServiceCategoryTypeDisplayStore = {

}

type ModuleSelf = {
  loaded:boolean;
  serviceCategoryTypes: IServiceCategoryType[];
  displayMap: Map<String, IServiceCategoryTypeDisplay>;
}

const { get, getModuleScoped, update, registerListener, unregisterListener } = createModuleStore<ServiceCategoryTypeDisplayStore, ModuleSelf>('ServiceCategoryTypeDisplay', {

},
{
  loaded: false,
  displayMap: new Map<String, IServiceCategoryTypeDisplay>(),
  serviceCategoryTypes: [],
});

const loadingKey = 'ServiceCategoryTypeDisplay';

export default function useServiceCategoryTypeDisplay() {
  const setState = useState(get())[1];

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    void onInitDisplayMap();
  }, []);

  async function onInitDisplayMap() {
    try {
      const moduleScope = getModuleScoped();
      if (!moduleScope.loaded) {
        moduleScope.loaded = true;
        const res = await findAllServiceCategoryTypes();
        if (res.data) {
          moduleScope.serviceCategoryTypes = res.data;
          for (let dto of res.data) {
            if (dto.serviceCategoryDisplayName != null || dto.serviceTypeDisplayName != null) {
              const val = createServiceCategoryTypeDisplay(
                dto.serviceCategoryDisplayName,
                dto.serviceTypeDisplayName,
              );
              moduleScope.displayMap.set(`${dto.category}_${dto.serviceType}`, val);
            }
          }
        }
      }
    } catch (err:any) {
      console.log(err);
    }
  }

  function getServiceTypeDisplay(category: string | null, serviceType: string | null):string {
    if (category == null && !serviceType) {
      return serviceType!;
    }
    if (category == null || serviceType == null) {
      return '';
    }
    var key = `${category}_${serviceType}`;
    var displayMap = getModuleScoped().displayMap;
    if (displayMap.has(key)) {
      return displayMap.get(key)!.serviceTypeDisplayName as string;
    } else {
      return serviceType;
    }
  }

  function getDisplay(category:string, serviceType:string):IServiceCategoryTypeDisplay {
    const moduleScope = getModuleScoped();
    return moduleScope.displayMap[`${category}_${serviceType}`];
  }


  return {
    loadingKey: loadingKey,
    ...get(),
    serviceCategoryTypes: getModuleScoped().serviceCategoryTypes,
    displayMap: getModuleScoped().displayMap,
    getServiceTypeDisplay,
    getDisplay,
  };
}