import { Box, Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { HookCheckbox } from 'components/reactHookForm';
import DiscountScheduleSelect from 'pages/groupDeal/formComponents/DiscountScheduleSelect';
import useGroupDeal from 'pages/groupDeal/hookStore/useGroupDeal';
import { DemoUtil } from 'util/demoUtil';

export default function GroupDealAdminConfig() {
  const formCtx = useFormContext();
  const { groupDealId } = useParams();
  const isNew = groupDealId === 'new';
  const { findServiceOffering, doesNeighborhoodHaveTerritoryEnabled, isEditing, isProviderAdmin, editingClicked, nsoi } = useGroupDeal();
  const { openModal } = useConfirmationModal('requestNeighborhoodModal');
  const watchedTsoi = useWatch({ control: formCtx.control, name: 'territoryEnabled' });
  const watchedServiceOfferingId = useWatch({ control: formCtx.control, name: 'serviceOfferingId' });
  const watchedNeighborhoodId = useWatch({ control: formCtx.control, name: 'neighborhoodId' });
  const serviceOffering = findServiceOffering(watchedServiceOfferingId);

  const _doesNeighborhoodHaveTerritoryEnabled = doesNeighborhoodHaveTerritoryEnabled(serviceOffering, watchedNeighborhoodId);
  let watchedSuperDeal = useWatch({ control: formCtx.control, name: 'superDeal' });
  return (
    <>
      {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
        <Box sx={{ width: '100%' }} textAlign='left'>
          <Box sx={{ width: '100%' }} textAlign='left'>
            <HookCheckbox
              name='vendorDriven'
              label='Is Vendor Driven'
              extraProps={{
                disabled: isEditing,
              }}
            />
          </Box>
          <Box sx={{ width: '100%' }} textAlign='left'>
            <HookCheckbox
              name='territoryEnabled'
              label='TSOI?'
              extraProps={{
                disabled: !editingClicked,
              }}
            />
          </Box>
          <Box sx={{ width: '100%' }} textAlign='left'>
            <HookCheckbox
              name='superDeal'
              label='Super Deal?'
              extraProps={{
                disabled: !editingClicked,
              }}
            />
          </Box>
          <Box sx={{ width: '100%' }} textAlign='left'>
            <HookCheckbox
              name='shouldSendHappeningSoonAlert'
              label='Send Happening Soon Alert'
              extraProps={{
                disabled: !editingClicked,
              }}
            />
          </Box>
          <DiscountScheduleSelect/>
        </Box>
      )}
    </>
  );
}