import { Box, Drawer, Grid, Rating, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HistoricalParticipantsMenu } from 'components/historicalParticipants';
import Loading from 'components/layout/Loading';
import { useCallback } from 'react';
import { createDateIgnoreTimezone, formatLongMonthDayYear } from 'util/dateUtil';
import useReviewDrawer from '../hookStore/useReviewDrawer';
import Header from './Header';
import ReviewSection from './ReviewSection';
import styles from './reviewDrawer.module.css';

const linkStyle = (theme:Theme) => ({
  color: theme.palette.primary.main,
});

export default function ReviewDrawer() {
  const theme = useTheme();
  const xsBrk = useMediaQuery(theme.breakpoints.only('xs'));
  const {
    anchor,
    open,
    loadingKey,
    historicalParticipants,
    customerReview,
  } = useReviewDrawer();

  const topRef = useCallback(node => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  return (
    <Drawer
      anchor={anchor}
      open={open}
      classes={{
        paper: xsBrk ? 'fullWidthDrawerRoot' : '',
      }}

    >
      <Loading loadingKey={loadingKey} size={100} absolute>
        <Box
          className={xsBrk ? styles.wrapperMobile : styles.wrapper}
        >
          <Header ref={topRef}/>
          <Grid container sx={{ padding: '15px' }}>
            <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
              {/* {historicalParticipants.length > 0 && (
                <Box sx={{ padding: 1, border: '1px solid gainsboro', borderRadius: '8px', boxShadow: '2px 2px 5px gray' }}>
                  <Typography variant='subtitle1' sx={{ marginLeft: '18px' }}>Last service</Typography>
                  <HistoricalParticipantMenuItem historicalParticipant={historicalParticipants[0]}/>
                </Box>
              )} */}
              <HistoricalParticipantsMenu historicalParticipants={historicalParticipants}/>
            </Grid>
            <ReviewSection
              title='Review Date'
              content={(
                <Typography variant='body1' component='div'>{formatLongMonthDayYear(createDateIgnoreTimezone(customerReview.reviewDate))}</Typography>
              )}
            />
            <ReviewSection
              title='Rating'
              content={(
                <Rating value={parseFloat(customerReview.rating)} readOnly/>
              )}
            />
            <ReviewSection
              title='Review'
              content={(
                <Typography variant='body1'>{customerReview.comment}</Typography>
              )}
            />
            <ReviewSection
              title='Email'
              content={(
                <a href={`mailto:${customerReview.individualId}`} style={linkStyle(theme)}>{customerReview.individualId}</a>
              )}
            />
          </Grid>
        </Box>
      </Loading>
    </Drawer>
  );
}