import { IDropdownOption } from './dropdown';

export interface IRemoveCustomerForm {
  customerRemoveReason:IDropdownOption;
  comment:string | null;
}

export const PRICE = 'PRICE';
export const COULD_NOT_REACH_CUSTOMER = 'COULD_NOT_REACH_CUSTOMER';
export const COMMUNICATION_CHALLENGES = 'COMMUNICATION_CHALLENGES';
export const SCHEDULING = 'SCHEDULING';
export const NEEDED_UNSUPPLIED_SERVICE = 'NEEDED_UNSUPPLIED_SERVICE';
export const FOUND_ANOTHER_PROVIDER = 'FOUND_ANOTHER_PROVIDER';
export const OTHER = 'OTHER';
export const customerRemoveReasons:IDropdownOption[] = [
  {
    key: PRICE,
    optionValue: PRICE,
    optionText: 'Price',
  },
  {
    key: COULD_NOT_REACH_CUSTOMER,
    optionValue: COULD_NOT_REACH_CUSTOMER,
    optionText: 'Could not reach customer',
  },
  {
    key: COMMUNICATION_CHALLENGES,
    optionValue: COMMUNICATION_CHALLENGES,
    optionText: 'Communication challenges',
  },
  {
    key: SCHEDULING,
    optionValue: SCHEDULING,
    optionText: 'Scheduling / timing',
  },
  {
    key: NEEDED_UNSUPPLIED_SERVICE,
    optionValue: NEEDED_UNSUPPLIED_SERVICE,
    optionText: 'Needed a service we do not offer',
  },
  {
    key: FOUND_ANOTHER_PROVIDER,
    optionValue: FOUND_ANOTHER_PROVIDER,
    optionText: 'Customer chose a different provider',
  },
  {
    key: OTHER,
    optionValue: OTHER,
    optionText: 'Other - please specify in comments',
  },
];
