import { useFlags } from 'launchdarkly-react-client-sdk';
import GroupDeal from './GroupDeal';
import OldGroupDeal from './v1/GroupDeal';


export default function GroupDealVersionChooser() {
  const { groupDealMgmtV2 } = useFlags();

  if (groupDealMgmtV2) {
    return (
      <GroupDeal/>
    );
  }
  return (
    <OldGroupDeal/>
  );
}