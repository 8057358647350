
import { Grid, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../../hookStore/useCustomerDetail';
import usePriceCalculator from '../../hookStore/usePriceCalculator';
import styles from '../confirmCustomerDrawer.module.css';
import { ICustomerServiceLineItem } from 'model/customer';
import { createEmptyServiceItemPricingInfo } from 'model/priceCalculator';
import { formatUSD } from 'util/moneyUtil';

export interface IOneTimeChargePriceCalculationProps {
  index:number;
  serviceItem:ICustomerServiceLineItem;
}

export default function OneTimeChargePriceCalculation({
  index,
  serviceItem,
}:IOneTimeChargePriceCalculationProps) {
  const { getValues, control, formState } = useFormContext();
  const {
    customer,
  } = useCustomerDetail();

  const watchUnitBasedPrice = useWatch({ control, name: `oneTimeChargeServiceItems.${index}.unitBasedPrice` });
  const watchedUnits = useWatch({ control, name: `oneTimeChargeServiceItems.${index}.units` });
  return (
    <div className={styles.serviceItemCardBottom}>
      <Grid container className={styles.serviceItemPriceNoDiscount} textAlign='right' justifyContent={'end'} >
        <Grid item xs='auto'>
          <div className={styles.serviceItemPriceAmount}>
            <Typography variant='caption' textAlign='left'>Amount</Typography>
            <Typography variant='h6' sx={{ fontSize: '18px' }}>{formatUSD(watchUnitBasedPrice*(watchedUnits ?? 1), '-')}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}