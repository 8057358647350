import Axios, { AxiosResponse } from 'axios';
import { IFindAnalogousNSORequest, IFindNSOsRequestDTO, INeighborhoodServiceOffering } from 'model/neighborhoodServiceOffering';

export function findNeighborhoodServiceOfferingDetails(nsoId:any, serviceInstanceId?:any) {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/neighborhoodServiceOffering/v2/findDetails/${nsoId}${serviceInstanceId ? '/serviceInstances/' + serviceInstanceId : ''}`);
}

export function findNSOs(serviceProviderId:string, request:IFindNSOsRequestDTO):Promise<AxiosResponse<INeighborhoodServiceOffering[]>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/neighborhoodServiceOffering/v2/serviceProviders/${serviceProviderId}/findNeighborhoodServiceOfferings`, request);
}

export function findAnalogousNsoRequest(serviceProviderId:string, request: IFindAnalogousNSORequest):Promise<AxiosResponse<INeighborhoodServiceOffering>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/neighborhoodServiceOffering/v2/serviceProviders/${serviceProviderId}/findAnalogousNeighborhoodServiceOffering`, request);
}