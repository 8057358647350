import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import Footer from 'components/footer';
import useTableSearch, { escapeRegExp } from 'components/grid/useTableSearch';
import Loading from 'components/layout/Loading';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import MultiToolbar from 'pages/customerTable/MultiToolbar';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { createDateIgnoreTimezone, formatLongMonthDayYear, formatMonthDayYear } from 'util/dateUtil';
import { formatPhoneNumber } from 'util/formatUtil';
import { defaultFilterPanelConfig } from 'util/tableUtil';
import ActionItems from './ActionItems';
import styles from './customerTable.module.css';
import useCustomerTable from './hookStore/useCustomerTable';
import { getColumnData } from './tableConfig';


const customSearchMap = new Map<string, (searchString:string, fieldValue:string, row?:any) => boolean>();
customSearchMap.set('serviceDate', (searchString:string, fieldValue:string) => {
  const searchRegex = new RegExp(escapeRegExp(searchString), 'i');
  const formatted = formatLongMonthDayYear(createDateIgnoreTimezone(fieldValue));
  const slashFormat = formatMonthDayYear(createDateIgnoreTimezone(fieldValue));
  const dashFormat = formatMonthDayYear(createDateIgnoreTimezone(fieldValue), '-');
  return searchRegex.test(formatted) || searchRegex.test(slashFormat) || searchRegex.test(dashFormat);
});
customSearchMap.set('fullName', (searchString:string, fieldValue:string, row:any) => {
  const searchRegex = new RegExp(escapeRegExp(searchString), 'i');
  return searchRegex.test(`${row.firstName} ${row.lastName} ${row.neighborhoodName}`);
});

customSearchMap.set('phone', (searchString:string, fieldValue:string, row:any) => {
  const searchRegex = new RegExp(escapeRegExp(searchString), 'i');
  return searchRegex.test(`${row.phone} ${formatPhoneNumber(row.phone)}`);
});

customSearchMap.set('status', (searchString:string, fieldValue:string, row:any) => {
  const searchRegex = new RegExp(escapeRegExp(searchString), 'i');
  if (fieldValue === 'ACTIVE') {
    return searchRegex.test('confirmed');
  }
  return searchRegex.test(fieldValue);
});


const customSearch = {
  map: customSearchMap,
};

const StyledDataGrid = styled(DataGridPro)({
  [`& .${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer}`]: {
    visibility: 'visible',
    width: 'auto',
  },
});

export default function CustomersTable() {

  const { serviceProviderId } = useParams();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    loadingKey,
    quickSearchStorageKey,
    init,
    filteredCustomers,
    customers,
    sortModel,
    filterModel,
    onSortModelChange,
    navigateToCustomerDetail,
    updateGridFilterModel,
  } = useCustomerTable();
  const {
    rows,
    searchText,
    setRowsWithStoredFilter,
    requestSearch,
    clearStoredSearch,
  } = useTableSearch(quickSearchStorageKey, customSearch, [
    'firstName',
    'lastName',
    'fullName', //custom
    'email',
    'neighborhoodName',
    'phone',
    'serviceDate',
    'status',
    'streetAddress',
    'zipCode',
  ]);
  const { trackPageView } = useStreetfairAnalytics();
  const { columns, columnTotalWidth } = getColumnData(smDown);

  useEffect(() => {
    void init();
    void trackPageView('Customers');
  }, []);

  useEffect(() => {
    setRowsWithStoredFilter(filteredCustomers);
  }, [filteredCustomers]);

  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={10} className={styles.pageContainer}>
          <Grid className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} container item xs='auto' alignItems='center' wrap="nowrap">
            <HomeIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}/`}>Home</Link>
              <Link to={`/v1/${serviceProviderId}/customers`} className={styles.lastLink}>Customers</Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} >
            <Typography variant='h5' component='div' className={smDown ? styles.mobileHeader : styles.header} textAlign='left'>Customers</Typography>
          </Grid>
          <Grid container item xs={12} >
            <ActionItems/>
            <StyledDataGrid
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              rows={rows}
              components={{ Toolbar: MultiToolbar }}
              componentsProps={{
                filterPanel: defaultFilterPanelConfig,
                toolbar: {
                  quickSearchStorageKey,
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(filteredCustomers, event.target.value),
                  clearSearch: () => requestSearch(filteredCustomers, ''),
                },
              }}
              columns={columns}
              autoHeight={true}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              onRowClick={navigateToCustomerDetail}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  background: '#DBDBDB',
                  color: '#666',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-menuIcon': {
                  visibility: 'visible',
                  width: 'auto',
                },
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => updateGridFilterModel(newFilterModel)}

            />
          </Grid>
          <Footer className={styles.footer}/>
        </Grid>
      </Grid>
    </Loading>
  );
}