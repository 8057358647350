
import { Typography } from '@mui/material';
import styles from './groupDealShare.module.css';

export default function BulletPoints({ neighborhoodName }:any) {

  return (
    <ul className={styles.bulletPoints}>
      <li>
        <Typography variant='body1'>Ask your customers to share the message below to the {neighborhoodName} Facebook page.</Typography>
      </li>
    </ul>
  );
}