import { Button, ButtonProps, useTheme } from '@mui/material';

export interface ISecondaryColorButtonProps extends ButtonProps {

}
export default function SecondaryColorButton({ children, ...props }:ISecondaryColorButtonProps) {
  const theme = useTheme();
  return (
    <Button
      variant='contained'
      type='button'
      sx={{
        'background': theme.palette.secondary.main,
        'color': '#fff',
        '&:hover': {
          background: theme.palette.secondary.light,
        },
      }}
      {...props}
    >{children}
    </Button>
  );
}