import { FormHelperText, Grid, TextField } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import useToast from 'components/toast/useToast';
import { PRICE } from 'model/removeCustomerForm';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import CustomerRemoveReasonSelect from './CustomerRemoveReasonSelect';
import styles from './removeCustomerModal.module.css';

export function RemoveCustomerModal() {
  const { serviceProviderId } = useParams();
  const navigate = useNavigate();
  const { createSuccessToast } = useToast();
  const { removeCustomer } = useCustomerDetail();
  const { open } = useConfirmationModal('removeCustomerModal');
  const [reasonError, setReasonError] = useState({ hasError: false, message: '' });
  const [participantRemoveReason, setParticipantRemoveReason] = useState<string>(PRICE);
  const [participantRemoveComment, setParticipantRemoveComment] = useState<string>('');


  useEffect(() => {
    if (open) {
      setParticipantRemoveReason(PRICE);
      setParticipantRemoveComment('');
    }
  }, [open]);
  function onChange(e) {
    setParticipantRemoveReason(e.target.value);
  }

  return (
    <HookConfirmationModal
      modalKey={'removeCustomerModal'}
      title={() => (<div className={styles.confirmDeleteModalTitle}>Remove this customer?</div>)}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent={{ xs: 'center', md: 'left' }} className={styles.requestSupportModalBody}>
          <Grid item xs={12} sx={{ paddingBottom: 1 }}>
              Please select the reason why the customer will be removed. Optionally, leave a comment.
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomerRemoveReasonSelect
              onChange={onChange}
              value={participantRemoveReason}
              error={reasonError}
            />
          </Grid>
          <Grid item md={12}></Grid>
          <Grid item xs={12} md={6} >
            <TextField
              onChange={(e) => setParticipantRemoveComment(e.target.value)}
              value={participantRemoveComment}
              variant='standard'
              name='comment'
              label='Comment'
              multiline
              fullWidth
            />
            <FormHelperText>{' '}</FormHelperText>
          </Grid>
        </Grid>
      )}
      onConfirm={async () => {
        try {
          await removeCustomer({ participantRemoveReason, participantRemoveComment });
          createSuccessToast('Customer marked as removed.');
          setTimeout(() => {
            navigate(`/v1/${serviceProviderId}/customers`);
          }, 300);
        } catch (e:any) {

        }
      }}
      sx={{
        '& .MuiCardContent-root': {
          padding: 3,
        },
      }}
    />
  );
}