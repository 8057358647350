import { useEffect, useState } from 'react';
import createStore from 'hooks/hookStore';

export interface IModal {
  open:boolean;
}

function defaultEmptyModal(): IModal {

  return {
    open: false,
  };
}
type SfModalStore = {
  modalMap:Map<string, IModal>;
}

const { get, update, registerListener, unregisterListener } = createStore<SfModalStore>('sfModalStore', {
  modalMap: new Map<string, IModal>(),
});


export default function useModal(modalStoreKey:string) {
  const setState = useState(get())[1];


  if (!get().modalMap.has(modalStoreKey)) {
    initializeModalState();
  }

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function initializeModalState() {
    const { modalMap } = get();
    modalMap.set(modalStoreKey, defaultEmptyModal());
    update({ ...get(), modalMap });
  }

  function getModalState() : IModal {
    const { modalMap } = get();
    if (modalMap.has(modalStoreKey)) {
      let result = modalMap.get(modalStoreKey);
      if (!result) {
        return defaultEmptyModal();
      }
      return result;
    }
    return defaultEmptyModal();
  }

  function updateModalState(updatedState:any) {
    const { modalMap } = get();
    if (modalMap.has(modalStoreKey)) {
      const currentState = modalMap.get(modalStoreKey);
      const nextState = { ...currentState, ...updatedState };
      modalMap.set(modalStoreKey, nextState);
      update({
        ...get(),
        modalMap,
      });
    }
  }

  function openModal(state:any) {
    let currentState = getModalState();
    currentState.open = true;
    currentState = { ...currentState, ...state };
    updateModalState(currentState);
  }

  function closeModal(state:any) {
    let currentState = getModalState();
    currentState.open = false;
    currentState = { ...currentState, ...state };
    updateModalState(currentState);
  }

  return {
    loadingKey: modalStoreKey,
    ...getModalState(),
    getModalState,
    updateModalState,
    openModal,
    closeModal,
  };
}