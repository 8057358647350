import { Button, LinearProgress } from '@mui/material';
import { IGroupDealCustomer } from 'model/customer';
import useGroupDeal from 'pages/groupDeal/hookStore/useGroupDeal';

export interface IMoveToPlaceholderGroupDealProps {
  customer: IGroupDealCustomer;
}

export default function MoveToPlaceholderGroupDealButton({ customer }:IMoveToPlaceholderGroupDealProps) {
  const { isTerritoryServiceOfferingInstance, customerAccordionStateMap, updateCustomerAccordion } = useGroupDeal();
  return (
    <Button
      type='button'
      variant='contained'
      sx={{
        padding: '16px',
        width: {
          xs: '100%', sm: '300px',
        },
      }}
      onClick={async () => {
        const currentState = customerAccordionStateMap.get(customer.id);
        if (currentState) {
          updateCustomerAccordion(customer.id, { ...currentState, shouldShowMoveToGroupDeal: false, shouldShowMoveToPlaceholderGroupDeal: true });
        }
      }}
    >
      Move to Placeholder Group Deal
    </Button>
  );
}