import { FormControl, Grid } from '@mui/material';
import { HookDateRangePicker } from 'components/reactHookForm';
import { differenceInCalendarDays } from 'date-fns';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { ParticipantStatus } from 'model/status';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import styles from './confirmCustomerDrawer.module.css';


export default function ServiceDateRange() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { editingServiceDateAndMaxCustomerCount } = useCustomerDetail();
  const watchedStatus = useWatch({ control: formCtx.control, name: 'customerStatus' });
  return (
    <FormControl variant='standard' fullWidth error={isError('serviceDateRange')} className={styles.serviceDatePicker}>
      <Grid container alignItems='center'>
        <Grid item xs>
          <HookDateRangePicker
            labelStart='Service Date'
            labelEnd={''}
            name='serviceDateRange'
            textFieldProps={{
              fullWidth: true,
              variant: 'standard',
            }}
            validationRules={{
              validate: function(value) {
                let requiredStatus = watchedStatus === ParticipantStatus.PENDING || watchedStatus === ParticipantStatus.ACTIVE || watchedStatus === ParticipantStatus.COMPLETE;
                if (requiredStatus && (!value || value.length === 0 || !value[0] || (!value[0] && !value[1]))) {
                  return 'Required';
                }
                if (value[0] && value[1] && Math.abs(differenceInCalendarDays(value[0], value[1])) >= 5) {
                  return 'Service date range cannot be greater than 5 days';
                }

                return true;
              },
            }}
            dateRangePickerProps={{
              onAccept: () => { },
              InputAdornmentProps: {
                sx: {
                  marginBottom: '12px',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}