import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/SectionHeaders/PageHeader';
import SubscriberPrice from './subscriberPrice';
import { approveSubscriberForServiceOffering, deleteSubscriber, findSubscriberDetails, sendCustomerEmailCommunication, updateSubscriberBilling } from 'api/participantApi';
import PaymentDetails from 'components/paymentDetails/paymentDetails';

function SectionHeader({ headerText }:any) {
  return (
    <Col xs={12} style={{ borderBottom: '1px solid green', textAlign: 'left', paddingBottom: '4px', marginBottom: '8px' }}>
      <Row>
        <PageHeader headerText={headerText} style={{ borderBottom: '1px solid green' }}/>
      </Row>
    </Col>
  );
}

export default function Subscriber () {
  const [subscriber, setSubscriber] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [emailTemplateSelected, setEmailTemplateSelected] = useState<string>('');
  const [snackPack, setSnackPack] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<any>({ message: '', key: '' });
  const [emailOverride, setEmailOverride] = useState<string>('');
  const [emailOverrideHasError, setEmailOverrideHasError] = useState(false);

  useEffect(() => {
    if (params.subscriberId) {
      findSubscriberDetails(params.subscriberId).then((res: any) => {
        setSubscriber(res.data);
      }).catch((reason) => console.error(reason));
    }
  }, []);

  useEffect(() => {
    if (snackPack.length && !messageInfo.message) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  function notifyOfError(message:string) {
    const nextMessage = { message: `${message}`, toastType: 'error', key: new Date().getTime() };
    setSnackPack((prev) => [...prev, nextMessage]);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo({ message: '', key: '' });
  };

  const sendEmailCommunication = () => {
    sendCustomerEmailCommunication(subscriber.id, emailTemplateSelected, '', emailOverride, true)
      .then(function(response) {
        navigate(`/v0/${params.serviceProviderId}/subscribers`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function _updateSubscriberBilling(customerPrice) {
    const data = {
      price: customerPrice,
    };
    if (params.serviceProviderId && params.subscriberId) {
      const res = await updateSubscriberBilling(params.serviceProviderId, params.subscriberId, data);
      const nextMessage = { message: 'Updated customer price.', toastType: 'info', key: new Date().getTime() };
      setSnackPack((prev) => [...prev, nextMessage]);
    } else {
      console.error('Must define service provider id and customer id');
    }
  }

  const approveSubscriber = () => {
    approveSubscriberForServiceOffering(subscriber.neighborhoodServiceOfferingId, subscriber.id).then(function (response) {
      navigate(`/v0/${params.serviceProviderId}/subscribers`);
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeSubscriber = () => {
    deleteSubscriber(subscriber.id).then(function (response) {
      navigate(`/v0/${params.serviceProviderId}/subscribers`);
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (

    <>
      <Container style={{
        marginLeft: '10px',
      }}>
        <Row className="justify-content-start" style={{
          textAlign: 'left',
        }}>
          <Col sm={12} md={6}>
            <Button variant="contained" onClick={approveSubscriber} style={{
              marginTop: '15px',
              marginRight: '15px',
            }}>Approve</Button>
            <Button variant="contained" onClick={removeSubscriber} style={{
              marginTop: '15px',
              marginRight: '15px',
            }}>Remove</Button>
          </Col>
        </Row>
        <Row style={{
          textAlign: 'left',
        }}>
          <Col sm={12} md={6}>
            <Row style={{
              borderBottom: '1px solid #26995F',
              padding: '10px',
            }}>
              <PageHeader headerText={'SUBSCRIBER DETAILS'} />
            </Row>
            <Row>
              {editMode && subscriber &&
                <>
                </>
              }
              {!editMode && subscriber &&
                <>
                  <p>Name: {subscriber.fullName}</p>
                  <p>Address: {subscriber.streetAddress}</p>
                  <p>ZipCode: {subscriber.zipCode}</p>
                  <p>Phone: {subscriber.phone}</p>
                  <p>Email: {subscriber.email}</p>
                  <p>Neighborhood: {subscriber.neighborhood}</p>
                  <p>Status: {subscriber.status}</p>
                </>
              }
            </Row>
          </Col>
          <Col sm={12} md={6}>
            <Row style={{
              borderBottom: '1px solid #26995F',
              padding: '10px',
            }}>
              <PageHeader headerText={'SERVICE DETAILS'} />
            </Row>
            <Row>
              {editMode && subscriber &&
                <>
                </>
              }
              {!editMode && subscriber &&
                <>
                  <p>Service Type: {subscriber.serviceType}</p>
                  <p>Service Date: {subscriber.formattedServiceDate}</p>

                  {subscriber.subscriptionOption &&
                    <p>Subscription Option: {subscriber.subscriptionOption}</p>
                  }

                  {subscriber.customFieldSelections && subscriber.customFieldSelections.map((customOption: any) => {
                    return (
                      <>
                        <p>{customOption.label}: {customOption.value}</p>
                      </>);
                  })}
                </>
              }
            </Row>
            <Row>
              {subscriber && (
                <SubscriberPrice
                  customer={subscriber}
                  updateSubscriberPrice={_updateSubscriberBilling}
                  notifyOfError={notifyOfError}
                />
              )}
            </Row>
            {subscriber && subscriber.stripeCustomerId && <PaymentDetails customer={subscriber} serviceProviderId={params.serviceProviderId} />}
          </Col>

          <Col sm={12} md={6}>
            <SectionHeader
              headerText='CUSTOMER COMMUNICATIONS'
            />
            <Row xs={12}>
              <FormControl variant="standard" sx={{ minWidth: 200 }}>
                <InputLabel>Email Template</InputLabel>
                <Select
                  id="service-instance-select"
                  name="serviceInstanceId"
                  autoWidth
                  onChange={(e) => setEmailTemplateSelected(e.target.value)}
                  value={emailTemplateSelected}
                >
                  <MenuItem key='REVIEW_PROVIDER' value='REVIEW_PROVIDER'>Review Provider Email</MenuItem>
                </Select>
              </FormControl>
            </Row>
            <Row xs={12}>
              <TextField
                error={emailOverrideHasError}
                variant='standard'
                size='small'
                label="Email Override (optional)"
                name="emailOverride"
                defaultValue={emailOverride}
                onChange={(e) => setEmailOverride(e.target.value)}
              />
            </Row>
            <Row xs={12}>
              <Button variant="contained" onClick={sendEmailCommunication} style={{ marginTop: '15px' }}>Send Email</Button>
            </Row>
          </Col>


        </Row>
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          open={open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
        >
          <Alert
            severity={messageInfo.toastType}
            variant='filled'
            onClose={(e) => handleClose(e, null)}
          >
            {messageInfo ? messageInfo.message : undefined}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}