import { Checkbox, FormControlLabel, Switch, SwitchProps, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export interface IHookSwitchProps {
  name:string;
  label:string;
  defaultValue?:boolean;
  shouldRender?:boolean;
  onChecked?:Function;
  className?:string;
  switchProps?:SwitchProps;
}

export default function HookSwitch({ name, label, defaultValue, shouldRender, onChecked, className, switchProps }:IHookSwitchProps) {
  const methods = useFormContext();
  const { control } = methods;
  const { isError, getErrorMessage } = useErrorState(methods);
  return (
    <FormControlLabel
      className={className}
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            if (shouldRender === false) {
              return null as any;
            }
            return (
              <Switch
                {...field}
                checked={!!field.value}
                inputRef={field.ref}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  if (onChecked) {
                    onChecked(e.target.checked);
                  }
                }}
                {...switchProps}
              />
            );
          }
          }/>
      }
      label={label}
    />
  );
}