
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography } from '@mui/material';
import SecondaryColorButton from 'components/buttons/SecondaryColorButton';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import styles from './groupDealShare.module.css';

export interface IMessageCopyProps {
  serviceProviderName:string;
  serviceDate:string;
  serviceTypeName:string;
  neighborhoodName:string;
  nsoId:string;
  nsoiId:string;
  isActiveNeighborhood:boolean;
}

function getMessageText({ serviceDate, serviceProviderName, serviceTypeName, neighborhoodName, nsoId, nsoiId, isActiveNeighborhood }:IMessageCopyProps) {

  return (
    `Hi neighbors - ${serviceProviderName} will be in the neighborhood on ${serviceDate} doing ${serviceTypeName}.

If anyone else needs this service you can sign up using the link below. ${serviceProviderName} is offering a group discount for anyone in ${neighborhoodName} who signs up.

${window.REACT_APP_STREETFAIR_URL}/neighborhood-service-offering/${nsoId}/service-instance/${nsoiId}${isActiveNeighborhood ? '' : '?preview=true'}`);
}

export default function MessageCopy({ serviceDate, serviceProviderName, serviceTypeName, neighborhoodName, nsoId, nsoiId, isActiveNeighborhood }:IMessageCopyProps) {
  const { trackButtonClick } = useStreetfairAnalytics();
  return (
    <div className={styles.messageCopy}>
      <div className={styles.message}>
        <div className={styles.intro}>
          <Typography variant='body1'>Hi neighbors - {serviceProviderName} will be in the neighborhood on {serviceDate} doing {serviceTypeName}.</Typography>
        </div>
        <div className={styles.body}>
          <Typography variant='body1'>If anyone else needs this service you can sign up using the link below. {serviceProviderName} is offering a group discount for anyone in {neighborhoodName} who signs up.</Typography>
        </div>
        <div className={styles.link}>
          <Typography variant='body1'>{window.REACT_APP_STREETFAIR_URL}/neighborhood-service-offering/{nsoId}/service-instance/{nsoiId}</Typography>
        </div>
      </div>
      <div className={styles.bottomRow}>
        <SecondaryColorButton
          className={styles.messageCopyButton}
          variant='contained'
          onClick={() => {
            void trackButtonClick(ButtonClickTypes.GROUP_DEAL_CONFIRMATION_COPY_MESSAGE);
            void navigator.clipboard.writeText(getMessageText({ serviceDate, serviceProviderName, serviceTypeName, neighborhoodName, nsoId, nsoiId, isActiveNeighborhood }));
          }}
        >
          <ContentCopyIcon className={styles.copyIcon}/> Copy Message
        </SecondaryColorButton>
      </div>
    </div>
  );
}