import { InputLabel, FormHelperText, FormControl } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './formComponents.module.css';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export default function DiscountScheduleSelect() {

  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { discountScheduleOptions, isEditing } = useGroupDeal();

  let watchedSuperDeal = useWatch({ control: formCtx.control, name: 'superDeal' });

  if ((!discountScheduleOptions || discountScheduleOptions.length === 0) && formCtx.getValues().discountScheduleKey) {
    return null;
  }
  return (
    <FormControl variant='standard' fullWidth error={isError('discountScheduleKey')} className={styles.discountScheduleSelect}>
      <InputLabel htmlFor="discount-schedule-select">Select Discount</InputLabel>
      <HookSelect
        control={formCtx.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='discountScheduleKey'
        id='discount-schedule-select'
        menuItems={discountScheduleOptions}
        menuItemKeys={{
          displayValue: 'optionText',
          key: 'key',
          value: 'optionValue',
        }}
        extraProps={{
          disabled: discountScheduleOptions.length === 0 || isEditing,
        }}
      />
      <FormHelperText>{getErrorMessage('discountScheduleKey')}</FormHelperText>
    </FormControl>
  );
}