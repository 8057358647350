import { Typography, FormControlLabel, Radio, FormHelperText, FormControl, InputLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useCustomerDetail from '../../hookStore/useCustomerDetail';
import usePriceCalculator from '../../hookStore/usePriceCalculator';
import { HookSelect } from 'components/reactHookForm';
import HookRadioField from 'components/reactHookForm/HookRadioField';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { ICustomerServiceDetails, IProviderCustomerDetail } from 'model/customer';
import { ParticipantStatus } from 'model/status';


export interface IStatusChangeFieldProps {
  isRecurring: boolean;
}


export default function SubscriptionOption() {
  const { customer, getSubOptions, isSelectedOptionOneTime } = useCustomerDetail();
  const { calculatePriceClientside } = usePriceCalculator();
  const formContext = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formContext);
  let _options = getSubOptions(customer);
  return (
    <FormControl variant='standard' fullWidth error={isError('subscriptionSelection')}>
      <InputLabel htmlFor="customer-status">Subscription package</InputLabel>
      <HookSelect
        control={formContext.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='subscriptionSelection'
        id='customer-status'
        menuItems={_options}
        postOnChange={(e) => {
          if (isSelectedOptionOneTime(e.target.value, customer)) {
            formContext.setValue('convertingToCustomer', true);
            void calculatePriceClientside(formContext.getValues() as ICustomerServiceDetails);
          } else {
            formContext.setValue('convertingToCustomer', false);
            void calculatePriceClientside(formContext.getValues() as ICustomerServiceDetails);
          }
        }}
      />
      <FormHelperText>{getErrorMessage('subscriptionSelection')}</FormHelperText>
    </FormControl>
  );
}