
export interface ISignUpForm {
  email:string;
  password:string;
  passwordVerify:string;
}

export function createEmptySignUpForm():ISignUpForm {
  return {
    email: '',
    password: '',
    passwordVerify: '',
  };
}