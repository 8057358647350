import { IDropdownOption } from './dropdown';

export class ParticipantStatus {
  static INVITED = 'INVITED';
  static ACTIVE ='ACTIVE';
  static COMPLETE = 'COMPLETE';
  static PENDING='PENDING';
  static REMOVED='REMOVED';
  static LEAD = 'LEAD';
  static FORMER ='FORMER';
  static HOLD = 'HOLD';

  public static isPending(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.PENDING;
  }
  public static isActive(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.ACTIVE;
  }
  public static isCompleted(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.COMPLETE;
  }
  public static isInvited(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.INVITED;
  }
  public static isRemoved(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.REMOVED;
  }
  public static isOnHold(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.HOLD;
  }
  public static isLead(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.LEAD;
  }
  public static isFormer(status:string):boolean {
    return status.toLocaleUpperCase() === ParticipantStatus.FORMER;
  }

  public static statuses():IDropdownOption[] {
    return [
      // {
      //     key:ParticipantStatus.INVITED,
      //     optionValue:ParticipantStatus.INVITED,
      //     optionText:'Invited'
      // },
      {
        key: ParticipantStatus.PENDING,
        optionValue: ParticipantStatus.PENDING,
        optionText: 'Pending',
      },
      {
        key: ParticipantStatus.ACTIVE,
        optionValue: ParticipantStatus.ACTIVE,
        optionText: 'Confirmed',
      },
      {
        key: ParticipantStatus.COMPLETE,
        optionValue: ParticipantStatus.COMPLETE,
        optionText: 'Complete',
      },
      {
        key: ParticipantStatus.REMOVED,
        optionValue: ParticipantStatus.REMOVED,
        optionText: 'Removed',
      },
      // {
      //     key:ParticipantStatus.LEAD,
      //     optionValue:ParticipantStatus.LEAD,
      //     optionText:''
      // },
      {
        key: ParticipantStatus.FORMER,
        optionValue: ParticipantStatus.FORMER,
        optionText: 'Former Subscription',
      },
    ];
  }
}
