import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Footer from 'components/footer';
import useTableSearch, { escapeRegExp } from 'components/grid/useTableSearch';
import Loading from 'components/layout/Loading';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash.debounce';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { createDateIgnoreTimezone, formatLongMonthDayYear, formatMonthDayYear } from 'util/dateUtil';
import { defaultFilterPanelConfig } from 'util/tableUtil';
import MultiToolbar from './MultiToolbar';
import ReviewDrawer from './drawer/ReviewDrawer';
import useReviewDrawer from './hookStore/useReviewDrawer';
import useReviews from './hookStore/useReviews';
import styles from './reviews.module.css';
import { getColumnData } from './tableConfig';


const debounceSearchAnalytics = debounce(async (eventTracking):Promise<any> => {
  try {
    eventTracking();
  } catch (err) {
    console.log(err);
  }
}, 1500);

const customSearchMap = new Map<string, (searchString:string, fieldValue:string, row?:any) => boolean>();
customSearchMap.set('reviewDate', (searchString:string, fieldValue:string) => {
  const searchRegex = new RegExp(escapeRegExp(searchString), 'i');
  const formatted = formatLongMonthDayYear(createDateIgnoreTimezone(fieldValue));
  const slashFormat = formatMonthDayYear(createDateIgnoreTimezone(fieldValue));
  const dashFormat = formatMonthDayYear(createDateIgnoreTimezone(fieldValue), '-');
  return searchRegex.test(formatted) || searchRegex.test(slashFormat) || searchRegex.test(dashFormat);
});
customSearchMap.set('fullName', (searchString:string, fieldValue:string, row:any) => {
  const searchRegex = new RegExp(escapeRegExp(searchString), 'i');
  return searchRegex.test(`${row.firstName} ${row.lastName}`);
});
const customSearch = {
  map: customSearchMap,
};

export default function Reviews() {
  const { serviceProviderId } = useParams();
  const { reviewsManagement } = useFlags();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { openCustomerReviewDrawer } = useReviewDrawer();
  const {
    loadingKey,
    quickSearchStorageKey,
    init,
    sortModel,
    onSortModelChange,
    filteredReviews,
  } = useReviews();
  const {
    rows,
    searchText,
    setRowsWithStoredFilter,
    requestSearch,
    clearStoredSearch,
  } = useTableSearch(quickSearchStorageKey, customSearch, [
    'firstName',
    'lastName',
    'fullName',
    'individualId',
    'neighborhoodName',
    'reviewDate',
    'rating',
  ]);
  const { columns, columnTotalWidth } = getColumnData(smDown);
  const { trackPageView, trackButtonClick } = useStreetfairAnalytics();

  useEffect(() => {
    void init();
    void trackPageView('Reviews_Table');
  }, []);

  useEffect(() => {
    setRowsWithStoredFilter(filteredReviews);
  }, [filteredReviews]);
  if (!reviewsManagement) {
    return null;
  }
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={10} className={styles.pageContainer}>
          <Grid className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} container item xs='auto' alignItems='center' wrap="nowrap">
            <HomeIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}/`}>Home</Link>
              <Link to={`/v1/${serviceProviderId}/reviews`} className={styles.lastLink}>Reviews</Link>
            </Breadcrumbs>
          </Grid>
          <Grid container item xs={12} justifyContent='space-between'>
            <Grid item xs={12} sm>
              <Typography variant='h5' component='div' className={smDown ? styles.mobileHeader : styles.header} textAlign='left'>Reviews</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} >
            <DataGrid
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              rows={rows}
              components={{ Toolbar: MultiToolbar }}
              componentsProps={{
                filterPanel: defaultFilterPanelConfig,
                toolbar: {
                  quickSearchStorageKey,
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {

                    void debounceSearchAnalytics(async () => {
                      void trackButtonClick(ButtonClickTypes.REVIEWS_TABLE_SEARCH_USED);
                    });

                    requestSearch(filteredReviews, event.target.value);
                  },
                  clearSearch: () => requestSearch(filteredReviews, ''),
                },
              }}
              columns={columns}
              autoHeight={true}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              onRowClick={openCustomerReviewDrawer}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  background: '#DBDBDB',
                  color: '#666',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-menuIcon': {
                  visibility: 'visible',
                  width: 'auto',
                },
              }}
            />
          </Grid>
          <Footer className={styles.footer}/>
          <ReviewDrawer/>
        </Grid>
      </Grid>
    </Loading>
  );
}