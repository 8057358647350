import createStore from 'hooks/hookStore';
import { ICustomer } from 'model/customer';
import { IDiscountSchedule } from 'model/discount';
import { IDropdownOption } from 'model/dropdown';
import { ICustomerAccordionState, IDiscountTableData, IGroupDealForm, createEmptyGroupDealForm } from 'model/groupDeal';
import { INeighborhood } from 'model/neighborhood';
import { INeighborhoodServiceOfferingInstance, createEmptyNeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import { IServiceOffering } from 'model/serviceOffering';

export interface IMatchingNsoData {
  discountSchedule:IDiscountSchedule | null;
  neighborhoodPrice:string | null;
}

export class GroupDealCreationStep {
  text:String;
  key:String;

  constructor(text:string, key:string) {
    this.text = text;
    this.key = key;
  }
}

export class GroupDealCreationState {
  static createEmpty():GroupDealCreationState {
    return new GroupDealCreationState(0, this.createSteps());
  }

  static createSteps() {
    return [
      new GroupDealCreationStep('Enter Service Information', 'enter_service_information'),
      new GroupDealCreationStep('Add a Customer', 'enter_customer_information'),
      // GroupDealCreationStep('Set Deal Details', 'set_deal_details'),
    ];
  }

  currentActiveStepIndex: number;
  steps: GroupDealCreationStep[];

  constructor(currentActiveStepIndex: number, steps: GroupDealCreationStep[]) {
    this.currentActiveStepIndex = currentActiveStepIndex;
    this.steps = steps;
  }

  nextStep() {
    if (this.currentActiveStepIndex >= this.steps.length -1) {
      return;
    }
    this.currentActiveStepIndex += 1;
  }

  prevStep() {
    if (this.currentActiveStepIndex === 0) {
      return;
    }
    this.currentActiveStepIndex -= 1;
  }

}

export type GroupDeal = {
  isProviderAdmin: boolean;
  isTerritoryServiceOfferingInstance: boolean;
  editingClicked: boolean;
  pendingCustomers: ICustomer[];
  placeholderCustomers: ICustomer[];
  activeOrCompleteCustomers: ICustomer[];
  nsoisWithSameNso: INeighborhoodServiceOfferingInstance[];
  nsoDetails: any;
  nsoi: INeighborhoodServiceOfferingInstance;
  formData: IGroupDealForm;
  discountSchedules:any[];
  serviceOfferingToServiceTypeOptions: IDropdownOption[];
  neighborhoodOptions: IDropdownOption[];
  discountScheduleOptions: IDropdownOption[];
  isEditing: boolean;
  customersToggled: boolean;
  serviceOfferings: IServiceOffering[];
  prospectiveDiscountTable: IDiscountTableData | null;
  matchingNsoData: IMatchingNsoData;
  customerAccordionStateMap: Map<string, ICustomerAccordionState>;
  //v2
  groupDealCreationState: GroupDealCreationState;
  maxCustomerCountOptions: IDropdownOption[];
  customerIndexToNeighborhoodMap: Map<number, INeighborhood>;
  customerIndexToPossibleNeighborhoodsMap: Map<number, INeighborhood[]>;
  warningMessage:string | null;
  showCreateNeighborhood:boolean;
}

export const store = createStore<GroupDeal>('groupDeal', {
  isProviderAdmin: false,
  isTerritoryServiceOfferingInstance: false,
  editingClicked: false,
  pendingCustomers: [],
  activeOrCompleteCustomers: [],
  placeholderCustomers: [],
  nsoisWithSameNso: [],
  nsoDetails: null,
  nsoi: createEmptyNeighborhoodServiceOfferingInstance(),
  formData: createEmptyGroupDealForm(1),
  discountSchedules: [],
  serviceOfferingToServiceTypeOptions: [],
  neighborhoodOptions: [],
  discountScheduleOptions: [],
  isEditing: false,
  customersToggled: true,
  serviceOfferings: [],
  prospectiveDiscountTable: null,
  matchingNsoData: {
    discountSchedule: null,
    neighborhoodPrice: null,
  },
  customerAccordionStateMap: new Map<string, ICustomerAccordionState>(),
  groupDealCreationState: GroupDealCreationState.createEmpty(),
  maxCustomerCountOptions: [],
  customerIndexToNeighborhoodMap: new Map<number, INeighborhood>(),
  customerIndexToPossibleNeighborhoodsMap: new Map<number, INeighborhood[]>(),
  warningMessage: null,
  showCreateNeighborhood: false,
});