import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Shake, { useShake } from 'components/layout/Shake';
import { ServiceInstanceType } from 'model/ancillary';
import { IGroupDealForm, createEmptyGroupDealForm } from 'model/groupDeal';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import styles from './groupDeal.module.css';
import GroupDealCreationFields from './groupDealCreation/GroupDealCreationFields';
import GroupDealCreationStatusWrapper from './groupDealCreation/wizardStatus/GroupDealCreationStatusWrapper';
import useAddressAutocomplete from './hookStore/useAddressAutocomplete';
import useGroupDeal from './hookStore/useGroupDeal';
import useGroupDealCreationStore from './hookStore/useGroupDealCreationStore';


export interface IGroupDealFormInternalProps {
  initialFormData: IGroupDealForm;
}
const shakeStoreKey = 'saveGroupDeal';

function GroupDealFormInternal({ initialFormData }: IGroupDealFormInternalProps) {
  const theme = useTheme();
  const { setAnimate } = useShake(shakeStoreKey);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const formContext = useForm<IGroupDealForm>({ mode: 'onTouched', defaultValues: createEmptyGroupDealForm(1) });
  const { save, cancel, nsoi } = useGroupDeal();
  const { groupDealCreationState, onNext } = useGroupDealCreationStore();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<IGroupDealForm> = data => {
    void save(data);
  };
  const onInvalid : any = data => {
    setAnimate(true);
  };
  const watchedCustomers = useWatch({ control: formContext.control, name: 'customers' });

  const { isSubmitting, isValid } = formContext.formState;
  const isLastIndex = groupDealCreationState.currentActiveStepIndex === groupDealCreationState.steps.length -1;
  const neighborhoodId = formContext.getValues('neighborhoodId');
  const createdNeighborhoodName = formContext.getValues('createNeighborhoodRequestDTO.neighborhoodName');
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit, onInvalid)} className={styles.formElement}>
        <Grid container>
          <Grid container item xs={12} sm={12} md={4} lg={3}
            flexDirection='column'
            className={!mdDown ? styles.desktopSection : styles.section}
          >
            <GroupDealCreationStatusWrapper/>
          </Grid>
          <Grid container item xs={12} sm={12} md={8} lg={9} className={`${!mdDown ? styles.desktopCustomerWrapper: styles.customerWrapper}`}>
            <GroupDealCreationFields/>
            <Grid container item xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }} alignItems='flex-start'>
              {
                <Grid item xs={12} sm={'auto'} order={{ xs: 0, sm: 1 }}>
                  <Shake
                    storeKey={shakeStoreKey}
                    timeout={900}
                    renderProps={(animatedClassName) => (
                      <Button
                        className={`${styles.saveButton} ${animatedClassName}`}
                        disabled={isLastIndex && (
                          isSubmitting ||
                          watchedCustomers.length === 0 ||
                          nsoi.serviceInstanceType === ServiceInstanceType.PLACEHOLDER ||
                          (!neighborhoodId && !createdNeighborhoodName))}
                        variant='contained'
                        type='button'
                        onClick={async (e) => {
                          if (groupDealCreationState.currentActiveStepIndex === 0) {
                            const result = await formContext.trigger([
                              'serviceOfferingId',
                              'serviceDates',
                              'maxCustomerCount',
                            ]);
                            if (result) {
                              onNext();
                            }
                          } else {
                            await formContext.handleSubmit(onSubmit, onInvalid)(e);
                          }

                        }}
                      >
                        <>{groupDealCreationState.currentActiveStepIndex === 0 ? 'Next' : 'Create Group Deal'}</>
                      </Button>
                    )}/>
                </Grid>
              }
              <Grid item xs={12} sm='auto' sx={{ marginRight: { xs: 0, sm: '20px' } }}>
                <Button
                  className={smDown ? styles.mobileCancelButton : styles.cancelButton}
                  type='button'
                  variant='outlined'
                  onClick={() => cancel()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function GroupDealForm() {
  const { formData } = useGroupDeal();
  const { init } = useGroupDealCreationStore();
  const { init: initAutocomplete } = useAddressAutocomplete();
  useEffect(() => {
    void init();
    void initAutocomplete();
  }, []);
  return (
    <GroupDealFormInternal initialFormData={formData}/>
  );
}