import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography, useTheme } from '@mui/material';
import styles from './customerAccordion.module.css';

export default function CreateNewButton({ onClick, isSelected, text }) {
  const theme = useTheme();
  const secondaryColor = theme.palette.secondary.main;
  const secondaryColorLight = theme.palette.secondary.light;
  const extraLight = theme.palette.tertiary.altExtraLight;
  return (
    <Grid container
      className={styles.createNewButton}
      onClick={onClick}
      sx={{
        'background': isSelected && secondaryColor,
        '&:hover': {
          background: isSelected ? secondaryColorLight : secondaryColor,
        },
        '&:hover span': {
          color: isSelected ? extraLight : extraLight,
        },
        '&:hover svg': {
          color: isSelected ? extraLight : extraLight,
        },
      }}
      justifyContent='center'
    >
      <Grid item xs='auto'>
        <AddIcon sx={{ color: isSelected ? extraLight : secondaryColor }}/>
      </Grid>
      <Grid item xs='auto'>
        <Typography
          variant='subtitle2'
          component='span'
          color={isSelected ? extraLight : secondaryColor}
        >
          <>{text}</>
        </Typography>
      </Grid>
    </Grid>
  );
}