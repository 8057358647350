import { useAnalytics } from '@segment/analytics-react';
import { useLocation } from 'react-router-dom';
import useSelf from './useSelf';

export namespace ButtonClickTypes {
  export const CUSTOMER_DETAIL_HAPPENING_SOON_CARD = 'CUSTOMER_DETAIL_HAPPENING_SOON_CARD';
  export const CUSTOMER_DETAIL_NSO_LINK = 'CUSTOMER_DETAIL_NSO_LINK';
  export const CUSTOMER_DETAIL_SERVICE_HISTORY_DROPDOWN = 'CUSTOMER_DETAIL_SERVICE_HISTORY_DROPDOWN';
  export const CUSTOMER_DETAIL_VIEW_GROUP_DEAL_LINK = 'CUSTOMER_DETAIL_VIEW_GROUP_DEAL_LINK';
  export const CUSTOMER_DETAIL_EDIT_DETAILS = 'CUSTOMER_DETAIL_EDIT_DETAILS';
  export const CUSTOMER_DETAIL_REVIEW_CONFIRM = 'CUSTOMER_DETAIL_REVIEW_CONFIRM';
  export const CUSTOMER_DETAIL_DRAWER_ADD_SERVICE_ITEM = 'CUSTOMER_DETAIL_DRAWER_ADD_SERVICE_ITEM';
  export const CUSTOMER_DETAIL_DRAWER_ADD_ONE_TIME_SERVICE_ITEM = 'CUSTOMER_DETAIL_DRAWER_ADD_ONE_TIME_SERVICE_ITEM';
  export const CREATE_GROUP_DEAL = 'CREATE_GROUP_DEAL';
  export const CUSTOMER_DETAIL_CONFIRMATION_MODAL_HOME = 'CUSTOMER_DETAIL_CONFIRMATION_MODAL_HOME';
  export const CUSTOMER_DETAIL_CONFIRMATION_MODAL_NEXT = 'CUSTOMER_DETAIL_CONFIRMATION_MODAL_NEXT';
  export const GROUP_DEAL_CONFIRMATION_COPY_MESSAGE = 'GROUP_DEAL_CONFIRMATION_COPY_MESSAGE';
  export const GROUP_DEAL_CONFIRMATION_COPY_LINK = 'GROUP_DEAL_CONFIRMATION_COPY_LINK';
  export const CUSTOMERS_TABLE_NEIGHBORHOOD_FILTER = 'CUSTOMERS_TABLE_NEIGHBORHOOD_FILTER';
  export const CUSTOMERS_TABLE_STATUS_FILTER = 'CUSTOMERS_TABLE_STATUS_FILTER';
  export const CUSTOMERS_TABLE_NEEDS_CONTACT = 'CUSTOMERS_TABLE_NEEDS_CONTACT';
  export const CUSTOMERS_TABLE_NEEDS_QUOTE = 'CUSTOMERS_TABLE_NEEDS_QUOTE';
  export const CUSTOMERS_TABLE_NEEDS_CONFIRMATION = 'CUSTOMERS_TABLE_NEEDS_CONFIRMATION';
  export const CUSTOMERS_TABLE_NEEDS_COMPLETE = 'CUSTOMERS_TABLE_NEEDS_COMPLETE';
  export const GROUP_DEALS_TABLE_SHOW_PAST_DEALS = 'GROUP_DEALS_TABLE_SHOW_PAST_DEALS';
  export const GROUP_DEALS_TABLE_SEARCH_USED = 'GROUP_DEALS_TABLE_SEARCH_USED';
  export const GROUP_DEALS_TABLE_NEIGHBORHOOD_DROPDOWN = 'GROUP_DEALS_TABLE_NEIGHBORHOOD_DROPDOWN';

  export const REVIEWS_TABLE_SEARCH_USED = 'REVIEWS_TABLE_SEARCH_USED';
  export const REVIEWS_TABLE_NEIGHBORHOOD_DROPDOWN = 'REVIEWS_TABLE_NEIGHBORHOOD_DROPDOWN';
  export const REVIEWS_TABLE_RATING_FILTER = 'REVIEWS_TABLE_RATING_FILTER';


}

export default function useStreetfairAnalytics() {
  const { track } = useAnalytics();
  const { pathname } = useLocation();
  const { populateUser, populateServiceProvider } = useSelf();

  async function trackPageView(pageName:string) {
    try {
      const _selfUser = await populateUser();
      let serviceProvider = await populateServiceProvider();
      if (window.REACT_APP_NODE_ENV !== 'local') {
        void track('Page Viewed', {
          environment: window.REACT_APP_NODE_ENV,
          user: _selfUser.userName,
          provider: serviceProvider.name,
          providerId: serviceProvider.id,
          app: 'Provider Portal',
          pageName: pageName,
          path: pathname,
          url: pathname,
        });
      } else {
        console.log('AnalyticsPageView', _selfUser.userName, serviceProvider, pageName, pathname);
      }
    } catch (err:any) {
      console.error('Could not call Segment', err);
    }
  }


  async function trackButtonClick(buttonClick:string) {
    try {
      const _selfUser = await populateUser();
      let serviceProvider = await populateServiceProvider();
      if (window.REACT_APP_NODE_ENV !== 'local') {
        void track('Button Click', {
          environment: window.REACT_APP_NODE_ENV,
          user: _selfUser.userName,
          provider: serviceProvider.name,
          providerId: serviceProvider.id,
          app: 'Provider Portal',
          buttonClick: buttonClick,
          path: pathname,
          url: pathname,
        });
      } else {
        console.log('AnalyticsButtonClick', _selfUser.userName, serviceProvider, buttonClick, pathname);
      }
    } catch (err:any) {
      console.error('Could not call Segment', err);
    }
  }

  return {
    trackPageView,
    trackButtonClick,
  };
}