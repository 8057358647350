
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardContent, Modal, Grid, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { ReactNode } from 'react';
import styles from './modalFrame.module.css';
import useModal from './useModal';

export interface IModalFrameProps {
  modalKey:string;
  modalClassName?: string;
  cardClassName?: string;
  cardContentClassName?: string;
  children: ReactNode | JSX.Element;
  overrideStyles?: any;
}

export default function ModalFrame({
  modalKey,
  modalClassName,
  cardClassName,
  cardContentClassName,
  overrideStyles,
  children,
}:IModalFrameProps) {
  const { open, closeModal } = useModal(modalKey);
  const theme = useTheme();

  return (
    <Modal
      open={open}
      aria-labelledby={`${modalKey}-modal-title`}
      aria-describedby={`${modalKey}-modal-description`}
      className={`${styles.modalParent} ${modalClassName ?? ''}`}
      sx={{
        ...overrideStyles,
      }}
    >
      <Card className={`${styles.modalCard} ${cardClassName}`}>
        <CardContent className={`${cardContentClassName}`}>
          <Grid container>
            <Grid container item justifyContent='end' xs={12}>
              <IconButton
                onClick={closeModal}
              >
                <CloseIcon sx={{ color: theme.palette.primary.main }}/>
              </IconButton>
            </Grid>
            {children}
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}