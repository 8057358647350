import { GridCallbackDetails, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { findOwned, findServiceProviders } from 'api/serviceProviderApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import useSelf from 'hooks/useSelf';
import { IServiceProvider } from 'model/serviceProvider';
import logPageView from 'util/analyticsUtil';
import { containsProviderAdmin, containsProviderMulti } from 'util/role';

const loadingKey = 'switchProviderTable';
const quickSearchStorageKey = 'switchProviderTable';
type SwitchProviderTableData = {
  serviceProviders: IServiceProvider[];
  sortModel:any;
}


const { get, update, registerListener, unregisterListener } = createStore<SwitchProviderTableData>('switchProviderTable', {
  serviceProviders: [],
  sortModel: [{
    field: 'name',
    sort: 'asc' as any,
  }],
});

export default function useSwitchProvider() {
  const setState = useState(get())[1];
  const navigate = useNavigate();

  const { roles } = useSelf();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { serviceProviderId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    void init();
  }, [roles]);

  async function init() {
    onLoading();
    logPageView(serviceProviderId);

    const isProviderAdmin = containsProviderAdmin(roles);
    if (!isProviderAdmin) {
      navigate('/');
    }

    await refresh(isProviderAdmin);
    doneLoading();
  }

  async function refresh(isProviderAdmin:boolean) {
    try {
      let serviceProvidersRes:any = null;
      if (!isProviderAdmin) {
        serviceProvidersRes = await findOwned();
      } else {
        serviceProvidersRes = await findServiceProviders();
      }
      update({
        ...get(),
        serviceProviders: serviceProvidersRes.data,
      });
    } catch (e:any) {
      console.error(e);
    }
  }

  function onSortModelChange(sortModel) {
    update({
      ...get(),
      sortModel,
    });
  }

  function switchProvider(params:GridRowParams, event:MuiEvent<React.MouseEvent>, details:GridCallbackDetails) {
    navigate(`/v1/${params.row.id}/`);
  }

  return {
    loadingKey,
    quickSearchStorageKey,
    ...get(),
    init,
    refresh,
    onSortModelChange,
    switchProvider,
  };
}