import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HookTextField } from 'components/reactHookForm';
import { IFormNote } from 'model/operations';
import { formatMonthDayTimeYearSeconds } from 'util/dateUtil';

export interface IOperationNoteProps {
  index:number;
  onRemove: () => void;
}

function createTitle(note:IFormNote):string {
  if (note.timestamp === '') {
    return 'New Note';
  }

  return `${formatMonthDayTimeYearSeconds(note.timestamp)}`;
}

export default function OperationNote({ index, onRemove }:IOperationNoteProps) {
  const { getValues, setValue } = useFormContext();
  const operationNote = getValues(`notes.${index}`) as IFormNote;
  const [expandedNote, setExpandedNote] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <HookTextField
          variant='outlined'
          multiline={expandedNote}
          minRows={expandedNote ? 5 : 1}
          maxRows={expandedNote ? 10 : 1}
          name={`notes.${index}.note`}
          InputProps={{
            endAdornment: (
              <IconButton
                title="expand"
                aria-label="expand"
                size="small"
                onClick={() => {
                  setExpandedNote(!expandedNote);
                }}
              >
                {!expandedNote ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize='small'/>}
              </IconButton>
            ),
          }}
        ></HookTextField>
      </Grid>
      <Grid container item xs={4} alignContent={'flex-start'}>
        <Grid item xs={12}>
          <Typography component='div' textAlign={'left'}>{createTitle(operationNote)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component='div' textAlign={'left'}>{operationNote.userName}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <IconButton
          type='button'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}>
          <DeleteForeverIcon/>
        </IconButton>
      </Grid>
    </Grid>
  );
}