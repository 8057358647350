import { isBefore, isAfter, parseISO } from 'date-fns';
import get from 'lodash/get';

export function sortByServiceInstanceBeginDate(a, b) {
  return a.beginServiceDate > b.beginServiceDate ? 1 : -1;
}

export function sortByString(propertyPath:string, isDescending?:boolean) {
  if (isDescending) {
    return function(a:any, b:any) {
      var nameA = get(a, propertyPath, '').toUpperCase(); // ignore upper and lowercase
      var nameB = get(b, propertyPath, '').toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }

      // names must be equal
      return 0;
    };
  }
  return function(a:any, b:any) {
    var nameA = get(a, propertyPath, '').toUpperCase(); // ignore upper and lowercase
    var nameB = get(b, propertyPath, '').toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
}

export function sortBySortOrder(a, b) {
  return a.sortOrder > b.sortOrder ? 1 : -1;
}

export function sortByPropertyAsc(property) {
  return (a, b) => {
    const aProp = get(a, property, null);
    const bProp = get(b, property, null);
    if (aProp === null && bProp === null) {
      return 0;
    }
    if (aProp === null) {
      return 1;
    }
    if (bProp === null) {
      return -1;
    }
    if (aProp < bProp) {
      return -1;
    }
    if (aProp > bProp) {
      return 1;
    }
    return 0;
  };
}

function _sortByPropertyValueAsc(aProp, bProp) {
  if (aProp === null && bProp === null) {
    return 0;
  }
  if (aProp === null) {
    return 1;
  }
  if (bProp === null) {
    return -1;
  }
  if (aProp < bProp) {
    return -1;
  }
  if (aProp > bProp) {
    return 1;
  }
  return 0;
}

function _sortByPropertyValueDesc(aProp, bProp) {
  if (aProp === null && bProp === null) {
    return 0;
  }
  if (aProp === null) {
    return -1;
  }
  if (bProp === null) {
    return 1;
  }
  if (aProp < bProp) {
    return 1;
  }
  if (aProp > bProp) {
    return -1;
  }
  return 0;
}

export function sortByPropertiesAsc(property, property2) {
  return (a, b) => {
    const aProp = get(a, property, null);
    const bProp = get(b, property, null);
    const aProp2 = get(a, property2, null);
    const bProp2 = get(b, property2, null);

    const firstSortResult = _sortByPropertyValueAsc(aProp, bProp);
    if (firstSortResult === 0) {
      return _sortByPropertyValueAsc(aProp2, bProp2);
    }
    return firstSortResult;
  };
}

export function sortByPropertiesDesc(property, property2) {
  return (a, b) => {
    const aProp = get(a, property, null);
    const bProp = get(b, property, null);
    const aProp2 = get(a, property2, null);
    const bProp2 = get(b, property2, null);

    const firstSortResult = _sortByPropertyValueDesc(aProp, bProp);
    if (firstSortResult === 0) {
      return _sortByPropertyValueDesc(aProp2, bProp2);
    }
    return firstSortResult;
  };
}

export interface ISortParam {
  primaryAsc:boolean;
  secondaryAsc:boolean;
}

export function sortByProperties(property, property2, sort:ISortParam) {
  return (a, b) => {
    const aProp = get(a, property, null);
    const bProp = get(b, property, null);
    const aProp2 = get(a, property2, null);
    const bProp2 = get(b, property2, null);


    const firstSortResult = sort.primaryAsc ? _sortByPropertyValueAsc(aProp, bProp) : _sortByPropertyValueDesc(aProp, bProp);
    if (firstSortResult === 0) {
      return sort.secondaryAsc ? _sortByPropertyValueAsc(aProp2, bProp2) : _sortByPropertyValueDesc(aProp2, bProp2);
    }
    return firstSortResult;
  };
}

export function sortByPropertyDesc(property) {
  return (a, b) => {
    const aProp = get(a, property, null);
    const bProp = get(b, property, null);
    if (aProp === null && bProp === null) {
      return 0;
    }
    if (aProp === null) {
      return -1;
    }
    if (bProp === null) {
      return 1;
    }
    if (aProp < bProp) {
      return 1;
    }
    if (aProp > bProp) {
      return -1;
    }
    return 0;
  };
}

export function sortByDateAsc(property) {
  return (a, b) => {
    const _aProp = get(a, property, null);
    const aProp = parseISO(_aProp);
    const _bProp = get(b, property, null);
    const bProp = parseISO(_bProp);
    if (aProp === null && bProp === null) {
      return 0;
    }
    if (aProp === null) {
      return -1;
    }
    if (bProp === null) {
      return 1;
    }
    if (isBefore(aProp, bProp)) {
      return 1;
    }
    if (isAfter(aProp, bProp)) {
      return -1;
    }
    return 0;
  };
}

export function sortByDateDesc(property) {
  return (a, b) => {
    const _aProp = get(a, property, null);
    const aProp = parseISO(_aProp);
    const _bProp = get(b, property, null);
    const bProp = parseISO(_bProp);
    if (aProp === null && bProp === null) {
      return 0;
    }
    if (aProp === null) {
      return 1;
    }
    if (bProp === null) {
      return -1;
    }
    if (isBefore(aProp, bProp)) {
      return -1;
    }
    if (isAfter(aProp, bProp)) {
      return 1;
    }
    return 0;
  };
}