import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import ServiceDateField from '../../formComponents/ServiceDateField';
import ServiceSelect from '../../formComponents/ServiceSelect';
import useGroupDeal from '../../hookStore/useGroupDeal';
import useGroupDealCreationStore from '../../hookStore/useGroupDealCreationStore';
import styles from '../groupDealCreation.module.css';
import MaxCustomerCountSelect from './MaxCustomerCountSelect';

export interface IServiceInformationCardProps {

}
export default function ServiceInformationCard({ }:IServiceInformationCardProps) {
  const formContext = useFormContext();
  const { getValues } = formContext;
  const { isProviderAdmin } = useGroupDeal();
  const { groupDealCreationState } = useGroupDealCreationStore();
  return (
    <Card className={styles.serviceInformationCard} >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle1' sx={{ textAlign: 'left' }}>Enter Service Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ServiceSelect suppressMargin={true}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ServiceDateField/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MaxCustomerCountSelect/>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}