
import { Typography, Button, useTheme, Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './formComponents.module.css';

export default function CustomerDiscountToggles() {
  const theme = useTheme();
  const { groupDealId } = useParams();
  const { customersToggled, setCustomersToggled } = useGroupDeal();
  const isNew = groupDealId === 'new';

  return (
    <div className={styles.customerDiscountToggles} >
      <Typography
        variant='subtitle2'
        component='div'
        className={customersToggled ? 'toggled' : ''}
        sx={{
          border: `1px solid ${theme.palette.secondary.main}`,
          background: customersToggled ? theme.palette.secondary.main : '',
          color: !customersToggled ? theme.palette.secondary.main : '#fff',
          cursor: 'pointer',
        }}
        onClick={() => {
          setCustomersToggled(true);
        }}
      >
        <>Customers</>
      </Typography>
      <Typography
        variant='subtitle2'
        component='div'
        className={!customersToggled ? 'toggled' : ''}
        sx={{
          border: `1px solid ${theme.palette.secondary.main}`,
          background: !customersToggled ? theme.palette.secondary.main : '',
          color: customersToggled ? theme.palette.secondary.main : '#fff',
          cursor: 'pointer',
        }}
        onClick={() => {
          setCustomersToggled(false);
        }}
      >
        <>Discount</>
      </Typography>
    </div>
  );
}