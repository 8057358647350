
import { Fade, Grid, LinearProgress, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import styles from './confirmCustomerDrawer.module.css';
import { DiscountScheduleTypes } from 'model/ancillary';
import { ICustomerServiceDetails, ICustomerServiceLineItem } from 'model/customer';
import { createEmptyServiceItemPricingInfo } from 'model/priceCalculator';
import { ParticipantStatus } from 'model/status';
import { formatDecimalNoPadding } from 'util/numberUtil';

export interface IServiceItemPriceCalculationProps {
  index:number;
  serviceItem:ICustomerServiceLineItem;
  shouldIncludeCurrentCustomerInDiscount:boolean;
  priceSuffix?:string;
}

export default function ServiceItemPriceCalculation({
  index,
  serviceItem,
  shouldIncludeCurrentCustomerInDiscount,
  priceSuffix,
}:IServiceItemPriceCalculationProps) {
  const { getValues, control, formState } = useFormContext();
  const watchConvertingToCustomer = useWatch({ control: control, name: 'convertingToCustomer' });
  const watchProviderAdminConvertToCustomer = useWatch({ control: control, name: 'providerAdminConvertToCustomer' });
  const convertingToCustomer = watchConvertingToCustomer || watchProviderAdminConvertToCustomer;
  const { discountArticulation } = useFlags();
  const {
    customer,
    shouldDiscountScheduleApply,
    isOnFirstRowOfDiscount,
  } = useCustomerDetail();
  const { clientSidePriceResult, isCalculating, getActiveParticipantCountIncludingCustomer } = usePriceCalculator();
  const discountTotal = clientSidePriceResult.priceResult.discountTotal;

  const serviceItemPricing = clientSidePriceResult?.serviceItemPrices[index] ?? createEmptyServiceItemPricingInfo();
  let discountScheduleType: string | undefined = '';
  let discountLevel:number | null | undefined = 0;
  if (discountArticulation) {
    discountScheduleType = customer?.discountSchedule?.type;
    discountLevel = clientSidePriceResult?.priceResult?.discountResult?.discountLevel;
  }

  const {
    discountDescription,
    discountDescriptionForCustomerMultiplier,
    discountDescriptionForUnitMultiplier,
    discountIncrementalValue,
    serviceItemPriceNoSuffix,
    discountStartingAt,
    forcedTwoDecimalServiceItemPrice,
    formattedPriceAfterDiscountNoSuffix,
  } = serviceItemPricing;
  const calculatedPriceAfterDiscountDisplayValue = serviceItemPricing?.formattedPriceAfterDiscount ?? serviceItemPricing?.formattedServiceItemPrice;
  const alteredCalculatedPriceAfterDiscount = convertingToCustomer ? (formattedPriceAfterDiscountNoSuffix ?? serviceItemPriceNoSuffix) : calculatedPriceAfterDiscountDisplayValue;
  const alteredForcedTwoDecimalServiceItemPrice = convertingToCustomer ? serviceItemPriceNoSuffix : serviceItemPricing.forcedTwoDecimalServiceItemPrice;

  return (
    <>
      {!shouldDiscountScheduleApply(index) && (
        <div className={styles.serviceItemCardBottom}>
          <Grid container className={styles.serviceItemPriceNoDiscount} textAlign='right' justifyContent={'end'} >
            <Grid item xs='auto'>
              <div className={styles.serviceItemPriceAmount}>
                <Typography variant='caption' textAlign='left'>Amount</Typography>
                <Typography variant='h6' sx={{ fontSize: '18px' }}>{alteredForcedTwoDecimalServiceItemPrice}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {shouldDiscountScheduleApply(index) && (
        <>
          <div className={styles.serviceItemCardBottom} style={{ paddingTop: '10px', borderRadius: '12px', position: 'relative' }}>
            <Fade in={isCalculating}
              unmountOnExit
            >
              <Grid container alignItems='center' className={styles.progressContainer}>
                <LinearProgress sx={{ width: '100%', margin: '0 20px' }}/>
              </Grid>
            </Fade>
            <div className={styles.groupDiscount}>
              <Typography variant='caption'>Group Discount</Typography>
              {discountArticulation && (
                <>
                  <div>
                    <Typography variant='body2' component='span' style={{ marginRight: '2px' }}>Number of Customers: {getActiveParticipantCountIncludingCustomer(getValues() as ICustomerServiceDetails)}</Typography>
                  </div>
                  <div>
                    {discountScheduleType === DiscountScheduleTypes.PercentSavePerCustomer.value && (
                      <Typography variant='body2' component='span' style={{ marginRight: '2px' }}>Discount: {discountLevel}%</Typography>
                    )}
                  </div>
                </>
              )}

              {!discountArticulation && (
                <>
                  <div>
                    {discountStartingAt && (
                      <>
                        <Typography variant='body2' component='span' style={{ marginRight: '2px' }}>{discountStartingAt}</Typography>
                        {!isOnFirstRowOfDiscount() && (
                          <>
                            <Typography variant='body2' component='span' style={{ marginRight: '2px' }}> + {discountDescription}</Typography>
                            <Typography variant='body2' component='span' style={{ marginRight: '2px' }}>{discountDescriptionForUnitMultiplier}</Typography>
                            <Typography variant='body2' component='span'>{discountDescriptionForCustomerMultiplier}</Typography>
                          </>
                        )}
                      </>
                    )}
                    {!discountStartingAt && (
                      <>
                        <Typography variant='body2' component='span' style={{ marginRight: '2px' }}>{discountDescription}</Typography>
                        <Typography variant='body2' component='span' style={{ marginRight: '2px' }}>{discountDescriptionForUnitMultiplier}</Typography>
                        <Typography variant='body2' component='span'>{discountDescriptionForCustomerMultiplier}</Typography>
                      </>
                    )}
                  </div>
                </>
              )}
              <Typography variant='body1' style={{ color: '#4657BB', fontWeight: 700 }}>Current Discount: ${formatDecimalNoPadding(discountTotal, '-')}</Typography>
            </div>
            <div className={styles.serviceItemPrice}>
              <div className={styles.discountFormula}>
                <Typography variant='caption'>Original Price - Discount</Typography>
                <Typography variant='body1'>{serviceItemPriceNoSuffix} - ${formatDecimalNoPadding(discountTotal, '-')} =</Typography>
              </div>
              <div className={styles.serviceItemPriceAmount}>
                <Typography variant='caption' component='div' textAlign='left'>Amount</Typography>
                <Typography variant='h6' sx={{ fontSize: '18px' }}>{alteredCalculatedPriceAfterDiscount}</Typography>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}