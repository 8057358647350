import createStore from 'hooks/hookStore';
import {
  IAdditionalServiceItem,
  ICustomFieldSelectionDTO,
  ICustomerServiceDetails,
  IInitialConfirmationForm,
  IProviderCustomerDetail,
  IProviderHistoricalParticipant,
  createEmptyCustomerServiceDetails,
  createEmptyInitialConfirmationForm,
  createEmptyProviderCustomerDetail,
} from 'model/customer';
import { INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import { IOperationsForm, createEmptyOperationsForm } from 'model/operations';
import { IPriceResult, IProviderPortalPriceResult, createEmptyPriceResult, createEmptyProviderPortalPriceResult } from 'model/priceCalculator';
import { IServiceProvider } from 'model/serviceProvider';

export type CustomerDetailData = {
  customer:IProviderCustomerDetail;
  provider?:IServiceProvider;
  isProviderAdmin:boolean;
  initialConfirmation:IInitialConfirmationForm;
  additionalServiceItems: IAdditionalServiceItem[];
  customFieldSelections: ICustomFieldSelectionDTO[];
  customerServiceDetails: ICustomerServiceDetails;
  editingServiceDateAndMaxCustomerCount:boolean;
  requestPaymentModalOpen: boolean;
  serverSidePriceResult:IPriceResult;
  clientSidePriceResult:IProviderPortalPriceResult;
  isCalculating:boolean;
  historicalParticipants:IProviderHistoricalParticipant[];
  nsoisWithSameNso: INeighborhoodServiceOfferingInstance[];
  matchingServiceDateInstance: INeighborhoodServiceOfferingInstance | null;
  selectedAlternateServiceDate:string;
  happeningSoonCompatibleNsoi:any;
  wasDateChanged: boolean;
  priceSuffix?:string;
  showOperations:boolean;
  operationsForm:IOperationsForm;
}

const store = createStore<CustomerDetailData>('customerDetail', {
  isProviderAdmin: false,
  additionalServiceItems: [],
  customFieldSelections: [],
  customer: createEmptyProviderCustomerDetail(),
  initialConfirmation: createEmptyInitialConfirmationForm(),
  customerServiceDetails: createEmptyCustomerServiceDetails(),
  editingServiceDateAndMaxCustomerCount: false,
  requestPaymentModalOpen: false,
  serverSidePriceResult: createEmptyPriceResult(),
  clientSidePriceResult: createEmptyProviderPortalPriceResult(),
  isCalculating: false,
  historicalParticipants: [],
  nsoisWithSameNso: [],
  matchingServiceDateInstance: null,
  selectedAlternateServiceDate: '',
  happeningSoonCompatibleNsoi: null,
  wasDateChanged: false,
  priceSuffix: '',
  showOperations: false,
  operationsForm: createEmptyOperationsForm(),
});

export default store;
