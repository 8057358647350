import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EventIcon from '@mui/icons-material/Event';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useLoading } from 'components/layout/Loading';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { useFormContext } from 'react-hook-form';
import { createDateIgnoreTimezone, formatDateRangeShortLocalDate, formatMonthDayYear } from 'util/dateUtil';
import styles from '../customer.module.css';
import useConfirmCustomerDrawer from '../hookStore/useConfirmCustomerDrawer';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import CaptionDetail from './CaptionDetail';

const editDetailsSx =
{
  width: {
    xs: '100%',
  },
  marginTop: {
    xs: '10px',
    sm: '0px',
  },
  marginBottom: {
    xs: '10px',
    sm: '0px',
  },
  marginRight: {
    xs: '32px',
    sm: '0px',
  },
};
const confirmSx = {
  width: {
    xs: '100%',
  },
  marginLeft: {
    sm: '10px',
  },
  marginTop: {
    xs: '10px',
    sm: '0px',
  },
  marginBottom: {
    xs: '10px',
    sm: '0px',
  },
  marginRight: {
    xs: '32px',
    sm: '0px',
  },
};

export default function PendingServiceScheduled() {
  const { control, getValues, setValue } = useFormContext();
  const {
    loadingKey,
    customer,
    customerServiceDetails,
    showPendingServiceScheduled,
    onConfirm,
    clearNsoisWithSameNso,
  } = useCustomerDetail();
  const { getLoadingState } = useLoading(loadingKey);
  const { isLoading } = getLoadingState();
  const {
    serverSidePriceResult,
  } = usePriceCalculator();
  const { openDrawer, open } = useConfirmCustomerDrawer();
  const { trackButtonClick } = useStreetfairAnalytics();
  const calculatedTotalPrice = serverSidePriceResult.totalPriceAfterDiscount;
  if (!showPendingServiceScheduled()) {
    return null;
  }
  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          label='Service Scheduled'
          control={
            <Checkbox
              checkedIcon={<CheckBoxIcon/>}
              checked={true}
              disabled
              sx={{
                '& .MuiSvgIcon-root': {
                  color: '#F1AE00',
                },
              }}
            />
          }
        />
      </Grid>
      <Grid container item xs={12} sx={{ marginLeft: '30px' }}>
        <div style={{ width: '280px' }}>
          {customer.recurring && (
            <CaptionDetail
              icon={<EventIcon className={styles.calendarIcon}/>}
              value={formatMonthDayYear(createDateIgnoreTimezone(customer.subscriptionStartDate)) || 'Not Set'}
              label={'Service Start Date'}
            />
          )}
          {!customer.recurring && !customer.endServiceDate && (
            <CaptionDetail
              icon={<div className={styles.calendarIcon}><EventIcon/></div>}
              value={formatMonthDayYear(customer.serviceDate) || formatMonthDayYear(customer.neighborhoodServiceOfferingInstanceServiceDate)}
              label={`${'Tentative Service Date'}`}
            />
          )}
          {!customer.recurring && customer.endServiceDate && (
            <CaptionDetail
              icon={<div className={styles.calendarIcon}><EventIcon/></div>}
              value={formatDateRangeShortLocalDate(customer.serviceDate, customer.endServiceDate)}
              label='Tentative Service Date'
              sx={{ fontSize: '12px' }}
            />
          )}
        </div>
        <Grid container item xs={12} sm='auto' alignItems='center'>
          <Button
            variant='outlined'
            onClick={() => {
              void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_REVIEW_CONFIRM);
              clearNsoisWithSameNso();
              openDrawer();
            }}
            sx={editDetailsSx}
          >
            <span>Review & Confirm</span>
          </Button>
        </Grid>
        {/* {(customer.serviceDate || (customer.recurring && customer.subscriptionStartDate)) && calculatedTotalPrice !== null && (
          <Grid container item xs={12} sm='auto' alignItems='center'>
            <Button
              variant='outlined'
              onClick={() => onConfirm(customerServiceDetails)}
              disabled={isLoading}
              sx={confirmSx}
            >
              <span>Confirm</span>
            </Button>
          </Grid>
        )} */}
      </Grid>
    </>
  );
}