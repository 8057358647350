import RepeatIcon from '@mui/icons-material/Repeat';
import { Grid, Typography, useTheme } from '@mui/material';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import HistoricalParticipantsMenu from 'components/historicalParticipants/HistoricalParticipantsMenu';


export default function HistoricalParticipants() {
  const theme = useTheme();

  const { historicalParticipants } = useCustomerDetail();
  if (historicalParticipants.length === 0) {
    return null;
  }
  return (
    <>
      <Grid
        container
        item xs={12} sm='auto'
        alignItems={{ xs: 'left', sm: 'center' }}
        sx={{
          marginLeft: {
            xs: '0px',
            sm: '10px',
          },
          marginTop: {
            xs: '10px',
            sm: '0px',
          },
        }}
      >
        <RepeatIcon sx={{ color: theme.palette.tertiary.main }}/>
        <Typography variant='body2' component='span' sx={{ color: theme.palette.tertiary.main }}>Repeat Customer</Typography>
      </Grid>
      <Grid container>
        <HistoricalParticipantsMenu historicalParticipants={historicalParticipants}/>
      </Grid>
    </>
  );
}