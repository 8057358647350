import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { TextField, IconButton, Checkbox, MenuItem, Menu, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import styles from './quicksearchToolbar.module.css';
import { getStoredSaveQuickSearch, setStoredSaveQuickSearch, setStoredSearchValue } from './storage';

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuicksearchToolbarProps {
  quickSearchStorageKey?:string;
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  placeholder:string;
}

function QuickSearchSettings({ quickSearchStorageKey }:any) {
  let defaultSaveQuickSearchValue = getStoredSaveQuickSearch(quickSearchStorageKey);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [saveQuickSearch, setSaveQuickSearch] = useState<boolean>(defaultSaveQuickSearchValue);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSaveQuickSearchClick = () => {
    const nextSaveQuickSearch = !saveQuickSearch;
    if (!nextSaveQuickSearch) {
      setStoredSearchValue(quickSearchStorageKey, '');
    }
    setStoredSaveQuickSearch(quickSearchStorageKey, nextSaveQuickSearch);
    setSaveQuickSearch(nextSaveQuickSearch);
  };
  return (
    <>
      <IconButton
        id='quick-search-settings-button'
        aria-controls={open ? 'quick-search-settings' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <SettingsIcon/>
      </IconButton>
      <Menu
        id='quick-search-settings'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'quick-search-settings-button',
        }}>
        <MenuItem onClick={handleSaveQuickSearchClick}>
          <Checkbox checked={saveQuickSearch}></Checkbox>
          <span>Save Quick Search</span>
        </MenuItem>
      </Menu>
    </>
  );
}

export default function QuicksearchToolbar({ quickSearchStorageKey, value, placeholder, onChange, clearSearch }: QuicksearchToolbarProps) {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container alignItems='center' justifyContent='space-between' sx={{ padding: '12px' }}>
      <Grid container item
        xs={12} sm={12} md lg={6}
        order={{ xs: 1, md: 0 }}
        justifyContent={{ xs: 'left', sm: 'right', md: 'left' }}
        className={mdDown ? styles.mobileStatusPillFilterWrapper : styles.statusPillFilterWrapper}>
        <Grid item xs='auto' className={styles.statusPillFilter}>

        </Grid>
        <Grid item xs='auto'>

        </Grid>
      </Grid>
      <Grid container item xs alignItems='center' justifyContent='end'>
        <TextField
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={placeholder || 'Search...'}
          InputProps={{
            endAdornment: (
              !value ? <SearchIcon fontSize="small" /> :
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: value ? 'visible' : 'hidden' }}
                  onClick={clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
            ),
          }}
          sx={{
            'width': {
              xs: 1,
              sm: 'auto',
            },
            'minWidth': {
              xs: 1,
              sm: '400px',
            },
            'background': '#F1F1F1',
            'borderRadius': '4px',
            'm': (t) => t.spacing(1, 0.5, 1.5),
            '& .MuiOutlinedInput-input': {
              padding: '12px',
            },
            '&&&&': {
              margin: 0,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}