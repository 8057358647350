import { Button } from '@mui/material';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { useNavigate, useParams } from 'react-router-dom';
import usePostConfirmModal from '../../hookStore/usePostConfirmModal';

export interface IWizardButtonProps {
  backVariant?:boolean;
}

export default function WizardButton({ backVariant }:IWizardButtonProps) {
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  const { activeStep, stepCount, handleNext, handleBack, closeModal } = usePostConfirmModal();
  const { trackButtonClick } = useStreetfairAnalytics();

  if (backVariant) {

    return (
      <Button
        variant='contained'
        onClick={handleBack}
        disabled={activeStep === 0}
        sx={{
          '&&&': {
            width: '270px',
          },
        }}
      >
        <>Previous</>
      </Button>
    );
  }
  if (activeStep === stepCount -1) {
    return (
      <Button
        variant='contained'
        onClick={() => {
          void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_CONFIRMATION_MODAL_HOME);
          closeModal({});
          navigate(`/v1/${serviceProviderId}/customers`);
        }}
        sx={{
          '&&&': {
            width: '270px',
          },
        }}
      >
        <>Return to Home</>
      </Button>);
  }
  return (
    <Button
      variant='contained'
      onClick={(e) => {
        void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_CONFIRMATION_MODAL_NEXT);
        handleNext();
      }}
      disabled={activeStep === stepCount - 1}
      sx={{
        '&&&': {
          width: '270px',
        },
      }}
    >
      <>Next</>
    </Button>
  );
}