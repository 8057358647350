import { Typography, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import useConfirmCustomerDrawer from '../hookStore/useConfirmCustomerDrawer';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import styles from './confirmCustomerDrawer.module.css';
import { CloseButton } from 'components/buttons';


const Header = React.forwardRef((props, ref)=>{

  const { refresh, customer } = useCustomerDetail();
  const { closeDrawer } = useConfirmCustomerDrawer();
  function onClose() {
    void refresh();
    closeDrawer();
  }
  return (
    <div className={styles.closeDrawer} ref={ref as any}>
      <Typography variant='h6' component='div' className={styles.confirmDrawerTitle}>{customer.firstName} {customer.lastName}</Typography>
      <CloseButton
        tooltipTitle="close pane"
        onClick={onClose}
        className={styles.closeDrawerButton}
        iconButtonProps={{
          color: 'primary',
        }}
      />
    </div>
  );
});

export default Header;