
import Axios, { AxiosResponse } from 'axios';
import { ICustomer, IHardDeleteParticipantDTO, IHistoricalParticipantRequest, IProviderCustomerDetail, IProviderHistoricalParticipant, ISaveCustomerServiceDetailsResponseDTO } from 'model/customer';
import { ICustomerTableRow } from 'model/customerTable';
import { IOperationsFormDto } from 'model/operations';

export function findProviderCustomer(serviceProviderId:string, customerId:string):Promise<AxiosResponse<IProviderCustomerDetail>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${serviceProviderId}/customers/${customerId}`);
}

export function findProviderSubscriber(serviceProviderId:string, subscriberId:string):Promise<AxiosResponse<IProviderCustomerDetail>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${serviceProviderId}/subscribers/${subscriberId}`);
}

export function saveCustomerInitialConfirmation(serviceProviderId:string, customerId:string, data:IProviderCustomerDetail):any {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${serviceProviderId}/customers/${customerId}/initialConfirmation`, data);
}

export function saveSubscriberInitialConfirmation(serviceProviderId:string, subscriberId:string, data:IProviderCustomerDetail):any {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${serviceProviderId}/subscribers/${subscriberId}/initialConfirmation`, data);
}

export function saveCustomerServiceDetails(providerId:string, data:any):Promise<AxiosResponse<ISaveCustomerServiceDetailsResponseDTO>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${providerId}/saveCustomerServiceDetails`, data);
}

export function saveOperationsDetails(providerId:string, data:IOperationsFormDto):Promise<AxiosResponse<void>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${providerId}/saveOperationsDetails`, data);
}

export function findCustomersByServiceProviderId(serviceProviderId:string, data:any):Promise<AxiosResponse<ICustomerTableRow[]>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${serviceProviderId}/customers`, data);
}

export function findByServiceProviderAndServiceInstance(serviceProviderId:string, nsoiId:string):Promise<AxiosResponse<ICustomer[]>> {
  return Axios.get(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${serviceProviderId}/nsois/${nsoiId}/customers`);
}

export function hardDeleteParticipant(data:IHardDeleteParticipantDTO) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/hardDeleteParticipant`, data);
}

export function findHistoricalParticipants(serviceProviderId:string, data:IHistoricalParticipantRequest):Promise<AxiosResponse<IProviderHistoricalParticipant[]>> {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/serviceProviders/${serviceProviderId}/findHistoricalParticipants`, data);
}

export function markRewardsAsPaid(customerId:string) {
  return Axios.post(`${window.REACT_APP_BASE_API_URI}/customer/v2/markCustomerRewardsAsPaid?customerId=${customerId}`);
}

//comment