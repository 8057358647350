
import get from 'lodash.get';

export function dedupe<Type>(array:Type[], keyProp:any): IterableIterator<Type> {
  var map = new Map<any, Type>();
  for (let i =0 ;i < array.length; i++) {
    let cur = array[i];
    let key = get(cur, keyProp, '');
    if (key !== '' && !map.has(key)) {
      map.set(key, cur);
    }
  }
  return map.values();
}

export function findFirstMatch<Type>(items:Type[], propertyPath:string, id:string | null):Type | null {
  let matchingItems = items.filter(x => get(x, propertyPath, '') === id);
  if (matchingItems.length > 0) {
    return matchingItems[0];
  }
  return null;
}