export default function PageHeader(props: any) {
  return (

    <h3 style={{
      fontWeight: 'bold',
      fontSize: '19px',
      color: '#26995F',
      marginBottom: '0',
    }}>
      {props.headerText}
    </h3>
  );
}