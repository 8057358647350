import Userfront from '@userfront/react';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import LogoHeader from '../componentsV1/Header/logoHeader';
import { getSelfRoles } from 'api/individualApi';
import Toast from 'components/toast';
import { ROLE_PROVIDER_ADMIN } from 'model/roles';
import { containsProviderAdmin } from 'util/role';


function Layout () {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  async function init() {
    if (location.pathname !== '/reset' && location.pathname.indexOf('sign-up') === -1 && !params.signUpLinkToken) {
      const rolesRes = await getSelfRoles();
      const isProviderAdmin = containsProviderAdmin(rolesRes.data);
      if (!isProviderAdmin) {
        navigate('/');
      }
    }
  }

  useEffect(() => {
    if (!Userfront.tokens.accessToken) {
      navigate('/sign-in');
      return;
    } else {
      void init();
    }
  }, []);

  return (
    <div>
      <LogoHeader serviceProviderId={params.serviceProviderId} />
      <main>
        <Outlet />
      </main>
      <Toast/>
    </div>
  );
}

export default Layout;