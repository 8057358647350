import { Typography, CardContent, Card, Grid } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import get from 'lodash.get';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import styles from './confirmCustomerDrawer.module.css';
import ServiceItemPriceCalculation from './ServiceItemPriceCalculation';
import { CloseButton } from 'components/buttons';
import { HookIntegerField, HookNumberField, HookPriceField, HookTextField } from 'components/reactHookForm';
import { ParticipantStatus } from 'model/ancillary';
import { ICustomerServiceDetails, IProviderCustomerDetail } from 'model/customer';

export default function CustomerServiceItemCard({ index, remove, watchConvertingToCustomer }:any) {
  const { getValues, control, formState } = useFormContext();
  const {
    customer,
    priceSuffix,
    shouldShowUnitBasedFields,
    firstServiceItemHasPricingSchedule,
    selectedAlternateServiceDate,
    matchingServiceDateInstance,
    getSubOptions,
    updatePriceSuffix,
  } = useCustomerDetail();
  const { calculatePriceClientside } = usePriceCalculator();
  const { customers, subscribers } = customer;
  const activeCustomerCount = customers.filter(x => x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE).length;
  const activeSubscriberCount = subscribers.filter(x => x.status === ParticipantStatus.ACTIVE || x.status === ParticipantStatus.COMPLETE).length;
  const shouldIncludeCurrentCustomerInDiscount:boolean = (customer.status === ParticipantStatus.PENDING || customer.status === ParticipantStatus.HOLD) && (activeCustomerCount > 0 || activeSubscriberCount > 0);
  const serviceItem = get(getValues(), `serviceItems.${index}`, null);


  const watchedPrice = useWatch({ control, name: `serviceItems.${index}.price` });
  const watchedUnits = useWatch({ control, name: `serviceItems.${index}.units` });
  const watchUnitBasedPrice = useWatch({ control, name: `serviceItems.${index}.unitBasedPrice` });
  const watchHonorGroupDeal = useWatch({ control, name: 'honorGroupDeal' });
  const watchSubOption = useWatch({ control, name: 'subscriptionSelection' });

  useEffect(() => {
    var subOptions = getSubOptions(customer);
    var filteredSubOptions = subOptions.filter(x => x.id === watchSubOption);

    let _priceSuffix = '';
    if (customer.recurring && !watchConvertingToCustomer) {
      _priceSuffix = ' / Service Visit';
      if (filteredSubOptions.length > 0 && filteredSubOptions[0]?.ancillary?.priceSuffix) {
        _priceSuffix = filteredSubOptions[0].ancillary.priceSuffix.replace('per', '/');
      }
    }
    updatePriceSuffix(_priceSuffix);
    void calculatePriceClientside(getValues() as ICustomerServiceDetails);
  }, [watchSubOption]);

  useEffect(() => {
    void calculatePriceClientside(getValues() as ICustomerServiceDetails);
  }, [watchedPrice, watchedUnits, watchUnitBasedPrice, watchHonorGroupDeal, selectedAlternateServiceDate, matchingServiceDateInstance]);

  return (
    <div className={styles.serviceItemCard}>
      <div className={styles.serviceItemCardContent}>
        <div className={styles.serviceItemCardTop}>
          <div className={styles.serviceItemCardHeader}>
            <Typography variant='subtitle2' component='span'>Item {index+1}</Typography>
            <CloseButton
              disabled={index === 0}
              tooltipTitle="remove service item"
              onClick={() => {remove(index);}}
              iconButtonProps={{
                color: 'primary',
              }}
            />
          </div>
          <HookTextField
            required
            label='Service Item'
            name={`serviceItems.${index}.name`}
            disabled={index === 0}
            className={styles.serviceItemName}
          />
          {!shouldShowUnitBasedFields(index) && (
            <HookPriceField
              disabled={firstServiceItemHasPricingSchedule(index, customer)}
              required
              allowNegative
              label={`${(customer.recurring && !watchConvertingToCustomer) ? 'Price ' + priceSuffix : 'Price'}`}
              name={`serviceItems.${index}.price`}
              className={styles.price}
            />
          )}
          {shouldShowUnitBasedFields(index) && (
            <Grid container spacing={1} className={styles.unitBasedFields}>
              <Grid item xs={6}>
                <HookNumberField
                  label='Qty'
                  name={`serviceItems.${index}.units`}
                />
              </Grid>
              <Grid item xs={6}>
                <HookPriceField
                  disabled={firstServiceItemHasPricingSchedule(index, customer)}
                  required
                  allowNegative={true}
                  label='Price'
                  name={`serviceItems.${index}.unitBasedPrice`}
                />
              </Grid>
            </Grid>
          )}
        </div>
        <ServiceItemPriceCalculation
          index={index}
          serviceItem={serviceItem}
          shouldIncludeCurrentCustomerInDiscount={shouldIncludeCurrentCustomerInDiscount}
        />

      </div>
    </div>
  );
}