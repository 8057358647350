import { Button, Grid } from '@mui/material';
import Userfront from '@userfront/react';
import { useNavigate } from 'react-router-dom';
import NavigationButton from '../../components/Navigation/NavigationButton';
import StreetFairLogo from '../../images/streetfair-logo-white.png';


function LogoHeader (props: any) {
  const navigate = useNavigate();

  const LogoutButton = Userfront.build({
    toolId: window.REACT_APP_PROVIDER_USERFRONT_LOGOUT,
  });

  const logOutButtonClick = (event: any) => {
    event.preventDefault();
    Userfront.logout();
  };

  const handleButtonClick = (event: any) => {
    window.scrollTo(0, 0);
    navigate(event.target.dataset.route);
  };

  return (
    <Grid container style={{
      background: '#299B60 ',
      padding: '10px',
    }}>
      <Grid container item sm={12}>
        <Grid item xs='auto'>
          <img alt="logo" style={{ marginTop: '5px' }} src={StreetFairLogo} height="50px" />
        </Grid>
        <div style={{ flexGrow: '1' }}></div>
        <Grid item xs='auto'>
          <Button id="logout-button"
            onClick={logOutButtonClick}
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              border: 'none',
              marginTop: '10px',
              marginRight: '10px',
            }}
          >
            Log out</Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="left" >
        <NavigationButton text = {'Home'} route={`/v0/${props.serviceProviderId}`} handleButtonClick = {handleButtonClick}/>
        <NavigationButton text = {'Customers'} route={`/v0/${props.serviceProviderId}/customers`} handleButtonClick = {handleButtonClick}/>
        <NavigationButton text = {'Subscribers'} route={`/v0/${props.serviceProviderId}/subscribers`} handleButtonClick = {handleButtonClick}/>
        <NavigationButton text = {'Neighborhoods'} route={`/v0/${props.serviceProviderId}/neighborhoods`} handleButtonClick = {handleButtonClick}/>
        <NavigationButton text = {'Payments'} route={`/v0/${props.serviceProviderId}/payments`} handleButtonClick={handleButtonClick} />
      </Grid>
    </Grid>
  );
}

export default LogoHeader;
