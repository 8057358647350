import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getSelfServiceProvider } from 'api/userApi';

function HomePage() {

  const params = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  async function init() {

    try {
      let serviceProviderId;

      if (params.serviceProviderId && params.serviceProviderId !== 'null') {
        //if provider user has multiple providers.
        serviceProviderId = params.serviceProviderId;
        localStorage.setItem('providerId', serviceProviderId);
      } else {
        serviceProviderId = localStorage.getItem('providerId');
        const serviceProviderRes = await getSelfServiceProvider();
        let resServiceProviderId = serviceProviderRes.data;
        if (serviceProviderId !== resServiceProviderId && resServiceProviderId) {
          localStorage.setItem('providerId', resServiceProviderId);
          serviceProviderId = resServiceProviderId;
        }
      }
      if (serviceProviderId) {
        navigate(`/v1/${serviceProviderId}/`);
      } else {
        setMessage('Could not find data. Please contact support at support@streetfair.com.');
      }
    } catch (err:any) {
      console.error(err);
    }
  }
  useEffect(() => {
    void init();
  }, []);

  if (message) {
    return (
      <Grid container sx={{ height: 'calc(100vh - 75px)' }} alignContent={'center'} justifyContent={'center'}>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Typography textAlign={'left'}
            sx={{
              padding: '0px 20px',
            }}
          >{message}</Typography>
        </div>

      </Grid>
    );
  }
  return (
    <div>Redirecting...</div>
  );
}

export default HomePage;