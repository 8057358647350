
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createHappeningSoonDefaultVariant, createNeighborsNotified, createPriceDropAlert, createConfirmationEmailInviteMore, IModalSlideProps, createConfirmationEmailMaxCustomers, createHappeningSoonAddedCustomer, createHappeningSoonFullyBooked, createHappeningSoonPastCutoffDate, createNeighborsNotifiedSubscriberVariant } from '../postConfirmModal/modalSlide';
import { findServiceInstanceById } from 'api/serviceInstanceApi';
import { useLoading } from 'components/layout/Loading';
import useModal from 'components/modals/useModal';
import { createEmptyServiceCardProps, IServiceCardProps } from 'components/serviceCard';
import createStore from 'hooks/hookStore';
import { DiscountScheduleTypes, ParticipantStatus } from 'model/ancillary';
import { IProviderCustomerDetail } from 'model/customer';
import { IServiceProvider } from 'model/serviceProvider';

export type ConvertCustomerModalStore = {

}

const { get, update, registerListener, unregisterListener } = createStore<ConvertCustomerModalStore>('ConvertCustomerModalStore', {


});

export const convertCustomerModalKey = 'convertCustomerModalKey';


export default function useConvertCustomerModalStore() {
  const { customerId, subscriberId } = useParams();
  const setState = useState(get())[1];
  const modal = useModal(convertCustomerModalKey);
  const { onLoading, doneLoading } = useLoading(convertCustomerModalKey);
  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init(customer:IProviderCustomerDetail, provider:IServiceProvider) {
    onLoading();

    doneLoading(200);
  }


  function openModal() {
    modal.openModal({});
    update({
      ...get(),
    });
  }

  function closeModal() {
    modal.closeModal({});
    update({
      ...get(),
    });
  }


  return {
    ...get(),
    convertCustomerModalKey,
    ...modal,
    openModal,
    closeModal,
    init,
  };
}