import { PostConfirmSteps } from './constants';
import { IProviderCustomerDetail } from 'model/customer';

export interface IModalSlideProps {
  key:string;
  title: string;
  subtitle:string;
  description:string;
  description2?:string;
}

export interface IHappeningSoonSlideProps extends IModalSlideProps{
  showUpdateMaxCap:boolean;
  showPleaseContactSupport:boolean;
}

export function createHappeningSoonDefaultVariant(customer:IProviderCustomerDetail):IModalSlideProps {
  return {
    key: PostConfirmSteps.HAPPENING_SOON_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'You are featured on the home page!',
    description: `Your service visit is now available for more neighbors to sign up! It has been added in the "Happening Soon" section for others
        in the area to see. This will appear in ${customer.neighborhoodName} and its adjacent neighborhoods as well.`,
  };
}

export function createHappeningSoonAddedCustomer():IHappeningSoonSlideProps {
  return {
    key: PostConfirmSteps.HAPPENING_SOON_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'You\'re even more popular!',
    description: 'We\'ve added this customer to your existing service visit on the neighborhood home page. This looks great and helps you build reputation in the area.',
    showUpdateMaxCap: false,
    showPleaseContactSupport: false,
  };
}

export function createHappeningSoonFullyBooked():IHappeningSoonSlideProps {
  return {
    key: PostConfirmSteps.HAPPENING_SOON_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'You\'re all booked up!',
    description: 'This service date is now "sold out." It will no longer appear in the neighborhood happening soon section on StreetFair.',
    showUpdateMaxCap: true,
    showPleaseContactSupport: false,
  };
}


export function createHappeningSoonPastCutoffDate():IHappeningSoonSlideProps {
  return {
    key: PostConfirmSteps.HAPPENING_SOON_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'You\'re all booked up!',
    description: 'The sign up window has passed for this service visit. It will no longer appear in the neighborhood happening soon section on StreetFair.',
    showUpdateMaxCap: false,
    showPleaseContactSupport: true,
  };
}

export function createPriceDropAlert():IModalSlideProps {
  return {
    key: PostConfirmSteps.PRICE_DROP_ALERT_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'Price Drop Alert!',
    description: 'Your other customer(s) on this group deal got an email letting them know about the additional savings - with a link to invite even more neighbors!',

  };
}


export function createNeighborsNotified():IModalSlideProps {
  return {
    key: PostConfirmSteps.NEIGHBORS_NOTIFIED_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'Neighbors Notified!',
    description: 'We\'ve sent out an email notification to relevant users in the neighborhood letting them know about your upcoming visit!',

  };
}

export function createNeighborsNotifiedSubscriberVariant():IModalSlideProps {
  return {
    key: PostConfirmSteps.NEIGHBORS_NOTIFIED_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'You\'re even more popular!',
    description: 'We\'ve added this new customer to your StreetFair listing! This looks great and helps you build your reputation / ranking in the neighborhood.',

  };
}

export function createConfirmationEmailInviteMore(customer:IProviderCustomerDetail):IModalSlideProps {
  return {
    key: PostConfirmSteps.CONFIRMATION_EMAIL_SLIDE,
    title: 'Customer Scheduled! Now What?',
    subtitle: 'This customer can invite more neighbors!',
    description: `${customer.firstName} has received a confirmation email (which doesn\'t show them price) with a link to invite more neighbors to sign up for this service.`,
    description2: 'You should ask them to share that link too!',

  };
}

export function createConfirmationEmailMaxCustomers(customer:IProviderCustomerDetail, titleOverride?:string):IModalSlideProps {
  return {
    key: PostConfirmSteps.CONFIRMATION_EMAIL_SLIDE,
    title: titleOverride ?? 'Customer Scheduled! Now What?',
    subtitle: 'This customer received a confirmation email',
    description: `${customer.firstName} has received a confirmation email from StreetFair. The email does not include the date or price, but the customer can access that information via their StreetFair account.`,

  };
}