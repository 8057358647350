import { Box } from '@mui/material';
import React from 'react';
import useGroupDeal from '../../hookStore/useGroupDeal';
import useGroupDealCreationStore from '../../hookStore/useGroupDealCreationStore';
import GroupDealAdminConfig from './GroupDealAdminConfig';
import GroupDealCreationStatus from './GroupDealCreationStatus';

export default function GroupDealCreationStatusWrapper() {

  const { groupDealCreationState } = useGroupDealCreationStore();

  return (
    <>
      {groupDealCreationState.steps.map((x, i) => {
        const isActive = groupDealCreationState.currentActiveStepIndex === i;
        const isCompleted = groupDealCreationState.currentActiveStepIndex > i;
        return (
          <Box key={i} sx={{ paddingBottom: '20px', width: '100%' }}>
            <GroupDealCreationStatus text={x.text} active={isActive} completed={isCompleted} />

          </Box>
        );
      })}
      <GroupDealAdminConfig/>
    </>
  );

}