import { Button, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useTermsAndConditions from './hookStore/useTermsAndConditions';
import styles from './termsAndConditions.module.css';
import { HookCheckbox } from 'components/reactHookForm';
import { createEmptyTermsAndConditionsForm, ITermsAndConditionsData } from 'model/termsAndConditions';
import { formatMonthDayTime, formatMonthDayTimeYear } from 'util/dateUtil';

export interface ITermsAndConditionsFormInternalProps {
  initialTermsAndConditionsData: ITermsAndConditionsData;
}

export function TermsAndConditionsFormInternal({ initialTermsAndConditionsData }:ITermsAndConditionsFormInternalProps) {
  const formContext = useForm<ITermsAndConditionsData>({ mode: 'onTouched', defaultValues: createEmptyTermsAndConditionsForm() });
  const { save } = useTermsAndConditions();
  useEffect(() => {
    formContext.reset(initialTermsAndConditionsData);

  }, [initialTermsAndConditionsData]);

  const onSubmit : SubmitHandler<ITermsAndConditionsData> = data => {
    void save(data);
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sx={{ paddingLeft: '10px' }} >
            <HookCheckbox
              defaultValue={false}
              name='agreedToPartnership'
              label='I agree to the terms and conditions above'
              onChecked={() => {}}
              extraProps={{
                disabled: initialTermsAndConditionsData.agreedToPartnership === true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              type='submit'
              className={styles.saveButton}
              disabled={initialTermsAndConditionsData.agreedToPartnership ?? false}
            >
              <>{initialTermsAndConditionsData.agreedToPartnership && initialTermsAndConditionsData.agreedToPartnershipDate ? `Accepted ${formatMonthDayTimeYear(initialTermsAndConditionsData.agreedToPartnershipDate)}` : 'Save'}</>
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function TermsAndConditionsForm() {
  const { termsAndConditionsData } = useTermsAndConditions();
  return (
    <TermsAndConditionsFormInternal initialTermsAndConditionsData={termsAndConditionsData}/>
  );
}