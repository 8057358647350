import { Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import PageHeader from '../../components/SectionHeaders/PageHeader';
import { findCustomersByServiceProviderId } from 'api/participantApi';

function CustomerList () {
  const [customers, setCustomers] = useState<any[]>([]);
  const [serviceProviderId, setServiceProviderId] = useState<string>('');
  const params = useParams();

  const getFullName = (row: any) => {
    return row.firstName + ' ' + row.lastName;
  };

  useEffect(() => {
    let serviceProvider;
    if (params.serviceProviderId) {
      serviceProvider = params.serviceProviderId;
      setServiceProviderId(serviceProvider);
      localStorage.setItem('providerId', serviceProvider);
    } else {
      serviceProvider = localStorage.getItem('providerId');
      setServiceProviderId(serviceProvider);
    }

    findCustomersByServiceProviderId(serviceProvider)
      .then((res: any) => {
        setCustomers(res.data);
      }).catch((reason) => console.error(reason));;
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (cellParams: any) => {
        return <a href={`/v0/${serviceProviderId}/customer/${cellParams.row.id}`} >{getFullName(cellParams.row)}</a>;
      },
    },
    { field: 'streetAddress', headerName: 'Address', width: 250 },
    { field: 'zipCode', headerName: 'ZipCode', width: 100 },
    { field: 'neighborhood', headerName: 'Neighborhood', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (cellParams:any) => {

        return (
          <Grid container item xs={12} justifyContent='center' alignItems='center'>
            <Link to={`/v1/${serviceProviderId}/customers/${cellParams.row.id}`}>Customer Detail</Link>
          </Grid>
        );
      },
    },
  ];
  const columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
  const sortModel = [{
    field: 'name',
    sort: 'asc' as any,
  }];

  return (
    <>
      <Container style={{
        marginLeft: '10px',
      }}>
        <Row style={{
          borderBottom: '1px solid #26995F',
          padding: '10px',
          textAlign: 'left',
        }}>
          <PageHeader headerText={(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>CUSTOMERS</span>
              <Link to={`/v1/${serviceProviderId}/customers`} style={{ fontSize: '10px', marginLeft: '8px' }}>V2</Link>
            </div>
          )} />

        </Row>
        <DataGrid rows={customers}
          columns={columns}
          autoHeight={true}
          sortModel={sortModel}
          style={{
            minWidth: '500px',
            maxWidth: `${columnTotalWidth}px`,
            marginTop: '16px',
          }}
        />
      </Container>
    </>
  );
}

export default CustomerList;
