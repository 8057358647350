import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import OperationNote from './OperationNote';
import { IFormNote, INote } from 'model/operations';
import { sortByDateAsc, sortByDateDesc } from 'util/sortFunctions';

export default function OperationsNotes() {
  const { control, getValues, formState } = useFormContext();
  const { fields: notes, append, replace, remove } = useFieldArray({
    control: control,
    name: 'notes',
  });

  const watchedNotes = useWatch({ control, name: 'notes' });
  useEffect(() => {
    if (notes.length !== watchedNotes.length) {
      let newNotes:IFormNote[] = [...watchedNotes];
      newNotes.sort(sortByDateAsc('timestamp'));
      replace(newNotes);
    }
  }, [watchedNotes]);

  return (
    <Grid container sx={{ border: '1px solid gainsboro', padding: '4px' }}>
      <Grid container sx={{ borderBottom: '1px solid gainsboro', paddingBottom: '4px', marginBottom: '8px' }}>
        <Grid item xs={6}>Note</Grid>
        <Grid item xs={4}>Timestamp/UserId</Grid>
        <Grid item xs={2}>Actions</Grid>
      </Grid>
      {notes.map((x, i) => {
        return (
          <Grid key={x.id} item xs={12} sx={{ marginTop: i === 0 ? '0px': '16px' }}>
            <OperationNote
              index={i}
              onRemove={() => {
                remove(i);
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}