import Userfront from '@userfront/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createProviderUser } from 'api/userApi';
import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import { IProviderUserSignUpRequest } from 'model/user';
import { ISignUpForm, createEmptySignUpForm } from 'model/userfront';

type SignUpData = {
  formData: ISignUpForm;
  errorMessage: string | null;
  showAlreadySignedIn: boolean;
}

const loadingKey = 'signUpLoading';

const { get, update, registerListener, unregisterListener } = createStore<SignUpData>('SignUpData', {
  formData: createEmptySignUpForm(),
  errorMessage: null,
  showAlreadySignedIn: false,
});

export default function useSignUpStore() {
  const setState = useState(get())[1];
  const { serviceProviderId, signUpLinkToken } = useParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    await refresh();
    doneLoading(300);
  }

  async function refresh() {

    if (Userfront.tokens.accessToken) {

      update({ ...get(), showAlreadySignedIn: true });
    } else if (serviceProviderId) {
      //we should preemptively set their local storage providerId as the serviceProviderId in the route
      localStorage.setItem('providerId', serviceProviderId);
    }
  }

  function setErrorMessage(errorMessage: string | null) {
    update({ ...get(), errorMessage: errorMessage });
  }
  async function save(data:ISignUpForm) {

    onLoading();
    try {
      var dto:IProviderUserSignUpRequest = {
        email: data.email,
        serviceProviderId: serviceProviderId!,
        oneTimeLinkToken: signUpLinkToken!,
        password: data.password,
      };
      const res = await createProviderUser(dto);
      doneLoading(300);
      if (res.data && res.data.success) {
        navigate(res.data.oneTimeLink);
      } else if (res.data && !res.data.success) {
        setErrorMessage(res.data.errorMessage);
      }
    } catch (err:any) {
      setErrorMessage('Please contact support@streetfair.com for assistance');
      doneLoading(300);
    }


  }

  return {
    loadingKey: loadingKey,
    ...get(),
    init,
    save,
    setErrorMessage,
  };
}