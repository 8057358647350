import { format } from 'date-fns';
import { getDaysRemaining, getTimeRemaining } from './dateUtil';
import { sortByServiceInstanceBeginDate } from './sortFunctions';

export function calculateSpotsLeft(customerCount, maxCustomerCount) {
  if (!maxCustomerCount) {
    return 0;
  }
  return maxCustomerCount - (customerCount || 0);
}

/**
 * Determine when to show spots left banner by calculating how many spots are left and checking if it is less than the threshold specified.
 * (e.g. threshold = 5, maxCustomerCount = 10, customerCount = 6. Thus only 4 spots left and will show the banner)
 * @param customerCount
 * @param maxCustomerCount
 * @returns
 */

export function shouldShowSpotsLeftBanner(customerCount, maxCustomerCount) {
  //max customer count was 0 or undefined so we just assume that we'll never show the banner
  const spotsLeftThreshold = 2;
  if (!maxCustomerCount) {
    return false;
  }
  const spotsLeft = calculateSpotsLeft(customerCount, maxCustomerCount);
  return spotsLeft < spotsLeftThreshold && customerCount < maxCustomerCount;
}

export function shouldShowBookNowWarning(serviceDate, cutoffDayCount) {
  var cutoffDays = 3;

  if (cutoffDayCount != null && cutoffDayCount >= 0) {
    cutoffDays = cutoffDayCount;
  }
  const daysRemaining = getDaysRemaining(serviceDate);
  if (daysRemaining === cutoffDays) {
    const hoursRemaining = getTimeRemaining(serviceDate, cutoffDayCount, { unit: 'hour' });
    return hoursRemaining as number > (24 * cutoffDays);
  }
  const flag = daysRemaining >= cutoffDays && daysRemaining <= (cutoffDays + 1);
  return flag;
}

export function isServiceInstanceInList(serviceInstances, serviceInstanceId) {
  return serviceInstances.filter( x => x.id === serviceInstanceId).length > 0;
}

export function getServiceInstanceFromNSO(serviceInstances, serviceInstanceId) {
  if (serviceInstances && serviceInstances.length > 0) {
    if (serviceInstanceId) {
      //IF there is a service instance in the URL, then is that service instance!
      var serviceInstance = serviceInstances.filter(instance => instance.id === serviceInstanceId);
      return serviceInstance[0];
    } else {
      //OTHERWISE go ahead and grab the first one in the list that is not yet sold out
      return serviceInstances[0];
    }
  }
}

export function getFormattedNSOIDate(serviceInstance) {
  return `${format(new Date(serviceInstance.serviceDate), 'LLL d')}${serviceInstance.endServiceDate ? '-' + format(new Date(serviceInstance.endServiceDate), 'd') : ''}`;
}

export function getFormattedDate (dateToFormat, endDateToFormat) {
  var options = { month: 'long', day: 'numeric' };
  var optionsTwo = { day: 'numeric' };
  return dateToFormat.toLocaleDateString('en-US', options) + ' - ' + endDateToFormat.toLocaleDateString('en-US', optionsTwo) ;
}

export function isServiceInstanceSoldOut (instance) {
  if (!instance.maxCustomerCount || instance.maxCustomerCount > instance.customers.length) {
    return false;
  } else {
    return true;
  }
}

export function isServiceInstancePastCutoffDate(serviceDate, cutoffDayCount) {
  var daysRemaining = getDaysRemaining(serviceDate);
  var cutoffDays = 3;
  if (cutoffDayCount != null && cutoffDayCount >= 0) {
    cutoffDays = cutoffDayCount;
  }
  if (daysRemaining > cutoffDays) {
    return false;
  } else {
    return true;
  }
}

const maxCustomerCountFromServiceInstance = (instance) => {
  if (instance != null) {
    return instance.maxCustomerCount;
  } else {
    return 0;
  }
};

export function showSpotsLeftOnServiceInstance (instance, serviceDate, cutoffDayCount) {
  var shouldShow = shouldShowSpotsLeftBanner(instance.customers.length, maxCustomerCountFromServiceInstance(instance));
  var shouldShowBookByDateWarning = shouldShowBookNowWarning(serviceDate, instance.cutoffDayCount);
  var isServiceInstancePastBookingDate = isServiceInstancePastCutoffDate(serviceDate, cutoffDayCount);
  var isSoldOut = isServiceInstanceSoldOut(instance);
  if (isSoldOut || isServiceInstancePastBookingDate) {
    return false;
  } else {
    return shouldShow || shouldShowBookByDateWarning;
  }
}

export function filterHappeningSoonNSOIs(happeningSoonServicesRes, offeringId?) {
  let nextServiceRecommendations:any[] = [];
  for (let i = 0; i < happeningSoonServicesRes.data.length; i++) {
    const nso : any = happeningSoonServicesRes.data[i];
    const serviceType = nso.serviceTypes[0];
    let recommendationData = nso.serviceInstances.map( (x, j)=> {

      return {
        nsoId: nso.neighborhoodServiceOfferingId,
        maxCustomerCount: x.maxCustomerCount,
        beginServiceDate: x.serviceDate,
        endServiceDate: x.endServiceDate ? x.endServiceDate : x.serviceDate,
        cutoffDayCount: x.cutoffDayCount,
        serviceType,
        serviceProvider: { name: nso.providerName },
        customers: x.customers,
        altColor: j > 0,
        serviceInstanceId: x.id,
      };
    }).filter(x => x!= null && (!offeringId || x.nsoId != offeringId));
    var filteredRecs = recommendationData.filter((rec) => {
      if (!isServiceInstancePastCutoffDate(rec.beginServiceDate, rec.cutoffDayCount) && !isServiceInstanceSoldOut(rec)) {
        return true;
      } else {
        return false;
      }
    });
    if (filteredRecs.length > 0) {
      nextServiceRecommendations.push(filteredRecs[0]);
    }
    nextServiceRecommendations.sort(sortByServiceInstanceBeginDate);
  }
  return nextServiceRecommendations;
}