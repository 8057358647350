export const defaultFilterPanelConfig = {
  filterFormProps: {
    linkOperatorInputProps: {
      size: 'small',
      sx: {
        display: 'none',
      },
    },
    columnInputProps: {
      size: 'small',
    },
    operatorInputProps: {
      size: 'small',

    },
    valueInputProps: {
      InputComponentProps: {
        size: 'small',
        sx: {
          marginLeft: {
            xs: '36px',
            sm: 0,
          },
          marginTop: {
            xs: '8px',
            sm: 0,
          },
        },
      },
    },
    deleteIconProps: {
      sx: {
        width: {
          xs: '32px',
        },
      },
    },
  },
  sx: {
    '& .MuiDataGrid-filterForm': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',

      maxWidth: {
        xs: '350px',
        sm: '600px',
      },
    },
    '& .MuiDataGrid-filterFormLinkOperatorInput': { },
    '& .MuiDataGrid-filterFormColumnInput': { marginRight: '2px' },
    '& .MuiDataGrid-filterFormOperatorInput': { marginRight: '2px' },
  },
};