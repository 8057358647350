import { DateRange } from '@mui/x-date-pickers-pro';
import { format, isEqual, parseISO } from 'date-fns';
import { NOT_SHOULD_HONOR_GROUP_DEAL, SHOULD_HONOR_GROUP_DEAL } from 'pages/customer/confirmCustomerDrawer/HonorGroupDealRadio';
import { createDateIgnoreTimezone, normalize, parseLocalDate } from 'util/dateUtil';
import { calculateDiscount } from 'util/discountCalculator';
import { isNullOrUndefined } from 'util/falsyCheckingUtil';
import { IDiscountSchedule, IDiscountTableRow, createEmptyDiscountSchedule } from './discount';
import { INote } from './operations';
import { ICustomFieldDTO, IServiceSubscriptionOptions } from './serviceOffering';
import { ParticipantStatus } from './status';


export enum SchedulingPreference {
  ASAP = 'ASAP',
  FLEXIBLE ='FLEXIBLE'
}

export interface IHistoricalParticipantRequest {
  individualId:string;
}

export interface IProviderHistoricalParticipant {
  id:string;
  status:string;
  neighborhoodId:string;
  neighborhoodName:string;
  serviceProviderId:string;
  neighborhoodServiceOfferingId:string;
  neighborhoodServiceOfferingInstanceId:string;
  serviceType:string;
  serviceDate:Date | null;
  firstServiceDate:Date | null;
}
export interface IHardDeleteParticipantDTO {
  serviceProviderId:string;
  customerId:string | null;
  subscriberId:string | null;
}

export function schedulingPreferenceDisplayValue(enumValue:SchedulingPreference):string {
  if (enumValue ===SchedulingPreference.ASAP) {
    return 'ASAP';
  } else if (enumValue === SchedulingPreference.FLEXIBLE) {
    return 'Flexible';
  }
  return 'Undefined';
}

export interface ICustomer {
  id:string;
  additionalServicesFollowUp: string | null;
  createdDate:string;
  neighborhoodServiceOfferingInstanceId:string;
  neighborhoodId:string;
  serviceProviderId:string;
  serviceProviderName: string;
  neighborhoodName:string;
  joinDateTime:string;
  price?:number;
  unitQuantity?:number;
  status:string;
  comment:string;
  firstName:string;
  lastName:string;
  streetAddress:string;
  streetAddress2:string;
  city:string;
  state:string;
  zipCode:string;
  phone:string;
  email:string;
  customFieldSelections:ICustomFieldSelectionDTO[];
  serviceItems: ICustomerServiceLineItem[];
  oneTimeChargeServiceItems: ICustomerServiceLineItem[];
  discountTable: IDiscountTableRow[];
  discountSchedule:IDiscountSchedule;
  communicationPreference:string;
  wasCustomerContacted:boolean;
  customerContactComment:string;
  wasConsultationScheduled:boolean;
  wasQuoteSent:boolean;
  customerQuoteComment:string;
  serviceScheduledDate:string;
  markedCompleteDate:string;
  consultationDate:string;
  serviceDate:string | null;
  endServiceDate: string | null;
  serviceRequestDateStart:string;
  serviceRequestDateEnd:string;
  disableConfirmationEmail:boolean;
  hasCreditCard:boolean;
  deletedDate:Date | null;
  placeholder:boolean;
  schedulingPreference:SchedulingPreference | null;
  participantRemoveReason?:string;
  participantRemoveComment?:string;
  providerUploadedCustomer:boolean | null;
}

export interface IGroupDealCustomer extends ICustomer{
  wasAdded:boolean;
}

export interface IMinimalCustomerDTO {
  id:string;
  firstName:string;
  lastName:string;
  profilePicUrl:string;
  status:string;
  isPlaceholder:boolean;
}


export interface IMinimalSubscriberDTO {
  id:string;
  firstName:string;
  lastName:string;
  profilePicUrl:string;
  status:string;
  isPlaceholder:boolean;
}

export interface IUpdateCustomerServiceInstance {
  neighborhoodServiceOfferingInstanceId:string;
}

export interface IUpdateCustomerBilling {
  price:number;
}


export interface IInitialConfirmationForm {
  wasCustomerContacted:boolean;
  wasConsultationScheduled:boolean;
  wasQuoteSent:boolean;
  wasServiceScheduled:boolean;
  wasMarkedComplete:boolean;
  consultationDate:Date;
  customerContactComment:string;
  customerQuoteComment:string;
}

export interface ICustomerServiceDetails {
  additionalServicesFollowUp:string | null;
  firstCustomer:boolean;
  hasAlreadySetServiceDateAndMaxCustomerCount:boolean;
  neighborhoodServiceOfferingId:string;
  neighborhoodServiceOfferingInstanceId:string;
  customerId:string;
  subscriberId:string;
  customerStatus:string;
  serviceItems: ICustomerServiceLineItem[];
  oneTimeChargeServiceItems: ICustomerServiceLineItem[];
  serviceDate?:string;
  serviceDateRange?:DateRange<Date>;
  subscriptionStartDate: string | null;
  maxCustomerCount?:string;
  isMaxCustomerSwitchToggled:boolean;
  totalPrice?:number;
  discount?:number;
  disableConfirmationEmail:boolean;
  recurring:boolean;
  subscriptionSelection: string | null;
  participantRemoveReason?: string;
  participantRemoveComment?: string;
  honorGroupDeal:string;
  convertingToCustomer?:boolean;
  convertToSubscriber?:boolean;
  priceSuffix?:string;
  providerAdminConvertToCustomer?:boolean;
  serviceRelationshipOnus:string | null;
  notes:INote[];
  snoozeDate:string | null;
}

export interface ICustomerServiceLineItem {
  name:string;
  price?:string;
  unitBasedPrice?:string;
  units?:string;
}

export interface ICustomFieldSelectionDTO {
  value:string;
  label:string;
  fieldName:string;
  customFieldSelectedOptions: ICustomFieldSelectedOption[];
}

export interface ICustomFieldSelectedOption{
  value:string;
}

export interface IAdditionalServiceItem {
  value:string;
}

export interface IProviderCustomerDetail extends ICustomer{
  //service types
  serviceCategory:string;
  serviceType: string;
  serviceTypeNameOverride:string | null;
  cadenceType:string;
  customFields: ICustomFieldDTO[];
  bookingPeriodOpen:boolean;
  hasDisabledMaxCustomerCount:boolean;
  neighborhoodServiceOfferingId:string;
  neighborhoodServiceOfferingInstanceServiceDate:string;
  neighborhoodServiceOfferingInstanceEndServiceDate:string | null;
  maxCustomerCount?:number;
  firstCustomer:boolean;
  hasAlreadySetServiceDateAndMaxCustomerCount:boolean;
  serviceRequestDate:string | null;
  recurring:boolean;
  subscriptionOptions: IServiceSubscriptionOptions | null;
  subscriptionSelection:string | null;
  subscriptionStartDate:string | null;
  workflowSteps: IServiceOfferingWorkflowStep[];
  sentCustomerConfirmationEmailDate:string | null;
  shouldShowEmail:boolean;
  customers:IMinimalCustomerDTO[];
  subscribers:IMinimalSubscriberDTO[];
  oneTimeOnlyOption?: string;
  nsoPrice?:number;
  nonPlaceholderActiveCustomerCount:number;
  zipCodeTerritory:string | null;
  adjustedMaxCustomerCount?:number;
  serviceRelationshipOnus:string | null;
  notes:INote[];
  snoozeDate:string | null;
  activeParticipantCountAtCompletion?:number;
}

export interface IServiceOfferingWorkflowStep {
  stepName:string;
  shouldShow:boolean;
}

export function createInitialConfirmationForm(data:IProviderCustomerDetail):IInitialConfirmationForm {
  return {
    wasConsultationScheduled: data.wasConsultationScheduled,
    wasCustomerContacted: data.wasCustomerContacted,
    wasQuoteSent: data.wasQuoteSent,
    wasServiceScheduled: Boolean(data.serviceScheduledDate) || (data.status === ParticipantStatus.ACTIVE || data.status === ParticipantStatus.COMPLETE),
    wasMarkedComplete: Boolean(data.markedCompleteDate) || data.status === ParticipantStatus.COMPLETE,
    consultationDate: data.consultationDate ? parseISO(data.consultationDate) : new Date(),
    customerContactComment: data.customerContactComment,
    customerQuoteComment: data.customerQuoteComment,
  };
}

export function createCustomerServiceDetails(data:IProviderCustomerDetail, serviceDateRangePickerCustomerDetailJun2023:boolean):ICustomerServiceDetails {
  let serviceDate = data.serviceDate ? parseLocalDate(data.serviceDate) : parseLocalDate(data.neighborhoodServiceOfferingInstanceServiceDate);
  let endServiceDate: Date | null = null;
  if (serviceDateRangePickerCustomerDetailJun2023 && data.serviceDate && data.endServiceDate) {
    endServiceDate = parseLocalDate(data.endServiceDate) || parseLocalDate(data.neighborhoodServiceOfferingInstanceEndServiceDate ?? '');
  }
  let subscriptionDate = createDateIgnoreTimezone(data.subscriptionStartDate);

  let partial:ICustomerServiceDetails = {
    additionalServicesFollowUp: data.additionalServicesFollowUp,
    customerId: !data.recurring ? data.id : '',
    subscriberId: data.recurring ? data.id : '',
    serviceItems: createServiceItemsFromCustomerData(data),
    oneTimeChargeServiceItems: createOneTimeChargeServiceItemsFromCustomerData(data),
    neighborhoodServiceOfferingId: data.neighborhoodServiceOfferingId,
    neighborhoodServiceOfferingInstanceId: data.neighborhoodServiceOfferingInstanceId,
    customerStatus: pickValidCustomerStatus(data.status),
    maxCustomerCount: data.maxCustomerCount ? data.maxCustomerCount.toString() : '',
    subscriptionStartDate: (subscriptionDate instanceof Date) ? subscriptionDate.toISOString() : '',
    isMaxCustomerSwitchToggled: data.maxCustomerCount ? data.maxCustomerCount > 1 : true,
    disableConfirmationEmail: data.disableConfirmationEmail,
    firstCustomer: data.firstCustomer,
    hasAlreadySetServiceDateAndMaxCustomerCount: data.hasAlreadySetServiceDateAndMaxCustomerCount,
    recurring: data.recurring,
    subscriptionSelection: data.subscriptionSelection,
    honorGroupDeal: SHOULD_HONOR_GROUP_DEAL,
    serviceRelationshipOnus: data.serviceRelationshipOnus,
    notes: data.notes,
    snoozeDate: data.snoozeDate,
  };
  if (serviceDateRangePickerCustomerDetailJun2023) {
    let _serviceDate = data.serviceDate ? parseLocalDate(data.serviceDate) : parseLocalDate(data.neighborhoodServiceOfferingInstanceServiceDate);
    let serviceDates:DateRange<Date> = endServiceDate !== null ? [_serviceDate, endServiceDate] : [_serviceDate, null];
    partial.serviceDateRange = serviceDates;
    return partial;
  } else {
    partial.serviceDate = (serviceDate instanceof Date) ? serviceDate.toISOString() : '';
    return partial;
  }
}

export function formatServiceRequestDateRange(data:IProviderCustomerDetail):string | null {
  const serviceRequestDateStart = data.serviceRequestDateStart !== null ? normalize(data.serviceRequestDateStart) : null;
  const serviceRequestDateEnd = data.serviceRequestDateEnd !== null ? normalize(data.serviceRequestDateEnd) : null;
  if (serviceRequestDateStart === null && serviceRequestDateEnd === null) {
    return null;
  } else if (serviceRequestDateStart && serviceRequestDateEnd) {
    if (isEqual(serviceRequestDateStart, serviceRequestDateEnd) ) {
      return format(serviceRequestDateStart, 'MMM do');
    } else {
      return `${format(serviceRequestDateStart, 'MMM do')} - ${format(serviceRequestDateEnd, 'MMM do')}`;
    }
  }
  return null;
}

function pickValidCustomerStatus(currentStatus) {
  if (currentStatus != ParticipantStatus.PENDING &&
        currentStatus != ParticipantStatus.ACTIVE &&
        currentStatus != ParticipantStatus.COMPLETE &&
        currentStatus != ParticipantStatus.HOLD &&
        currentStatus != ParticipantStatus.REMOVED) {
    return ParticipantStatus.PENDING;
  }
  return currentStatus;
}

function createServiceItemsFromCustomerData(data:IProviderCustomerDetail) {
  if (data.serviceItems && data.serviceItems.length > 0) {
    // the data was already saved once so we don't need to generate a first default service item, etc
    return data.serviceItems.map(convertServiceItem);
  }
  const firstServiceItem = createEmptyServiceItem();
  firstServiceItem.name = data.serviceType;
  const shouldIncludeCurrentCustomerInDiscount:boolean = data.status === ParticipantStatus.ACTIVE || data.status === ParticipantStatus.COMPLETE;
  if (hasDiscountScheduleOrPricingSchedule(data) && !data.discountSchedule?.unitBasedPricingFlag) {
    const currentPrice = calculateDiscount(data.discountTable, shouldIncludeCurrentCustomerInDiscount);
    firstServiceItem.price = isNullOrUndefined(currentPrice) ? currentPrice.toString() : data.discountTable[0].unformattedAmount?.toString();
    return [firstServiceItem];
  } else if (hasDiscountScheduleOrPricingSchedule(data) && data.discountSchedule?.unitBasedPricingFlag) {
    const currentPrice = calculateDiscount(data.discountTable, shouldIncludeCurrentCustomerInDiscount);
    firstServiceItem.unitBasedPrice = isNullOrUndefined(currentPrice) ? currentPrice.toString() : data.discountTable[0].unformattedAmount?.toString();
    return [firstServiceItem];
  } else {
    return [firstServiceItem];
  }
}

function createOneTimeChargeServiceItemsFromCustomerData(data:IProviderCustomerDetail) {
  if (data.oneTimeChargeServiceItems && data.oneTimeChargeServiceItems.length > 0) {
    return data.oneTimeChargeServiceItems.map(convertServiceItem);
  }
  return [];
}

function hasDiscountScheduleOrPricingSchedule(data:IProviderCustomerDetail) {
  return data.discountSchedule && (data.discountSchedule.pricingSchedule || data.discountSchedule.discountSchedule);
}
function convertServiceItem(x:ICustomerServiceLineItem) {
  return {
    ...x,
    price: x.price !== null && x.price !== undefined ? x.price.toString() : '',
	    unitBasedPrice: x.unitBasedPrice !== null && x.unitBasedPrice !== undefined ? x.unitBasedPrice.toString() : '',
	    units: x.units !== null && x.units !== undefined ? x.units.toString() : '',
  };
}
export function createEmptyProviderCustomerDetail():IProviderCustomerDetail {
  return {
    id: '',
    additionalServicesFollowUp: null,
    firstName: '',
    lastName: '',
    communicationPreference: '',
    neighborhoodServiceOfferingInstanceId: '',
    neighborhoodId: '',
    serviceProviderId: '',
    serviceProviderName: '',
    neighborhoodName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    email: '',
    status: '',
    serviceCategory: '',
    serviceType: '',
    serviceTypeNameOverride: '',
    customFields: [],
    customFieldSelections: [],
    comment: '',
    price: -1,
    streetAddress2: '',
    unitQuantity: -1,
    joinDateTime: new Date().toISOString(),
    wasConsultationScheduled: false,
    wasCustomerContacted: false,
    serviceScheduledDate: new Date().toISOString(),
    markedCompleteDate: new Date().toISOString(),
    customerContactComment: '',
    customerQuoteComment: '',
    wasQuoteSent: false,
    consultationDate: new Date().toISOString(),
    serviceDate: new Date().toISOString(),
    endServiceDate: null,
    serviceRequestDateStart: format(new Date(), 'yyyy-MM-dd'),
    serviceRequestDateEnd: format(new Date(), 'yyyy-MM-dd'),
    neighborhoodServiceOfferingId: '',
    serviceItems: [],
    oneTimeChargeServiceItems: [],
    maxCustomerCount: 1,
    discountSchedule: createEmptyDiscountSchedule(),
    discountTable: [],
    neighborhoodServiceOfferingInstanceServiceDate: new Date().toISOString(),
    neighborhoodServiceOfferingInstanceEndServiceDate: null,
    disableConfirmationEmail: false,
    hasCreditCard: false,
    firstCustomer: false,
    hasAlreadySetServiceDateAndMaxCustomerCount: false,
    serviceRequestDate: null,
    deletedDate: null,
    recurring: false,
    subscriptionSelection: null,
    subscriptionStartDate: null,
    subscriptionOptions: null,
    workflowSteps: [],
    sentCustomerConfirmationEmailDate: '',
    placeholder: false,
    createdDate: '',
    shouldShowEmail: true,
    customers: [],
    subscribers: [],
    hasDisabledMaxCustomerCount: false,
    schedulingPreference: null,
    cadenceType: '',
    bookingPeriodOpen: false,
    nonPlaceholderActiveCustomerCount: 0,
    zipCodeTerritory: null,
    notes: [],
    serviceRelationshipOnus: null,
    snoozeDate: null,
    providerUploadedCustomer: false,
  };
}

export function createEmptyCustomFieldSelection() {
  return {
    value: '',
    label: '',
  };
}

export function createEmptyInitialConfirmationForm():IInitialConfirmationForm {
  return {
    wasCustomerContacted: false,
    wasConsultationScheduled: false,
    wasQuoteSent: false,
    consultationDate: new Date(),
    customerContactComment: '',
    customerQuoteComment: '',
    wasServiceScheduled: false,
    wasMarkedComplete: false,
  };
}

export function createEmptyCustomerServiceDetails():ICustomerServiceDetails {
  return {
    additionalServicesFollowUp: null,
    customerId: '',
    subscriberId: '',
    neighborhoodServiceOfferingId: '',
    neighborhoodServiceOfferingInstanceId: '',
    serviceItems: [],
    oneTimeChargeServiceItems: [],
    customerStatus: ParticipantStatus.PENDING,
    maxCustomerCount: '',
    serviceDate: '',
    serviceDateRange: [null, null],
    isMaxCustomerSwitchToggled: true,
    disableConfirmationEmail: false,
    firstCustomer: false,
    hasAlreadySetServiceDateAndMaxCustomerCount: false,
    recurring: false,
    subscriptionStartDate: '',
    subscriptionSelection: '',
    honorGroupDeal: NOT_SHOULD_HONOR_GROUP_DEAL,
    notes: [],
    serviceRelationshipOnus: null,
    snoozeDate: null,
  };
}

export function createEmptyServiceItem():ICustomerServiceLineItem {
  return {
    name: '',
    price: '',
    unitBasedPrice: '',
    units: '1',
  };
}

export function createEmptyCustomer():ICustomer {
  return {
    id: '',
    additionalServicesFollowUp: null,
    neighborhoodServiceOfferingInstanceId: '',
    neighborhoodId: '',
    serviceProviderId: '',
    serviceProviderName: '',
    neighborhoodName: '',
    joinDateTime: '',
    price: 0,
    unitQuantity: 0,
    status: '',
    comment: '',
    firstName: '',
    lastName: '',
    streetAddress: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    email: '',
    customFieldSelections: [],
    serviceItems: [],
    oneTimeChargeServiceItems: [],
    discountTable: [],
    discountSchedule: createEmptyDiscountSchedule(),
    communicationPreference: '',
    wasCustomerContacted: false,
    customerContactComment: '',
    wasConsultationScheduled: false,
    wasQuoteSent: false,
    customerQuoteComment: '',
    serviceScheduledDate: '',
    markedCompleteDate: '',
    consultationDate: '',
    serviceDate: '',
    endServiceDate: '',
    serviceRequestDateStart: '',
    serviceRequestDateEnd: '',
    disableConfirmationEmail: false,
    hasCreditCard: false,
    deletedDate: null,
    placeholder: false,
    createdDate: '',
    schedulingPreference: null,
    providerUploadedCustomer: false,
  };
}

export function createEmptyGroupDealCustomer():IGroupDealCustomer {
  let customer = createEmptyCustomer();
  (customer as IGroupDealCustomer).wasAdded = true;
  return customer as IGroupDealCustomer;
}

export interface ISaveCustomerServiceDetailsResponseDTO {
  customerId?:string;
  subscriberId?:string;
  convertedToCustomer:boolean;
  convertedToSubscriber:boolean;
}