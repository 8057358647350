import { UNSELECTED_OPTION } from './dropdown';

export interface IFindReviewsForProviderRequest {
  neighborhoodId:string | null;
  rating:string | null;
}

export function createEmptyFindReviewsForProviderRequest():IFindReviewsForProviderRequest {
  return {
    neighborhoodId: UNSELECTED_OPTION,
    rating: '5.0',
  };
}

export interface IProviderStreetfairReview {
  id:string;
  individualId:string;
  neighborhoodId:string | null;
  neighborhoodName:string | null;
  reviewDate:string;
  comment:string | null;
  rating:string;
  firstName:string;
  lastName:string;
}

export function createProviderStreetfairReview():IProviderStreetfairReview {
  return {
    id: '',
    individualId: '',
    neighborhoodId: '',
    neighborhoodName: '',
    reviewDate: '',
    comment: '',
    rating: '',
    firstName: '',
    lastName: '',
  };
}