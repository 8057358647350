import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Typography } from '@mui/material';

export interface IGroupDealCreationStatusProps {
  completed: boolean;
  active:boolean;
  text:String;
}

export default function GroupDealCreationStatus({
  completed, active, text,
}: IGroupDealCreationStatusProps) {

  let colorVariant = 'disabled';

  let icon = <RadioButtonUncheckedIcon color='disabled'/> ;
  if (completed) {
    colorVariant = 'secondary';
    icon = <CheckCircleIcon color='secondary'/> ;
  } else if (active) {
    colorVariant = 'secondary';
    icon = <CheckCircleOutlineOutlinedIcon color='secondary'/> ;
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {icon}
      <Typography
        variant='body1'
        sx={{
          display: 'inline-block',
          fontWeight: '600',
          color: colorVariant === 'disabled' ? '#9E9E9E' : '#4657BB',
          marginLeft: '10px',
          textAlign: 'left',
        }}
      >
        {text}
      </Typography>
    </div>
  );
}