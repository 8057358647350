import { Alert, Grid, Typography } from '@mui/material';
import { useLoading } from 'components/layout/Loading';
import { HookIntegerField } from 'components/reactHookForm';
import HookSwitch from 'components/reactHookForm/HookSwitch';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DiscountScheduleTypes } from 'model/ancillary';
import { IProviderCustomerDetail } from 'model/customer';
import { ParticipantStatus } from 'model/status';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useConfirmCustomerDrawer, { COMPLETION_DRAWER_VARIANT } from '../hookStore/useConfirmCustomerDrawer';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import HonorGroupDealRadio, { NOT_SHOULD_HONOR_GROUP_DEAL } from './HonorGroupDealRadio';
import ServiceDate from './ServiceDate';
import ServiceDateRange from './ServiceDateRange';
import styles from './confirmCustomerDrawer.module.css';
import SubscriptionOption from './subscriptionSpecific/SubscriptionOption';
import SubscriptionStartDate from './subscriptionSpecific/SubscriptionStartDateOld';

function getLabelVariant(customer:IProviderCustomerDetail) {
  if (customer.hasDisabledMaxCustomerCount) {
    return `Would you like to let other neighbors in ${customer.neighborhoodName || 'the neighborhood'} area know that you will be in the neighborhood on this date?`;
  } else {
    return `Allow other ${customer.neighborhoodName || ''} area neighbors to join in on this service visit`;
  }
}

function getServiceDateTitle(variant:string, customer: IProviderCustomerDetail, convertingToCustomer:boolean) {

  if (variant === COMPLETION_DRAWER_VARIANT) {
    return 'When did you complete this service?';
  }
  return (!customer.recurring || convertingToCustomer) ? 'Set Service Date' : 'Set First Service Date';
}

//when creating a group deal an additional toggle and integer field show up. there is a variant of this when changing the date for a customer to another
// group deal
function shouldShowAdditionalGroupDealConfiguration(
  customer: IProviderCustomerDetail,
  convertingToCustomer:boolean,
  variant:string,
  wasADateOrDateRangeSelected:boolean,
  matchingServiceDateInstance:any,
  selectedAlternateServiceDate:string | null,
  isLoading:boolean,
) {
  // a. if it is a recurring customer, we don't want to show the additional group deal configuration. but we do want to show it if we are converting to a customer
  // b. we do not want to show additional group deal configuration in the "completed service" variant of this drawer
  // c. we do not want to show additional group deal configuration until they select a date
  // d. we do not want to show additional group deal config if the customer would prospectively be attached to a service instance based on the selected date
  // with a date.
  // e. don't show while loading
  // f. typically don't show if the customer is already on a group deal, but if an alternate service date was selected that would effectively create a new
  // NSOI then we want to show a variant of these fields (i.e. honor group deal radio)

  return (!customer.recurring || convertingToCustomer) &&
    variant !== COMPLETION_DRAWER_VARIANT &&
    wasADateOrDateRangeSelected &&
    matchingServiceDateInstance === null &&
    !isLoading &&
    (!customer.neighborhoodServiceOfferingInstanceServiceDate || selectedAlternateServiceDate);
}

export default function EditServiceDateAndMaxCustomerCount() {
  const formContext = useFormContext();
  const { serviceDateRangePickerCustomerDetailJun2023 } = useFlags();
  const watchSwitch = useWatch({ control: formContext.control, name: 'isMaxCustomerSwitchToggled' });
  const watchCustomerStatus = useWatch({ control: formContext.control, name: 'customerStatus' });
  const watchMaxCustomerCount = useWatch({ control: formContext.control, name: 'maxCustomerCount' });
  const watchServiceDate = useWatch({ control: formContext.control, name: 'serviceDate' });
  const watchServiceDateRange = useWatch({ control: formContext.control, name: 'serviceDateRange' });
  const watchHonorGroupDeal = useWatch({ control: formContext.control, name: 'honorGroupDeal' });
  const {
    customer,
    nsoisWithSameNso,
    matchingServiceDateInstance,
    selectedAlternateServiceDate,
    refreshAvailableNsoisToMove,
  } = useCustomerDetail();
  const { loadingKey, variant } = useConfirmCustomerDrawer();
  const loading = useLoading(loadingKey);
  const { getLoadingState } = loading;
  const wasADateOrDateRangeSelected = (watchServiceDate && !watchServiceDateRange) || (watchServiceDateRange && watchServiceDateRange[0]);

  const wasNsoiInitialized = customer.neighborhoodServiceOfferingInstanceServiceDate && (customer.maxCustomerCount ?? 0) > 0;
  const hasDiscount = customer.discountSchedule.type !== DiscountScheduleTypes.NoDiscount.value;
  const firstNonPlaceholderActiveCustomerOnJob = customer.firstCustomer && customer.neighborhoodServiceOfferingInstanceServiceDate && customer.nonPlaceholderActiveCustomerCount === 0;
  useEffect(() => {
    if (watchServiceDate && variant !== COMPLETION_DRAWER_VARIANT) {
      void refreshAvailableNsoisToMove(watchServiceDate, null, loading);
    }
  }, [watchServiceDate]);

  useEffect(() => {
    if (serviceDateRangePickerCustomerDetailJun2023 && watchServiceDateRange && variant !== COMPLETION_DRAWER_VARIANT) {
      void refreshAvailableNsoisToMove(watchServiceDateRange[0], watchServiceDateRange[1], loading);
    }
  }, [watchServiceDateRange]);

  let alertText = `The service date that you selected is different from the ${!hasDiscount ? 'service visit' : 'group deal'} that this customer is on.`;
  if (!customer.neighborhoodServiceOfferingInstanceServiceDate) {
    alertText = '';
  }
  if (firstNonPlaceholderActiveCustomerOnJob) {
    alertText = 'Setting this customer\'s service date (1st customer) will change the group service date for this neighborhood.';
  }

  const watchConvertingToCustomer = useWatch({ control: formContext.control, name: 'convertingToCustomer' });
  const watchProviderAdminConvertToCustomer = useWatch({ control: formContext.control, name: 'providerAdminConvertToCustomer' });
  const convertingToCustomer = watchConvertingToCustomer || watchProviderAdminConvertToCustomer;
  const serviceDateTitle = getServiceDateTitle(variant, customer, convertingToCustomer);

  return (
    <Grid container >
      <Grid container item xs={12} alignItems='center'>
        <Typography variant='h6' sx={{ color: '#000', marginRight: '10px' }}>
          {serviceDateTitle}
        </Typography>
      </Grid>

      <div className={styles.setServiceDateCard}>
        <Typography variant='subtitle2' component='span'>Service Info</Typography>
        {(!customer.recurring || convertingToCustomer) && (
          <Grid container item xs={12} justifyContent='center'>
            {serviceDateRangePickerCustomerDetailJun2023 ? <ServiceDateRange/> : <ServiceDate/>}
          </Grid>
        )}
        {(customer.recurring) && (
          <>
            <Grid container item xs={12} justifyContent='center'>
              {!convertingToCustomer && <SubscriptionStartDate/>}
              <SubscriptionOption/>
            </Grid>
          </>
        )}
        { shouldShowAdditionalGroupDealConfiguration(
          customer,
          convertingToCustomer,
          variant,
          wasADateOrDateRangeSelected,
          matchingServiceDateInstance,
          selectedAlternateServiceDate,
          getLoadingState().isLoading,
        ) &&
        (
          <>
            {alertText && (
              <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
                <Alert severity={firstNonPlaceholderActiveCustomerOnJob ? 'warning' : 'info'}>
                  <span>{alertText}</span>
                </Alert>
              </Grid>
            )}
            {hasDiscount && wasNsoiInitialized && !firstNonPlaceholderActiveCustomerOnJob && (
              <Grid container item xs={12} alignItems='center'>
                <HonorGroupDealRadio/>
              </Grid>
            )}
            {(watchHonorGroupDeal === NOT_SHOULD_HONOR_GROUP_DEAL || !hasDiscount || !wasNsoiInitialized) && (
              <>
                {wasNsoiInitialized && (
                  <Grid item xs={12}>
                    <Typography variant='subtitle1' component='div' sx={{ marginBottom: '10px' }}>Create a new {!hasDiscount ? 'service visit' : 'group deal'}</Typography>
                  </Grid>
                )}
                <Grid container item xs={12} alignItems='center'>
                  <Grid item xs>
                    <HookSwitch
                      name='isMaxCustomerSwitchToggled'
                      label={getLabelVariant(customer)}
                      className={styles.allowNeighborJoinSwitch}
                    />
                  </Grid>
                </Grid>

                {!customer.hasDisabledMaxCustomerCount && (
                  <Grid container item xs={12} sx={{ marginTop: '10px' }}>
                    {watchSwitch && (
                      <>
                        <HookIntegerField
                          required={watchCustomerStatus === ParticipantStatus.PENDING || watchCustomerStatus === ParticipantStatus.ACTIVE || watchCustomerStatus === ParticipantStatus.COMPLETE}
                          name='maxCustomerCount'
                          label='Max # of Customers'
                          helperText='Max number of neighbors, including this one, that can join in on this service visit'
                          validationRules={{
                            validate: function(value) {
                              if (value < 1) {
                                return 'Must be at least one.';
                              }
                              return true;
                            },
                          }}
                        />
                        {watchMaxCustomerCount && parseInt(watchMaxCustomerCount) < 2 &&
                        <Alert style={{ width: '100%', margin: '20px 0px 10px 0px' }} severity="warning">
                          <span>Setting max to 1 means that no additional neighbors will be able to join in. This customer will be the only one on this service visit.</span>
                        </Alert>
                        }
                      </>
                    )}
                  </Grid>
                )}
              </>
            )}
          </>
        )}

      </div>
    </Grid>
  );
}