import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import Footer from 'components/footer';
import useTableSearch, { escapeRegExp } from 'components/grid/useTableSearch';
import Loading from 'components/layout/Loading';
import { isEqual, parseISO } from 'date-fns';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import debounce from 'lodash.debounce';
import { ServiceInstanceType } from 'model/ancillary';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createDateIgnoreTimezone, formatLongMonthDay, formatLongMonthDayYear, formatMonthDayTime, formatMonthDayYear, formatYearOnly } from 'util/dateUtil';
import { DemoUtil } from 'util/demoUtil';
import { defaultFilterPanelConfig } from 'util/tableUtil';
import MultiToolbar from './MultiToolbar';
import styles from './groupDealsTable.module.css';
import useGroupDealsTable from './hookStore/useGroupDealsTable';

const debounceSearchAnalytics = debounce(async (eventTracking):Promise<any> => {
  try {
    eventTracking();
  } catch (err) {
    console.log(err);
  }
}, 1500);


const customSearchMap = new Map<string, (searchString:string, fieldValue:any) => boolean>();
customSearchMap.set('serviceDate', (searchString:string, fieldValue:string) => {
  var formattedSearchString = searchString.replaceAll('-', '/');
  const searchRegex = new RegExp(escapeRegExp(formattedSearchString), 'i');
  const formatted = formatLongMonthDayYear(createDateIgnoreTimezone(fieldValue));
  const slashFormat = formatMonthDayYear(createDateIgnoreTimezone(fieldValue));

  return searchRegex.test(formatted) || searchRegex.test(slashFormat) ;
});

customSearchMap.set('neighborhood.zipCodes', (searchString:string, fieldValue:string[]) => {
  return fieldValue.join(',').indexOf(searchString) > -1;
});

const customSearch = {
  map: customSearchMap,
};
function ShareButton({ serviceProviderId, groupDealId }) {
  const navigate = useNavigate();
  function handleClick(e) {
    e.stopPropagation();
    navigate(`/v1/${serviceProviderId}/groupDeals/${groupDealId}/groupDealShare/share`);
  }
  return (
    <Button
      variant='text'
      onClick={handleClick}
    >
      <Typography variant='subtitle2' color='primary'>Share</Typography>
    </Button>
  );
}


const desktopColumns: GridColDef[] = [
  {
    field: 'neighborhoodAndZips',
    valueGetter: (params) => {
      const zipCodes = params.row.neighborhood?.zipCodes;
      return `${params.row.neighborhoodName} ${zipCodes?.join(',')}`;
    },
    headerName: 'Neighborhood',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      const zipCodes = params.row.neighborhood?.zipCodes;
      const isTSOI = params.row.zipCodeTerritory;
      return (
        <div className={styles.nameNeighborhoodCell}>
          <Typography variant='body2' component='div'>{params.row.neighborhoodName} </Typography>
          <Typography variant='caption' component='div'>Zip Code(s): {zipCodes?.join(',')}</Typography>
        </div>
      );
    },
  },
  {
    field: 'serviceDate',
    valueGetter: (params) => {
      if (params.row.serviceInstanceType === ServiceInstanceType.PLACEHOLDER) {
        return `${params.row.createDate} ${formatMonthDayTime(params.row.createDate)} Placeholder`;
      }
      if (!params.row.serviceDate) {
        return 'No service date';
      }
      if (params.row.serviceDate && params.row.endServiceDate && !isEqual(parseISO(params.row.serviceDate), parseISO(params.row.endServiceDate))) {
        let formattedServiceDate = formatLongMonthDayYear(createDateIgnoreTimezone(params.row.serviceDate));
        let formattedEndServiceDate = formatLongMonthDayYear(createDateIgnoreTimezone(params.row.endServiceDate));
        return `${params.row.serviceDate} ${params.row.endServiceDate} ${formattedServiceDate} ${formattedEndServiceDate}`;
      }
      return `${params.row.serviceDate} ${formatLongMonthDayYear(createDateIgnoreTimezone(params.row.serviceDate))}`;
    },
    headerName: 'Service Date',
    minWidth: 100,
    flex: 1,
    renderCell: (params: any) => {
      if (params.row.serviceInstanceType === ServiceInstanceType.PLACEHOLDER) {
        return <Typography variant='body2' component='div' sx={{ fontWeight: 'bold' }}>Placeholder {formatMonthDayTime(params.row.createDate)}</Typography>;
      }
      if (!params.row.serviceDate) {
        return <Typography variant='body2' component='div' sx={{ fontWeight: 'bold' }}>No service date</Typography>;
      }

      if (params.row.serviceDate && params.row.endServiceDate && !isEqual(parseISO(params.row.serviceDate), parseISO(params.row.endServiceDate))) {
        let formattedServiceDate = formatLongMonthDayYear(createDateIgnoreTimezone(params.row.serviceDate));
        let formattedEndServiceDate = formatLongMonthDayYear(createDateIgnoreTimezone(params.row.endServiceDate));
        return (
          <span>{formattedServiceDate} to {formattedEndServiceDate}</span>
        );
      }
      return (<span>{formatLongMonthDayYear(createDateIgnoreTimezone(params.row.serviceDate))}</span>);
    },
  },
  // { field: 'maxCustomerCount', headerName: 'Max Customer Count', minWidth: 100, flex: 1 },
  { field: 'serviceTypeName', headerName: 'Service', minWidth: 100, flex: 1 },
  {
    field: 'share',
    minWidth: 100,
    flex: 1,
    hideSortIcons: true,
    disableColumnMenu: true,
    renderHeader: (params:any) => (<span style={{ visibility: 'hidden' }}>noheader</span>),
    renderCell: (params: any) => (
      <Grid container justifyContent='flex-end'>
        <Grid item xs='auto'>
          <ShareButton serviceProviderId={params.row.serviceProviderId} groupDealId={params.row.id}/>
        </Grid>
      </Grid>
    ),
  },
];

const mobileColumns: GridColDef[] = [
  {
    field: 'neighborhoodAndZips',
    valueGetter: (params) => {
      const zipCodes = params.row.neighborhood?.zipCodes;
      return `${params.row.neighborhoodName} ${zipCodes?.join(',')}`;
    },
    headerName: 'Neighborhood/Zips',
    minWidth: 100,
    flex: 3,
    renderCell: (params: any) => {
      const zipCodes = params.row.neighborhood?.zipCodes;
      return (
        <div className={styles.mobileNameNeighborhoodCell}>
          <Typography variant='body2' component='div'>{params.row.neighborhoodName}</Typography>
          <Typography variant='caption' component='div'>Zip Code(s): {zipCodes?.join(',')}</Typography>
        </div>
      );
    },
  },
  {
    field: 'serviceDate',
    valueGetter: (params) => {
      if (params.row.serviceInstanceType === ServiceInstanceType.PLACEHOLDER) {
        return `${params.row.createDate} ${formatMonthDayTime(params.row.createDate)} Placeholder ${params.row.serviceTypeName}`;
      }
      if (!params.row.serviceDate) {
        return 'No service date';
      }
      if (params.row.serviceDate && params.row.endServiceDate && !isEqual(parseISO(params.row.serviceDate), parseISO(params.row.endServiceDate))) {
        let formattedServiceDate = formatLongMonthDayYear(createDateIgnoreTimezone(params.row.serviceDate));
        let formattedEndServiceDate = formatLongMonthDayYear(createDateIgnoreTimezone(params.row.endServiceDate));
        return `${params.row.serviceDate} ${params.row.endServiceDate} ${formattedServiceDate} ${formattedEndServiceDate} ${params.row.serviceTypeName}`;
      }
      return `${params.row.serviceDate} ${formatLongMonthDayYear(createDateIgnoreTimezone(params.row.serviceDate))} ${params.row.serviceTypeName}`;
    },
    headerName: 'Service/Service Date',
    minWidth: 100,
    flex: 2,
    renderCell: (params:any) => {
      if (!params.row.serviceDate) {
        return (
          <div className={styles.mobileNameNeighborhoodCell}>
            <Typography variant='caption' component='div'>{params.row.serviceTypeName}</Typography>
            <Typography variant='body2' component='div'>No service date</Typography>
          </div>
        );
      }
      if (params.row.serviceDate && params.row.endServiceDate && !isEqual(parseISO(params.row.serviceDate), parseISO(params.row.endServiceDate))) {
        let formattedServiceDate = formatLongMonthDay(createDateIgnoreTimezone(params.row.serviceDate));
        let formattedEndServiceDate = formatLongMonthDay(createDateIgnoreTimezone(params.row.endServiceDate));
        let formattedYear = formatYearOnly(createDateIgnoreTimezone(params.row.serviceDate));

        return (
          <div>
            <Typography variant='caption' component='div'>{params.row.serviceTypeName}</Typography>
            <div style={{ fontSize: '11px' }}>{formattedServiceDate} - {formattedEndServiceDate}, {formattedYear}</div>
          </div>
        );
      }
      return (
        <div>
          <Typography variant='caption' component='div'>{params.row.serviceTypeName}</Typography>
          <Typography variant='body2' component='div' className={styles.mobileServiceDate}>{formatLongMonthDayYear(createDateIgnoreTimezone(params.row.serviceDate))}</Typography>
        </div>
      );
    },
  },
  {
    field: 'share',
    minWidth: 70,
    flex: 1,
    hideSortIcons: true,
    disableColumnMenu: true,
    renderHeader: (params:any) => (<span style={{ visibility: 'hidden' }}>noheader</span>),
    renderCell: (params: any) => (
      <Grid container justifyContent='flex-end'>
        <Grid item xs='auto'>
          <ShareButton serviceProviderId={params.row.serviceProviderId} groupDealId={params.row.id}/>
        </Grid>
      </Grid>
    ),
  },
];

function getColumnData(breakpoint):any {
  let columns;
  let columnTotalWidth = 0;
  if (breakpoint) {
    columns = mobileColumns;
    columnTotalWidth = mobileColumns.map(x => x.width).reduce((a:any, b:any) => a+b, 0);
  } else {
    columns = desktopColumns;
    columnTotalWidth = columns.map(x => x.width).reduce((a:any, b:any) => a+b, 0) +1;
  }
  return { columns, columnTotalWidth };
}

export default function GroupDealsTable() {
  const { serviceProviderId } = useParams();

  const navigate = useNavigate();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    loadingKey,
    quickSearchStorageKey,
    init,
    sortModel,
    onSortModelChange,
    filteredNeighborhoodServiceOfferingInstances,
    navigateToDetail,
    isProviderAdmin,
  } = useGroupDealsTable();
  const {
    rows,
    searchText,
    setRowsWithStoredFilter,
    requestSearch,
    clearStoredSearch,
  } = useTableSearch(quickSearchStorageKey, customSearch,
    ['fullFormattedServiceDate', 'formattedServiceDate', 'neighborhoodName', 'serviceDate', 'serviceTypeName', 'neighborhood.zipCodes']);
  const { columns, columnTotalWidth } = getColumnData(smDown);
  const { trackPageView, trackButtonClick } = useStreetfairAnalytics();

  useEffect(() => {
    void init();
    void trackPageView('Group_Deals_Table');
  }, []);

  useEffect(() => {
    setRowsWithStoredFilter(filteredNeighborhoodServiceOfferingInstances);
  }, [filteredNeighborhoodServiceOfferingInstances]);
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={10} className={styles.pageContainer}>
          <Grid className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} container item xs='auto' alignItems='center' wrap="nowrap">
            <HomeIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}/`}>Home</Link>
              <Link to={`/v1/${serviceProviderId}/groupDeals`} className={styles.lastLink}>Group Deals</Link>
            </Breadcrumbs>
          </Grid>
          <Grid container item xs={12} justifyContent='space-between'>
            <Grid item xs={12} sm>
              <Typography variant='h5' component='div' className={smDown ? styles.mobileHeader : styles.header} textAlign='left'>Group Deals</Typography>
            </Grid>
            {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
              <Grid item xs={12} sm='auto' alignSelf={{ xs: 'center', sm: 'center' }} sx={{ paddingRight: '10px' }}>
                <Button
                  className={smDown ? styles.mobileCreateGroupDeal : styles.createGroupDeal}
                  variant='contained'
                  type='button'
                  onClick={() => {
                    navigate(`/v1/${serviceProviderId}/groupDeals/new/old`);
                  }}
                >
                Create New Group Deal (Old)
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm='auto' alignSelf={{ xs: 'center', sm: 'center' }}>
              <Button
                className={smDown ? styles.mobileCreateGroupDeal : styles.createGroupDeal}
                variant='contained'
                type='button'
                onClick={() => {
                  void trackButtonClick(ButtonClickTypes.CREATE_GROUP_DEAL);
                  navigate(`/v1/${serviceProviderId}/groupDeals/new`);
                }}
              >
                Create New Group Deal
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} >
            <DataGridPro
              initialState={{
                sorting: {
                  sortModel: [{ field: 'neighborhood', sort: 'asc' }],
                },
                pagination: {
                  pageSize: 25,

                },
              }}
              rows={rows}
              components={{ Toolbar: MultiToolbar }}
              componentsProps={{
                filterPanel: defaultFilterPanelConfig,
                toolbar: {
                  quickSearchStorageKey,
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    void debounceSearchAnalytics(async () => {
                      void trackButtonClick(ButtonClickTypes.GROUP_DEALS_TABLE_SEARCH_USED);
                    });
                    requestSearch(
                      filteredNeighborhoodServiceOfferingInstances,
                      event.target.value);
                  },
                  clearSearch: () => requestSearch(filteredNeighborhoodServiceOfferingInstances, ''),
                },
              }}
              columns={columns}
              autoHeight={true}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              onRowClick={navigateToDetail}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  background: '#DBDBDB',
                  color: '#666',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-menuIcon': {
                  visibility: 'visible',
                  width: 'auto',
                },
              }}
            />
          </Grid>
          <Footer className={styles.footer}/>
        </Grid>
      </Grid>
    </Loading>
  );
}