import { Button, Grid, useTheme } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { DemoUtil } from 'util/demoUtil';
import { HardDeleteCustomerButton } from '../hardDeleteCustomer';
import { HoldCustomerButton } from '../holdCustomer';
import useConvertCustomerModalStore from '../hookStore/useConvertCustomerModal';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import { MarkRewardsAsPaidButton } from '../markRewardsAsPaid';
import OperationsForm from './OperationsForm';


export default function OperationsView() {
  const theme = useTheme();
  const { serviceProviderId } = useParams();
  const { isProviderAdmin, showOperations, customer } = useCustomerDetail();
  const { openModal } = useConvertCustomerModalStore();
  const { customerId } = useParams();
  if (!showOperations) {
    return null;
  }
  return (
    <Grid container sx={{ marginLeft: '24px' }}>
      {customer.neighborhoodServiceOfferingInstanceServiceDate && (
        <Grid container item sx={{ margin: '20px 0 10px 10px' }}>
          <Link to={`/v1/${serviceProviderId}/groupDeals/${customer.neighborhoodServiceOfferingInstanceId}`}>View Group Deal</Link>
        </Grid>
      )}
      <OperationsForm/>
      <Grid container item sx={{ marginTop: '32px' }}>
        {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
          <Grid item xs={12}
            textAlign={{ xs: 'left' }}
            sx={{
              marginTop: {
                xs: '10px',
              },
            }}>
            <MarkRewardsAsPaidButton/>
          </Grid>
        )}
        {
          <Grid item xs={12}
            textAlign={{ xs: 'left' }}
            sx={{
              marginTop: {
                xs: '10px',
              },
            }}>
            <HoldCustomerButton/>
          </Grid>
        }
        {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
          <Grid item xs={12}
            textAlign={{ xs: 'left' }}
            sx={{
              marginTop: {
                xs: '10px',
              },
            }}>
            <HardDeleteCustomerButton/>
          </Grid>
        )}
        {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && customerId && (
          <Grid item xs={12}
            textAlign={{ xs: 'left' }}
            sx={{
              marginTop: {
                xs: '10px',
              },
            }}>
            <Button
              variant='text'
              onClick={() => {
                openModal();
              }}
              sx={{
                borderColor: theme.palette.tertiary.red,
                color: theme.palette.tertiary.red,
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              <span>Convert to Subscriber</span>
            </Button>
          </Grid>
        )}
      </Grid>

    </Grid>
  );
}