import { Button, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './customer.module.css';

export interface ICustomerProps {
  customer: any;
  updateCustomerPrice:Function;
  notifyOfError:Function;
  neighborhoodServiceOffering: any;
}

function isValidNumber(value:any) {
  const isValid = !isNaN(value - parseFloat(value));
  return isValid;
}

export default function CustomerPrice({ customer, updateCustomerPrice, notifyOfError, neighborhoodServiceOffering }:ICustomerProps) {
  const [price, setPrice] = useState(null);
  const [unitQuantity, setUnitQuantity] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [unitQuantityHasError, setUnitQuantityHasError] = useState(false);
  function changePrice(e) {
    const currentPrice = e.target.value;
    const isValid = isValidNumber(currentPrice);
    if (!isValid) {
      setHasError(true);
    } else {
      setPrice(e.target.value);
      setHasError(false);
    }
  }
  function changeUnitQuantity(e) {
    const unitCount = e.target.value;
    const isValid = isValidNumber(unitCount);
    if (!isValid) {
      setUnitQuantityHasError(true);
    } else {
      setUnitQuantity(e.target.value);
      setUnitQuantityHasError(false);
    }
  }
  useEffect(() => {
    setPrice(customer.price);
    setUnitQuantity(customer.unitQuantity);
  }, []);

  const shouldShowPriceInput = () => {
    if (neighborhoodServiceOffering && neighborhoodServiceOffering.discountSchedule
        && neighborhoodServiceOffering.discountSchedule.type === 'DollarPerCustomer') {
      return false;
    }
    return true;
  };

  const shouldShowUnitQuantityInput = () => {
    if (neighborhoodServiceOffering && neighborhoodServiceOffering.discountSchedule
          && neighborhoodServiceOffering.discountSchedule.unitBasedPricingFlag) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Grid container item spacing={1} className={styles.customerPrice} alignItems='end'>
      {shouldShowPriceInput() &&
          <Grid container item xs={12}>
            <Grid item xs={'auto'}>
              <TextField
                error={hasError}
                variant='standard'
                size='small'
                label="Customer Price"
                name="price"
                defaultValue={customer.price}
                onChange={changePrice}
              />
            </Grid>
          </Grid>
      }
      {shouldShowUnitQuantityInput() &&
          <Grid container item xs={12}>
            <Grid item xs={'auto'}>
              <TextField
                error={unitQuantityHasError}
                variant='standard'
                size='small'
                label="# of Units"
                name="unitQuantity"
                defaultValue={customer.unitQuantity}
                onChange={changeUnitQuantity}
              />
            </Grid>
          </Grid>
      }
      <Grid item xs='auto'>
        <Button
          size='small'
          variant='contained'
          type='button'
          onClick={() => {
            if (!hasError) {
              updateCustomerPrice(price, unitQuantity);
            } else {
              notifyOfError('Invalid customer price');
            }
          }}
        >
            Change Price
        </Button>
      </Grid>
    </Grid>
  );
}