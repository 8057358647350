import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import styles from './holdCustomerModal.module.css';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { ParticipantStatus } from 'model/ancillary';

export default function HoldCustomerButton() {
  const theme = useTheme();
  const { openModal } = useConfirmationModal('holdCustomerModal');
  const { customer } = useCustomerDetail();
  const isDisabled = (
    customer.status === ParticipantStatus.COMPLETE ||
        (customer.status === ParticipantStatus.REMOVED || customer.status === ParticipantStatus.FORMER)
  );
  return (
    <Button
      disabled={isDisabled}
      variant='text'
      type='button'
      onClick={openModal}
      className={styles.requestPaymentButton}
      sx={{
        borderColor: theme.palette.tertiary.red,
        color: theme.palette.tertiary.red,
        width: {
          xs: '100%',
          sm: 'auto',
        },
      }}
    >
      <span>Hold Customer</span>
    </Button>
  );
}