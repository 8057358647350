import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useAddressAutocomplete from '../hookStore/useAddressAutocomplete';
import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './formComponents.module.css';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import HookFreeSolo from 'components/reactHookForm/HookFreeSolo';
import { DemoUtil } from 'util/demoUtil';

export interface ICustomerCardProps {
  index:number;
  onRemove: () => void;
}
export default function CustomerCard({ index, onRemove }:ICustomerCardProps) {
  const formContext = useFormContext();
  const { getValues } = formContext;
  const { addresses, typedAddresses, debounceAutocomplete, isLoadingData, postClear } = useAddressAutocomplete();
  const { isProviderAdmin } = useGroupDeal();
  function onClickRemove() {
    onRemove();
  }
  let isPlaceholder = getValues(`customers.${index}.placeholder`);
  return (
    <Card className={styles.customerCard}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={12} alignItems='center'>
            <Grid item xs>
              {isPlaceholder && <Typography variant='subtitle2' textAlign='left'>Customer {index+1} {index === 0 && '(Placeholder)'}</Typography>}
              {!isPlaceholder && <Typography variant='subtitle2' textAlign='left'>Customer {index+1} {index === 0 && '(Required)'}</Typography>}
            </Grid>
            <Grid item xs='auto'>
              <IconButton
                type='button'
                disabled={isPlaceholder || index === 0}
                onClick={() => onClickRemove()}
              >
                <DeleteForeverIcon/>
              </IconButton>
            </Grid>
          </Grid>
          {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
            <Grid item xs={12} textAlign='left'>
              <HookCheckbox
                name={`customers.${index}.isPlaceholderCustomer`}
                label='Is Placeholder'
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <HookTextField
              required
              disabled={isPlaceholder}
              name={`customers.${index}.firstName`}
              label='First Name'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookTextField
              required
              disabled={isPlaceholder}
              name={`customers.${index}.lastName`}
              label='Last Name'
            />
          </Grid>
          <Grid item xs={12}>
            <HookFreeSolo
              name={`customers.${index}.streetAddress`}
              label='Street Address'
              dropdownOptions={addresses}
              postOnTextChange={(e) => {
                void debounceAutocomplete({ address: e.target.value }, formContext, `customers.${index}.streetAddress`);
              }}
              postOnChange={async (_formContext, value, reason, details) => {
                if (reason === 'removeOption' || reason === 'clear') {
                  postClear(`customers.${index}.streetAddress`);
                }
              }}
              rules={{
                validate: function(value) {
                  if (!value && !typedAddresses.has(`customers.${index}.streetAddress`)) {
                    return 'Street address is required.';
                  }
                  return true;
                },
              }}
              extraProps={{
                disabled: isPlaceholder,
              }}
              loading={isLoadingData}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}