import { Button, FormControl, FormHelperText, Grid, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from '../convertCustomerModal.module.css';
import { HookDatePicker } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { ParticipantStatus } from 'model/status';

export default function SubscriptionStartDate() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const watchedStatus = useWatch({ control: formCtx.control, name: 'customerStatus' });
  return (
    <FormControl variant='standard' fullWidth error={isError('subscriptionStartDate')} className={styles.providerWorkflowServiceDatePicker}>
      <Grid container alignItems='center'>
        <Grid item xs>
          <HookDatePicker
            label='Service Start Date'
            name='subscriptionStartDate'
            textFieldProps={{ fullWidth: true, variant: 'standard' }}
            required={watchedStatus === ParticipantStatus.PENDING || watchedStatus === ParticipantStatus.ACTIVE}
            datePickerProps={{
              showTodayButton: true,
              clearable: true,
              onAccept: () => {},
              InputAdornmentProps: {
                sx: {
                  marginBottom: '12px',
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <FormHelperText>{getErrorMessage('subscriptionStartDate')}</FormHelperText>
    </FormControl>
  );
}