import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Alert, Button, Card, CardContent, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import HookFreeSolo from 'components/reactHookForm/HookFreeSolo';
import useToast from 'components/toast/useToast';
import { INeighborhood } from 'model/neighborhood';
import useGroupDealCreationStore from 'pages/groupDeal/hookStore/useGroupDealCreationStore';
import { SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { DemoUtil } from 'util/demoUtil';
import { isNullOrUndefinedOrEmptyArray } from 'util/falsyCheckingUtil';
import useAddressAutocomplete from '../../hookStore/useAddressAutocomplete';
import useGroupDeal from '../../hookStore/useGroupDeal';
import styles from '../groupDealCreation.module.css';
import CreateNeighborhood from './CreateNeighborhood';
import CustomerNeighborhoodSelect from './CustomerNeighborhoodSelect';
import NeighborhoodStatusLine from './NeighborhoodStatusLine';


export interface IAddCustomerCardProps {
  index:number;
  onRemove: () => void;
}
export default function AddCustomerCard({ index, onRemove }:IAddCustomerCardProps) {
  const formContext = useFormContext();
  const { getValues } = formContext;
  const { addresses, typedAddresses, debounceAutocomplete, isLoadingData, postClear } = useAddressAutocomplete();
  const { isProviderAdmin, warningMessage } = useGroupDeal();
  const { createErrorToast } = useToast();
  const theme = useTheme();
  const xsBrk = useMediaQuery(theme.breakpoints.only('xs'));
  const {
    customerIndexToNeighborhoodMap,
    customerIndexToPossibleNeighborhoodsMap,
    showCreateNeighborhood,
    onFindNeighborhoodByPlace,
    onSelectPossibleNeighborhood,
    onShowCreateNeighborhood,
    clearCustomerNeighborhood,

  } = useGroupDealCreationStore();
  function onClickRemove() {
    onRemove();
  }

  let isPlaceholder = getValues(`customers.${index}.placeholder`);
  let mapItem = customerIndexToPossibleNeighborhoodsMap.get(index);
  const showFindNeighborhoodButton = !(mapItem || !isNullOrUndefinedOrEmptyArray(mapItem)) &&
    typedAddresses.has(`customers.${index}.streetAddress`) &&
    typedAddresses.get(`customers.${index}.streetAddress`);
  return (
    <Card className={styles.customerCard}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={12} alignItems='center'>
            <Grid item xs='auto' sx={{ marginRight: '20px' }}>
              {!isPlaceholder && <Typography variant='subtitle2' textAlign='left'>Add your Customer</Typography>}
            </Grid>
            {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
              <Grid item xs textAlign='left'>
                <HookCheckbox
                  name={`customers.${index}.isPlaceholderCustomer`}
                  label='Is Placeholder'
                />
              </Grid>
            )}
            {index !== 0 && (
              <Grid item xs='auto'>
                <IconButton
                  type='button'
                  disabled={isPlaceholder || index === 0}
                  onClick={() => onClickRemove()}
                >
                  <DeleteForeverIcon/>
                </IconButton>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <HookTextField
              required
              disabled={isPlaceholder}
              name={`customers.${index}.firstName`}
              label='First Name'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookTextField
              required
              disabled={isPlaceholder}
              name={`customers.${index}.lastName`}
              label='Last Name'
            />
          </Grid>

          <Grid item xs={12}>
            <HookFreeSolo
              preventAdd
              name={`customers.${index}.streetAddress`}
              label='Street Address'
              dropdownOptions={addresses}
              postOnTextChange={(e) => {
                void debounceAutocomplete({ address: e.target.value }, formContext, `customers.${index}.streetAddress`, (recommendations) => {
                  clearCustomerNeighborhood(formContext, index);
                });
              }}
              postOnChange={async (_formContext, value, reason, details) => {
                if (reason === 'selectOption') {
                  if (value.ancillary && value.ancillary.placeId) {
                    clearCustomerNeighborhood(formContext, index);
                    void onFindNeighborhoodByPlace(formContext, index, value.ancillary.placeId);
                  }
                } else if (reason === 'removeOption' || reason === 'clear') {

                  postClear(`customers.${index}.streetAddress`);
                  clearCustomerNeighborhood(formContext, index);
                }
              }}
              rules={{
                validate: function(value) {
                  if (!value && !typedAddresses.has(`customers.${index}.streetAddress`)) {
                    return 'Street address is required.';
                  }
                  return true;
                },
              }}
              onEnterPressed={(e) => {
                //NOOP. want to disable how pressing enter clears the input
              }}
              extraProps={{
                disabled: isPlaceholder,
                onInputChange: (e:SyntheticEvent, value:string, reason:string) => {
                  if (reason === 'reset' || reason === 'clear') {
                    postClear(`customers.${index}.streetAddress`);
                    clearCustomerNeighborhood(formContext, index);
                  }
                },
              }}
              loading={isLoadingData}
            />
          </Grid>
          {!isNullOrUndefinedOrEmptyArray(customerIndexToPossibleNeighborhoodsMap.get(index)) && (
            <Grid container item xs={12} sm={12} md={6}>
              <CustomerNeighborhoodSelect
                value={customerIndexToNeighborhoodMap.get(index)?.id ?? ''}
                neighborhoodOptions={customerIndexToPossibleNeighborhoodsMap.get(index)!}
                error={{
                  hasError: false,
                  message: '',
                }}
                onChange={(e) => {
                  let neighborhood:INeighborhood = customerIndexToPossibleNeighborhoodsMap.get(index)!.filter(x => x.id === e.target.value)[0];
                  onSelectPossibleNeighborhood(formContext, index, neighborhood);
                }}
              />
            </Grid>
          )}
          {showFindNeighborhoodButton && (
            <Grid container item xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }}>
              <Button
                type='button'
                variant='contained'
                fullWidth={xsBrk}
                onClick={(e) => {
                  if (addresses.length === 1) {
                    clearCustomerNeighborhood(formContext, index);
                    void onFindNeighborhoodByPlace(formContext, index, addresses[0].ancillary.placeId);
                  } else {
                    createErrorToast('Street Address was invalid. Please type the full address of the customer or select a value from the dropdown.', 3);
                  }

                }}
              >
              Find Neighborhood
              </Button>
            </Grid>
          )}

          <NeighborhoodStatusLine
            neighborhood={customerIndexToNeighborhoodMap.get(index)}
            showCreateNeighborhood={showCreateNeighborhood}
          />
          {showCreateNeighborhood && (
            <CreateNeighborhood/>
          )}
          { warningMessage !== null && (
            <Grid item xs={12}>
              <Alert severity='warning'>{warningMessage}</Alert>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}