import Userfront from '@userfront/react';
import { getSelfRoles } from 'api/individualApi';
import { anonymousFindServiceProviderById, findServiceProviderById } from 'api/serviceProviderApi';
import { IMinimalUserDTO, createEmptyMinimalUser, getSelfUser } from 'api/userApi';
import { AxiosResponse } from 'axios';
import { useLoading } from 'components/layout/Loading';
import { createModuleStore } from 'hooks/hookStore';
import { IRole } from 'model/roles';
import { IServiceProvider, createEmptyServiceProvider } from 'model/serviceProvider';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

type ProviderSelf = {
  serviceProvider:IServiceProvider;
  roles: IRole[];
  selfUser: IMinimalUserDTO;
}

type ModuleSelf = {
  rolesLoaded:boolean;
  userPromise:Promise<AxiosResponse<IMinimalUserDTO>> | null;
  providerPromise:Promise<AxiosResponse<any, any>> | null;
  serviceProvider:IServiceProvider;
}


const { get, getModuleScoped, update, registerListener, unregisterListener } = createModuleStore<ProviderSelf, ModuleSelf>('providerSelf', {
  serviceProvider: createEmptyServiceProvider(),
  roles: [],
  selfUser: createEmptyMinimalUser(),
},
{
  rolesLoaded: false,
  userPromise: null,
  providerPromise: null,
  serviceProvider: createEmptyServiceProvider(),
});

const loadingKey = 'providerSelf';

export default function useSelf() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId: pathServiceProviderId, signUpLinkToken } = useParams();
  const location = useLocation();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== '/reset' && location.pathname !== '/sign-in' && !signUpLinkToken) {
      const moduleScope = getModuleScoped();
      if (!moduleScope.rolesLoaded) {
        moduleScope.rolesLoaded = true;
        void populateRoles();
      }
      if (moduleScope.userPromise === null) {
        void populateUser();
      }
    }

  }, []);

  async function onGetSelfServiceProvider(serviceProviderId) {
    try {
      if (serviceProviderId) {
        const moduleScope = getModuleScoped();
        moduleScope.providerPromise = findServiceProviderById(serviceProviderId);
        moduleScope.serviceProvider = (await moduleScope.providerPromise).data;
        update({
          ...get(),
          serviceProvider: moduleScope.serviceProvider,
        });
      }
    } catch (err:any) {
      console.log(err);
    }
  }
  async function onGetAnonymousServiceProvider(serviceProviderId) {
    try {
      if (serviceProviderId) {
        const moduleScope = getModuleScoped();
        moduleScope.providerPromise = anonymousFindServiceProviderById(serviceProviderId);
        moduleScope.serviceProvider = (await moduleScope.providerPromise).data;
        update({
          ...get(),
          serviceProvider: moduleScope.serviceProvider,
        });
      }
    } catch (err:any) {
      console.log(err);
    }
  }

  async function populateUser() : Promise<IMinimalUserDTO> {
    const moduleScope = getModuleScoped();
    if (Userfront.tokens.accessToken) {
      if (moduleScope.userPromise === null) {
        moduleScope.userPromise = getSelfUser();
        const _selfUser = (await moduleScope.userPromise).data;
        update({
          ...get(),
          selfUser: _selfUser,
        });
        return _selfUser;
      } else {
        const _selfUser = (await moduleScope.userPromise).data;
        return _selfUser;
      }
    } else {
      return new Promise((res) => {
        var minimal = createEmptyMinimalUser();
        minimal.userName = 'anonymous';
        res(minimal);
      });
    }
  }

  async function populateServiceProvider() : Promise<IServiceProvider> {
    const moduleScope = getModuleScoped();
    if (moduleScope.providerPromise === null ) {
      let serviceProvider:IServiceProvider;
      try {
        if (pathServiceProviderId) {
          moduleScope.providerPromise = findServiceProviderById(pathServiceProviderId);
          serviceProvider = (await moduleScope.providerPromise).data;
          moduleScope.serviceProvider = serviceProvider;
          update({
            ...get(),
            serviceProvider: serviceProvider,
          });
          return serviceProvider;
        }
      } catch (err:any) {
        console.log(err);
      }
      return createEmptyServiceProvider();
    } else {
      const serviceProvider = (await moduleScope.providerPromise).data;
      moduleScope.serviceProvider = serviceProvider;
      update({
        ...get(),
        serviceProvider: serviceProvider,
      });
      return serviceProvider;
    }
  }

  async function populateRoles() {
    const discountTableRes = await getSelfRoles();
    update({
      ...get(),
      roles: discountTableRes.data,
    });
  }


  return {
    loadingKey: loadingKey,
    ...get(),
    onGetSelfServiceProvider,
    onGetAnonymousServiceProvider,
    populateUser,
    populateServiceProvider,
  };
}