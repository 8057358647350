import { Typography } from '@mui/material';
import styles from '../convertCustomerModal.module.css';

export default function SlideTitle({ children }:any) {

  return (
    <Typography
      variant='h5'
      component='div'
      className={styles.slideTitle}
      sx={{ fontSize: { xs: '18px', sm: '24px' } }}
    >
      {children}
    </Typography>
  );
}