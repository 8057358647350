import { createEmptyServiceItem, ICustomer, ICustomerServiceLineItem } from './customer';
import { IDiscountSchedule } from './discount';
import { ISubscriber } from './subscriber';

export interface IPriceResult {
  primaryServiceItemPriceBeforeDiscount:number;
  additionalServiceItemsTotalPrice:number;
  discountTotal:number;
  discountResult?:IDiscountResult;
  primaryServiceItemPriceAfterDiscount:number;
  totalPriceAfterDiscount:number;
}

export interface IDiscountResult {
  originalPrice:number | null;
  discountedPrice:number | null;
  discountTotal:number | null;
  finalPrice:number | null;
  adjustedCount:number;
  reachedMaxDiscount:boolean;
  participantCountAtMaxDiscount:number;
  discountLevel:number | null;
}

export interface IProviderPortalPriceResult {
  priceResult:IPriceResult;
  serviceItemPrices:IServiceItemPricingInfo[];
}

export interface IServiceItemPricingInfo {
  serviceItem:ICustomerServiceLineItem;
  formattedServiceItemPrice?:string;
  serviceItemPriceNoSuffix?:string;
  forcedTwoDecimalServiceItemPrice?:string;
  formattedPriceAfterDiscount?:string;
  formattedPriceAfterDiscountNoSuffix?:string;
  discountStartingAt?:string;
  discountDescription?:string;
  discountIncrementalValue?:string;
  discountDescriptionForCustomerMultiplier?:string;
  discountDescriptionForUnitMultiplier?:string;
  doesDiscountScheduleApply:boolean;
}

export function createEmptyServiceItemPricingInfo():IServiceItemPricingInfo {
  return {
    serviceItem: createEmptyServiceItem(),
    formattedServiceItemPrice: '',
    serviceItemPriceNoSuffix: '',
    forcedTwoDecimalServiceItemPrice: '',
    formattedPriceAfterDiscount: '',
    discountStartingAt: '',
    discountDescription: '',
    discountIncrementalValue: '',
    discountDescriptionForCustomerMultiplier: '',
    discountDescriptionForUnitMultiplier: '',
    doesDiscountScheduleApply: false,
  };
}

export function createEmptyPriceResult():IPriceResult {
  return {
    primaryServiceItemPriceBeforeDiscount: -1,
    additionalServiceItemsTotalPrice: -1,
    discountTotal: -1,
    primaryServiceItemPriceAfterDiscount: -1,
    totalPriceAfterDiscount: -1,
  };
}

export function createEmptyProviderPortalPriceResult():IProviderPortalPriceResult {
  return {
    priceResult: createEmptyPriceResult(),
    serviceItemPrices: [],
  };
}

export interface IPriceCalculatorParameters {
  discountSchedule:IDiscountSchedule;
  customer:ICustomer;
  subscriber:ISubscriber;
  participantCount:number;
  nsoPrice?:number;
  priceSuffix:string | null;
}