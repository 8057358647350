import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Alert, Breadcrumbs, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Footer from 'components/footer';
import Loading from 'components/layout/Loading';
import useSelf from 'hooks/useSelf';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageBox from './PageBox';
import styles from './home.module.css';
import useHome from './hookStore/useHome';


export default function Home() {
  const { serviceProviderId } = useParams();
  const { serviceProvider } = useSelf();
  const { loadingKey, pageBoxes, init, createPageBoxes } = useHome();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { trackPageView } = useStreetfairAnalytics();

  useEffect(() => {
    void init();
    void trackPageView('Home');
  }, []);

  useEffect(() => {
    void createPageBoxes();
  }, []);
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={10} className={styles.pageContainer} >
          <Grid className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} container item xs='auto' alignItems='center' wrap="nowrap">
            <HomeIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}/`}>Home</Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} >
            <Typography variant='h5' component='div' className={smDown ? styles.mobileHeader : styles.header} textAlign='left'>Streetfair Provider Portal</Typography>
          </Grid>
          {serviceProvider && serviceProvider.hipFireProvider &&
            <Alert severity='info' sx={{ marginBottom: '20px', textAlign: 'left', width: '100%' }}>
            Welcome to the StreetFair community.  Please use the link below to schedule a demo with our service provider success team and finish setting up your account.
              <Grid style={{ marginTop: '10px' }}>
                <a href='https://calendly.com/streetfair/overview' target='_blank' rel='noreferrer'> Schedule a Demo</a>
              </Grid>
            </Alert>
          }
          <Grid container item spacing={2}>
            {pageBoxes.map((x, i) => (
              <Grid item xs={12} md={6} key={i}>
                <PageBox {...x}/>
              </Grid>
            ))}
          </Grid>
          <Footer className={styles.footer}/>
        </Grid>
      </Grid>
    </Loading>
  );
}