import { useEffect, useState } from 'react';
import Confetti, { Props } from 'react-confetti';


export interface ISFConfettiProps extends Props{
  showConfetti:boolean;
}

export default function SFConfetti({ showConfetti, ...props }:ISFConfettiProps) {
  const [windowSize, setWindowSize] = useState<any>(undefined);
  useEffect(() => {

    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!windowSize || !showConfetti ) {
    return null;
  }
  return (
    <Confetti
      width={windowSize.width}
      height={windowSize.height}
      recycle={false}
      {...props}
    />
  );
}