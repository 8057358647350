import { InputLabel, FormHelperText, FormControl, Typography, LinearProgress, Box } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './formComponents.module.css';
import { useLoading } from 'components/layout/Loading';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';

function isZipValid(zipCode) {
  if (!zipCode) {
    return false;
  }
  if (zipCode.toString().length != 5) {
    return false;
  }
  const matched = !!zipCode.toString().match(/^[0-9]{1,10}$/g);
  if (!matched) {
    return false;
  }
  return true;
}

export default function NeighborhoodSelect() {
  const formCtx = useFormContext();
  const { control, formState } = formCtx;
  const { searchNeighborhoods, neighborhoodLoadingKey } = useGroupDeal();
  const { getLoadingState } = useLoading(neighborhoodLoadingKey);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { neighborhoodOptions, isEditing } = useGroupDeal();
  const watchedZipCode = useWatch({ control: formCtx.control, name: 'zipCode' });

  const { isLoading } = getLoadingState();
  useEffect(() => {
    if (watchedZipCode && isZipValid(watchedZipCode)) {
      void searchNeighborhoods(watchedZipCode);
    }
  }, [watchedZipCode]);
  //TODO: gotta be a better way to handle this. When navigating from an edit, back out to table, then into new the old neighborhoodId is around for
  // a few renders before being reset. The following conditional prevents warnings in the console.
  if ((!neighborhoodOptions || neighborhoodOptions.length === 0) && formCtx.getValues().neighborhoodId) {
    return <Typography variant='body2'>No neighborhoods found</Typography>;
  }
  let zipCodeError = !isZipValid(watchedZipCode);

  if (isLoading) {
    return (
      <Box sx={{ width: '100%', minHeight: '20px', paddingTop: '30px' }}>
        <LinearProgress/>
      </Box>
    );
  }
  return (
    <>
      {!isEditing &&
      <FormControl
        variant='standard'
        fullWidth
        disabled={neighborhoodOptions.length === 0 || isEditing || zipCodeError}
        error={isError('neighborhoodId')}
        className={styles.neighborhoodSelect}
      >
        <InputLabel htmlFor="neighborhood-select">Select Neighborhood</InputLabel>
        <HookSelect
          control={formCtx.control}
          rules={{
            required: { value: true, message: 'Required' },
          }}
          name='neighborhoodId'
          id='neighborhood-select'
          menuItems={neighborhoodOptions}
          menuItemKeys={{
            displayValue: 'optionText',
            key: 'key',
            value: 'optionValue',
          }}
          extraProps={{
            disabled: neighborhoodOptions.length === 0 || isEditing || zipCodeError,
          }}
        />
        <FormHelperText>{getErrorMessage('neighborhoodId') || ' '}</FormHelperText>
      </FormControl>
      }
    </>
  );
}