import { Alert, Snackbar } from '@mui/material';
import Fade, { FadeProps } from '@mui/material/Fade';
import { useEffect } from 'react';
import useToast from './useToast';

type TransitionProps = Omit<FadeProps, 'direction'>;

function FadeOut(props: TransitionProps) {
  return <Fade {...props} />;
}

export default function Toast() {
  const { messageInfo, snackPack, open, handleClose, closeToast, addToastInternal, handleExited } = useToast();

  useEffect(() => {
    if (snackPack.length && !messageInfo.message) {
      addToastInternal();
    } else if (snackPack.length && messageInfo && open) {
      closeToast();
    }
  }, [messageInfo, snackPack, open]);
  const color = messageInfo.severity || 'warning';
  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionComponent={FadeOut}
      TransitionProps={{ onExited: handleExited }}
      sx={{
        'xs': {
          '& .MuiAlert-filledSuccess': {
            marginRight: '20px',
          },
        },
        '& .MuiAlert-filledSuccess': {
          border: '1px solid grey',
          boxShadow: '0px 0px 4px white',
          backgroundColor: 'primary.main',
          fontWeight: 'bold',
        },
      }}
    >
      <Alert
        severity={color}
        variant='filled'
        onClose={(e) => handleClose(e, null)}
      >
        {messageInfo ? messageInfo.message : undefined}
      </Alert>
    </Snackbar>
  );
}