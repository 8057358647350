import { useTheme } from '@mui/material';
import styles from './status.module.css';
import StatusBall from './StatusBall';
import { ParticipantStatus } from 'model/status';

export interface IStatus {
  status: string;
  size?:number;
  marginRight?:number;
  fontSize?:number;
  className?:string;
}

export default function Status({ status, size, marginRight, fontSize, className }:IStatus) {
  const theme = useTheme();

  let displayName = 'Inactive';
  if (ParticipantStatus.isPending(status)) {
    displayName = 'Pending';
  } else if (ParticipantStatus.isActive(status)) {
    displayName = 'Confirmed';
  } else if (ParticipantStatus.isOnHold(status)) {
    displayName = 'On Hold';
  } else if (ParticipantStatus.isCompleted(status)) {
    displayName = 'Complete';
  } else if (ParticipantStatus.isFormer(status)) {
    displayName = 'Former';
  } else if (ParticipantStatus.isInvited(status)) {
    displayName = 'Invited';
  } else if (ParticipantStatus.isLead(status)) {
    displayName = 'Lead';
  } else if (ParticipantStatus.isRemoved(status)) {
    displayName = 'Removed';
  }

  return (
    <div className={`${styles.wrapper} ${className || ''}`}>
      <StatusBall
        marginRight={marginRight}
        size={size}
        status={status}
      />
      <div style={{ fontSize: fontSize || '14px', lineHeight: 'initial' }}>{displayName}</div>
    </div>
  );
}