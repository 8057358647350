import { getTermsAndConditions, saveTermsAndConditions } from 'api/serviceProviderApi';
import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { ITermsAndConditionsData, createEmptyTermsAndConditionsForm } from 'model/termsAndConditions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type TermsAndConditionsData = {
  termsAndConditionsData: ITermsAndConditionsData;
  agreedToPartnership:boolean;
}

const loadingKey = 'termsAndConditions';

const { get, update, registerListener, unregisterListener } = createStore<TermsAndConditionsData>('termsAndConditions', {
  termsAndConditionsData: createEmptyTermsAndConditionsForm(),
  agreedToPartnership: false,
});

export default function useTermsAndConditions() {
  const setState = useState(get())[1];
  const { serviceProviderId } = useParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const navigate = useNavigate();
  const { trackButtonClick } = useStreetfairAnalytics();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    await refresh();
    doneLoading(300);
  }

  async function refresh() {
    if (serviceProviderId) {
      const serviceProviderRes = await getTermsAndConditions(serviceProviderId);
      if (serviceProviderRes.data) {
        update({
          ...get(),
          termsAndConditionsData: serviceProviderRes.data,
        });
      }
    }
  }

  async function save(data:ITermsAndConditionsData) {
    if (serviceProviderId) {
      onLoading();
      void trackButtonClick('Terms_and_Conditions_Agreement');
      await saveTermsAndConditions(serviceProviderId, data);
      await refresh();
      doneLoading(300);
    }
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    init,
    save,
  };
}