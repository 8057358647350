import { GridCallbackDetails, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import { findCustomersByServiceProviderId } from 'api/customerApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import useSelf from 'hooks/useSelf';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { CustomerActionItems } from 'model/actionItems';
import { ICustomerSearchDTO, ICustomerTableRow, createEmptyCustomerSearchDTO } from 'model/customerTable';
import { IDropdownOption, UNSELECTED_OPTION, createDefaultDropdownOption } from 'model/dropdown';
import { ParticipantStatus } from 'model/status';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import logPageView from 'util/analyticsUtil';
import { dedupe } from 'util/arrayUtil';
import { containsProviderAdmin } from 'util/role';
import { sortByString } from 'util/sortFunctions';

const loadingKey = 'customerTable';
type CustomerTableData = {
  customers: ICustomerTableRow[];
  filteredCustomers:ICustomerTableRow[];
  customerStatusSelectionText:string;
  filter: ICustomerSearchDTO;
  sortModel:any;
  customerStatusOptions: IDropdownOption[];
  neighborhoodOptions:IDropdownOption[];
  actionItemOptions:IDropdownOption[];
  customersToContactCount:number;
  customersToConfirmCount:number;
  customersToCompleteCount:number;
  customersToQuoteCount:number;
  filterModel:any;
}

const { get, update, registerListener, unregisterListener } = createStore<CustomerTableData>('customerTable', {
  customers: [],
  filteredCustomers: [],
  customerStatusSelectionText: '',
  filter: createEmptyCustomerSearchDTO(),
  sortModel: [{
    field: 'name',
    sort: 'asc' as any,
  }],
  filterModel: { items: [] },
  customerStatusOptions: [createDefaultDropdownOption(), ...ParticipantStatus.statuses()],
  neighborhoodOptions: [createDefaultDropdownOption()],
  actionItemOptions: [createDefaultDropdownOption(), ...CustomerActionItems.actionItems()],
  customersToContactCount: 0,
  customersToConfirmCount: 0,
  customersToCompleteCount: 0,
  customersToQuoteCount: 0,
});

const quickSearchStorageKey = 'providerCustomers';
export default function useCustomerTable() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { createInfoToast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { trackButtonClick } = useStreetfairAnalytics();
  const { serviceProviderId } = useParams();
  const { roles } = useSelf();


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  useEffect(() => {
    setCustomerStatusOptions();
  }, [roles]);

  function setCustomerStatusOptions() {
    const next = [createDefaultDropdownOption(), ...ParticipantStatus.statuses()];
    let isProviderAdmin = containsProviderAdmin(roles);
    if (isProviderAdmin) {
      next.push({
        key: ParticipantStatus.HOLD,
        optionValue: ParticipantStatus.HOLD,
        optionText: 'On Hold',
      });
    }
    update({
      ...get(),
      customerStatusOptions: next,
    });
  }

  async function init() {
    onLoading();
    logPageView(serviceProviderId);

    if (searchParams.has('serviceDate')) {
      const serviceDate = searchParams.get('serviceDate') as string;
      //We may or may not add this back. Delete forever (and any related code) after jan 1 2023
      // window.sessionStorage.setItem('serviceDate', serviceDate);
      update({
        ...get(),
        filterModel: {
          items: [{ columnField: 'serviceDate', operatorValue: 'contains', value: serviceDate }],
        },
      });
    } else {

      update({
        ...get(),
        filter: createEmptyCustomerSearchDTO(),
        filterModel: {
          items: [],
        },
      });
    }
    await refresh();
    doneLoading();
  }

  async function refresh() {
    try {
      if (serviceProviderId) {
        const { filter } = get();
        let customersRes = await findCustomersByServiceProviderId(serviceProviderId, filter);
        let customers = customersRes.data || [];
        let sortedCustomers = sortByStatusPendingFirst(customers);
        let neighborhoodOptions = extractNeighborhoods(sortedCustomers);

        const filteredCustomers = filterData(sortedCustomers, filter);
        let _customersToContactCount = customers.filter( x => x.needsContact).length;
        let _customersToConfirmCount = customers.filter( x => x.needsConfirmation).length;
        let _customersToCompleteCount = customers.filter( x => x.needsCompleted).length;
        let _customersToQuoteCount = customers.filter( x => x.needsQuote && !x.needsContact).length;
        update({
          ...get(),
          customers: sortedCustomers,
          filteredCustomers,
          neighborhoodOptions,
          filter,
          customersToContactCount: _customersToContactCount,
          customersToConfirmCount: _customersToConfirmCount,
          customersToCompleteCount: _customersToCompleteCount,
          customersToQuoteCount: _customersToQuoteCount,
          customerStatusSelectionText: getCustomerStatusSelectionText(filter),
        });

      } else {
        createInfoToast('No ID for service provider.');
      }
    } catch (e:any) {
      console.error(e);
    }
  }


  function sortByStatusPendingFirst(customers:ICustomerTableRow[]):ICustomerTableRow[] {
    customers.sort((a, b) => {
      let aSortValue = getStatusSortVal(a.status);
      let bSortValue = getStatusSortVal(b.status);
      if (aSortValue < bSortValue) {
        return -1;
      }
      if (aSortValue > bSortValue) {
        return 1;
      }
      return 0;
    });
    return customers;
  }

  function extractNeighborhoods(customers:ICustomerTableRow[]):IDropdownOption[] {
    const optionsWithDupes = customers
      .map( x=> ({ key: x.neighborhoodId, optionValue: x.neighborhoodId, optionText: x.neighborhoodName }))
      .filter( x=> x.key && x.optionText);
    const deduped = dedupe(optionsWithDupes, 'key');
    let result = Array.from(deduped);
    result.unshift(createDefaultDropdownOption());
    result.sort(sortByString('optionText'));
    return result;
  }

  function onStatusClose() {
    update({
      ...get(),
      customerStatusSelectionText: getCustomerStatusSelectionText(get().filter),
    });
  }

  function onStatusChange(value:any) {
    const { filter, customers } = get();
    const nextFilter = { ...filter };
    if (value.indexOf('UNSELECTED') > -1) {
      nextFilter.customerStatuses = [...ParticipantStatus.statuses().map(x => x.optionValue)];
    } else {
      nextFilter.customerStatuses = value;
    }
    const filteredCustomers = filterData(customers, nextFilter);
    update({
      ...get(),
      filter: nextFilter,
      filteredCustomers,
    });
  }

  function getCustomerStatusSelectionText(filter:ICustomerSearchDTO) {
    let { customerStatusOptions } = get();
    let filteredStatuses = customerStatusOptions.filter(x => {
      return filter.customerStatuses.indexOf(x.optionValue) > -1;
    });
    if (filteredStatuses.length === customerStatusOptions.length) {
      return 'All';
    } else if (filteredStatuses.length === 0) {
      return 'None selected';
    } else {
      return filteredStatuses.sort(sortByStatus).map(x => x.optionText).join(',');
    }
  }

  function sortByStatus(a:IDropdownOption, b:IDropdownOption) {
    let aSortValue = getStatusSortVal(a.optionValue);
    let bSortValue = getStatusSortVal(b.optionValue);

    if (aSortValue < bSortValue) {
      return -1;
    }
    if (aSortValue > bSortValue) {
      return 1;
    }
    return 0;
  }

  function getStatusSortVal(value:string) {
    if (value === 'PENDING') {
      return -1;
    }
    if (value === 'ACTIVE') {
      return 0;
    }
    if (value === 'COMPLETE') {
      return 1;
    }
    if (value === 'REMOVED') {
      return 2;
    }
    return 3;
  }

  function onTableFilterChange(propertyName, propertyValue) {
    const { filter, customers } = get();
    const nextFilter = { ...filter };
    nextFilter[propertyName] = propertyValue;
    _updateWithFilter(nextFilter);
  }

  function filterData(customers:ICustomerTableRow[], newFilter:ICustomerSearchDTO) {
    const filteredCustomers = customers.filter(x => {
      let statusPass = false;
      let neighborhoodPass = false;
      let actionItemPass = false;
      if (newFilter.customerStatuses.indexOf(UNSELECTED_OPTION) > -1) {
        statusPass = true;
      } else {
        statusPass = newFilter.customerStatuses.indexOf(x.status) > -1;
      }
      if (newFilter.neighborhoodId === UNSELECTED_OPTION) {
        neighborhoodPass = true;
      } else {
        neighborhoodPass = newFilter.neighborhoodId === x.neighborhoodId;
      }
      if (newFilter.actionItem === UNSELECTED_OPTION) {
        actionItemPass = true;
      } else {
        actionItemPass = CustomerActionItems.matchesActionItem(x, newFilter.actionItem);
      }
      return statusPass && neighborhoodPass && actionItemPass;
    });
    return filteredCustomers;
  }


  function onSortModelChange(sortModel) {
    update({
      ...get(),
      sortModel,
    });
  }

  function navigateToCustomerDetail(params:GridRowParams, event:MuiEvent<React.MouseEvent>, details:GridCallbackDetails) {
    if (params.row.recurring) {
      navigate(`/v1/${serviceProviderId}/subscribers/${params.row.id}`);
    } else {
      navigate(`/v1/${serviceProviderId}/customers/${params.row.id}`);
    }
  }


  function resetFiltersToDefault() {
    let nextFilter = createEmptyCustomerSearchDTO();
    window.sessionStorage.removeItem('serviceDate');
    updateGridFilterModel({ items: [] });
    searchParams.delete('serviceDate');
    setSearchParams(searchParams);
    _updateWithFilter(nextFilter);
  }

  function onClickViewCustomersToContact(e:React.SyntheticEvent) {
    void trackButtonClick(ButtonClickTypes.CUSTOMERS_TABLE_NEEDS_CONTACT);
    let customersToContactFilter = createEmptyCustomerSearchDTO();
    customersToContactFilter.customerStatuses = [ParticipantStatus.PENDING];
    customersToContactFilter.actionItem = CustomerActionItems.NEEDS_CONTACTED;
    _updateWithFilter(customersToContactFilter);
  }

  function onClickViewCustomersToQuote(e:React.SyntheticEvent) {
    void trackButtonClick(ButtonClickTypes.CUSTOMERS_TABLE_NEEDS_QUOTE);
    let customersToContactFilter = createEmptyCustomerSearchDTO();
    customersToContactFilter.customerStatuses = [ParticipantStatus.PENDING];
    customersToContactFilter.actionItem = CustomerActionItems.NEEDS_QUOTE;
    _updateWithFilter(customersToContactFilter);
  }

  function onClickViewCustomersToConfirm(e:React.SyntheticEvent) {
    void trackButtonClick(ButtonClickTypes.CUSTOMERS_TABLE_NEEDS_CONFIRMATION);
    let customersToConfirmFilter = createEmptyCustomerSearchDTO();
    customersToConfirmFilter.customerStatuses = [ParticipantStatus.PENDING];
    customersToConfirmFilter.actionItem = CustomerActionItems.NEEDS_CONFIRMATION;
    _updateWithFilter(customersToConfirmFilter);
  }

  function onClickViewCustomersToMarkComplete(e:React.SyntheticEvent) {
    void trackButtonClick(ButtonClickTypes.CUSTOMERS_TABLE_NEEDS_COMPLETE);
    let customerToCompleteFilter = createEmptyCustomerSearchDTO();
    customerToCompleteFilter.customerStatuses = [ParticipantStatus.ACTIVE];
    customerToCompleteFilter.actionItem = CustomerActionItems.NEEDS_COMPLETED;
    _updateWithFilter(customerToCompleteFilter);

  }

  function _updateWithFilter(nextFilter:ICustomerSearchDTO) {
    const { customers } = get();
    const filteredCustomers = filterData(customers, nextFilter);
    update({
      ...get(),
      filter: nextFilter,
      filteredCustomers,
      customerStatusSelectionText: getCustomerStatusSelectionText(nextFilter),
    });
  }

  function updateGridFilterModel(nextFilterModel:any) {
    update({ ...get(), filterModel: nextFilterModel });
  }

  return {
    loadingKey,
    quickSearchStorageKey,
    ...get(),
    init,
    refresh,
    onTableFilterChange,
    onSortModelChange,
    navigateToCustomerDetail,
    onClickViewCustomersToContact,
    onClickViewCustomersToQuote,
    onClickViewCustomersToConfirm,
    onClickViewCustomersToMarkComplete,
    resetFiltersToDefault,
    onStatusChange,
    onStatusClose,
    updateGridFilterModel,
  };
}