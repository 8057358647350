import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Grid, Typography } from '@mui/material';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { HookCheckbox } from 'components/reactHookForm';
import { IServiceOffering } from 'model/serviceOffering';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DemoUtil } from 'util/demoUtil';
import useGroupDeal from '../hookStore/useGroupDeal';
import DiscountScheduleSelect from './DiscountScheduleSelect';
import MaxCustomerCountField from './MaxCustomerCountField';
import NeighborhoodPricingField from './NeighborhoodPricingField';
import NeighborhoodSelect from './NeighborhoodSelect';
import ServiceDateField from './ServiceDateField';
import ServiceSelect from './ServiceSelect';
import ZipCodeField from './ZipCodeField';
import styles from './formComponents.module.css';


function shouldShowMaxCustomerCount(serviceOffering:IServiceOffering | null) {
  return serviceOffering !== null && serviceOffering.hasDisabledMaxCustomerCount !== true;
}

export default function BasicFields() {
  const formCtx = useFormContext();
  const { groupDealId } = useParams();
  const isNew = groupDealId === 'new';
  const { findServiceOffering, doesNeighborhoodHaveTerritoryEnabled, isEditing, isProviderAdmin, editingClicked, nsoi } = useGroupDeal();
  const { openModal } = useConfirmationModal('requestNeighborhoodModal');
  const watchedTsoi = useWatch({ control: formCtx.control, name: 'territoryEnabled' });
  const watchedServiceOfferingId = useWatch({ control: formCtx.control, name: 'serviceOfferingId' });
  const watchedNeighborhoodId = useWatch({ control: formCtx.control, name: 'neighborhoodId' });
  const serviceOffering = findServiceOffering(watchedServiceOfferingId);

  const _doesNeighborhoodHaveTerritoryEnabled = doesNeighborhoodHaveTerritoryEnabled(serviceOffering, watchedNeighborhoodId);
  let watchedSuperDeal = useWatch({ control: formCtx.control, name: 'superDeal' });

  return (
    <Grid container alignContent='flex-start'>
      {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && (
        <>
          <HookCheckbox
            name='vendorDriven'
            label='Is Vendor Driven'
            extraProps={{
              disabled: isEditing,
            }}
          />
          <HookCheckbox
            name='territoryEnabled'
            label='TSOI?'
            extraProps={{
              disabled: !editingClicked,
            }}
          />
          <HookCheckbox
            name='superDeal'
            label='Super Deal?'
            extraProps={{

            }}
          />
          <Grid item xs={12} textAlign='left'>
            <HookCheckbox
              name='shouldSendHappeningSoonAlert'
              label='Send Happening Soon Alert'
              extraProps={{
                disabled: !editingClicked,
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign='left'>
            <HookCheckbox
              name='shouldCloseGroupDeal'
              label='Close Group Deal'
            />
          </Grid>
          {/* <Grid item xs={12} textAlign='left'>
            <HookCheckbox
              name='shouldSendPriceDropAlert'
              label='Send Price Drop Alert'
              extraProps={{
                disabled: !editingClicked,
              }}
            />
          </Grid> */}
          <DiscountScheduleSelect/>
        </>
      )}
      <ServiceDateField />
      {shouldShowMaxCustomerCount(serviceOffering) && (
        <MaxCustomerCountField className={styles.maxCustomerCountFieldWrapper} />)
      }
      <ServiceSelect />
      <ZipCodeField />
      <Grid item xs={12}>
      </Grid>
      <NeighborhoodSelect />
      {!nsoi.zipCodeTerritory &&
        <Grid item xs={12}>
          <NeighborhoodPricingField />
        </Grid>
      }
      {/* hiding this since it has confused some service providers */}
      {/* {!isNew && (
        <Grid container item xs={12} textAlign='left' alignItems='center' sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Grid item xs='auto' sx={{ paddingBottom: '2px' }}>
            <a target="_blank"
              style={{ fontSize: '16px' }}
              href={`${window.REACT_APP_STREETFAIR_URL}/provider-view/neighborhood-service-offering/${nsoi.neighborhoodServiceOfferingId}/service-instance/${nsoi.id}`}
            >
              <>View full listing here</>
            </a>
          </Grid>
        </Grid>
      )} */}
      {!isEditing && (<Grid container item xs={12}
        alignItems='center'
        className={styles.requestNeighborhoodWrapper}
        onClick={openModal}
      >
        <Grid item xs='auto'>
          <LiveHelpIcon color='primary' fontSize="small" />
        </Grid>

        <Grid item xs='auto' >
          <Typography variant='subtitle2' className={styles.greenText} color='primary' >Request an unlisted neighborhood</Typography>
        </Grid>
      </Grid>
      )}

    </Grid>
  );
}