import { Button, Grid, Grow } from '@mui/material';
import { useForm } from 'react-hook-form';
import useConvertCustomerModalStore from '../hookStore/useConvertCustomerModal';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import SlideTitle from './components/SlideTitle';
import styles from './convertCustomerModal.module.css';
import ConvertCustomerForm from './form/ConvertCustomerForm';
import { useLoading } from 'components/layout/Loading';
import ModalFrame from 'components/modals/ModalFrame';
import { ICustomerServiceDetails } from 'model/customer';
import { getRandomInt } from 'util/randomUtil';

const overrideStyles = {
  [`& .${styles.postConfirmModal}.MuiPaper-root`]: {
    width: {
      xs: '100%',
      sm: 'auto',
    },
    height: {
      xs: '100%',
      sm: 'auto',
    },
    borderRadius: {
      xs: '0',
      sm: '12px',
    },
  },
  [`& .${styles.postConfirmModal}.MuiCardContent-root`]: {
    padding: {
      xs: '12px',
      sm: '24px',
    },
    minWidth: {
      xs: 'auto',
      sm: '600px',
    },
    maxWidth: {
      sm: '700px',
    },

  },
  [`& .${styles.postConfirmModal}.MuiButton-root`]: {
    width: {
      xs: '100%',
      sm: 'auto',
    },
  },
};

export default function ConvertCustomerModal() {
  const { convertCustomerModalKey, loadingKey } = useConvertCustomerModalStore();
  const {
    customer,
    provider,
    customerServiceDetails,
    isSelectedOptionOneTime,
  } = useCustomerDetail();

  const { getLoadingState } = useLoading(loadingKey);
  const { isLoading } = getLoadingState();
  const formContext = useForm<ICustomerServiceDetails>({ mode: 'onTouched', defaultValues: customerServiceDetails });

  return (
    <ModalFrame
      modalKey={convertCustomerModalKey}
      modalClassName={styles.postConfirmModal}
      cardContentClassName={styles.postConfirmModal}
      cardClassName={styles.postConfirmModal}
      overrideStyles={overrideStyles}
    >
      <div>
        <Grid container justifyContent='center' >
          <Grid container item xs={12} justifyContent='center'>
            <SlideTitle>
              Convert Customer to Subscriber
            </SlideTitle>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <ConvertCustomerForm/>
          </Grid>

        </Grid>
      </div>

    </ModalFrame>
  );
}