import { getAutocompleteForAddress } from 'api/googleApi';
import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import debounce from 'lodash.debounce';
import { IDropdownOption, createPlaceholderDropdownOption } from 'model/dropdown';
import { IAutofillAddress } from 'model/google';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type AddressAutocomplete = {
  addresses:IDropdownOption[];
  isLoadingData:boolean;
  typedAddresses:Map<string, string | null>;
}

const { get, update, registerListener, unregisterListener } = createStore<AddressAutocomplete>('addressAutocomplete', {
  addresses: [createPlaceholderDropdownOption()],
  isLoadingData: false,
  typedAddresses: new Map<string, string | null>(),
});

const loadingKey = 'addressAutocomplete';

export default function useAddressAutocomplete() {
  const setState = useState(get())[1];
  const { serviceProviderId } = useParams();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function init() {
    update({
      addresses: [createPlaceholderDropdownOption()],
      isLoadingData: false,
      typedAddresses: new Map<string, string | null>(),
    });
  }

  const debounceAutocomplete = debounce(async (address, formContext, propertyPath, onNewRecommendations?:(recommendations: IAutofillAddress[]) => void):Promise<any> => {
    if (serviceProviderId) {
      const { typedAddresses } = get();
      typedAddresses.set(propertyPath, address.address);
      update({ ...get(), isLoadingData: true });
      let recommendations:IAutofillAddress[] = [];
      if (window.REACT_APP_NODE_ENV !== 'local') {
        const res = await getAutocompleteForAddress(serviceProviderId, address);
        recommendations = res.data.recommendations;
        if (onNewRecommendations) {
          onNewRecommendations(recommendations);
        }
      }
      let addresses:IDropdownOption[] = recommendations.map((x, i) => ({
        key: i.toString(),
        optionValue: x.streetAddress,
        optionText: x.description,
        ancillary: x,
      }));
      update({
        ...get(),
        addresses,
        isLoadingData: false,
      });
    }
  }, 500);

  function postClear(propertyPath) {
    const { typedAddresses } = get();
    typedAddresses.set(propertyPath, null);
    update({
      ...get(),
      typedAddresses,
    });
  }

  return {
    loadingKey: loadingKey,
    ...get(),
    debounceAutocomplete,
    postClear,
    init,
  };
}