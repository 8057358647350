import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import store from './customerDetailDataStore';
import { hardDeleteParticipant } from 'api/customerApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import { IHardDeleteParticipantDTO } from 'model/customer';

const { get, update, registerListener, unregisterListener } = store;

const loadingKey = 'hardDeleteCustomer';

export default function useHardDeleteCustomer() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();
  const navigate = useNavigate();
  const { serviceProviderId } = useParams();
  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function onHardDeleteParticipant(data:IHardDeleteParticipantDTO) {
    onLoading();
    try {
      await hardDeleteParticipant(data);
      createSuccessToast('Customer deleted.');
      navigate(`/v1/${serviceProviderId}/customers`);
    } catch (e:any) {
      console.error(e);
      createErrorToast(e);
    }
    doneLoading();
  }

  return {
    ...get(),
    onHardDeleteParticipant,
  };
}