import Axios, { AxiosResponse } from 'axios';
import { IGroupDealForm, IMoveToGroupDealForm } from 'model/groupDeal';
import { IMovableGroupDealsDto, INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';

const apiUrl = window.REACT_APP_BASE_API_URI;


export function findForProviderByNeighborhoodServiceOffering(serviceProviderId:string, neighborhoodServiceOfferingId:string, data: IMovableGroupDealsDto)
  :Promise<AxiosResponse<INeighborhoodServiceOfferingInstance[]>> {
  return Axios.post(`${apiUrl}/serviceInstance/v2/serviceProviders/${serviceProviderId}/nsois/${neighborhoodServiceOfferingId}`, data);
}

export function findServiceInstancesByProviderAndNeighborhoodServiceOffering(providerId:string, neighborhoodServiceOfferingId:string) {
  return Axios.get(`${apiUrl}/serviceInstance/v2/serviceProvider/${providerId}/findByProviderAndNeighborhoodServiceOffering/${neighborhoodServiceOfferingId}`);
}

export function findServiceInstancesByServiceProviderId(serviceProviderId:string, queryParams:any) {
  return Axios.get(`${apiUrl}/serviceInstance/v2/serviceProviders/${serviceProviderId}`, {
    params: queryParams,
  });
}

export function findServiceInstanceById(serviceProviderId:string, serviceInstanceId:string) {
  return Axios.get(`${apiUrl}/serviceInstance/v2/serviceProviders/${serviceProviderId}/find/${serviceInstanceId}`);
}

export function saveGroupDeal(data:IGroupDealForm, serviceProviderId:string) {
  return Axios.post(`${apiUrl}/serviceInstance/v2/serviceProviders/${serviceProviderId}/saveGroupDeal`, data);
}

export function moveGroupDeal(data:IMoveToGroupDealForm, serviceProviderId:string) {
  return Axios.post(`${apiUrl}/serviceInstance/v2/serviceProviders/${serviceProviderId}/moveGroupDeal`, data);
}

export function deleteGroupDeal(serviceProviderId:string, serviceInstanceId:string) {
  return Axios.delete(`${apiUrl}/serviceInstance/v2/serviceProviders/${serviceProviderId}/groupDeals/${serviceInstanceId}`);
}
