import { IDiscountSchedule, IDiscountTableRow, IPricingSchedule } from 'model/discount';

/**
 *
 * @param discountTable
 * @returns Returns the current discount given the discount table. Defaults to the first item in the table if not current row or 0 if the discount table is not defined.
 */
export function calculateDiscount(discountTable:IDiscountTableRow[], shouldIncludeCurrentCustomerInDiscount:boolean):number {
  for (let i = 0; i < discountTable.length;i++) {
    let x = discountTable[i];
    if (shouldIncludeCurrentCustomerInDiscount && x.currentRow && x.unformattedAmount) {
      if (i+1 >= discountTable.length) {
        return x.unformattedAmount;
      }
      return (discountTable[i+1] as any).unformattedAmount;
    }
    if (x.currentRow && x.unformattedAmount) {
      return x.unformattedAmount;
    }
  }
  if (discountTable && discountTable[0] && discountTable[0].unformattedAmount) {
    return discountTable[0].unformattedAmount;
  }
  return 0;
}

export function calculateDiscountForPricingSchedule(discountTable:IDiscountTableRow[], pricingSchedule:IPricingSchedule, shouldIncludeCurrentCustomerInDiscount:boolean) {
  const index = calculateDiscountIndex(discountTable, shouldIncludeCurrentCustomerInDiscount);
  let _discount = pricingSchedule.decrement ? pricingSchedule.decrement * (index) : 0;
  return _discount;
}

/**
 * If current customer/subscriber is pending then we'd need to show what discount they'd be getting once they're confirmed
 * @param discountTable
 * @param shouldIncludeCurrentCustomerInDiscount
 * @returns
 */
export function calculateDiscountIndex(discountTable:IDiscountTableRow[], shouldIncludeCurrentCustomerInDiscount:boolean):number {
  for (let i = 0; i < discountTable.length;i++) {
    let x = discountTable[i];
    if (shouldIncludeCurrentCustomerInDiscount && x.currentRow) {
      if (i+1 >= discountTable.length) {
        return i;
      }
      return i+1;
    }
    if (x.currentRow) {
      return i;
    }
  }
  return 0;
}

/**
 * Calculates the final price based on the offset from the current discount level
 * @param discountTable
 * @param offset how many discount levels to offset
 * @returns the unformatted amount of the discount calculated from the offset of the current discount.
 * If the offset forces the value out of bounds of the discount table it will take either the first row or last row depending on the direction of the offset.
 */
export function calculateDiscountWithOffset(discountTable:IDiscountTableRow[], offset:number):number {
  if (offset < 0 && Math.abs(offset) >= discountTable.length) {
    //if offset is negative and greater than the length of the table then return theh first row's amount
    return discountTable[0].unformattedAmount || 0;
  }
  let targetIndex = -1;
  for (let i = 0; i < discountTable.length; i++) {
    let cur = discountTable[i];
    if (cur.currentRow) {
      targetIndex = i + offset;
      if (targetIndex <= 0) {
        return discountTable[0].unformattedAmount || 0;
      }
    }
    if (cur.unformattedAmount && (targetIndex === i || (targetIndex >= discountTable.length && i == discountTable.length - 1))) {
      return cur.unformattedAmount;
    }
  }
  return discountTable[0].unformattedAmount || 0;
}

export function formatDiscountAmount(discountAmount:string, discountSchedule:IDiscountSchedule) {
  let priceSuffix = '';
  if (discountSchedule.priceSuffix) {
    priceSuffix = discountSchedule.priceSuffix.replace('per ', '/');
  }
  if (discountSchedule.type === 'DollarPerCustomer' && discountSchedule.pricingSchedule) {
    return `$${discountSchedule.pricingSchedule.decrement}${priceSuffix}`;
  } else {
    return `$${discountAmount}${priceSuffix}`;
  }
}