import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio } from '@mui/material';
import HookRadioField from 'components/reactHookForm/HookRadioField';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import styles from './confirmCustomerDrawer.module.css';

export const ADDITIONAL_SERVICES_YES = 'YES';
export const ADDITIONAL_SERVICES_NO = 'NO';
export const ADDITIONAL_SERVICES_LATER = 'LATER';

export const ADDITIONAL_SERVICES_YES_DESC = 'Yes';
export const ADDITIONAL_SERVICES_NO_DESC = 'No, no longer interested';
export const ADDITIONAL_SERVICES_LATER_DESC = 'Not yet, I will schedule later';
export default function AdditionalServicesFollowUp() {
  const {
    additionalServiceItems,
  } = useCustomerDetail();
  const formContext = useFormContext();
  if (additionalServiceItems.length === 0) {
    return null;
  }
  function onChange(e) {
    formContext.setValue('additionalServicesFollowUp', e.target.value);
  }

  const { isError, getErrorMessage } = useErrorState(formContext);

  return (
    <div className={styles.serviceItemCard} style={{ padding: '20px' }}>
      <FormControl fullWidth error={isError('additionalServicesFollowUp')}>
        <FormLabel id="honor-group-deal-radio-buttons-label">Were you able to schedule the additional service(s) requested?</FormLabel>
        <HookRadioField
          horizontal
          name='additionalServicesFollowUp'
          required
          defaultValue={ADDITIONAL_SERVICES_LATER}
        >
          <FormHelperText sx={{ paddingLeft: '0px', marginLeft: '0px' }}>{getErrorMessage('additionalServicesFollowUp')}</FormHelperText>
          <Box sx={{ width: '100%' }}>
            <FormControlLabel value={ADDITIONAL_SERVICES_YES} control={<Radio onChange={onChange}/>} label={ADDITIONAL_SERVICES_YES_DESC} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <FormControlLabel value={ADDITIONAL_SERVICES_NO} control={<Radio onChange={onChange}/>} label={ADDITIONAL_SERVICES_NO_DESC} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <FormControlLabel value={ADDITIONAL_SERVICES_LATER} control={<Radio onChange={onChange}/>} label={ADDITIONAL_SERVICES_LATER_DESC}/>
          </Box>
        </HookRadioField>

      </FormControl>
    </div>
  );

  return (
    <Box>

    </Box>
  );
}