import { Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { IServiceCardProps } from '.';
import styles from './serviceCard.module.css';
import ServiceCardAvatar from 'components/cardAvatar';
import cardShadow from 'images/happen-soon-shadow.png';
import { formatDay, formatMonth, getTimeRemaining } from 'util/dateUtil';
import { calculateSpotsLeft, shouldShowBookNowWarning, shouldShowSpotsLeftBanner } from 'util/serviceInstanceAvailabilityUtils';


/**
 *
 * @param altColor used to switch to the alt theme of the service card
 * @param customers this parameter has the customers already on the job for the service date. Optional but defaults to []
 * @param customerCount derived from ServiceInstance. Optional but effectively defaults to 0
 * @param spotsLeftThreshold define when the spots available banner appears as a result of comparing number of spots left to this number.
 * (e.g. if (maxCustomerCount - customerCount) < 4 then show banner)
 * @param maxCustomerCount derived from ServiceInstance. Optional but effectively defaults to 0
 * @returns
 */
export default function ServiceCard({
  id,
  altColor,
  customers,
  customerCount,
  beginServiceDate,
  endServiceDate,
  cutoffDayCount,
  serviceProvider,
  serviceType,
  maxCustomerCount,
  maxAvatarIcons,
  avatarIconStyleOverride,
  onClick,
  className,
}:IServiceCardProps) {
  const theme = useTheme();
  const _maxAvatarIcons = maxAvatarIcons || 5;
  const cardBackgroundColor = '#FFFFFF';
  const calendarTitleColor = altColor ? theme.palette.secondary.darker : theme.palette.tertiary.main;
  let extraCustomers = 0;
  if (customers && customers.length > 0) {
    extraCustomers = Math.max(customers.length - _maxAvatarIcons, 0);
  }
  const getTimeLeftToBook = () => {
    return getTimeRemaining(beginServiceDate, cutoffDayCount);
  };
  const showSpotsLeft = shouldShowSpotsLeftBanner(customerCount, maxCustomerCount) || shouldShowBookNowWarning(beginServiceDate, cutoffDayCount);
  const shouldShowSoldOut = maxCustomerCount !== null && maxCustomerCount !== undefined && calculateSpotsLeft(customerCount, maxCustomerCount) <= 0;
  const isBookingPeriodOpen = getTimeLeftToBook() !== 0;
  const availabilityColor = (showSpotsLeft || shouldShowSoldOut || !isBookingPeriodOpen) ? theme.palette.tertiary.red : cardBackgroundColor;

  let month, beginDay, endDay;
  if (beginServiceDate && endServiceDate) {
    month = formatMonth(beginServiceDate);
    beginDay = formatDay(beginServiceDate);
    endDay = formatDay(endServiceDate);
  } else {
    month = '???';
    beginDay = '??';
    endDay = '??';
  }
  return (
    <Paper
      id={id}
      elevation={3}
      style={{
        background: availabilityColor,
        boxSizing: 'border-box',
        boxShadow: 'none',
        border: 'none',
      }}
      className={`${styles.outerPaper} ${(showSpotsLeft || shouldShowSoldOut) && styles.spotsAvailable} ${className}`}
      onClick={onClick}
      sx={{
        textAlign: 'center',
      }}
    >
      <Paper
        elevation={4}
        style={{
          backgroundColor: cardBackgroundColor,
          backgroundImage: `url(${cardShadow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left bottom',
          boxSizing: 'border-box',
          boxShadow: '0px 0px 10px rgba(0,0,0,0.18)',
        }}
        className={styles.innerPaper}
      >
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs='auto'>
            <Paper elevation={8} style={{ background: calendarTitleColor, boxShadow: 'none', textAlign: 'center' }} className={styles.outerCalendar}>
              <Typography variant='body2' component='div'>{month}</Typography>
              <Paper className={styles.innerCalendar}>
                {beginDay !== endDay ?
                  (
                    <Typography variant='subtitle2'>{beginDay}-{endDay}</Typography>
                  ) :
                  (
                    <Typography variant='subtitle2'>{beginDay}</Typography>
                  )}
              </Paper>
            </Paper>
          </Grid>
          <Grid container item xs alignItems='center'>
            <Grid item xs={12} textAlign='left'>
              <Typography className='happeningSoonCardServiceType' variant='subtitle2' component='span'>{serviceType}</Typography>
            </Grid>
            <Grid item xs={12} textAlign='left'>
              <Typography variant='body2' component='div'><span className={styles.by}>By</span> {serviceProvider ? serviceProvider.name : ''}</Typography>
            </Grid>
            <Grid item xs='auto'>
              <Stack direction='row' className={styles.avatarGroup} alignItems='center'>
                {
                  customers && customers.filter((x, i) => i < _maxAvatarIcons).map((x, i) => {
                    return (
                      <ServiceCardAvatar
                        index={i}
                        key={`${x.id}`}
                        firstName={x.firstName}
                        lastName={x.lastName}
                        avatarIconStyleOverride={avatarIconStyleOverride}
                        className='happeningSoonServiceCardAvatar'/>
                    );
                  })
                }
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {extraCustomers > 0 && <Typography>+{extraCustomers} Neighbors</Typography>}
            </Grid>
            <Grid item xs={12}>

            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {!isBookingPeriodOpen && (
        <Typography variant='caption' className={styles.availability}>
          <span className='happeningSoonPastCutoffDate'>Booking Period Closed</span>
        </Typography>
      )}
      {((showSpotsLeft || shouldShowSoldOut) && isBookingPeriodOpen) && (
        <Typography variant='caption' className={styles.availability}>
          { shouldShowSoldOut &&
                <span className="happeningSoonSoldOut">
                    Sold out!
                </span>
          }
          { !shouldShowSoldOut && shouldShowBookNowWarning(beginServiceDate, cutoffDayCount) &&
                        <span className='happeningSoonSignUpEnds'>
                            Sign up ends in <span>{getTimeLeftToBook()}</span>
                        </span>
          }
          {!shouldShowBookNowWarning(beginServiceDate, cutoffDayCount) && calculateSpotsLeft(customerCount, maxCustomerCount) > 0 &&
                        <span className='happeningSoonOnlyXSpotsLeft'>
                            Only {calculateSpotsLeft(customerCount, maxCustomerCount)} {calculateSpotsLeft(customerCount, maxCustomerCount) > 1 ? 'spots' : 'spot'} left!
                        </span>
          }
        </Typography>
      )}
    </Paper>
  );
}