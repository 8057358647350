import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { FormControl, FormHelperText, Grid, InputAdornment } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import { HookDatePicker } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { ParticipantStatus } from 'model/status';

export default function SnoozeDate() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('snoozeDate')}
    // className={styles.serviceDatePicker}
      sx={{ paddingTop: '8px' }}
    >
      <Grid container alignItems='center'>
        <Grid item xs>
          <HookDatePicker
            label='Snooze Until'
            name='snoozeDate'
            textFieldProps={{
              fullWidth: true,
              variant: 'standard',
            }}
            datePickerProps={{
              onAccept: () => {},
            }}
          />
        </Grid>
      </Grid>
      <FormHelperText>{getErrorMessage('snoozeDate')}</FormHelperText>
    </FormControl>
  );
}