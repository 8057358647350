import { useEffect, useState } from 'react';
import store from './customerDetailDataStore';
import { IProviderCustomerDetail, ICustomerServiceLineItem } from 'model/customer';

const { get, update, registerListener, unregisterListener } = store;

export default function useServiceItemUtil() {
  const setState = useState(get())[1];


  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function hasUnitBasedPricing(customer:IProviderCustomerDetail) {
    return customer.discountSchedule && customer.discountSchedule.unitBasedPricingFlag;
  }

  function getServiceItemUnits(serviceItem:ICustomerServiceLineItem):number {
    if (!serviceItem) {
      return 1;
    }
    let quantity = 1;
    if (serviceItem.units) {
      const _units = parseFloat(serviceItem.units);
      if (!isNaN(_units) && _units > 0) {
        quantity = _units;
      }
    }
    return quantity;
  }

  return {
    hasUnitBasedPricing,
    getServiceItemUnits,
  };
}