import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import styles from '../convertCustomerModal.module.css';
import SubscriptionOption from './SubscriptionOption';
import SubscriptionStartDate from './SubscriptionStartDate';
import { useLoading } from 'components/layout/Loading';
import Shake, { useShake } from 'components/layout/Shake';
import { HookCheckbox } from 'components/reactHookForm';
import { ServiceInstanceType } from 'model/ancillary';
import { ICustomerServiceDetails, IProviderCustomerDetail } from 'model/customer';
import useConvertCustomerModalStore from 'pages/customer/hookStore/useConvertCustomerModal';
import useCustomerDetail from 'pages/customer/hookStore/useCustomerDetail';

export interface IConvertCustomerFormInternalProps {
  initialFormData: ICustomerServiceDetails;
}
const shakeStoreKey = 'saveConvertCustomerForm';

function ConvertCustomerFormInternal({ initialFormData }: IConvertCustomerFormInternalProps) {
  const theme = useTheme();
  const { setAnimate } = useShake(shakeStoreKey);
  const { closeModal } = useConvertCustomerModalStore();
  const { onSubmitConvertCustomerToSubscriber } = useCustomerDetail();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const formContext = useForm<ICustomerServiceDetails>({ mode: 'onTouched', defaultValues: initialFormData });

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<ICustomerServiceDetails> = data => {
    closeModal();
    data.convertToSubscriber = true;
    void onSubmitConvertCustomerToSubscriber(data);
  };

  const onInvalid : any = data => {
    setAnimate(true);
  };

  const { formState: { isSubmitting, isValid } } = formContext;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit, onInvalid)} className={styles.formElement}>
        <Grid container item xs={12} spacing={1}
          justifyContent={'center'}
          className={`${!mdDown ? styles.desktopCustomerWrapper: styles.customerWrapper}`}>
          <Grid item xs={6}>
            <SubscriptionStartDate/>
          </Grid>
          <Grid item xs={6}>
            <SubscriptionOption/>
          </Grid>
          <Grid container item xs={12}>
            <HookCheckbox
              name="disableConfirmationEmail"
              label="Disable customer communications"
            />
          </Grid>
          <Grid container item xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }} alignItems='flex-start' sx={{ paddingTop: '20px' }}>
            {
              <Grid item xs={12} sm={'auto'} order={{ xs: 0, sm: 1 }}>
                <Shake
                  storeKey={shakeStoreKey}
                  timeout={900}
                  renderProps={(animatedClassName) => (
                    <Button
                      className={`${styles.saveButton} ${animatedClassName}`}
                      variant='contained'
                      type='button'
                      onClick={async (e) => {
                        await formContext.handleSubmit(onSubmit, onInvalid)(e);
                      }}
                      sx={{
                        '&&&': {
                          color: isSubmitting ? '#dadada' : '',
                          backgroundColor: isSubmitting ? 'rgba(0,0,0,0.12)' :'',
                          border: isSubmitting ? 'rgba(0,0,0,0.26)' :'',
                        },
                      }}
                    >
                      <>Convert</>
                    </Button>
                  )}/>
              </Grid>
            }
            <Grid item xs={12} sm='auto' sx={{ marginRight: { xs: 0, sm: '20px' } }}>
              <Button
                className={smDown ? styles.mobileCancelButton : styles.cancelButton}
                type='button'
                variant='outlined'
                onClick={() => closeModal()}
              >
                  Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </FormProvider>
  );
}

export default function ConvertCustomerForm() {
  const { customerServiceDetails } = useCustomerDetail();
  return (
    <ConvertCustomerFormInternal initialFormData={customerServiceDetails}/>
  );
}