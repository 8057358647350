import { Grid, Typography } from '@mui/material';
import ServiceCard from 'components/serviceCard';
import { useNavigate } from 'react-router-dom';
import styles from './groupDealShare.module.css';
import useGroupDealShare from './hookStore/useGroupDealShare';

export default function HappeningSoonDemo() {
  const navigate = useNavigate();
  const { happeningSoonCompatibleNsoi, nsoi } = useGroupDealShare();
  if (!happeningSoonCompatibleNsoi) {
    return <div>Nothing</div>;
  }
  return (
    <Grid container className={styles.happeningSoonDemo}>
      <Grid item xs={12} textAlign='left'>
        <Typography variant='h6' className={`${styles.pageSubHeader} ${styles.happeningSoonHeader}`}>{nsoi.neighborhoodName} neighbors will see this:</Typography>
      </Grid>
      <Grid container item justifyContent={{ xs: 'left' }}>
        <a style={{ textDecoration: 'none' }} target="_blank" href={`${window.REACT_APP_STREETFAIR_URL}/neighborhood-service-offering/${nsoi.neighborhoodServiceOfferingId}/service-instance/${nsoi.id}`}>
          <ServiceCard
            id={`happening-soon-card-${happeningSoonCompatibleNsoi.nsoId.toUpperCase()}`}
            altColor={happeningSoonCompatibleNsoi.altColor}
            customers={happeningSoonCompatibleNsoi.customers}
            beginServiceDate={happeningSoonCompatibleNsoi.beginServiceDate}
            endServiceDate={happeningSoonCompatibleNsoi.endServiceDate}
            cutoffDayCount={happeningSoonCompatibleNsoi.cutoffDayCount}
            serviceProvider={happeningSoonCompatibleNsoi.serviceProvider}
            serviceType={happeningSoonCompatibleNsoi.serviceType}
            customerCount={happeningSoonCompatibleNsoi.customers.length}
            maxCustomerCount={happeningSoonCompatibleNsoi.maxCustomerCount}
            maxAvatarIcons={5}
            onClick={() => {}}
          />
        </a>
      </Grid>
    </Grid>
  );
}