import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';

interface ICloseButtonProps {
  tooltipTitle:string;
  onClick:any;
  className?:string;
  disabled?:boolean;
  iconButtonProps?:any;
  tooltipProps?:any;
}
export default function CloseButton({ tooltipTitle, onClick, className, disabled, tooltipProps, iconButtonProps }:ICloseButtonProps) {
  return (
    <Tooltip title={tooltipTitle} className={className} {...tooltipProps}>
      <div>
        <IconButton onClick={onClick} disabled={disabled} {...iconButtonProps}>
          <CloseIcon/>
        </IconButton>
      </div>
    </Tooltip>
  );
}