import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CreateNewButton from './CreateNewButton';
import styles from './customerAccordion.module.css';
import CustomerAccordionFields from './CustomerAccordionFields';
import OtherGroupDeal from './OtherGroupDeal';
import { ServiceInstanceType } from 'model/ancillary';
import { IGroupDealCustomer } from 'model/customer';
import { createEmptyMoveToGroupDealForm, IMoveGroupDealError, IMoveToGroupDealForm } from 'model/groupDeal';
import { INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import useGroupDeal from 'pages/groupDeal/hookStore/useGroupDeal';
import useMoveGroupDealStore from 'pages/groupDeal/hookStore/useMoveGroupDeal';
import { createDateIgnoreTimezone, formatLongMonthDay, formatMonthDay, formatMonthDayTime } from 'util/dateUtil';

export interface IMoveToPlaceholderGroupDealProps {
  onlyOneCustomer:boolean;
  shouldShowMaxCustomerCount:boolean;
  customer:IGroupDealCustomer;
  onRemove: () => void;
}

export interface IOtherGroupDealProps {
  isSelected:boolean;
  nsoi: INeighborhoodServiceOfferingInstance;
  onClick:any;
}

export default function MoveToPlaceholderGroupDeal({ shouldShowMaxCustomerCount, customer, onlyOneCustomer, onRemove }: IMoveToPlaceholderGroupDealProps) {
  const { nsoi, nsoisWithSameNso } = useGroupDeal();
  const [showCreateGroupDeal, setShowCreateGroupDeal] = useState<boolean>(false);
  const [data, setData] = useState<IMoveToGroupDealForm>(createEmptyMoveToGroupDealForm(customer.id));
  const [error, setError] = useState<IMoveGroupDealError>({
    serviceDatesError: null,
    maxCustomerCountError: null,
  });
  const [selectedNsoiId, setSelectedNsoiId] = useState<string>('');
  const [createNewSelected, setCreateNewSelected] = useState<boolean>(false);
  const theme = useTheme();
  const secondaryColor = theme.palette.secondary.main;

  const { save, loadingKey, selectGroupDeal, createGroupDeal } = useMoveGroupDealStore();

  return (
    <>
      <Grid container>
        <Grid item xs={12} textAlign='left' sx={{ marginBottom: 2 }}>
          <Typography variant='subtitle2'>Select or create the placeholder group deal to move this customer to.</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid container
            className={styles.otherGroupDealWrapper}
            sx={{ border: `1px solid ${secondaryColor}` }}
          >
            {nsoisWithSameNso && nsoisWithSameNso.filter(x=> x.serviceInstanceType === ServiceInstanceType.PLACEHOLDER && x.id !== nsoi.id).map((x, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <OtherGroupDeal
                    nsoi={x}
                    isSelected={selectedNsoiId === x.id}
                    onClick={() => {
                      selectGroupDeal(x, customer.id, setData, setShowCreateGroupDeal, setSelectedNsoiId, setCreateNewSelected);
                    }}
                    overrideButtonText={`Placeholder ${formatMonthDayTime(x.createDate)}`}
                  />
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <CreateNewButton
                isSelected={createNewSelected}
                onClick={() => {
                  createGroupDeal(customer.id, setData, setShowCreateGroupDeal, setSelectedNsoiId, setCreateNewSelected);
                }}
                text='Create new placeholder'
              />
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' className={styles.groupDealButtonWrapper}>
            <Grid item xs={12}>
              <Button
                variant='contained'
                type='button'
                onClick={async () => {
                  data.placeholderMove = true;
                  await save(data, customer, onlyOneCustomer, shouldShowMaxCustomerCount, onRemove, setError);
                }}
                className={styles.saveButton}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}