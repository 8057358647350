import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { Grid, Menu, MenuItem, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import Userfront from '@userfront/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './header.module.css';
import ServiceProviderNameToggle from './ServiceProviderNameToggle';
import Spacer from 'components/layout/Spacer';
import UserProfileDrawer from 'components/userProfileDrawer';
import useUserProfileDrawer from 'components/userProfileDrawer/useUserProfileDrawer';
import useSelf from 'hooks/useSelf';
import FullLogo from 'images/logo.svg';
import SquareLogo from 'images/square-logo.svg';
import { IServiceProvider } from 'model/serviceProvider';
import { containsProviderMulti } from 'util/role';

export interface ILogoHeader {
  disableLogoNav?:boolean;
  hideBottomBoxShadow?:boolean;
  hideUserButtons?:boolean;
  forceFullLogo?:boolean;
  noMaxWidth?:boolean;
}
export default function LogoHeader({
  disableLogoNav,
  hideBottomBoxShadow,
  hideUserButtons,
  forceFullLogo,
  noMaxWidth,
}:ILogoHeader) {

  const { openProfile } = useUserProfileDrawer();
  const { serviceProvider } = useSelf();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (Userfront.tokens.accessToken) {
      openProfile();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const signIn = () => {
    navigate('/sign-in?redirect=' + window.location);
  };
  const signUp = () => {
    navigate('/sign-up?redirect=' + window.location);
  };
  const profile = () => {
    navigate ('/user-profile');
  };
  const signOut = () => {
    Userfront.logout();
  };

  const getBoxShadow = () => {
    if (hideBottomBoxShadow) {
      return '';
    } else {
      return '0px 2px 10px rgba(0, 0, 0, 0.05)';
    }
  };

  const handleHomeButtonClick = () => {
    if (disableLogoNav) {
      return;
    }
    navigate('/');
  };


  return (
    <>
      <Grid
        container
        className={`${styles.header} mui-fixed`}
        style={{ boxShadow: getBoxShadow(), borderBottom: '1px solid #eee' }}
        justifyContent="center"
      >
        <Grid container className={noMaxWidth ? '' : styles.headerContainer} justifyContent='space-between' flexWrap='nowrap'>
          <Grid container item xs={2} justifyContent="flex-start" className={styles.logo} onClick={handleHomeButtonClick} >
            <Grid item sx={{ display: { xs: forceFullLogo ? 'block': 'none', md: 'block' } }}>
              <img alt="streetfair-logo" src={FullLogo}/>
            </Grid>
            <Grid item sx={{ display: { xs: forceFullLogo ? 'none':'block', md: 'none' } }}>
              <img alt="streetfair-logo" src={SquareLogo}/>
            </Grid>
          </Grid>
          <Grid container item xs alignItems='center' justifyContent='center' sx={{
            maxWidth: {
              xs: '200px', sm: '300px', lg: '1000px',
            },
          }}>
            <ServiceProviderNameToggle
              serviceProviderName={serviceProvider.name}
            />
          </Grid>
          {!hideUserButtons && (
            <Grid container item xs={2} justifyContent="flex-end">
              <Grid item className={styles.dualSignInMenu} onClick={handleClick} alignItems='flex-start'>
                <MenuIcon className={styles.menuIcon} />
                <PersonIcon className={styles.profileIcon}/>
              </Grid>
              {!Userfront.tokens.accessToken &&
                <>
                  <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    open={open}

                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={signIn}>Sign in</MenuItem>
                  </Menu>
                </>
              }
            </Grid>
          )}
          {hideUserButtons && (
            <Grid container item xs={2} justifyContent="flex-end">
            </Grid>
          )}
        </Grid>
        <UserProfileDrawer/>
      </Grid>
      <div style={{ marginBottom: '75px' }}></div>
    </>
  );
}