

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InputBase, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { IDropdownOption } from 'model/dropdown';

export interface IElementPillFilterProps {
  id:string;
  options: IDropdownOption[];
  handleSelection: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  selection: string;
  renderValue?: (value:any) => ReactNode;
  renderOption: (value:IDropdownOption) => JSX.Element | ReactNode;
  className?:string;
  multiple?:boolean;
}

const CustomInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    'color': theme.palette.primary.main,
    'borderRadius': 30,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': `2px solid ${theme.palette.primary.main}`,
    'fontSize': 14,
    'padding': '4px 10px 3px 10px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 30,
      borderWidth: '2px',
    },
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
  },
}));

export default function ElementPillFilter({ id, options, handleSelection, selection, className, renderValue, renderOption }:IElementPillFilterProps) {
  return (
    <Select
      variant="outlined"
      id={id}
      value={selection}
      fullWidth
      input={<CustomInput/>}
      onChange={handleSelection}
      IconComponent={KeyboardArrowDownIcon}
      className={className}
      renderValue={renderValue}
    >
      {
        options.map((x: any, i) => {
          return <MenuItem key={i} value={x.optionValue}>{renderOption(x)}</MenuItem>;
        })
      }
    </Select>
  );
}