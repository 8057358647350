import { Button, Container } from '@mui/material';
import { Row } from 'react-bootstrap';

export default function NavigationButton (props : any) {

  return (
    <Button data-route={props.route} onClick={props.handleButtonClick} style={{
      textAlign: 'left',
      color: 'white',
      marginTop: '5px',
      marginRight: '35px',
    }}>{props.text}</Button>
  );
}