

import DoneAllIcon from '@mui/icons-material/DoneAll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, InputBase, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { IDropdownOption } from 'model/dropdown';

export interface IMultiPillFilterProps {
  id:string;
  options: IDropdownOption[];
  handleSelection: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
  handleClose:(event: React.SyntheticEvent) => void;
  selections: string[];
  renderValue?: (value:any) => ReactNode;
  className?:string;
}

const CustomInput = styled(InputBase)(({ theme }) => ({
  '& .Mui-selected': {
    color: 'red',
  },
  '& .MuiInputBase-input': {
    'color': theme.palette.primary.main,
    'borderRadius': 30,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': `2px solid ${theme.palette.primary.main}`,
    'fontSize': 14,
    'padding': '4px 10px 3px 10px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 30,
      borderWidth: '2px',
    },
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
  },
}));

export default function MultiSelectPillFilter({ id, options, handleSelection, handleClose, selections, className, renderValue }:IMultiPillFilterProps) {
  const allSelected = (options.length - 1) === selections.length;
  return (
    <Select
      variant="outlined"
      id={id}
      value={selections}
      fullWidth
      displayEmpty
      input={<CustomInput/>}
      onChange={handleSelection}
      onClose={handleClose}
      IconComponent={KeyboardArrowDownIcon}
      className={className}
      renderValue={renderValue}
      multiple
    >
      {
        options.map((x: any, i) => {
          return (
            <MenuItem key={i} value={x.optionValue}>
              {x.optionValue !== 'UNSELECTED' && (
                <>
                  <Checkbox checked={selections.indexOf(x.optionValue) > -1} />
                  {x.optionText}
                </>
              )}
              {x.optionValue == 'UNSELECTED' && !allSelected && (
                <div style={{ paddingLeft: '10px' }}>
                  <DoneAllIcon/>
                  {x.optionText}
                </div>
              )}
              {x.optionValue === 'UNSELECTED' && allSelected && (
                <div style={{ paddingLeft: '10px' }}>All selected</div>
              )}

            </MenuItem>
          );
        })
      }
    </Select>
  );
}