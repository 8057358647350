import Axios, { AxiosResponse } from 'axios';
import { IProviderUserSignUpRequest, IProviderUserSignUpResult } from 'model/user';

const apiUrl = window.REACT_APP_BASE_API_URI;

export interface IMinimalUserDTO {
  userId:string;
  userName:string;
}

export function createEmptyMinimalUser():IMinimalUserDTO {
  return {
    userId: '',
    userName: '',
  };
}

export function getSelfServiceProvider():Promise<AxiosResponse<any>> {
  return Axios.get(`${apiUrl}/users/v2/getSelfServiceProvider`);
}

export function getSelfUser():Promise<AxiosResponse<IMinimalUserDTO>> {
  return Axios.get(`${apiUrl}/users/v2/getSelfUser`);
}

export function createProviderUser(data:IProviderUserSignUpRequest):Promise<AxiosResponse<IProviderUserSignUpResult>> {
  return Axios.post(`${apiUrl}/users/v2/public/createProviderUser`, data);
}