import { IMinimalCustomerDTO, IMinimalSubscriberDTO } from './customer';
import { IDiscountSchedule, IDiscountTableRow } from './discount';
import { INeighborhood } from './neighborhood';
import { IServiceOffering } from './serviceOffering';
import { IServiceProvider } from './serviceProvider';

export interface IServiceType {
  id:string;
  name:string;
  legacyId:number;
  serviceCode:string;
  serviceImageIconName:string;
  sortOrder?:number;
}

export interface INeighborhoodServiceOfferingDTO {

  id:string;
  name:string;
  serviceProvider:IServiceProvider;
  offering:IServiceOffering;
  neighborhoodId:string;
  neighborhoodName:string;
  neighborhood:INeighborhood;
  discountSchedule:IDiscountSchedule;
  price:number | null;
  serviceType:IServiceType;
  serviceTypeName:string;
  serviceCategoryName:string;
  discountTableRow:IDiscountTableRow[];
  participantCount:number;
  previouslyHiredCount:number;
  participantCountType:string | null;
  serviceInstances:INeighborhoodServiceOfferingInstance[];
  serviceInstanceDTOs:INeighborhoodServiceOfferingInstanceDTO[];
  subscribers:IMinimalSubscriberDTO[];
  customers:IMinimalCustomerDTO[];
  previouslyHiredCustomers:IMinimalCustomerDTO[];
  enabled:boolean;
  maxServiceInstancesShown:number | null;
}


export interface INeighborhoodServiceOfferingInstanceDTO {
  id:string;
  neighborhoodId:string;
  neighborhoodServiceOfferingId:string;
  providerId:string;
  providerName:string;
  serviceCategory:string;
  serviceType:string;
  serviceDate:Date;
  endServiceDate?:Date;
  maxCustomerCount:number | null;
  cutoffDayCount:number | null;
  serviceInstanceType:string | null;
  zipCodeTerritory:string | null;
  customers:IMinimalCustomerDTO[];
  serviceTypeNameOverride:string | null;
  bookingPeriodOpen:boolean;
  spotAvailable:boolean;
}

export interface INeighborhoodServiceOfferingInstance {

  id: string;
  createDate:string;
  neighborhoodServiceOfferingId: string;
  serviceDate: string;
  endServiceDate: string | null;
  status: string;
  customerCount: number | null;
  maxCustomerCount: number | null;
  neighborhoodId: string;
  deletedDate: Date;
  dynamicDiscountTable?: boolean;
  isForCustomerUpload: boolean | null;
  firstCustomerId: string;
  origin: string;
  cutoffDayCount: number | null;
  discountScheduleId: string;
  discountScheduleVersion: string;
  customers: IMinimalCustomerDTO[];
  serviceProviderId: string;
  serviceTypeName: string;
  serviceTypeNameOverride: string | null;
  neighborhoodName: string;
  zipCodeTerritory: string;
  serviceInstanceType:string;
  serviceOfferingId:string;
  closedOffDate: Date | null;
}

export interface IGroupDealTableNeighborhoodServiceOfferingInstance extends INeighborhoodServiceOfferingInstance {
  neighborhood: INeighborhood;
}

export function createEmptyNeighborhoodServiceOfferingInstance() {
  return {
    id: '',
    createDate: '',
    neighborhoodServiceOfferingId: '',
    serviceDate: '',
    endServiceDate: null,
    status: '',
    customerCount: 0,
    maxCustomerCount: 0,
    neighborhoodId: '',
    deletedDate: new Date(),
    isForCustomerUpload: false,
    firstCustomerId: '',
    origin: '',
    cutoffDayCount: 3,
    discountScheduleId: '',
    discountScheduleVersion: '',
    serviceOfferingId: '',
    customers: [],
    serviceProviderId: '',
    serviceTypeName: '',
    serviceTypeNameOverride: '',
    neighborhoodName: '',
    zipCodeTerritory: '',
    serviceInstanceType: '',
    closedOffDate: null,
  };
}

export interface IMovableGroupDealsDto {
  zipCodeTerritory:string | null;
  neighborhoodId:string | null;
  shouldShowPastDeals:boolean;
}