import { FormControl, Grid } from '@mui/material';
import { HookDateRangePicker } from 'components/reactHookForm';
import { differenceInCalendarDays } from 'date-fns';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useGroupDeal from '../hookStore/useGroupDeal';

export default function ServiceDateField() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { editingClicked } = useGroupDeal();

  return (
    <FormControl variant='standard' fullWidth error={isError('serviceDates')} >
      <Grid container alignItems='center'>
        <Grid item xs>
          <HookDateRangePicker
            labelStart='Service Date Range'
            labelEnd=''
            name='serviceDates'
            validationRules={{
              validate: function(value) {
                if (!value || value.length === 0 || !value[0] || (!value[0] && !value[1])) {
                  return 'Required';
                }
                if (value[0] && value[1] && Math.abs(differenceInCalendarDays(value[0], value[1])) >= 5) {
                  return 'Service date range cannot be greater than 5 days';
                }

                return true;
              },
            }}
            dateRangePickerProps={{
              // disabled: !editingClicked,
              showTodayButton: true,
              clearable: true,
              onAccept: () => {},
              InputAdornmentProps: {
                sx: {
                  marginBottom: '12px',
                },
              },
            }}
            textFieldProps={{ fullWidth: true, variant: 'standard' }}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}