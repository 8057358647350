
export interface IDiscountTableMetadata {
  tableRowBackground:string;
  neighborLabel:string;
  customerArray: any[];
  rowClass: string;
}

export const confirmedMetadata = (confirmedCustomers):IDiscountTableMetadata => ({
  tableRowBackground: '#C6FFDD',
  neighborLabel: 'Confirmed',
  customerArray: confirmedCustomers,
  rowClass: 'confirmedCurrentRow',
});

export const pendingMetadata = (pendingCustomers):IDiscountTableMetadata => ({
  tableRowBackground: '#FCF0D1',
  neighborLabel: 'Pending',
  customerArray: pendingCustomers,
  rowClass: 'pendingCurrentRow',
});

export const mixedMetadata = (pendingCustomers):IDiscountTableMetadata => ({
  tableRowBackground: '#C6FFDD',
  neighborLabel: '',
  customerArray: pendingCustomers,
  rowClass: 'pendingCurrentRow',
});

export const defaultMetadata = ():IDiscountTableMetadata => ({
  tableRowBackground: '#FFF',
  neighborLabel: '',
  customerArray: [],
  rowClass: '',
});

function shouldUseConfirmedMetadata(discountTableRow:any, maxDiscountCount:number, totalCustomerCount:number, pendingCount:number, confirmedCount:number, rowIndex:number):boolean {
  return discountTableRowIconPredicate(discountTableRow, maxDiscountCount, confirmedCount, rowIndex);
}

function shouldUsePendingMetadata(discountTableRow:any, maxDiscountCount:number, totalCustomerCount:number, pendingCount:number, confirmedCount:number, rowIndex:number):boolean {
  if (confirmedCount === 0) {
    // when there are no confirmed then it is identical scenario to shouldUseConfirmedMetadata but for pending count
    return discountTableRowIconPredicate(discountTableRow, maxDiscountCount, pendingCount, rowIndex);
  }
  //when there are confirmed customers/subscribers then we should consider the total customer count to determine where to show the pending icons and beige background.
  // for example if the discount table has 6 possible rows and we have 3 confirmed and 1 pending then we would expect the 4th row to show the pending icons and the 3rd
  // row to show the 3 confirmed.
  return discountTableRowIconPredicate(discountTableRow, maxDiscountCount, totalCustomerCount, rowIndex);
}

function discountTableRowIconPredicate(discountTableRow:any, maxDiscountCount:number, count:number, rowIndex:number):boolean {
  if (rowIndex === 0 && discountTableRow.count > 1 && count > 0 && count <= discountTableRow.count) {
    return true;
  }
  //when the count is greater than or equal to the max discount count (i.e. last possible discount table row number) then
  //we will show the appropriate icons if the discountTableRow is the last row.
  if (count >= maxDiscountCount) {
    //only show icons on last row in this case.
    return discountTableRow.count === maxDiscountCount;
  }
  //when the count exactly matches a row's count then we want to show the relevant icons on that row.
  if (count === discountTableRow.count) {
    return true;
  }
  //otherwise we shouldn't show the icons and instead a white/clear background is displayed
  return false;
}

export function createDiscountTableMetadata(
  isNewServiceInstance:boolean,
  discountTableRow:any,
  index:number,
  confirmedCustomers:any[],
  pendingCustomers:any[],
  maxDiscountCount:number,
) :IDiscountTableMetadata {

  let metadata = defaultMetadata();
  const pendingCount = pendingCustomers.length;
  const confirmedCount = confirmedCustomers.length;
  const totalCustomerCount = confirmedCount + pendingCount;
  if (isNewServiceInstance) {
    return metadata;
  }
  const _shouldUseConfirmedMetadata = shouldUseConfirmedMetadata(discountTableRow, maxDiscountCount, totalCustomerCount, pendingCount, confirmedCount, index);
  const _shouldUsePendingMetadata = shouldUsePendingMetadata(discountTableRow, maxDiscountCount, totalCustomerCount, pendingCount, confirmedCount, index);

  if (_shouldUseConfirmedMetadata && _shouldUsePendingMetadata) {
    if (index === 0 && discountTableRow.count > 1 && pendingCustomers.length > 0 && confirmedCustomers.length > 0) {
      // case with a starting offset that should show both confirmed and pending on same row
      return mixedMetadata([...pendingCustomers, ...confirmedCustomers]);
    }
  }
  if (_shouldUseConfirmedMetadata) {
    return confirmedMetadata(confirmedCustomers);
  }
  if (_shouldUsePendingMetadata) {
    if (index === 0 && discountTableRow.count > 1 && pendingCustomers.length > 0 && confirmedCustomers.length > 0) {
      // case with a starting offset that should show both confirmed and pending on same row
      return mixedMetadata([...pendingCustomers, ...confirmedCustomers]);
    }
    return pendingMetadata(pendingCustomers);
  }
  return metadata;
}