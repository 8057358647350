import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Grid, Typography, useTheme, useThemeProps } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import ActionItem from './ActionItem';
import styles from './customerTable.module.css';
import useCustomerTable from './hookStore/useCustomerTable';

function ViewActionItemLink({ linkText }) {
  const theme = useTheme();
  return (
    <button className={styles.viewActionItemButton}>
      <VisibilityIcon color='primary' fontSize='small'/>
      <Typography variant='body2' color={theme.palette.primary.main}>{linkText}</Typography>
    </button>
  );
}

function ActionItemAlert() {
  const {
    customersToContactCount,
    customersToConfirmCount,
    customersToCompleteCount,
  } = useCustomerTable();
  const theme = useTheme();
  if (customersToContactCount > 0 || customersToConfirmCount > 0 || customersToCompleteCount > 0) {
    return (
      <Grid container alignItems='center'>
        <WarningAmberIcon color={'error'}/>
        <Typography variant='body2' sx={{ marginLeft: '4px' }}>Actions required on items below</Typography>
      </Grid>
    );
  }
  return null;
}

export default function ActionItems() {
  const {
    customersToContactCount,
    customersToQuoteCount,
    customersToConfirmCount,
    customersToCompleteCount,
    onClickViewCustomersToContact,
    onClickViewCustomersToQuote,
    onClickViewCustomersToConfirm,
    onClickViewCustomersToMarkComplete,
  } = useCustomerTable();


  return (
    <Grid container item xs={12} className={styles.actionItemContainer} spacing={1}>
      <Grid item xs={12} textAlign='left'>
        <ActionItemAlert/>
      </Grid>
      {customersToContactCount > 0 && (
        <Grid item xs={12} md={4}>
          <ActionItem
            onClick={onClickViewCustomersToContact}
            title='Customers to Contact'
            count={customersToContactCount}
            view={(
              <ViewActionItemLink
                linkText={'View'}
              />
            )}
          />
        </Grid>
      )}
      {customersToQuoteCount > 0 && (
        <Grid item xs={12} md={4}>
          <ActionItem
            onClick={onClickViewCustomersToQuote}
            title='Customers to Quote/Estimate'
            count={customersToQuoteCount}
            view={(
              <ViewActionItemLink
                linkText={'View'}
              />
            )}
          />
        </Grid>
      )}
      {customersToConfirmCount > 0 && (
        <Grid item xs={12} md={4}>
          <ActionItem
            onClick={onClickViewCustomersToConfirm}
            title='Customers to Confirm'
            count={customersToConfirmCount}
            view={(
              <ViewActionItemLink
                linkText={'View'}
              />
            )}
          />
        </Grid>
      )}
      {customersToCompleteCount > 0 && (
        <Grid item xs={12} md={4}>
          <ActionItem
            onClick={onClickViewCustomersToMarkComplete}
            title='Customers to Mark Complete'
            count={customersToCompleteCount}
            view={(
              <ViewActionItemLink
                linkText={'View'}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
}