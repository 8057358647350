import { Grid, Typography, useTheme } from '@mui/material';
import styles from './priceSlash.module.css';

export interface IPriceSlashProps {
  originalPrice: string;
  currentPrice:string;
  slashPrice:boolean;
  priceLabel?:string;
  priceSubText?:string | null;
  priceSuffix?:string;
  nsoPageStyle?:boolean;
}
export default function PriceSlash({ originalPrice, currentPrice, slashPrice, priceLabel, priceSubText, priceSuffix, nsoPageStyle }:IPriceSlashProps) {
  const theme = useTheme();
  return (
    <Grid container className={'priceSlash'} alignItems='center'>
      <Grid container item xs={12} textAlign='left' >
        <Typography
          variant="body1"
          component='div'
          className='nsoPagePriceLabel'
          sx={{
            '&': {
              fontSize: nsoPageStyle ? 16 : 13,
              color: '#666',
              marginRight: '4px',
              lineHeight: 'initial',
            },
          }}
        >
          {priceLabel}
        </Typography>
        {priceSubText && (
          <Typography
            variant='body1'
            component='div'
            sx={{
              '&': {
                fontSize: nsoPageStyle ? 16 : 13,
                color: '#666',
                lineHeight: 'initial',
              },
            }}
            className='nsoPagePriceSubtext'
          >
            ({priceSubText.replace('(', '').replace(')', '')})
          </Typography>
        )}
      </Grid>
      {slashPrice &&
        <Grid item>
          <Typography
            variant='body1'
            component='div'
            className={`nsoPageOriginalPriceAmount ${styles.originalPriceText}`}
            sx={{
              fontSize: nsoPageStyle ? 18 : 14,
              color: '#666',
            }}
          >
            <>{originalPrice} {priceSuffix ? priceSuffix.replace('per', '/') : ''}</>
          </Typography>
        </Grid>
      }
      <Grid item>
        <Typography
          variant='h6'
          component='div'
          className={'nsoPagePriceAmount'}
          sx={{
            fontSize: nsoPageStyle ? 22 : 18,
          }}
        >
          <>{currentPrice} {priceSuffix ? priceSuffix.replace('per', '/') : ''}</>
        </Typography>
      </Grid>
    </Grid>
  );
}