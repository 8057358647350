import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { IProviderHistoricalParticipant } from 'model/customer';
import { useState } from 'react';
import HistoricalParticipantMenuItem from './HistoricalParticipantMenuItem';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    'borderRadius': 6,
    'marginTop': theme.spacing(1),
    'minWidth': 180,
    'color':
          theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    'boxShadow':
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export interface IHistoricalParticipantMenuProps {
  historicalParticipants: IProviderHistoricalParticipant[];
}
export default function HistoricalParticipantsMenu({ historicalParticipants }:IHistoricalParticipantMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { trackButtonClick } = useStreetfairAnalytics();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_SERVICE_HISTORY_DROPDOWN);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        id="service-history-button"
        aria-controls={open ? 'service-history-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        size='small'
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          marginTop: '10px',
        }}
      >
        Service History
      </Button>
      <StyledMenu
        id="service-history-menu"
        MenuListProps={{
          'aria-labelledby': 'service-history-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          historicalParticipants.map(x => {
            return (
              <HistoricalParticipantMenuItem key={x.id} historicalParticipant={x} />
            );
          })
        }
      </StyledMenu>
    </>
  );
}