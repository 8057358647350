import { createTheme } from '@mui/material';


declare module '@mui/material/styles' {
  interface Theme {
    textColor: {
      primary: string;
      secondary?:string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    textColor?: {
      primary?: string;
      secondary?:string;
    };
  }
  interface Palette {
    outdatedButtonPrimary: Palette['primary'];
    buttonPrimary: Palette['primary'];
    buttonSecondary: Palette['primary'];
    tertiary: Palette['primary'];
  }

  interface PaletteColor {
    darker?:string;
    extraLight?:string;
    altExtraLight?:string;
    red?:string;
  }

  interface SimplePaletteColorOptions {
    darker?:string;
    extraLight?:string;
    altExtraLight?:string;
    red?:string;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    outdatedButtonPrimary?: PaletteOptions['primary'];
    buttonPrimary?: PaletteOptions['primary'];
    buttonSecondary?: PaletteOptions['primary'];
    tertiary?: Palette['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buttonPrimary: true;
    buttonSecondary:true;
    outdatedButtonPrimary: true;
  }
}

const mainTheme = createTheme({
  typography: {
    fontFamily: [
      '"Sora"',
      '"Rubik"',
      '-apple-system',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontWeight: '700',
      fontSize: '64px',
    },
    h2: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontWeight: '700',
      fontSize: '40px',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    h3: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontWeight: '700',
      fontSize: '32px',
    },
    h4: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontWeight: '700',
      fontSize: '28px',
    },
    h5: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontWeight: 700,
      fontSize: '24px',
    },
    h6: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontWeight: '700',
      fontSize: '22px',
    },
    subtitle1: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      color: '#000',
      fontWeight: '400',
      fontSize: '16px',
    },
    subtitle2: {
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontWeight: '600',
      fontSize: '16px',
      color: '#000',
    },
    body1: {
      color: '#676767',
      fontFamily: '"Rubik", -apple-system, sans-serif',
      fontSize: '16px',
    },
    body2: {
      color: '#676767',
      fontFamily: '"Rubik", -apple-system, sans-serif',
      fontSize: '14px',
    },
    button: {
      color: '#676767',
      fontFamily: '"Sora", -apple-system, sans-serif',
      fontSize: '16px',
    },
    caption: {
      color: '#676767',
      fontFamily: '"Rubik", -apple-system, sans-serif',
      fontSize: '12px',
    },
    overline: {
      color: '#676767',
      fontFamily: '"Rubik", -apple-system, sans-serif',
      fontSize: '10px',
    },
  },
  palette: {
    primary: {
      dark: '#224E4E',
      main: '#4F9866',
      light: '#ABD5A8',
    },
    secondary: {
      darker: '#2E3A82',
      dark: '#486C9C',
      main: '#4657BB',
      light: '#6075F0',
    },
    tertiary: {
      extraLight: '#E5F3F7',
      altExtraLight: '#F9F9F9',
      light: '#9FD4E2',
      main: '#598AAB',
      dark: '#F1AE00',
      red: '#DE3051',
      contrastText: '#f5f5f5',
    },
    outdatedButtonPrimary: {
      main: '#4F9866',
      contrastText: '#fff',
    },
    buttonPrimary: {
      main: '#4F9866',
      contrastText: '#fff',
    },
    buttonSecondary: {
      main: '#5AA15D',
      contrastText: '#fff',
    },
  },
  textColor: {
    primary: '#000',
    secondary: '#676767',
  },
  components: {
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          border: 'none',
        },
        avatar: {
          border: 'none',
          boxShadow: '-1px 2px 2px grey',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 'initial',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          'background': '#fff',
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
          },
        },
        standard: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          'background': '#fff',
          '&.Mui-focused': {
            background: '#fff',
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
          },
          ':hover': {
            background: '#fff',
          },
        },
        input: {
          background: '#fff',
        },

      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          'fontSize': '14px',
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          'fontSize': '14px',
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          'fontSize': '14px',
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& a': {
            color: '#4F9866',
            textDecoration: 'none',
            fontSize: '14px',
          },
        },
        ol: {

        },
        li: {
          color: '#4F9866',
        },
        separator: {
          color: '#4F9866',
          marginLeft: '2px',
          marginRight: '2px',
        },
      },
    },
  },
});

export default mainTheme;