import Userfront from '@userfront/react';
import LogoHeader from 'components/Header/logoHeader';

export default function PasswordReset() {

  Userfront.init(window.REACT_APP_PROVIDER_USERFRONT_TENANT);

  const PasswordResetForm = Userfront.build({
    toolId: window.REACT_APP_PROVIDER_USERFRONT_PASSWORD_RESET,
  });

  return (
    <>
      <LogoHeader hideUserButtons/>
      <PasswordResetForm />
    </>

  );
}