import { ParticipantStatus } from './status';

export interface ICustomerTableRow {
  id:string;
  customerId:string;
  firstName:string;
  lastName:string;
  status:string;
  neighborhoodId:string;
  neighborhoodName:string;
  serviceType:string | null;
  communicationPreference:string;
  email:string;
  streetAddress:string;
  zipCode:string;
  estimatedDate:Date | null;
  deletedDate:Date | null;
  serviceDate:string | null;
  endServiceDate:string | null;
  needsContact:boolean;
  needsConfirmation:boolean;
  needsCompleted:boolean;
  needsQuote:boolean;
}

export interface ICustomerSearchDTO {
  customerStatus:string | 'UNSELECTED';
  customerStatuses:string[];
  neighborhoodId:string | 'UNSELECTED';
  actionItem:string | 'UNSELECTED';
}

export function createEmptyCustomerSearchDTO() {
  return {
    customerStatus: 'UNSELECTED',
    customerStatuses: [ParticipantStatus.PENDING, ParticipantStatus.ACTIVE],
    neighborhoodId: 'UNSELECTED',
    actionItem: 'UNSELECTED',
  };
}