
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { INeighborhood } from 'model/neighborhood';

export interface ICustomerNeighborhoodSelectProps {
  neighborhoodOptions: INeighborhood[];
  value:string;
  onChange: (e) => void;
  error: {hasError:boolean; message:string};
}
export default function CustomerNeighborhoodSelect({ neighborhoodOptions, value, onChange, error }:ICustomerNeighborhoodSelectProps) {


  return (
    <FormControl variant='standard' fullWidth error={error.hasError} >
      <InputLabel htmlFor='customer-neighborhood-select'>Select Neighborhood</InputLabel>
      <Select
        id='customer-neighborhood-select'
        onChange={onChange}
        value={value}
      >
        {
          neighborhoodOptions.map((x: INeighborhood, i) => {
            return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>;
          })
        }c
      </Select>
      <FormHelperText>{error.message || ' '}</FormHelperText>
    </FormControl>
  );
}