import { differenceInCalendarDays, formatDistanceStrict, startOfDay, subDays } from 'date-fns';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';


export function normalize(date:string | Date):Date {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
}

export function parseLocalDate(date: string | Date | null) : Date | null {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
}

export function createDateIgnoreTimezoneNullPlaceholder(date) {
  if (!date) {return null;}
  let originalDate = new Date(date);
  let startOfDayOriginalDate = startOfDay(originalDate);
  return new Date(startOfDayOriginalDate.valueOf() + startOfDayOriginalDate.getTimezoneOffset() * 60 * 1000);
}

export function createDateIgnoreTimezone(date) {
  if (!date) {return '';}
  let originalDate = new Date(date);
  let startOfDayOriginalDate = startOfDay(originalDate);
  var nextDate = new Date(startOfDayOriginalDate.valueOf() + startOfDayOriginalDate.getTimezoneOffset() * 60 * 1000);
  return nextDate;
}

export function formatMonthDayTime(date:string | Date):string {
  let normalized = normalize(date);
  return formatDate(normalized, 'MMM dd, h:mm aa');
}

export function formatMonthDayTimeYear(date:string | Date):string {
  let normalized = normalize(date);
  return formatDate(normalized, 'MMM dd, yyyy h:mm aa');
}

export function formatMonthDayTimeYearSeconds(date:string | Date):string {
  let normalized = normalize(date);
  return formatDate(normalized, 'MMM dd, yyyy h:mm:ss aa');
}

export function formatMonthDay(date) {
  if (!date) {return '';}
  if (date instanceof Date && !isNaN(date.getTime())) {
    return formatDate(date, 'MMM-dd');
  }
  return formatDate(parseISO(date), 'MMM-dd');
}

export function formatMonthDayYear(date, separator?:string) {
  if (!date) {return '';}
  if (date instanceof Date && !isNaN(date.getTime())) {
    return formatDate(date, `MM${separator ?? '/'}dd${separator ?? '/'}yyyy`);
  }
  return formatDate(parseISO(date), `MM${separator ?? '/'}dd${separator ?? '/'}yyyy`);
}

/**
 * Formats like Nov 1, 2021
 * @param date
 * @returns
 */
export function formatLongMonthDayYear(date:any) {
  if (!date) {return '';}
  if (date instanceof Date && !isNaN(date.getTime())) {
    return formatDate(date, 'MMM dd, yyyy');
  }
  return formatDate(parseISO(date), 'MMM dd, yyyy');
}


export function formatYearOnly(date:any | Date) {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return formatDate(date, 'yyyy');
  }
  return formatDate(parseISO(date), 'yyyy');
}

/**
 * Formats like Nov 1st
 * @param date
 * @returns
 */
export function formatLongMonthDay(date) {
  if (!date) {return '';}
  if (date instanceof Date && !isNaN(date.getTime())) {
    return formatDate(date, 'MMM do');
  }
  return formatDate(parseISO(date), 'MMM do');
}


export function formatMonth(date) {
  var originalDate = new Date(date);
  var timeZoneAdjustedDate = new Date(originalDate.valueOf() + originalDate.getTimezoneOffset() * 60 * 1000);
  return formatDate(timeZoneAdjustedDate, 'MMM');
}

export function getTimeRemaining (serviceDate, cutoffDayCount, options?: {
  addSuffix?: boolean;
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  roundingMethod?: 'floor' | 'ceil' | 'round';
  locale?: Locale;
}) {
  if (serviceDate) {
    var cutoffDays = 3;
    if (cutoffDayCount != null && cutoffDayCount >= 0) {
      cutoffDays = cutoffDayCount;
    }
    var originalDate = new Date(serviceDate);
    var bookByDate = subDays(originalDate, (cutoffDays ));
    if (new Date() < bookByDate) {
      var timeRemaining = formatDistanceStrict(new Date(), bookByDate, options);
      return timeRemaining;
    } else {
      return 0;
    }
  }
  return 0;
}

export function getDaysRemaining (serviceDate) {
  if (serviceDate) {
    var originalDate = new Date(serviceDate);
    return differenceInCalendarDays(originalDate, new Date());
  }
  return 0;
}

export function formatDay(date) {
  var originalDate = new Date(date);
  var timeZoneAdjustedDate = new Date(originalDate.valueOf() + originalDate.getTimezoneOffset() * 60 * 1000);
  return formatDate(timeZoneAdjustedDate, 'dd');
}

export function formatDateRange(serviceDate: Date | string | null, endServiceDate: Date | string | null) {
  var formattedDays = formatLongMonthDay(createDateIgnoreTimezone(serviceDate));
  var formattedEndDays = formatLongMonthDay(createDateIgnoreTimezone(endServiceDate));
  var formattedYear = formatYearOnly(createDateIgnoreTimezone(serviceDate));
  return `${formattedDays} - ${formattedEndDays}, ${formattedYear}`;
}

export function formatDateRangeLocalDate(serviceDate: Date | string | null, endServiceDate: Date | string | null) {
  var formattedDays = formatLongMonthDay(serviceDate);
  var formattedEndDays = formatLongMonthDay(endServiceDate);
  var formattedYear = formatYearOnly(serviceDate);
  return `${formattedDays} - ${formattedEndDays}, ${formattedYear}`;
}

export function formatDateRangeShort(serviceDate: Date | string | null, endServiceDate: Date | string | null) {
  var formattedDays = formatLongMonthDay(createDateIgnoreTimezone(serviceDate));
  var formattedEndDays = formatLongMonthDay(createDateIgnoreTimezone(endServiceDate));
  return `${formattedDays} - ${formattedEndDays}`;
}

export function formatDateRangeShortLocalDate(serviceDate: Date | string | null, endServiceDate: Date | string | null) {
  var formattedDays = formatLongMonthDay(serviceDate);
  var formattedEndDays = formatLongMonthDay(endServiceDate);
  return `${formattedDays} - ${formattedEndDays}`;
}