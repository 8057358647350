import React, { createContext, useContext } from 'react';
import { AnalyticsBrowser, } from '@segment/analytics-next';
export var createClient = function (settings, options) { return AnalyticsBrowser.load(settings, options); };
var AnalyticsContext = createContext(null);
export var AnalyticsProvider = function (_a) {
    var client = _a.client, children = _a.children;
    if (!(client instanceof AnalyticsBrowser)) {
        throw new Error('Invalid Segment `client`. Make sure you are using `createClient` correctly');
    }
    return (React.createElement(AnalyticsContext.Provider, { value: client }, children));
};
export var useAnalytics = function () {
    var analytics = useContext(AnalyticsContext);
    if (!(analytics instanceof AnalyticsBrowser)) {
        throw new Error('Analytics client not found. Make sure `AnalyticsProvider` is an ancestor of this component');
    }
    return analytics;
};
