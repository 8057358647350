import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ConfirmGroupDealDeleteModal from '../confirmGroupDealDeleteModal/ConfirmGroupDealDeleteModal';
import useAddressAutocomplete from '../hookStore/useAddressAutocomplete';
import useGroupDeal from '../hookStore/useGroupDeal';
import useRemoveGroupDealStore from '../hookStore/useRemoveGroupDeal';
import RequestNeighborhoodModal from '../requestNeighborhoodModal/RequestNeighborhoodModal';
import styles from './groupDeal.module.css';
import GroupDealForm from './GroupDealForm';
import Footer from 'components/footer';
import Loading from 'components/layout/Loading';
import Spacer from 'components/layout/Spacer';

export default function OldGroupDeal() {
  const { serviceProviderId, groupDealId } = useParams();
  const { loadingKey, init, nsoi } = useGroupDeal();
  const { init: initAutocomplete } = useAddressAutocomplete();
  const { openConfirmDeleteModal } = useRemoveGroupDealStore();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isNew = groupDealId === 'new';
  useEffect(() => {
    void init();
    void initAutocomplete();
    window.scrollTo(0, 0);
  }, []);
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={12} sm={10} md={10} lg={10} xl={8} className={styles.pageContainer}>
          <Grid className={smDown ? styles.mobileBreadcrumb : styles.breadcrumb} container item xs='auto' alignItems='center' wrap="nowrap">
            <CalendarTodayIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link to={`/v1/${serviceProviderId}/`}>Home</Link>
              <Link to={`/v1/${serviceProviderId}/groupDeals`}>Group Deals</Link>
              <Link
                to={`/v1/${serviceProviderId}/groupDeals/${groupDealId}`}
                className={styles.lastLink}
              >{groupDealId !== 'new' ? 'Group Deal Details' : 'New Group Deal'}</Link>
            </Breadcrumbs>
          </Grid>
          <Grid container item xs={12} textAlign='left' alignItems='center' className={smDown ? styles.mobileHeader : styles.header} spacing={1}>
            <Grid item xs='auto'>
              <Typography
                variant='h5'
                component='span'
                sx={{ marginRight: '8px' }}
                textAlign='left'>{groupDealId !== 'new' ? 'Group Deal Details' : 'New Group Deal'}</Typography>
            </Grid>
            {!smDown && <Spacer/>}
            <Grid item xs='auto' >
              {!isNew && (
                <Button
                  className={styles.editButton}
                  variant='outlined'
                  size='small'
                  type='button'
                  onClick={openConfirmDeleteModal}
                  sx={{
                    'color': theme.palette.tertiary.red,
                    'borderColor': theme.palette.tertiary.red,
                    '&:hover': {
                      borderColor: theme.palette.tertiary.red,
                    },
                  }}
                >
                Delete
                </Button>
              )}
            </Grid>
            {nsoi && nsoi.serviceTypeName &&
              <Grid container item xs={12} textAlign='left'>
                <Typography
                  variant='subtitle2'
                  component='span'
                  textAlign='left'>{nsoi.serviceTypeName}</Typography>
              </Grid>
            }
          </Grid>
          <Grid container className={smDown ? styles.mobileMainContent : styles.mainContent}>
            <GroupDealForm/>
          </Grid>
          <Footer className={smDown ? styles.mobileFooter : styles.footer}/>
        </Grid>
        <ConfirmGroupDealDeleteModal/>
        <RequestNeighborhoodModal/>
      </Grid>
    </Loading>
  );
}