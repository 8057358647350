import { MobileStepper, useTheme } from '@mui/material';
import usePostConfirmModal from '../../hookStore/usePostConfirmModal';

export default function PostConfirmStepper() {
  const theme = useTheme();
  const { activeStep, stepCount } = usePostConfirmModal();
  return (
    <MobileStepper
      variant="dots"
      sx={{
        '& .MuiMobileStepper-dot': {
          backgroundColor: '#d9d9d9',
          width: '29px',
          height: '5px',
          borderRadius: '0',
        },
        '& .MuiMobileStepper-dotActive': {
          backgroundColor: theme.palette.primary.main,
        },
      }}
      steps={stepCount}
      position="static"
      activeStep={activeStep}
      nextButton={<></>}
      backButton={<></>}
    />
  );
}