import EditIcon from '@mui/icons-material/Edit';
import { Alert, Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import get from 'lodash.get';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IMatchingNsoData } from '../hookStore/groupDealStore';
import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './formComponents.module.css';
import { PriceSlash } from 'components/priceSlash';
import { HookPriceField, HookTextField } from 'components/reactHookForm';
import { DiscountScheduleTypes } from 'model/ancillary';
import { IDropdownOption } from 'model/dropdown';
import { formatUSD } from 'util/moneyUtil';

function formatPriceSubtext(matchingNsoData:IMatchingNsoData | null) {
  if (!matchingNsoData?.discountSchedule?.priceSubText) {
    return '';
  }
  return `(${matchingNsoData?.discountSchedule?.priceSubText?.replace('(', '').replace(')', '')})`;
}

function getNeighborhoodName(neighborhoodOptions:IDropdownOption[], watchedNeighborhoodId:string):string {
  let neighborhoodName = 'the neighborhood';
  const matchingNeighborhoods = neighborhoodOptions.filter(x => x.optionValue === watchedNeighborhoodId);
  if (matchingNeighborhoods.length > 0) {
    neighborhoodName = matchingNeighborhoods[0].optionText;
  }
  return neighborhoodName;
}

export interface INeighborhoodPricePreviewProps {
  nsoPrice:string;
  priceLabel?:string;
  priceSubText?:string;
  priceSuffix?:string;
  buttonComponent:JSX.Element;

}
function NeighborhoodPricePreview({
  nsoPrice,
  priceLabel,
  priceSubText,
  priceSuffix,
  buttonComponent,
}:INeighborhoodPricePreviewProps) {

  return (
    <Grid container alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='subtitle2' textAlign='left' sx={{ marginBottom: 2, marginTop: 2 }}>Customers will see:</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={11}>
          <PriceSlash
            originalPrice={''}
            currentPrice={nsoPrice}
            slashPrice={false}
            priceLabel={priceLabel}
            priceSubText={priceSubText}
            priceSuffix={priceSuffix}
            nsoPageStyle
          />
        </Grid>
        <Grid item xs={1}>
          {buttonComponent}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function NeighborhoodPricingField() {
  const theme = useTheme();
  const formContext = useFormContext();
  const [editingNsoPrice, setEditingNsoPrice] = useState<boolean>(false);
  const [matchingNsoData, setMatchingNsoData] = useState<IMatchingNsoData | null>(null);
  const { isEditing, neighborhoodOptions, findMatchingNsoPricingData, nsoDetails } = useGroupDeal();
  const watched = useWatch({ control: formContext.control, name: 'nsoPrice' });
  const watchedNeighborhoodId = useWatch({ control: formContext.control, name: 'neighborhoodId' });
  const watchedServiceOfferingId = useWatch({ control: formContext.control, name: 'serviceOfferingId' });
  const watchedDiscountScheduleKey = useWatch({ control: formContext.control, name: 'discountScheduleKey' });

  async function updateNeighborhoodPricing(neighborhoodId:string, serviceOfferingId:string, discountScheduleKey:string | null) {
    const _matchingNsoData = await findMatchingNsoPricingData(neighborhoodId, serviceOfferingId, discountScheduleKey);
    if (_matchingNsoData.neighborhoodPrice) {
      formContext.setValue('nsoPrice', _matchingNsoData.neighborhoodPrice.toString());
    } else {
      formContext.setValue('nsoPrice', _matchingNsoData.discountSchedule?.priceAmount?.toString());
    }
    setMatchingNsoData(_matchingNsoData);
  }

  useEffect(() => {
    void updateNeighborhoodPricing(watchedNeighborhoodId, watchedServiceOfferingId, watchedDiscountScheduleKey);
    return () => {
      setMatchingNsoData(null);
    };
  }, [watchedNeighborhoodId, watchedServiceOfferingId, watchedDiscountScheduleKey]);
  const isInvalid = formContext.getFieldState('nsoPrice').invalid;
  if (isEditing) {
    let neighborhoodId = nsoDetails?.neighborhoodId;
    let serviceOfferingId = nsoDetails?.offering?.id;
    let discountSchedule = nsoDetails?.discountSchedule;
    let nsoPrice = nsoDetails?.price;
    if (!neighborhoodId || !serviceOfferingId) {
      return null;
    }
    if (discountSchedule.noPriceTextField !== null && discountSchedule?.noPriceTextField !== '') {
      return null;
    }

    return (
      <NeighborhoodPricePreview
        nsoPrice={formatUSD(nsoPrice ?? discountSchedule.priceAmount)}
        priceLabel={discountSchedule.priceLabel}
        priceSubText={discountSchedule.priceSubText}
        priceSuffix={discountSchedule.priceSuffix}
        buttonComponent={(<></>)}
      />
    );
  } else {
    if (!watchedNeighborhoodId || !watchedServiceOfferingId) {
      return null;
    }

    if (matchingNsoData?.discountSchedule?.noPriceTextField !== null && matchingNsoData?.discountSchedule?.noPriceTextField !== '') {
      return null;
    }
    let neighborhoodName = getNeighborhoodName(neighborhoodOptions, watchedNeighborhoodId);
    if (!editingNsoPrice) {
      let nsoPrice = formContext.getValues().nsoPrice;
      return (
        <NeighborhoodPricePreview
          nsoPrice={formatUSD(nsoPrice)}
          priceLabel={matchingNsoData?.discountSchedule?.priceLabel}
          priceSubText={matchingNsoData?.discountSchedule?.priceSubText}
          priceSuffix={matchingNsoData?.discountSchedule?.priceSuffix}
          buttonComponent={(
            <IconButton
              type='button'
              onClick={() => setEditingNsoPrice(true)}
              sx={{ marginTop: 1, marginBottom: 3 }}
            >
              <EditIcon sx={{ color: theme.palette.primary.main }}/>
            </IconButton>
          )}
        />
      );
    }
    return (
      <Grid container alignItems='center'>
        <Grid item xs={8}>
          <HookPriceField
            disabled={isEditing}
            label={`${matchingNsoData?.discountSchedule?.priceLabel} ${formatPriceSubtext(matchingNsoData)}` ?? 'Starting at'}
            name={'nsoPrice'}
            className={styles.nsoPrice}
            validationRules={{
              validate: function (value) {
                if (value === null || value === '' || value === undefined) {
                  return 'Required';
                }
                let discountScheduleAggregate = matchingNsoData?.discountSchedule;
                if (discountScheduleAggregate) {
                  const { priceAmount, discountSchedule, pricingSchedule, type } = discountScheduleAggregate;
                  if (discountSchedule && discountSchedule.maxDiscount && type === DiscountScheduleTypes.DollarSavePerCustomer.value) {
                    if (value - discountSchedule.maxDiscount <= 0) {
                      return 'This price with the max discount would make the total price less than or equal to 0';
                    }
                  } else if (pricingSchedule && pricingSchedule.priceFloor && pricingSchedule.startingAt && type === DiscountScheduleTypes.DollarPerCustomer.value) {
                    if (value < pricingSchedule.priceFloor) {
                      return 'This price is less than the price floor of the discount schedule.';
                    }
                  }
                }
                return true;
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            type='button'
            variant='outlined'
            disabled={isInvalid}
            onClick={() => setEditingNsoPrice(false)}
          >
            <>View</>
          </Button>
        </Grid>
        <Grid item xs={12}>
          {watched && get(formContext, 'formState.dirtyFields.nsoPrice', false) && (
            <Alert severity='warning' sx={{ textAlign: 'left', marginBottom: 3 }}>Setting this will change the price for the base service offering in {neighborhoodName}.</Alert>
          )}
        </Grid>
      </Grid>
    );
  }
}