import { Button, FormControl, FormHelperText, Grid, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import styles from './confirmCustomerDrawer.module.css';
import { HookDatePicker } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { ParticipantStatus } from 'model/status';

export default function ServiceDate() {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { editingServiceDateAndMaxCustomerCount } = useCustomerDetail();
  const watchedStatus = useWatch({ control: formCtx.control, name: 'customerStatus' });
  return (
    <FormControl variant='standard' fullWidth error={isError('serviceDate')} className={styles.serviceDatePicker}>
      <Grid container alignItems='center'>
        <Grid item xs>
          <HookDatePicker
            label='Service Date'
            name='serviceDate'
            textFieldProps={{
              fullWidth: true,
              variant: 'standard',
            }}
            required={watchedStatus === ParticipantStatus.PENDING || watchedStatus === ParticipantStatus.ACTIVE || watchedStatus === ParticipantStatus.COMPLETE}
            datePickerProps={{
              onAccept: () => {},
              InputAdornmentProps: {
                sx: {
                  marginBottom: '12px',
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <FormHelperText>{getErrorMessage('serviceDate')}</FormHelperText>
    </FormControl>
  );
}