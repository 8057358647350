
export interface IServiceCategoryType {
  category:string;
  serviceType:string;
  serviceTypeDisplayName:string | null;
  serviceCategoryDisplayName:string | null;
  categorySortOrder:number;
  categoryColorScheme:string;
  categoryImageName:string;
  createdDate:string;
  deletedDate:string;
  serviceCode:string;
  serviceImageIconName:string;
  serviceTypeSortOrder:number;
  serviceTypeColorScheme:string;
  serviceTypeId:string;
  tags:string[];
  categoryImageGuid:string;
  serviceTypeImageGuid:string;
}

export function createEmptyServiceCategoryType():IServiceCategoryType {
  return {
    category: '',
    serviceType: '',
    serviceTypeDisplayName: '',
    serviceCategoryDisplayName: '',
    categorySortOrder: 0,
    categoryColorScheme: '',
    categoryImageName: '',
    createdDate: '',
    deletedDate: '',
    serviceCode: '',
    serviceImageIconName: '',
    serviceTypeSortOrder: 0,
    serviceTypeColorScheme: '',
    serviceTypeId: '',
    tags: [],
    categoryImageGuid: '',
    serviceTypeImageGuid: '',
  };
}

export interface IServiceCategoryTypeDisplay {
  categoryDisplayName:string | null;
  serviceTypeDisplayName:string | null;
}

export function createEmptyServiceCategoryTypeDisplay():IServiceCategoryTypeDisplay {
  return {
    categoryDisplayName: '',
    serviceTypeDisplayName: '',
  };
}

export function createServiceCategoryTypeDisplay(categoryDisplayName:string | null, serviceTypeDisplayName:string | null):IServiceCategoryTypeDisplay {
  return {
    categoryDisplayName: categoryDisplayName,
    serviceTypeDisplayName: serviceTypeDisplayName,
  };
}