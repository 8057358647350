
import React, { useEffect, useState } from 'react';
import styles from './layout.module.css';
import createStore from 'hooks/hookStore';

export interface IShakeProps {
  renderProps:Function;
  timeout:number;
  storeKey:string;
}

type ShakeStore = {
  animate:boolean;
  timeout:number;
}

export function useShake(storeKey) {
  const { get, registerListener, unregisterListener, update } = createStore<ShakeStore>(storeKey, {
    animate: false,
    timeout: 300,
  }, true);
  const setState = useState(get())[1];

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function setAnimate(animate) {
    let curState = get();
    update({ ...curState, animate });
  }

  return {
    ...get(),
    storeKey,
    setAnimate,
  };
}

export default function Shake({ renderProps, timeout, storeKey }:IShakeProps) {
  const { setAnimate, animate } = useShake(storeKey);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setAnimate(false);
      }, timeout);
    }
  }, [animate]);
  return renderProps(animate ? styles.shake : styles.noshake);
}