import { FormHelperText, FormControl, InputLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useCustomerDetail from '../../hookStore/useCustomerDetail';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';


export interface IStatusChangeFieldProps {
  isRecurring: boolean;
}


export default function SubscriptionOption() {
  const { customer, getSubOptions, isSelectedOptionOneTime } = useCustomerDetail();

  const formContext = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formContext);
  let _options = getSubOptions(customer, true);
  return (
    <FormControl variant='standard' fullWidth error={isError('subscriptionSelection')}>
      <InputLabel htmlFor="customer-status">Subscription package</InputLabel>
      <HookSelect
        control={formContext.control}
        rules={{
          required: { value: true, message: 'Required' },
        }}
        name='subscriptionSelection'
        id='customer-status'
        menuItems={_options}
        postOnChange={(e) => {

        }}
      />
      <FormHelperText>{getErrorMessage('subscriptionSelection')}</FormHelperText>
    </FormControl>
  );
}