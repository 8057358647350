import { Box, Grid, Typography } from '@mui/material';
import useToast from 'components/toast/useToast';
import useServiceCategoryTypeDisplay from 'hooks/useServiceCategoryTypeDisplay';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { CadenceType } from 'model/ancillary';
import { formatServiceRequestDateRange, schedulingPreferenceDisplayValue } from 'model/customer';
import { customerRemoveReasons } from 'model/removeCustomerForm';
import { IServiceSubscriptionOptions, ISubscriptionOption } from 'model/serviceOffering';
import { Link, useParams } from 'react-router-dom';
import { createDateIgnoreTimezone, formatLongMonthDayYear } from 'util/dateUtil';
import { formatPhoneNumber } from 'util/formatUtil';
import CopyButton from './common/CopyButton';
import styles from './customer.module.css';
import useCustomerDetail from './hookStore/useCustomerDetail';


function getSubscriptionSelectionDisplayText(subscriptionOptions:IServiceSubscriptionOptions | null, subscriptionValue:string):string {
  if (subscriptionOptions === null) {
    return 'None';
  }
  if (subscriptionOptions.options) {
    let _options: ISubscriptionOption[] = subscriptionOptions.options;
    let matching = _options.filter(x=> {
      return x.optionValue === subscriptionValue;
    });
    if (matching.length > 0) {
      return matching[0].optionName;
    }
  }
  return 'None';
}

function DetailSection({ label, content }:any) {

  return (
    <Grid container className={styles.detailSection} alignContent='flex-start' >
      <Grid item xs={12} className={styles.detailSectionLabel} >
        {label}
      </Grid>
      <Grid item xs={12} textAlign='left' className={styles.detailSectionContent}>
        {content}
      </Grid>
    </Grid>
  );
}

function TextContent({ children, colorOverride }:any) {
  return (
    <Typography variant='body1' component='span' sx={{ color: colorOverride ?? '#000' }}>{children}</Typography>
  );
}

function TextLink({ children }:any) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant='body1' component='div' sx={{ '&&': { lineHeight: 'initial' } }}>{children}</Typography>
    </Box>
  );
}


export default function Detail() {
  const { createInfoToast } = useToast();
  const {
    customer,
    happeningSoonCompatibleNsoi,
    additionalServiceItems,
    customFieldSelections,
  } = useCustomerDetail();
  const { serviceProviderId } = useParams();
  const serviceRequestDate = formatServiceRequestDateRange(customer);
  const { trackButtonClick } = useStreetfairAnalytics();
  const { getServiceTypeDisplay } = useServiceCategoryTypeDisplay();


  let streetAddress = customer.streetAddress;

  const neighborhoodServiceOfferingId = customer.neighborhoodServiceOfferingId;
  const neighborhoodServiceOfferingInstanceId = customer.neighborhoodServiceOfferingInstanceId;
  const serviceType = customer.serviceType;
  let mapApp = 'https://maps.google.com/?q=';
  //'https://maps.apple.com/maps?q=';

  if (customer.streetAddress2) {
    streetAddress += ` ${customer.streetAddress2}`;
  }
  if (customer.city) {
    streetAddress += ` ${customer.city}`;
  }
  if (customer.state) {
    streetAddress += `, ${customer.state}`;
  }
  if (customer.zipCode) {
    streetAddress += ` ${customer.zipCode}`;
  }
  return (
    <>
      {/* {happeningSoonCompatibleNsoi && (
        <Box sx={{ marginBottom: '10px' }}>
          <HappeningSoonDemo />
        </Box>
      )} */}
      {/* {!happeningSoonCompatibleNsoi && (
        <DetailSection
          label={<span></span>}
          content={(
            <TextContent>
              <div onClick={() => {
                void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_NSO_LINK);
              }}>
                <a
                  target="_blank"
                  href={`${window.REACT_APP_STREETFAIR_URL}/provider-view/neighborhood-service-offering/${customer.neighborhoodServiceOfferingId}`}
                  style={{ fontSize: '18px' }}
                >
                  <>View {customer.serviceType} listing here</>
                </a>
              </div>
            </TextContent>
          )}
        />
      )} */}
      {/* {customer.neighborhoodServiceOfferingInstanceServiceDate &&
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Group Deal</Typography>}
          content={(
            <div onClick={() => {
              void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_VIEW_GROUP_DEAL_LINK);
            }}>
              <Link to={`/v1/${serviceProviderId}/groupDeals/${customer.neighborhoodServiceOfferingInstanceId}`}>View</Link>
            </div>
          )}
        />
      } */}
      <DetailSection
        label={<Typography variant='caption' component='div' textAlign='left'>Personal Information</Typography>}
        content={(
          <>
            <Grid container sx={{ paddingBottom: '8px;' }} alignItems='center'>
              <Grid item xs='auto'>
                <a href={`${mapApp}${encodeURIComponent(streetAddress)}`} target="_blank" >
                  <>
                    {customer.streetAddress && (
                      <Grid container>
                        <TextContent colorOverride='blue'>{customer.streetAddress}</TextContent>
                      </Grid>
                    )}
                    {customer.streetAddress2 && (
                      <Grid container>
                        <TextContent colorOverride='blue'>{customer.streetAddress2}</TextContent>
                      </Grid>
                    )}
                    {customer.city && customer.state && customer.zipCode && (
                      <Grid container>
                        <TextContent colorOverride='blue'>{customer.city}, {customer.state} {customer.zipCode}</TextContent>
                      </Grid>
                    )}
                  </>
                </a>
              </Grid>
              <Grid item xs='auto'>
                <CopyButton message={streetAddress}/>
              </Grid>
            </Grid>
            {customer.phone && (
              <Grid container alignItems='center' sx={{ paddingBottom: '8px;' }}>
                <Grid item xs='auto'>
                  <TextLink><a href={`tel:${customer.phone}`}>{formatPhoneNumber(customer.phone)}</a></TextLink>
                </Grid>
                <Grid item xs='auto'>
                  <CopyButton message={formatPhoneNumber(customer.phone)}/>
                </Grid>
              </Grid>
            )}
            {customer.email && customer.shouldShowEmail && (
              <Grid container alignItems='center' sx={{ paddingBottom: '8px;' }}>
                <Grid item xs='auto'>
                  <TextLink>
                    <a
                      href={`mailto:${customer.email}`}
                      className={styles.textLinkEmail}
                    >
                      <>{customer.email}</>
                    </a>
                  </TextLink>
                </Grid>
                <Grid item xs='auto'>
                  <CopyButton message={customer.email}/>
                </Grid>
              </Grid>
            )}
          </>
        )}
      />
      <DetailSection
        label={<Typography variant='caption' component='div' textAlign='left'>Service Requested</Typography>}
        content={<TextContent><span style={{ textTransform: 'capitalize' }}>{
          customer.serviceTypeNameOverride ?? getServiceTypeDisplay(customer.serviceCategory, customer.serviceType)
        }</span></TextContent>}
      />
      {serviceRequestDate && !customer.neighborhoodServiceOfferingInstanceServiceDate && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Requested Service Date</Typography>}
          content={<TextContent>{serviceRequestDate || 'N/A'}</TextContent>}
        />
      )}
      {additionalServiceItems.length > 0 && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Additional Services</Typography>}
          content={(
            <ul style={{ margin: 0 }}>
              {additionalServiceItems.map(x => (
                <li key={x.value}>
                  {x.value}
                </li>
              ))}
            </ul>
          )}
        />
      )}
      {customer.subscriptionSelection && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Subscription Option</Typography>}
          content={<TextContent>{getSubscriptionSelectionDisplayText(customer.subscriptionOptions, customer.subscriptionSelection)}</TextContent>}
        />
      )}
      {customer.oneTimeOnlyOption && customer.cadenceType === CadenceType.MOSTLY_SUBSCRIPTION && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>One Time Option</Typography>}
          content={<TextContent>{customer.oneTimeOnlyOption}</TextContent>}
        />
      )}
      {customFieldSelections.map((x, i) => (
        <DetailSection
          key={i}
          label={<Typography variant='caption' component='div' textAlign='left'>{x.fieldName || x.label}</Typography>}
          content={<TextContent>{x.value}</TextContent>}
        />
      ))}
      <DetailSection
        label={<Typography variant='caption' component='div' textAlign='left'>Comments</Typography>}
        content={(
          <TextContent>
            <>{customer.comment || 'None'}</>
            {customer.comment && <CopyButton message={customer.comment}/>}
          </TextContent>
        )}
      />
      {customer.schedulingPreference && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Scheduling Preference</Typography>}
          content={<TextContent>{schedulingPreferenceDisplayValue(customer.schedulingPreference)}</TextContent>}
        />
      )}
      {customer.neighborhoodServiceOfferingInstanceServiceDate && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Group Deal Service Date</Typography>}
          content={<TextContent>{formatLongMonthDayYear(createDateIgnoreTimezone(customer.neighborhoodServiceOfferingInstanceServiceDate)) || 'N/A'}
            {customer.neighborhoodServiceOfferingInstanceEndServiceDate && customer.neighborhoodServiceOfferingInstanceEndServiceDate.length > 0 &&
              ' - ' + formatLongMonthDayYear(createDateIgnoreTimezone(customer.neighborhoodServiceOfferingInstanceEndServiceDate))
            }
          </TextContent>}
        />
      )}
      {customer.communicationPreference && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Communication Preference</Typography>}
          content={<TextContent><span style={{ textTransform: 'capitalize' }}>{customer.communicationPreference}</span></TextContent>}
        />
      )}
      {customer.hasCreditCard && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Payment Method</Typography>}
          content={<TextContent><span style={{ textTransform: 'capitalize' }}>Card on file</span></TextContent>}
        />
      )}
      <DetailSection
        label={<Typography variant='caption' component='div' textAlign='left'>Neighborhood</Typography>}
        content={(customer.neighborhoodName && (
          <>
            <TextContent>{customer.neighborhoodName || 'Not set'} </TextContent>
            <CopyButton message={customer.neighborhoodName}/>
          </>
        ))}
      />


      {customer.joinDateTime && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Submitted On</Typography>}
          content={<TextContent>{formatLongMonthDayYear(createDateIgnoreTimezone(customer.joinDateTime)) || 'N/A'}</TextContent>}
        />
      )}


      {customer.participantRemoveReason && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Cancelation Reason</Typography>}
          content={(
            <TextContent>
              {customerRemoveReasons.filter(x=> x.optionValue === customer.participantRemoveReason)[0].optionText}
            </TextContent>
          )}
        />
      )}

      {customer.participantRemoveComment && (
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Cancelation Comments</Typography>}
          content={(
            <TextContent>
              <>{customer.participantRemoveComment || 'None'}</>
            </TextContent>
          )}
        />
      )}
      {customer.neighborhoodServiceOfferingInstanceServiceDate &&
        <DetailSection
          label={<Typography variant='caption' component='div' textAlign='left'>Group Deal</Typography>}
          content={(
            <div onClick={() => {
              void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_VIEW_GROUP_DEAL_LINK);
            }}>
              <Link to={`/v1/${serviceProviderId}/groupDeals/${customer.neighborhoodServiceOfferingInstanceId}`}>View</Link>
            </div>
          )}
        />
      }
    </>
  );
}