import { GridCallbackDetails, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import { getSelfRoles } from 'api/individualApi';
import { findAllNeighborhoods } from 'api/neighborhoodApi';
import { findServiceInstancesByServiceProviderId } from 'api/serviceInstanceApi';
import { findServiceOfferingsByServiceProvider } from 'api/serviceOfferingApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import useServiceCategoryTypeDisplay from 'hooks/useServiceCategoryTypeDisplay';
import { IDropdownOption, UNSELECTED_OPTION, createDefaultDropdownOption } from 'model/dropdown';
import { INeighborhoodServiceOfferingInstanceSearchDTO, createEmptyNeighborhoodServiceOfferingInstanceSearchDTO } from 'model/groupDealsTable';
import { INeighborhood } from 'model/neighborhood';
import { IGroupDealTableNeighborhoodServiceOfferingInstance, INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import { IServiceOffering } from 'model/serviceOffering';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logPageView from 'util/analyticsUtil';
import { dedupe } from 'util/arrayUtil';
import { containsProviderAdmin } from 'util/role';
import { sortByDateDesc, sortByString } from 'util/sortFunctions';

const loadingKey = 'groupDealsTable';

const sortByDate = sortByDateDesc('serviceDate');

type GroupDealsTableData = {
  neighborhoodServiceOfferingInstances: INeighborhoodServiceOfferingInstance[];
  filteredNeighborhoodServiceOfferingInstances:INeighborhoodServiceOfferingInstance[];
  filter: INeighborhoodServiceOfferingInstanceSearchDTO;
  sortModel:any;
  neighborhoodOptions:IDropdownOption[];
  shouldShowPastDeals:boolean;
  neighborhoodMap: Map<string, INeighborhood>;
  isProviderAdmin: boolean;
}

const { get, update, registerListener, unregisterListener } = createStore<GroupDealsTableData>('groupDealsTable', {
  neighborhoodServiceOfferingInstances: [],
  filteredNeighborhoodServiceOfferingInstances: [],
  filter: createEmptyNeighborhoodServiceOfferingInstanceSearchDTO(),
  sortModel: [{
    field: 'serviceDate',
    sort: 'asc' as any,
  }],
  neighborhoodOptions: [createDefaultDropdownOption()],
  shouldShowPastDeals: false,
  neighborhoodMap: new Map<string, INeighborhood>(),
  isProviderAdmin: false,
});

const quickSearchStorageKey = 'groupDeals';
export default function useGroupDealsTable() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { createInfoToast } = useToast();
  const { getServiceTypeDisplay } = useServiceCategoryTypeDisplay();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { serviceProviderId } = useParams();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    logPageView(serviceProviderId);
    await refresh();
    doneLoading();
  }

  async function refresh() {
    try {
      const { neighborhoodMap } = get();
      if (serviceProviderId) {
        const { filter, shouldShowPastDeals } = get();
        let queryParams = {
          shouldShowPastDeals,
        };
        const rolesRes = await getSelfRoles();
        const isProviderAdmin = containsProviderAdmin(rolesRes.data);
        let [nsoisRes, neighborhoodsRes, serviceOfferingsRes] = await Promise.all([
          findServiceInstancesByServiceProviderId(serviceProviderId, queryParams),
          findAllNeighborhoods(),
          findServiceOfferingsByServiceProvider(serviceProviderId),
        ]);
        let _neighborhoodMap = createNeighborhoodMap(neighborhoodsRes.data);
        let _serviceOfferingMap = createServiceOfferingMap(serviceOfferingsRes.data);
        let nsois = nsoisRes.data || [];
        let sortedNsois = nsois.map(x => {
          var so =_serviceOfferingMap.get(x.serviceOfferingId);
          return {
            ...x,
            serviceTypeName: so?.serviceCategory && so.serviceType ? getServiceTypeDisplay(so?.serviceCategory, so?.serviceType) : x.serviceTypeName,
          };
        }).sort(sortByDate);
        let neighborhoodOptions = extractNeighborhoods(sortedNsois);
        const filteredNeighborhoodServiceOfferingInstances = filterData(sortedNsois, filter, _neighborhoodMap);
        update({
          ...get(),
          neighborhoodServiceOfferingInstances: sortedNsois,
          filteredNeighborhoodServiceOfferingInstances,
          neighborhoodOptions,
          filter,
          neighborhoodMap: _neighborhoodMap,
          isProviderAdmin,
        });

      } else {
        createInfoToast('No ID for service provider.');
      }
    } catch (e:any) {
      console.error(e);
    }
  }

  function createNeighborhoodMap(neighborhoods:INeighborhood[]):Map<string, INeighborhood> {
    var map = new Map<string, INeighborhood>();

    for (let i = 0; i < neighborhoods.length; i++) {
      let neighborhood = neighborhoods[i];
      if (!map.has(neighborhood.id)) {
        map.set(neighborhood.id, neighborhood);
      }
    }
    return map;
  }

  function createServiceOfferingMap(serviceOfferings:IServiceOffering[]):Map<string, IServiceOffering> {
    var map = new Map<string, IServiceOffering>();

    for (let i = 0; i < serviceOfferings.length; i++) {
      let so = serviceOfferings[i];
      if (so.id && !map.has(so.id)) {
        map.set(so.id, so);
      }
    }
    return map;
  }

  function extractNeighborhoods(nsois:INeighborhoodServiceOfferingInstance[]):IDropdownOption[] {
    const optionsWithDupes = nsois
      .map( x=> ({ key: x.neighborhoodId, optionValue: x.neighborhoodId, optionText: x.neighborhoodName }))
      .filter( x=> x.key && x.optionText);
    const deduped = dedupe(optionsWithDupes, 'key');
    let result = Array.from(deduped);
    result.unshift(createDefaultDropdownOption());
    result.sort(sortByString('optionText'));
    return result;
  }


  function onTableFilterChange(propertyName, propertyValue) {
    const { filter } = get();
    const nextFilter = { ...filter };
    nextFilter[propertyName] = propertyValue;
    _updateWithFilter(nextFilter);
  }

  function filterData(
    nsois:INeighborhoodServiceOfferingInstance[],
    newFilter:INeighborhoodServiceOfferingInstanceSearchDTO,
    neighborhoodMap:Map<string, INeighborhood>,
  ): IGroupDealTableNeighborhoodServiceOfferingInstance[] {
    const filteredNeighborhoodServiceOfferingInstances = nsois.filter(x => {
      let neighborhoodPass = false;
      if (newFilter.neighborhoodId === UNSELECTED_OPTION) {
        neighborhoodPass = true;
      } else {
        neighborhoodPass = newFilter.neighborhoodId === x.neighborhoodId;
      }
      return neighborhoodPass;
    }).map(x => {
      return {
        ...x,
        neighborhood: neighborhoodMap.get(x.neighborhoodId),
      } as IGroupDealTableNeighborhoodServiceOfferingInstance;
    });
    return filteredNeighborhoodServiceOfferingInstances;
  }

  function onSortModelChange(sortModel) {
    update({
      ...get(),
      sortModel,
    });
  }

  function navigateToDetail(params:GridRowParams, event:MuiEvent<React.MouseEvent>, details:GridCallbackDetails) {
    navigate(`/v1/${serviceProviderId}/groupDeals/${params.row.id}`);
  }

  function resetFiltersToDefault() {
    let nextFilter = createEmptyNeighborhoodServiceOfferingInstanceSearchDTO();
    _updateWithFilter(nextFilter);
  }

  function _updateWithFilter(nextFilter:INeighborhoodServiceOfferingInstanceSearchDTO) {
    const { neighborhoodServiceOfferingInstances, neighborhoodMap } = get();
    const filteredNeighborhoodServiceOfferingInstances = filterData(neighborhoodServiceOfferingInstances, nextFilter, neighborhoodMap);
    update({
      ...get(),
      filter: nextFilter,
      filteredNeighborhoodServiceOfferingInstances,
    });
  }

  function onShouldShowPastDealsChange(nextVal) {
    update({
      ...get(),
      shouldShowPastDeals: nextVal,
    });
    void refresh();
  }
  return {
    loadingKey,
    quickSearchStorageKey,
    ...get(),
    init,
    refresh,
    onTableFilterChange,
    onSortModelChange,
    navigateToDetail,
    resetFiltersToDefault,
    onShouldShowPastDealsChange,
  };
}

