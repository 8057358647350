import { Grid } from '@mui/material';
import WizardButton from './WizardButton';
import usePostConfirmModal from 'pages/customer/hookStore/usePostConfirmModal';

export default function SlideButtons() {
  const { activeStep } = usePostConfirmModal();

  return (
    <Grid container >
      {activeStep > 0 && (
        <Grid container item xs={12} sm={6} justifyContent='center' >
          <WizardButton backVariant/>
        </Grid>
      )}
      <Grid container item xs={12} sm={activeStep > 0 ? 6 : 12} justifyContent='center'
        sx={{
          order: {
            xs: -1,
            sm: 0,
          },
          marginBottom: {
            xs: 3,
            sm: 0,
          },
        }}>
        <WizardButton/>
      </Grid>
    </Grid>
  );
}