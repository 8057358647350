import { Typography, useTheme } from '@mui/material';
import React from 'react';
import styles from './ActionItem.module.css';
import PressIn, { usePressInStore } from 'components/layout/PressIn';

export interface IActionItemProps {
  title:string;
  count:number;
  view:any;
  onClick:(e:React.SyntheticEvent) => void;
}
export default function ActionItem({
  onClick,
  title,
  count,
  view,
}:IActionItemProps) {
  const theme = useTheme();
  const { setAnimate } = usePressInStore(title);
  const countBackground = theme.palette.tertiary.altExtraLight;
  const countColor = theme.palette.secondary.light;
  function onClickWrapper(e:React.SyntheticEvent) {
    setAnimate(true);
    onClick(e);
  }

  return (
    <PressIn
      storeKey={title}
      timeout={300}
      renderProps={(animatedClassName) => (
        <div className={`${styles.wrapper} ${animatedClassName}`} onClick={onClickWrapper}>
          <div className={styles.count} style={{ background: countBackground, color: countColor }}>{count}</div>
          <div className={styles.titleAndViewWrapper}>
            <Typography variant='body2' className={styles.title}>{title}</Typography>
            <div className={styles.view}>
              {view}
            </div>
          </div>
        </div>
      )}
    />

  );
}