import HelpIcon from '@mui/icons-material/Help';
import { AccordionSummary, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ParticipantStatus } from 'model/ancillary';
import { ICustomer } from 'model/customer';
import { formatDateRangeLocalDate, formatLongMonthDayYear } from 'util/dateUtil';
import styles from './customerAccordion.module.css';
export interface ISummaryProps {
  customer: ICustomer;
  expanded: boolean;
}

function StatusTag({ status, providerUploadedCustomer }: any) {
  if (providerUploadedCustomer) {
    return (
      <div className={`${styles.statusTag}`}>
      </div>
    );
  }
  if (status === ParticipantStatus.PENDING) {
    return (
      <div className={`${styles.statusTag} ${styles.pendingTag}`}>
        Request Pending
      </div>
    );
  } else if (status === ParticipantStatus.COMPLETE) {
    return (
      <div className={`${styles.statusTag} ${styles.completeTag}`}>
        Complete
      </div>
    );
  }
  return null;
}

export default function Summary({ customer, expanded }: ISummaryProps) {
  const theme = useTheme();
  const { placeholder, firstName, lastName, streetAddress, neighborhoodName, serviceDate, endServiceDate, status, providerUploadedCustomer } = customer;

  return (
    <AccordionSummary
      expandIcon={!placeholder ? (
        <Grid container >
          <Grid container item xs={12} sx={{ height: '33%', width: { xs: '50px', sm: '120px' } }} alignItems='flex-start' justifyContent='flex-end'>
            <StatusTag status={status} providerUploadedCustomer={providerUploadedCustomer}/>
          </Grid>
          <Grid container item xs={12} sx={{ height: '33%' }} alignItems='center' justifyContent='flex-end'>
            <Typography variant='subtitle2' component='div' color='primary'>{expanded ? 'Close' : 'Options'}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ height: '33%' }}>

          </Grid>
        </Grid>
      ) : (
        <span></span>
      )}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{
        'minHeight': 100,
        '&.MuiAccordionSummary-root': {
          alignItems: 'stretch',
          paddingRight: 0,
        },
        '&.MuiAccordionSummary-root.Mui-disabled': {
          opacity: 1,
        },
        '&.MuiAccordionSummary-root.Mui-disabled .MuiSvgIcon-root': {
          pointerEvents: 'all',
        },
        '& .MuiAccordionSummary-expandIconWrapper .MuiTypography-root': {
          transition: 'color 0.5s',
          paddingRight: 2,
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiTypography-root': {
          color: theme.palette.secondary.dark,
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(0deg)',
        },
      }}
    >
      {
        placeholder ? (
          <Box sx={{ textAlign: 'left' }}>
            <Typography component='div' variant='subtitle2' sx={{ color: theme.textColor.secondary }}>{firstName ? `${firstName[0]}.` : ''} {lastName ? `${lastName[0]}.` : ''}</Typography>
            <Grid container alignItems='center'>
              <Grid item sx={{ marginRight: '4px' }}>
                <Typography variant='body1'>Placeholder Customer</Typography>
              </Grid>
              <Grid item>
                <Tooltip title={`Set up by StreetFair team to start this group deal in ${neighborhoodName}`} enterTouchDelay={0}>
                  <HelpIcon sx={{ color: theme.textColor.secondary }} />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        ) :
          (
            <Box sx={{ textAlign: 'left', width: { xs: '150px', sm: '200px' } }}>
              <Typography component='div' variant='subtitle2'>{firstName} {lastName}</Typography>
              <Typography component='div' variant='subtitle1' style={{ fontSize: '13px' }}>{streetAddress}</Typography>
              <Typography component='div' variant='body2'>{neighborhoodName}</Typography>
              {serviceDate && endServiceDate && (
                <Typography
                  component='div'
                  sx={{ fontSize: '12px' }}
                  variant='body2'>{formatDateRangeLocalDate(serviceDate, endServiceDate)}</Typography>
              )}
              {serviceDate && !endServiceDate && (
                <Typography component='div' variant='body2'>{formatLongMonthDayYear(serviceDate)}</Typography>
              )}

            </Box>
          )
      }

    </AccordionSummary>
  );
}