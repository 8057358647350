
export const ProviderSupportRequestTypes = {
  GROUP_DEAL_NEW_NEIGHBORHOOD_REQUEST: 'GROUP_DEAL_NEW_NEIGHBORHOOD_REQUEST',
  CUSTOMER_DETAIL_REQUEST: 'CUSTOMER_DETAIL_REQUEST',
};

export interface IProviderSupportRequest {
  supportRequestType:string;
  message:string;
  serviceProviderId:string;
  customerId?:string;
}

export interface IServiceProvider {
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonPhone: string;
  id: string;
  imageUrlGuid:string;
  aboutUs?: string;
  legacyId?: number; //to be removed
  name: string;
  rating: {
    numberOfRatings: number;
    rating: number;
  };
  status: string;
  venmoId?: string;
  cashAppId?: string;
  hipFireProvider: boolean;
}

export function createEmptyServiceProvider():IServiceProvider {
  return {
    contactPersonEmail: '',
    contactPersonName: '',
    contactPersonPhone: '',
    id: '',
    imageUrlGuid: '',
    aboutUs: '',
    legacyId: 0,
    name: '',
    rating: {
      numberOfRatings: 0,
      rating: 0,
    },
    status: '',
    venmoId: '',
    cashAppId: '',
    hipFireProvider: false,
  };
}