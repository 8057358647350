
export interface ITermsAndConditionsData {
  oneTimePercent: number | null;
  recurringPercent:number | null;
  agreedToPartnership:boolean | null;
  agreedToPartnershipDate:string | null;
  customConditions: string | null;
}

export function createEmptyTermsAndConditionsForm():ITermsAndConditionsData {
  return {
    oneTimePercent: null,
    recurringPercent: null,
    agreedToPartnership: false,
    agreedToPartnershipDate: '',
    customConditions: '',
  };
}