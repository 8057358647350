import { Grid, Typography } from '@mui/material';

import { CloseButton } from 'components/buttons';
import { HookPriceField, HookTextField } from 'components/reactHookForm';
import get from 'lodash.get';
import { useFormContext, useWatch } from 'react-hook-form';
import useCustomerDetail from '../../hookStore/useCustomerDetail';
import styles from '../confirmCustomerDrawer.module.css';
import OneTimeChargePriceCalculation from './OneTimeChargePriceCalculation';


export default function OneTimeChargeServiceItemCard({ index, remove }:any) {
  const { getValues, control, formState } = useFormContext();
  const {
    customer,
    firstServiceItemHasPricingSchedule,
  } = useCustomerDetail();
  const { subscribers } = customer;
  const serviceItem = get(getValues(), `oneTimeChargeServiceItems.${index}`, null);

  const watchedPrice = useWatch({ control, name: `oneTimeChargeServiceItems.${index}.price` });
  const watchedUnits = useWatch({ control, name: `oneTimeChargeServiceItems.${index}.units` });
  const watchUnitBasedPrice = useWatch({ control, name: `oneTimeChargeServiceItems.${index}.unitBasedPrice` });
  const watchSubOption = useWatch({ control, name: 'subscriptionSelection' });


  return (
    <div className={styles.serviceItemCard}>
      <div className={styles.serviceItemCardContent}>
        <div className={styles.serviceItemCardTop}>
          <div className={styles.serviceItemCardHeader}>
            <Typography variant='subtitle2' component='span'>One Time Charge</Typography>
            <CloseButton
              tooltipTitle="remove one time charge"
              onClick={() => {remove(index);}}
              iconButtonProps={{
                color: 'primary',
              }}
            />
          </div>
          <HookTextField
            required
            label='Service Item'
            name={`oneTimeChargeServiceItems.${index}.name`}
            className={styles.serviceItemName}
          />

          <Grid container spacing={1} className={styles.unitBasedFields}>
            <Grid item xs={12}>
              <HookPriceField
                required
                allowNegative={true}
                label='Price'
                name={`oneTimeChargeServiceItems.${index}.unitBasedPrice`}
              />
            </Grid>
          </Grid>

        </div>
        <OneTimeChargePriceCalculation
          index={index}
          serviceItem={serviceItem}
        />
      </div>
    </div>
  );
}