import { Alert, Grid, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { INeighborhood, activeOrGreater, alphaOrBeta } from 'model/neighborhood';
import useGroupDealCreationStore from 'pages/groupDeal/hookStore/useGroupDealCreationStore';

export interface INeighborhoodStatusLineProps {
  neighborhood?: INeighborhood | null;
  showCreateNeighborhood?: boolean;
}

export interface INeighborhoodErrorProps {
  neighborhood?:INeighborhood | null;
  showCreateNeighborhood?: boolean;
}

export default function NeighborhoodStatusLine({
  neighborhood,
  showCreateNeighborhood,
}:INeighborhoodStatusLineProps) {
  const {} = useGroupDealCreationStore();
  const { gdmCreateNewNeighborhoodJun2023 } = useFlags();
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} justifyContent='flex-start'>
        {neighborhood && neighborhood.id && (
          <>
            <Typography
              component='span'
              sx={{
                fontWeight: 'bold',
                paddingLeft: { xs: '0', sm: '10px' },
                paddingRight: '10px',
              }}
            >
              <>Neighborhood: </>
            </Typography>
            <Typography
              component='span'
              variant='body1'
            >
              <>{neighborhood?.name ?? ''}</>
            </Typography>
          </>
        )}

      </Grid>
      {neighborhood && neighborhood.id && (
        <Grid container item xs={12} lg={6} justifyContent='flex-start' sx={{ paddingTop: { xs: '8px' } }} >
          <Grid container item xs='auto' >
            <Grid container item alignItems='center' sx={{ height: '36px' }}>
              <Typography
                component='span'
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: { xs: '0', sm: '10px' },
                  paddingRight: '10px',
                }}>Neighborhood Status: </Typography>
            </Grid>

          </Grid>
          <Grid container item alignItems='center' xs>
            <NeighborhoodStatus
              neighborhoodStatus={neighborhood?.status}
              notFound={neighborhood === null || neighborhood === undefined}
            />
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} justifyContent='flex-start' sx={{ paddingTop: { xs: '8px' } }}>
        <NeighborhoodError neighborhood={neighborhood} showCreateNeighborhood={gdmCreateNewNeighborhoodJun2023 && showCreateNeighborhood}/>
      </Grid>
    </Grid>
  );
}

function NeighborhoodError({ neighborhood, showCreateNeighborhood }:INeighborhoodErrorProps) {
  if (showCreateNeighborhood) {
    return (
      <>
        <Alert severity='info' sx={{ textAlign: 'left', width: '100%' }}>
        Could not find a neighborhood associated with this address. Please create a new neighborhood.
        </Alert>
      </>
    );
  }
  if (neighborhood === undefined || (neighborhood?.status && activeOrGreater(neighborhood?.status))) {
    return null;
  }
  if (neighborhood?.status && alphaOrBeta(neighborhood?.status)) {
    return (
      <Alert severity='warning' sx={{ textAlign: 'left', width: '100%' }}>
        There are no StreetFair users in this neighborhood yet. The customer must share the link for others to sign up.
      </Alert>
    );
  }
  return (
    <Alert severity='error' sx={{ textAlign: 'left', width: '100%' }}>
        Could not find a neighborhood associated with this address. Please contact StreetFair support at support@streetfair.com or call 704-686-8070 to have the neighborhood created.
    </Alert>
  );
}

export interface INeighborhoodStatusProps{
  neighborhoodStatus?:string;
  notFound?:boolean;
}
function NeighborhoodStatus({ neighborhoodStatus, notFound }:INeighborhoodStatusProps) {
  if (!neighborhoodStatus || notFound) {
    return null;
  }
  if (activeOrGreater(neighborhoodStatus)) {
    return (
      <Grid container item alignItems='center' flexWrap={'nowrap'}>
        <Typography variant='body1' color='primary' textAlign='left' component='span'> Live</Typography>

      </Grid>
    );
  }
  return (
    <Grid container item alignItems='center' flexWrap={'nowrap'}>
      <Typography variant='body1' color='disabled' textAlign='left' component='span'> Not Yet Live</Typography>

    </Grid>
  );
}