import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { chargeConsumer } from 'api/paymentsApi';

export default function ChargeCard (props: any) {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const onSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    await chargeCard(amount, props.providerId, props.individualId, props.email, props.customerId, props.subscriberId);
    props.fetchCharges();
    setLoading(false);
  };

  const chargeCard = async (currentAmount, providerId, individualId, email, customerId, subscriberId) => {
    const paymentData = { amount: currentAmount, providerId, email, customerId, subscriberId };

    const response = await chargeConsumer(individualId, paymentData);

    return response;
  };

  return (
    <Row className="justify-content-center">
      <Col sm={12} lg={8}>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicAmount">
            <Form.Label>Amount in Dollars</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in Dollars"
              value={amount}
              onChange={e => setAmount(e.target.value)}
            />
          </Form.Group>
          {loading && <CircularProgress></CircularProgress>}
          {!loading && (
            <Button variant="primary" type="submit">
              Submit
            </Button>
          )}
        </Form>
      </Col>
    </Row>
  );
}