import { Grid, Typography } from '@mui/material';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePostConfirmModal from '../hookStore/usePostConfirmModal';
import PostConfirmStepper from './components/PostConfirmStepper';
import SlideButtons from './components/SlideButtons';
import SlideDescription from './components/SlideDescription';
import SlideSubtitle from './components/SlideSubtitle';
import SlideTitle from './components/SlideTitle';
import WizardButton from './components/WizardButton';
import { IModalSlideProps } from './modalSlide';
import styles from './postConfirmModal.module.css';
import piggyBank from 'images/customer_confirmed/piggy_bank.png';

export default function PriceDropAlertSlide({ title, subtitle, description, description2 }: IModalSlideProps) {

  return (
    <Grid container justifyContent='center' >
      <Grid container item xs={12} justifyContent='center'>
        <SlideTitle>
          {title}
        </SlideTitle>
      </Grid>
      <Grid item xs='auto'>
        <img src={piggyBank} alt='piggy bank'/>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs='auto' >
        <PostConfirmStepper/>
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <SlideSubtitle>{subtitle}</SlideSubtitle>
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <SlideDescription>{description}</SlideDescription>
      </Grid>
      {description2 && (
        <Grid container item xs={12} justifyContent='center'>
          <SlideDescription>{description2}</SlideDescription>
        </Grid>
      )}
      <SlideButtons/>
    </Grid>
  );
}