import { Accordion, AccordionDetails, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomerActions from './CustomerActions';
import Summary from './Summary';
import { IGroupDealCustomer } from 'model/customer';
import { ICustomerAccordionState } from 'model/groupDeal';
import useGroupDeal from 'pages/groupDeal/hookStore/useGroupDeal';

export interface ICustomerAccordionProps {
  onlyOneCustomer: boolean;
  shouldShowMaxCustomerCount:boolean;
  index: number;
  onRemove: () => void;
  neighborhoodId: string;
}

export default function CustomerAccordion({ index, shouldShowMaxCustomerCount, onlyOneCustomer, onRemove, neighborhoodId }: ICustomerAccordionProps) {
  const { getValues } = useFormContext();
  const { refreshAvailableNsoisToMove, customerAccordionStateMap, updateCustomerAccordion } = useGroupDeal();
  const customer = getValues(`customers.${index}`) as IGroupDealCustomer;
  let isPlaceholder = getValues(`customers.${index}.placeholder`);
  const expanded = customerAccordionStateMap.get(customer.id)?.expanded ?? false;
  return (
    <Accordion
      expanded={expanded}
      onChange={async (e, nextExpanded: boolean) => {
        if (!isPlaceholder) {
          if (!nextExpanded) {
            updateCustomerAccordion(customer.id, { expanded: nextExpanded, loading: false, shouldShowMoveToGroupDeal: false, shouldShowMoveToPlaceholderGroupDeal: false });
          } else {
            updateCustomerAccordion(customer.id, { expanded: nextExpanded, loading: true, shouldShowMoveToGroupDeal: false, shouldShowMoveToPlaceholderGroupDeal: false });
            await refreshAvailableNsoisToMove(customer);
          }
        }
      }}
      disabled={isPlaceholder}
      sx={{
        '&.MuiAccordion-root.Mui-disabled': {
          background: '#FAFAFA',
        },
        '&.MuiAccordion-root': {
          borderRadius: '0',
          borderBottom: '1px solid gainsboro',
          boxShadow: '1px 1px 5px gainsboro',
          margin: '0px 7px 7px 0px',
        },
      }}>
      <Summary customer={customer} expanded={expanded} />
      <AccordionDetails>
        <CustomerActions
          shouldShowMaxCustomerCount={shouldShowMaxCustomerCount}
          customer={customer}
          neighborhoodId={neighborhoodId}
          onlyOneCustomer={onlyOneCustomer}
          onRemove={onRemove}
        />
      </AccordionDetails>
    </Accordion>

  );
}