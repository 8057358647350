import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { formatMonthDay } from 'util/dateUtil';

function ServiceInstanceSelect({ serviceInstances, value, onChange }:any) {
  return (
    <FormControl variant="standard" sx={{ minWidth: 200 }}>
      <InputLabel>Service Date</InputLabel>
      <Select
        id="service-instance-select"
        name="serviceInstanceId"
        autoWidth
        onChange={onChange}
        value={value}
      >
        {
          serviceInstances && serviceInstances.map((x: any, i) => {
            return <MenuItem key={i} value={x.id}>{formatMonthDay(x.serviceDate)}</MenuItem>;
          })
        }
      </Select>
    </FormControl>
  );
}

export default function ServiceDateSelect({ customer, serviceInstances, selectedServiceInstance, onChange, updateCustomerServiceInstance }) {


  return (
    <>
      <Grid item xs={12} textAlign='left'>
        <p>Service Type: {customer.serviceType}</p>
      </Grid>
      <Grid container alignItems='center' spacing={1}>
        <Grid item>
          <ServiceInstanceSelect
            serviceInstances={serviceInstances}
            value={selectedServiceInstance}
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <Button variant="contained"
            onClick={(e) => updateCustomerServiceInstance(selectedServiceInstance)}
            style={{
              marginTop: '15px',
              marginRight: '15px',
            }}
          >
            <span>Change Service Date</span>
          </Button>
        </Grid>
      </Grid>
      {customer.subscriptionOption &&
              <p>Subscription Option: {customer.subscriptionOption}</p>
      }
      {customer.customFieldSelections && customer.customFieldSelections.map((customOption: any) => {
        return (
          <>
            <p>{customOption.label}: {customOption.value}</p>
          </>
        );
      })}
    </>
  );
}