
export interface IItemMetadata {
  creatorId:string;
  updaterId:string;
  createDate:Date;
  updateDate:Date;
  deleteDate:Date;
}

export interface ISelectOption {
  key?:string;
  optionText?:string;
  optionValue?:string;
}

export type ParticipantStatusType = 'INVITED' | 'ACTIVE' | 'COMPLETE' | 'PENDING' | 'REMOVED' | 'LEAD' | 'FORMER' | 'HOLD'

export const ParticipantStatus = {
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE',
  COMPLETE: 'COMPLETE',
  PENDING: 'PENDING',
  REMOVED: 'REMOVED',
  LEAD: 'LEAD',
  FORMER: 'FORMER',
  HOLD: 'HOLD',
};

export const ServiceInstanceStatus = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};

export const ServiceProviderStatuses = [
  'PENDING',
  'REFERRAL',
  'ACTIVE',
];
export const ServiceProviderStatus = {
  PENDING: 'PENDING',
  REFERRAL: 'REFERRAL',
  ACTIVE: 'ACTIVE',
};

export const CadenceType = {
  ONE_TIME_ONLY: 'ONE_TIME_ONLY',
  MOSTLY_SUBSCRIPTION: 'MOSTLY_SUBSCRIPTION',
  MOSTLY_ONETIME: 'MOSTLY_ONETIME',
  SUBSCRIPTION_ONLY: 'SUBSCRIPTION_ONLY',
};

export class DiscountScheduleType {
  private _value:string;
  private _displayValue:string;

  constructor(value:string, displayValue:string) {
    this._value = value;
    this._displayValue = displayValue;
  }

  get value() {
    return this._value;
  }

  get displayValue() {
    return this._displayValue;
  }
}
export const DiscountScheduleTypes = {
  DollarPerCustomer: new DiscountScheduleType('DollarPerCustomer', '$ per customer'),
  DollarSavePerCustomer: new DiscountScheduleType('DollarSavePerCustomer', '$ save per customer'),
  PercentSavePerCustomer: new DiscountScheduleType('PercentSavePerCustomer', '% off per customer'),
  NoDiscount: new DiscountScheduleType('NoDiscount', 'No Discount'),
};

export interface IDiscountScheduleMetadata {
  priceLabel: string;
  priceAmount: number;
  unitBasedPricingFlag: boolean;
  priceSuffix: string;
  priceSubText: string;
  annualBillingCycleCount: number | null;
}

export const ServiceInstanceType = {
  SUPER_DEAL: 'SUPER_DEAL',
  PLACEHOLDER: 'PLACEHOLDER',
};