import { Button, useTheme } from '@mui/material';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import useHardDeleteCustomer from '../hookStore/useHardDeleteCustomer';
import styles from './hardDeleteCustomerModal.module.css';
import useConfirmationModal from 'components/modals/useConfirmationModal';

export default function HardDeleteCustomerButton() {
  const theme = useTheme();
  const { openModal } = useConfirmationModal('hardDeleteCustomerModal');
  return (
    <Button
      variant='text'
      type='button'
      onClick={openModal}
      sx={{
        borderColor: theme.palette.tertiary.red,
        color: theme.palette.tertiary.red,
        width: {
          xs: '100%',
          sm: 'auto',
        },
      }}
    >
      <span>Hard Delete</span>
    </Button>
  );
}