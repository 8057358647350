import Userfront from '@userfront/react';
import Axios from 'axios';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function useInterceptor(setToastMessage:any) {
  const [isIntercepting, setIsIntercepting] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { signUpLinkToken } = useParams();

  function initializeInterceptor() {
    Axios.interceptors.response.use(function(response) {return response;}, function (error) {
      //should use error.response.status and error.response.data

      if (location.pathname === '/sign-in' || location.pathname === '/reset') {
        console.log('at signin or reset. not redirecting');
      } else if (location.pathname.indexOf('/sign-up') > -1 && signUpLinkToken) {
        console.log('signup page. not redirecting');
      } else if (!Userfront.tokens.accessToken) {
        navigate(`/sign-in?redirect=${location.pathname}`);
      } else if (error.response.status === 401 && Userfront.tokens.accessToken) {
        if (location.pathname !== '/' ) {
          navigate('/');
        }
      } else if (error.response.status === 401) {
        navigate('/sign-in');
      } else if (error.response.status === 403) {
        if (!window.sessionStorage.getItem('reloaded_once')) {
          window.sessionStorage.setItem('reloaded_once', '1');
          window.location.reload();
        } else {
          const message ='You do not have access to this resource.';
          setToastMessage(message);
        }
      }
      return Promise.reject(error);
    });
    setIsIntercepting(true);
  }
  return {
    isIntercepting,
    initializeInterceptor,
  };
}