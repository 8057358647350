import Axios, { AxiosResponse } from 'axios';
import { IUserPersonalInfo } from 'model/user';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function getPersonalInfo():Promise<AxiosResponse<IUserPersonalInfo>> {
  return Axios.get(`${apiUrl}/individual/v2/getPersonalInfo`);
}

export function getSelfRoles():Promise<AxiosResponse<any>> {
  return Axios.get(`${apiUrl}/individual/v2/getSelfRoles`);
}
