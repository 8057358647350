import { InputLabel, FormHelperText, FormControl, Alert } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useGroupDeal from '../../hookStore/useGroupDeal';
import useGroupDealCreationStore from '../../hookStore/useGroupDealCreationStore';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';

export interface IMaxCustomerCountSelectProps {

}

export default function MaxCustomerCountSelect({ }:IMaxCustomerCountSelectProps) {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { maxCustomerCountOptions } = useGroupDealCreationStore();

  return (
    <>
      <FormControl variant='standard' fullWidth error={isError('maxCustomerCount')}>
        <InputLabel htmlFor="-select">Max # of Customers</InputLabel>
        <HookSelect
          control={formCtx.control}
          rules={{
            required: { value: true, message: 'Required' },
          }}
          name='maxCustomerCount'
          id='max-customer-count-select'
          menuItems={maxCustomerCountOptions}
          menuItemKeys={{
            displayValue: 'optionText',
            key: 'key',
            value: 'optionValue',
          }}
        />
        <FormHelperText>{getErrorMessage('maxCustomerCount') || ' '}</FormHelperText>
      </FormControl>
    </>
  );
}