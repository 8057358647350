import { Button, Card, CardContent, Grid, Modal, useMediaQuery, useTheme } from '@mui/material';
import styles from './ConfirmationModal.module.css';
import useConfirmationModal from './useConfirmationModal';

export default function HookConfirmationModal({ modalKey, title, message, onConfirm, isDisabled, sx }:any) {
  const { open, onConfirmArgs, cancelModal, confirmModal } = useConfirmationModal(modalKey);
  const theme = useTheme();
  const xsBrk = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Modal
      open={open}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
      className={styles.modalParent}
      sx={{
        margin: xsBrk ? '20px' : 0,
        ...sx,
      }}
    >
      <Card>
        <CardContent>
          <div id="confirm-modal-title" className={styles.modalTitle}>
            {title(onConfirmArgs) || 'You are about to delete this item'}
          </div>
          <div id="confirm-modal-description" className={styles.modalContent}>
            {message(onConfirmArgs) || (<div>Are you sure you want to delete?</div>)}
          </div>
          <Grid container justifyContent='right' spacing={1}>
            <Grid item xs='auto'>
              <Button type='button' variant='text' onClick={cancelModal}>Cancel</Button>
            </Grid>
            <Grid item xs='auto'>
              <Button
                disabled={isDisabled ?? false}
                type='button'
                color='primary'
                variant='contained'
                onClick={() => confirmModal(onConfirm)}
              >
                <>Confirm</>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}