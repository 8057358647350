import { DrawerProps } from '@mui/material';
import { GridCallbackDetails, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import { findHistoricalParticipants } from 'api/customerApi';
import { useLoading } from 'components/layout/Loading';
import createStore from 'hooks/hookStore';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { IProviderHistoricalParticipant } from 'model/customer';
import { IProviderStreetfairReview, createProviderStreetfairReview } from 'model/reviews';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const loadingKey = 'reviewDrawerData';
type ReviewDrawerData = {
  open:boolean;
  anchor:DrawerProps['anchor'];
  customerReview:IProviderStreetfairReview;
  historicalParticipants: IProviderHistoricalParticipant[];
}


const { get, update, registerListener, unregisterListener } = createStore<ReviewDrawerData>('reviewDrawerData', {
  open: false,
  anchor: 'right',
  customerReview: createProviderStreetfairReview(),
  historicalParticipants: [],
});
export default function useReviewDrawer() {
  const { serviceProviderId } = useParams();
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { trackPageView } = useStreetfairAnalytics();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  function openDrawer() {
    update({
      ...get(),
      open: true,
    });
  }

  function closeDrawer() {
    update({
      ...get(),
      open: false,
    });
  }

  async function openCustomerReviewDrawer(params:GridRowParams, event:MuiEvent<React.MouseEvent>, details:GridCallbackDetails) {

    void trackPageView('Customer_Review_Drawer');
    openDrawer();
    onLoading();
    if (!serviceProviderId) {
      return;
    }
    const historicalParticipantsRes = await findHistoricalParticipants(serviceProviderId, { individualId: params.row.individualId });
    update({
      ...get(),
      customerReview: params.row as IProviderStreetfairReview,
      historicalParticipants: historicalParticipantsRes.data,
    });
    doneLoading(300);
  }

  return {
    ...get(),
    loadingKey,
    openDrawer,
    closeDrawer,
    onDrawerLoading: onLoading,
    doneDrawerLoading: doneLoading,
    openCustomerReviewDrawer,
  };
}