import { findHistoricalParticipants, findProviderCustomer, findProviderSubscriber, saveCustomerInitialConfirmation, saveCustomerServiceDetails, saveOperationsDetails, saveSubscriberInitialConfirmation } from 'api/customerApi';
import { getSelfRoles } from 'api/individualApi';
import { requestPaymentFromCustomer, requestPaymentFromSubscriber } from 'api/paymentsApi';
import { findForProviderByNeighborhoodServiceOffering, findServiceInstanceById } from 'api/serviceInstanceApi';
import { findServiceProviderById } from 'api/serviceProviderApi';
import { getSelfUser } from 'api/userApi';
import { useLoading } from 'components/layout/Loading';
import useToast from 'components/toast/useToast';
import { differenceInCalendarDays, isAfter, isBefore, isSameDay } from 'date-fns';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash.debounce';
import { DiscountScheduleTypes, ParticipantStatus, ServiceInstanceStatus } from 'model/ancillary';
import { IAdditionalServiceItem, ICustomFieldSelectionDTO, ICustomerServiceDetails, IProviderCustomerDetail, IProviderHistoricalParticipant, createCustomerServiceDetails, createInitialConfirmationForm } from 'model/customer';
import { DiscountScheduleType } from 'model/discount';
import { IMovableGroupDealsDto, INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import { IOperationsForm, IOperationsFormDto, convertFormNote } from 'model/operations';
import { IPaymentRequest } from 'model/paymentRequest';
import { CustomFieldGroupTypes, ICustomFieldDTO } from 'model/serviceOffering';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import logPageView from 'util/analyticsUtil';
import { createDateIgnoreTimezone, formatLongMonthDayYear } from 'util/dateUtil';
import { calculateDiscountIndex } from 'util/discountCalculator';
import { containsProviderAdmin } from 'util/role';
import { StringUtil } from 'util/stringUtil';
import { NOT_SHOULD_HONOR_GROUP_DEAL } from '../confirmCustomerDrawer/HonorGroupDealRadio';
import store from './customerDetailDataStore';
import useConfirmCustomerDrawer, { COMPLETION_DRAWER_VARIANT } from './useConfirmCustomerDrawer';
import usePostConfirmModal from './usePostConfirmModal';
import usePriceCalculator from './usePriceCalculator';
import useServiceItemUtil from './useServiceItemUtil';
const debounceSaveCustomerInitialConfirmation = debounce(async (serviceProviderId, customerId, data):Promise<any> => {
  const res = await saveCustomerInitialConfirmation(serviceProviderId, customerId, data);
}, 300);

const debounceSaveSubscriberInitialConfirmation = debounce(async (serviceProviderId, subscriberId, data):Promise<any> => {
  const res = await saveSubscriberInitialConfirmation(serviceProviderId, subscriberId, data);
}, 300);

const loadingKey = 'customerDetail';


const { get, update, registerListener, unregisterListener } = store;


export default function useCustomerDetail() {
  const setState = useState(get())[1];
  const navigate = useNavigate();
  const { createErrorToast, createInfoToast, createSuccessToast } = useToast();
  const { variant, closeDrawer, onDrawerLoading, doneDrawerLoading } = useConfirmCustomerDrawer();
  const { init: postConfirmInit } = usePostConfirmModal();
  const { postCustomerConfirmationModal, serviceDateRangePickerCustomerDetailJun2023 } = useFlags();
  const {
    calculatePrice,
  } = usePriceCalculator();
  const {
    hasUnitBasedPricing,
    getServiceItemUnits,
  } = useServiceItemUtil();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { serviceProviderId, customerId, subscriberId } = useParams();
  const { trackButtonClick } = useStreetfairAnalytics();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      const res = await getSelfRoles();
      update({ ...get(), isProviderAdmin: containsProviderAdmin(res.data), showOperations: false });
      logPageView(serviceProviderId);
      await refresh();
    } catch (err:any) {
      console.log(err);
    }
    doneLoading();
  }

  async function refresh() {
    try {
      if (serviceProviderId && (customerId || subscriberId)) {

        let promises = [
          findServiceProviderById(serviceProviderId),
        ];
        if (customerId) {
          promises.push(findProviderCustomer(serviceProviderId, customerId));
        } else if (subscriberId) {
          promises.push(findProviderSubscriber(serviceProviderId, subscriberId));
        }
        let [providerRes, customerRes] = await Promise.all(promises);
        let historicalParticipants: IProviderHistoricalParticipant[] = [];
        if (customerRes.data.individualId) {
          const res = await findHistoricalParticipants(serviceProviderId, { individualId: customerRes.data.individualId });
          if (customerId) {
            historicalParticipants = res.data.filter(x => x.id !== customerId);
          } else if (subscriberId) {
            historicalParticipants = res.data.filter(x => x.id !== subscriberId);
          }
        }
        let happeningSoonCompatibleNsoi:any = null;
        if (customerRes.data && customerId) {
          try {
            happeningSoonCompatibleNsoi = await convertForHappeningSoonCard(customerRes.data, providerRes.data);
          } catch (e:any) {
            console.error(e);
          }
        }
        let additionalServiceItems = createAdditionalServiceItems(customerRes.data);
        const customFieldSelections = createCustomFieldSelections(customerRes.data);

        update({
          ...get(),
          additionalServiceItems,
          customFieldSelections,
          wasDateChanged: false,
          customer: customerRes.data,
          historicalParticipants,
          provider: providerRes.data,
          editingServiceDateAndMaxCustomerCount: (customerRes.data.firstCustomer && !customerRes.data.hasAlreadySetServiceDateAndMaxCustomerCount) || customerRes.data.recurring,
          initialConfirmation: createInitialConfirmationForm(customerRes.data),
          customerServiceDetails: createCustomerServiceDetails(customerRes.data, serviceDateRangePickerCustomerDetailJun2023),
          nsoisWithSameNso: [],
          selectedAlternateServiceDate: '',
          matchingServiceDateInstance: null,
          requestPaymentModalOpen: false,
          happeningSoonCompatibleNsoi: happeningSoonCompatibleNsoi,
        });
      } else {
        createInfoToast('No ID for service provider.');
      }
    } catch (e:any) {
      console.error(e);
    }
  }

  function setEditingServiceDateAndMaxCustomerCount(editingServiceDateAndMaxCustomerCount:boolean) {
    update({
      ...get(),
      editingServiceDateAndMaxCustomerCount,
    });
  }

  function getDiscountSchedule () {
    return get().customer.discountSchedule;
  }

  async function onInitialConfirmation(getValues, ignoreSuccessToast?:boolean) {
    const { customer, initialConfirmation } = get();
    const dataToSave = { ...customer, ...initialConfirmation, ...getValues() };
    if (dataToSave.wasCustomerContacted === null) {
      dataToSave.wasCustomerContacted = false;
    }
    if (dataToSave.wasConsultationScheduled === null) {
      dataToSave.wasConsultationScheduled = false;
    }
    if (dataToSave.wasQuoteSent === null) {
      dataToSave.wasQuoteSent = false;
    }
    try {
      if (dataToSave.recurring) {
        await debounceSaveSubscriberInitialConfirmation(serviceProviderId, subscriberId, dataToSave);
      } else {
        await debounceSaveCustomerInitialConfirmation(serviceProviderId, customerId, dataToSave);
      }

      debouncedSuccessToast();
    } catch (e:any) {
      console.error(e);
    }
  }

  function initialConfirmationToast(ignoreSuccessToast) {
    if (!ignoreSuccessToast) {
      createSuccessToast('Saved');
    }
  }

  const debouncedSuccessToast = useCallback(debounce(initialConfirmationToast, 700, {
    leading: false,
    trailing: true,
  }), []);

  const onSendPaymentRequest :SubmitHandler<IPaymentRequest> = async data => {
    const dataToSave = { ...data };
    if (get().customer.recurring) {
      const res = await requestPaymentFromSubscriber(dataToSave);
    } else {
      const res = await requestPaymentFromCustomer(dataToSave);
    }

    setRequestPaymentModalOpen(false);
    window.scroll(0, 0);
    createSuccessToast('Payment request sent!');
  };

  const onSendTestPaymentRequest :SubmitHandler<IPaymentRequest> = async data => {
    const dataToSave = { ...data };
    dataToSave.testMode = true;
    const res = await requestPaymentFromCustomer(dataToSave);
    createSuccessToast('Test payment request sent!');
  };

  async function revertToActive() {
    const { customerServiceDetails } = get();
    let dataToSave = { ...customerServiceDetails };
    dataToSave.customerStatus = ParticipantStatus.ACTIVE;
    onSaveCustomerServiceDetails(dataToSave);
  }

  async function revertToPending() {
    const { customerServiceDetails } = get();
    let dataToSave = { ...customerServiceDetails };
    dataToSave.customerStatus = ParticipantStatus.PENDING;
    onSaveCustomerServiceDetails(dataToSave);
  }

  async function removeCustomer({ participantRemoveReason, participantRemoveComment }:any) {
    const { customerServiceDetails } = get();
    let dataToSave = { ...customerServiceDetails };
    dataToSave.participantRemoveReason = participantRemoveReason;
    dataToSave.participantRemoveComment = participantRemoveComment;
    dataToSave.customerStatus = (dataToSave.recurring && dataToSave.customerStatus === ParticipantStatus.ACTIVE) ? ParticipantStatus.FORMER : ParticipantStatus.REMOVED;
    void trackButtonClick('Remove_Customer_Button');
    onSaveCustomerServiceDetails(dataToSave);
  }

  async function holdCustomer() {
    const { customerServiceDetails } = get();
    let dataToSave = { ...customerServiceDetails };
    dataToSave.customerStatus = ParticipantStatus.HOLD;
    onSaveCustomerServiceDetails(dataToSave);
  }

  async function onSubmitDrawerAsPending(data) {
    if (data.customerStatus !== ParticipantStatus.COMPLETE && data.customerStatus !== ParticipantStatus.FORMER) {
      data.customerStatus = ParticipantStatus.PENDING;
    }
    update({
      ...get(),
      wasDateChanged: true,
    });
    onSaveCustomerServiceDetails(data);
  }

  async function onConfirm(data) {
    onLoading();
    if (data.customerStatus !== ParticipantStatus.COMPLETE && data.customerStatus !== ParticipantStatus.FORMER) {
      data.customerStatus = ParticipantStatus.ACTIVE;
    }
    await onSaveCustomerServiceDetails(data);
    doneLoading();
  }

  async function onSubmitOperations(data:IOperationsForm) {
    onLoading();
    const { customerServiceDetails } = get();
    const userInfoRes = await getSelfUser();
    let dto:IOperationsFormDto = {
      customerId: customerServiceDetails.customerId,
      subscriberId: customerServiceDetails.subscriberId,
      recurring: customerServiceDetails.recurring,
      notes: data.notes.map(x => convertFormNote(x, userInfoRes.data)),
      snoozeDate: data.snoozeDate,
      serviceRelationshipOnus: data.serviceRelationshipOnus,
    };
    try {
      const res = await saveOperationsDetails(serviceProviderId || '', dto);
      if (res.status === 200) {
        await refresh();
        closeDrawer();
        debouncedSuccessToast();
      }
    } catch (e:any) {
      console.log(e);
      createErrorToast('Error occurred');
    }

    doneLoading();
  }

  async function onSubmitConvertCustomerToSubscriber(data:any) {
    onLoading();
    await onSaveCustomerServiceDetails(data);
    doneLoading();
  }

  async function onSubmitDrawer(data) {
    if (variant === COMPLETION_DRAWER_VARIANT) {
      data.customerStatus = ParticipantStatus.COMPLETE;
    } else if (data.customerStatus !== ParticipantStatus.COMPLETE && data.customerStatus !== ParticipantStatus.FORMER) {
      data.customerStatus = ParticipantStatus.ACTIVE;
    }
    update({
      ...get(),
      wasDateChanged: true,
    });
    await onSaveCustomerServiceDetails(data);

  }

  const onSaveCustomerServiceDetails :SubmitHandler<ICustomerServiceDetails> = async (data) => {
    onDrawerLoading();
    const { matchingServiceDateInstance, customer, wasDateChanged } = get();
    const wasNsoiInitialized = customer.neighborhoodServiceOfferingInstanceServiceDate && (customer.maxCustomerCount ?? 0) > 0;
    const dataToSave:any = { ...data };
    if (serviceDateRangePickerCustomerDetailJun2023) {
      if (dataToSave.serviceDateRange[0] && dataToSave.serviceDateRange[1]) {
        dataToSave.serviceDate = dataToSave.serviceDateRange[0];
        dataToSave.endServiceDate = dataToSave.serviceDateRange[1];
      } else if (dataToSave.serviceDateRange[0] && !dataToSave.serviceDateRange[1]) {
        dataToSave.serviceDate = dataToSave.serviceDateRange[0];
      } else if ( Math.abs(differenceInCalendarDays(dataToSave.serviceDateRange[1], dataToSave.serviceDateRange[0])) == 0) {
        dataToSave.serviceDate = dataToSave.serviceDateRange[0];
        dataToSave.endServiceDate = null;
      }
    }

    if (isSelectedOptionOneTime(data.subscriptionSelection, customer)) {
      dataToSave.convertToCustomer = true;
    }
    if (data.providerAdminConvertToCustomer) {
      dataToSave.convertToCustomer = true;
      dataToSave.providerAdminConvertToCustomer = true;
    }
    if (variant !== COMPLETION_DRAWER_VARIANT) {
      if (wasDateChanged && matchingServiceDateInstance !== null) {
        dataToSave.neighborhoodServiceOfferingInstanceId = matchingServiceDateInstance.id;
      } else if (wasDateChanged && (
        !matchingServiceDateInstance &&
        (customer.discountSchedule.type === DiscountScheduleTypes.NoDiscount.value && wasNsoiInitialized) ||
        (dataToSave.honorGroupDeal === NOT_SHOULD_HONOR_GROUP_DEAL && wasNsoiInitialized))
      ) {
        //we're not honoring the group deal and the service date and max customer count was already set on the customer's nsoi.
        //This means we'll create a new one and so need to clear the nsoiId off the request.
        dataToSave.neighborhoodServiceOfferingInstanceId = null;
      }
    }

    if ([ParticipantStatus.FORMER, ParticipantStatus.REMOVED].indexOf(dataToSave.customerStatus) === -1) {
      delete dataToSave.participantRemoveReason;
      delete dataToSave.participantRemoveComment;
    }
    const {
      customer: { status, hasDisabledMaxCustomerCount },
      clientSidePriceResult,
      serverSidePriceResult,
    } = get();
    const priceResult = clientSidePriceResult.priceResult.discountTotal !== -1 ? clientSidePriceResult.priceResult : serverSidePriceResult;
    //change of status from non-active or non-complete to active/complete status
    if (data.isMaxCustomerSwitchToggled && hasDisabledMaxCustomerCount) {
      dataToSave.maxCustomerCount = '999999';
    } else if (!data.isMaxCustomerSwitchToggled) {
      dataToSave.maxCustomerCount = '1';
    } else if (dataToSave.neighborhoodServiceOfferingInstanceId !== null && wasNsoiInitialized) {
      //if we're not making a new instance then set the maxCustomerCount back to the original
      dataToSave.maxCustomerCount = customer.maxCustomerCount;
    } else {
      dataToSave.maxCustomerCount = data.maxCustomerCount;
    }
    dataToSave.serviceItems = [];
    for (let i = 0; i < data.serviceItems.length; i++) {
      let x = { ...data.serviceItems[i] };
      if (x.unitBasedPrice && !x.units) {
        x.units = '1';
      }
      dataToSave.serviceItems.push(x);
    }
    //TODO: don't think this is used any more. Neither is the discount.
    if (priceResult.totalPriceAfterDiscount) {
      dataToSave.totalPrice = priceResult.totalPriceAfterDiscount;
    }

    dataToSave.discount = priceResult.discountTotal;
    try {
      if (!serviceProviderId) {
        console.error('No service provider id. Cannot save');
        return;
      }
      if (dataToSave.subscriberId === '') {
        delete dataToSave.subscriberId;
      }

      const res = await saveCustomerServiceDetails(serviceProviderId || '', dataToSave);
      if (res.data && res.data.convertedToCustomer) {
        update({
          ...get(),
          wasDateChanged: false,
        });
        closeDrawer(true);
        debouncedSuccessToast();
        navigate(`/v1/${serviceProviderId}/customers/${res.data.customerId}`);
        location.reload();
      } else if (res.data && res.data.convertedToSubscriber) {
        navigate(`/v1/${serviceProviderId}/subscribers/${res.data.subscriberId}`);
        location.reload();
      } else {
        update({
          ...get(),
          wasDateChanged: false,
        });
        await refresh();
        closeDrawer();
        debouncedSuccessToast();
        const { customer: refreshedCustomer, provider } = get();
        if (refreshedCustomer && provider && shouldShowPostConfirmationModal(status, dataToSave.customerStatus)) {
          await postConfirmInit(refreshedCustomer, provider);
        }
      }


    } catch (e:any) {
      console.error(e);
      createErrorToast('Unable to save service. Please contact StreetFair for support.');
    } finally {
      doneDrawerLoading(100);
    }
  };

  function shouldShowPostConfirmationModal( originalStatus:string, nextStatus:string) {
    return postCustomerConfirmationModal && originalStatus !== nextStatus && originalStatus === ParticipantStatus.PENDING && nextStatus === ParticipantStatus.ACTIVE;
  }
  function onSubmitError(data) {
    console.log('error', data);
  }

  function shouldShowUnitBasedFields(index:number) {
    const { customer } = get();

    if (index > 0) {
      return true;
    }
    if (index === 0) {
      return customer.discountSchedule?.unitBasedPricingFlag;
    }
    return false;
  }

  function shouldDiscountScheduleApply(index:number) {
    const { customer } = get();
    const { discountSchedule } = customer;

    if (index > 0) {
      return false;
    }
    if (discountSchedule?.type === DiscountScheduleType.NoDiscount) {
      return false;
    }
    return true;
  }

  function setRequestPaymentModalOpen(requestPaymentModalOpen: boolean) {
    update({
      ...get(),
      requestPaymentModalOpen,
    });
  }

  function getRequestPaymentModalOpen() {
    return get().requestPaymentModalOpen;
  }

  /**
   * Potentially show the look-ahead: They're either the only active/complete customer or they're pending and there are no other active/complete customers.
   * Controls whether or not the discount formula displays additional info beyond the starting at discount.
   * @returns
   */
  function isOnFirstRowOfDiscount() {
    const { customer } = get();
    const isPending:boolean = customer.status === ParticipantStatus.PENDING;
    const index = calculateDiscountIndex(customer.discountTable, isPending);
    return index === 0;
  }

  function firstServiceItemHasPricingSchedule(index, customer:IProviderCustomerDetail) {
    if (index === 0 && customer.discountSchedule && customer.discountSchedule.pricingSchedule) {
      return true;
    }
    return false;
  }

  function canSetServiceScheduled() {
    const { customer } = get();
    let hasServiceItems = customer.serviceItems.length > 0;
    let hasMaxCustomerCount = customer.maxCustomerCount && customer.maxCustomerCount > 0;
    let hasServiceDate = customer.serviceDate || customer.subscriptionStartDate;
    return hasServiceItems && hasMaxCustomerCount && hasServiceDate;
  }

  function hasValueString(val?:string):boolean {
    return val !== null && val !== undefined;
  }

  function showPendingServiceScheduled() {
    const { customer } = get();

    const shouldShow = customer.status === 'PENDING' && customer.serviceScheduledDate === null &&
      (customer.serviceDate || customer.subscriptionStartDate);
      // && customer.serviceItems.length > 0 &&
      // (hasValueString(customer.serviceItems[0].price) || hasValueString(customer.serviceItems[0].unitBasedPrice));

    return shouldShow;
  }

  function clearNsoisWithSameNso() {
    update({
      ...get(),
      nsoisWithSameNso: [],
    });
  }


  /**
   * This method is used to determine if the customer should be put onto another group deal, stay on the same group deal, or create a new one and add them to that
   * @param selectedServiceDate
   * @param loading
   */
  async function refreshAvailableNsoisToMove(selectedServiceDate:string, selectedEndServiceDate:string | null, loading:any) {
    if (serviceProviderId) {
      loading.onLoading();
      const { customer, customerServiceDetails } = get();
      const selectedServiceDateOffset = createDateIgnoreTimezone(selectedServiceDate);
      const selectedEndServiceDateOffset = createDateIgnoreTimezone(selectedEndServiceDate);

      const formattedSelectedServiceDate = formatLongMonthDayYear(selectedServiceDateOffset);
      let nextNsoisWithSameNso:INeighborhoodServiceOfferingInstance[] = await _getNsoisWithSameNso();
      let matchingServiceDateInstance:INeighborhoodServiceOfferingInstance | null = null;
      let selectedAlternateServiceDate = '';

      if (customerServiceDetails.serviceDate && !_doesDateMatch(selectedServiceDateOffset, createDateIgnoreTimezone(customerServiceDetails.serviceDate))) {
        //if the customer's service date exists and the new one selected is different
        selectedAlternateServiceDate = formattedSelectedServiceDate;

      }
      for (let i = 0; i < nextNsoisWithSameNso.length && matchingServiceDateInstance == null; i++) {
        let x = nextNsoisWithSameNso[i];
        if (selectedEndServiceDateOffset) {
          if (_doesDateOrDateRangeMatch(selectedServiceDateOffset, selectedEndServiceDateOffset, x)) {
            matchingServiceDateInstance = x;
          }
        } else if (!selectedEndServiceDateOffset && _doesDateOrDateRangeMatchOld(selectedServiceDateOffset, x)) {
          matchingServiceDateInstance = x;
        }
      }
      update({
        ...get(),
        nsoisWithSameNso: nextNsoisWithSameNso,
        matchingServiceDateInstance,
        selectedAlternateServiceDate,
      });
      loading.doneLoading(0);
    }
  }

  function _doesDateMatch(selectedServiceDateOffset:Date | '', instanceServiceDate: Date | ''):boolean {
    return instanceServiceDate !== '' && selectedServiceDateOffset !== '' && isSameDay(instanceServiceDate, selectedServiceDateOffset);
  }

  function _doesDateOrDateRangeMatch(selectedServiceDateOffset:Date | '', selectedEndServiceDateOffset:Date | '', serviceInstance:INeighborhoodServiceOfferingInstance):boolean {
    const instanceServiceDate = createDateIgnoreTimezone(serviceInstance.serviceDate);
    const instanceEndServiceDate = createDateIgnoreTimezone(serviceInstance.endServiceDate);
    if (!selectedEndServiceDateOffset) {
      //do the service date match
      const serviceDateMatch = _doesDateMatch(selectedServiceDateOffset, instanceServiceDate);
      const dateRangeMatch = (
        instanceServiceDate !== '' &&
        selectedServiceDateOffset !== '' &&
        instanceEndServiceDate !== '' &&
        (isAfter(selectedServiceDateOffset, instanceServiceDate) || isSameDay(instanceServiceDate, selectedServiceDateOffset)) &&
        (isSameDay(instanceEndServiceDate, selectedServiceDateOffset) || isBefore(selectedServiceDateOffset, instanceEndServiceDate))
      );
      return serviceDateMatch || dateRangeMatch;
    } else {
      // match the range within another range. check that serviceDate is in the range and then if endServiceDate in the range
      const dateRangeMatch = (
        instanceServiceDate !== '' &&
        selectedServiceDateOffset !== '' &&
        instanceEndServiceDate !== '' &&
        (isAfter(selectedServiceDateOffset, instanceServiceDate) || isSameDay(instanceServiceDate, selectedServiceDateOffset)) &&
        (isSameDay(instanceEndServiceDate, selectedServiceDateOffset) || isBefore(selectedServiceDateOffset, instanceEndServiceDate))
      );
      const endDateRangeMatch = (
        instanceServiceDate !== '' &&
        selectedEndServiceDateOffset &&
        instanceEndServiceDate !== '' &&
        (isAfter(selectedEndServiceDateOffset, instanceServiceDate) || isSameDay(instanceServiceDate, selectedEndServiceDateOffset)) &&
        (isSameDay(instanceEndServiceDate, selectedEndServiceDateOffset) || isBefore(selectedEndServiceDateOffset, instanceEndServiceDate))
      );
      //potentially also select the same day twice with how the date picker works. in that case we just need to make sure that
      //the service instance date matches each date in the date range
      //(e.g. service instance: july 7 2023, selectedBeginDate: july 7 2023, selectedEndDate: july 7 2023)
      const beginServiceDateMatch = _doesDateMatch(selectedServiceDateOffset, instanceServiceDate);
      const endServiceDateMatch = _doesDateMatch(selectedEndServiceDateOffset, instanceServiceDate);
      return (dateRangeMatch && endDateRangeMatch) || (beginServiceDateMatch && endServiceDateMatch);
    }

  }

  //deprecated
  function _doesDateOrDateRangeMatchOld(selectedServiceDateOffset:Date | '', serviceInstance:INeighborhoodServiceOfferingInstance):boolean {
    const instanceServiceDate = createDateIgnoreTimezone(serviceInstance.serviceDate);
    const instanceEndServiceDate = createDateIgnoreTimezone(serviceInstance.endServiceDate);
    const serviceDateMatch = _doesDateMatch(selectedServiceDateOffset, instanceServiceDate);
    const dateRangeMatch = (
      instanceServiceDate !== '' &&
      selectedServiceDateOffset !== '' &&
      instanceEndServiceDate !== '' &&
      (isAfter(selectedServiceDateOffset, instanceServiceDate) || isSameDay(instanceServiceDate, selectedServiceDateOffset)) &&
      (isSameDay(instanceEndServiceDate, selectedServiceDateOffset) || isBefore(selectedServiceDateOffset, instanceEndServiceDate))
    );
    return serviceDateMatch || dateRangeMatch;
  }

  async function _getNsoisWithSameNso():Promise<INeighborhoodServiceOfferingInstance[]> {
    let nextNsoisWithSameNso:INeighborhoodServiceOfferingInstance[] = [];
    const { customer, nsoisWithSameNso } = get();
    if (serviceProviderId) {
      if (nsoisWithSameNso.length === 0) {
        const data:IMovableGroupDealsDto = {
          neighborhoodId: customer.neighborhoodId,
          zipCodeTerritory: customer.zipCodeTerritory,
          shouldShowPastDeals: false,
        };
        const nsoisWithSameNsoRes = await findForProviderByNeighborhoodServiceOffering(serviceProviderId, customer.neighborhoodServiceOfferingId, data );

        nextNsoisWithSameNso = nsoisWithSameNsoRes.data.filter(x => x.status === ServiceInstanceStatus.ACTIVE);
      } else {
        nextNsoisWithSameNso = nsoisWithSameNso.filter(x => x.status === ServiceInstanceStatus.ACTIVE);
      }
      if (customer.neighborhoodServiceOfferingInstanceId) {
        var res = await findServiceInstanceById(serviceProviderId, customer.neighborhoodServiceOfferingInstanceId);
        if (res.data) {
          nextNsoisWithSameNso.push(res.data);
        }
      }
    }

    return nextNsoisWithSameNso;
  }

  async function convertForHappeningSoonCard(customer: any, provider:any) {
    if (provider?.id && customer.neighborhoodServiceOfferingInstanceId) {
      var nsoi = await findServiceInstanceById(provider.id, customer.neighborhoodServiceOfferingInstanceId);
      if (nsoi.data) {
        var endServiceDate = customer.neighborhoodServiceOfferingInstanceEndServiceDate;
        return {
          nsoId: customer.neighborhoodServiceOfferingId,
          maxCustomerCount: customer.adjustedMaxCustomerCount,
          beginServiceDate: customer.neighborhoodServiceOfferingInstanceServiceDate,
          endServiceDate: endServiceDate ? endServiceDate : customer.neighborhoodServiceOfferingInstanceServiceDate,
          cutoffDayCount: nsoi.data.cutoffDayCount,
          serviceType: nsoi.data.serviceTypeName,
          serviceProvider: { name: provider?.name ?? '' },
          customers: customer.customers,
          altColor: false,
          serviceInstanceId: nsoi.data.id,
        };
      }
    }
    return null;
  }

  function getSubOptions(customer:IProviderCustomerDetail, excludeOneTime?:boolean) {
    if (customer.subscriptionOptions && customer.subscriptionOptions.options && customer.subscriptionOptions.options.length > 0) {
      return customer.subscriptionOptions.options.filter(x => !excludeOneTime || (!x.isOneTime && excludeOneTime)).map(x => {
        return {
          id: x.optionValue,
          name: x.optionName,
          ancillary: x,
        };
      });
    }
    return [];
  }

  function isSelectedOptionOneTime(subscriptionSelection:string | null, customer:IProviderCustomerDetail):boolean {
    if (subscriptionSelection) {
      var subOptions = getSubOptions(customer);
      var filteredSubOptions = subOptions.filter(x => x.id === subscriptionSelection);
      return filteredSubOptions.length > 0 && filteredSubOptions[0].ancillary?.isOneTime;
    }
    return false;
  }

  function updatePriceSuffix(priceSuffix:string) {
    update({
      ...get(),
      priceSuffix,
    });
  }

  function onShowOperations() {
    update({
      ...get(),
      showOperations: true,
    });
  }

  function onHideOperations() {
    update({
      ...get(),
      showOperations: false,
    });
  }

  /**
   * get the custom field selections that were the ADDITIONAL_SERVICES group type and extract
   * the optionValues out of the value string (since it can be all of the selected option values of a multiselect concatenated with a comma)
   */
  function createAdditionalServiceItems(customer:IProviderCustomerDetail):IAdditionalServiceItem[] {
    let additionalServiceItems:IAdditionalServiceItem[] = [];
    const additionalServiceItemCustomFieldOptions = findAdditionalServiceItemCustomFields(customer);
    customer.customFieldSelections.filter(x => {
      if (additionalServiceItemCustomFieldOptions.has(x.fieldName)) {
        if (x.customFieldSelectedOptions && x.customFieldSelectedOptions.length === 0) {
          const customField = additionalServiceItemCustomFieldOptions.get(x.fieldName);
          let copyOfValue = `${x.value}`;
          customField?.customFieldOptions?.forEach(y => {
            let val = StringUtil.extractString(copyOfValue, y.optionValue);
            if (val !== null) {
              additionalServiceItems.push({ value: val });
            }
          });
        } else {
          additionalServiceItems.push(...x.customFieldSelectedOptions.map(y => ({ value: y.value })));
        }
      }
    });
    return additionalServiceItems;
  }

  //get the custom fields that aren't the ADDITIONAL_SERVICES group type
  function createCustomFieldSelections(customer:IProviderCustomerDetail):ICustomFieldSelectionDTO[] {
    const additionalServiceItemCustomFieldOptions = findAdditionalServiceItemCustomFields(customer);
    return customer.customFieldSelections.filter(x => {
      return !additionalServiceItemCustomFieldOptions.has(x.fieldName);
    });
  }

  function findAdditionalServiceItemCustomFields(customer:IProviderCustomerDetail):Map<String, ICustomFieldDTO> {
    const additionalServiceItemCustomFieldOptions = new Map<String, ICustomFieldDTO>();
    customer.customFields.forEach(x => {
      if (x.customFieldGroupType === CustomFieldGroupTypes.ADDITIONAL_SERVICES && x.fieldName) {
        additionalServiceItemCustomFieldOptions.set(x.fieldName, x);
      }
    });
    return additionalServiceItemCustomFieldOptions;
  }

  return {
    loadingKey,
    ...get(),
    init,
    onInitialConfirmation,
    onSendPaymentRequest,
    onSendTestPaymentRequest,
    onSubmitError,
    calculatePrice: calculatePrice,
    getDiscountSchedule,
    shouldShowUnitBasedFields,
    shouldDiscountScheduleApply,
    getRequestPaymentModalOpen,
    setRequestPaymentModalOpen,
    isOnFirstRowOfDiscount,
    firstServiceItemHasPricingSchedule,
    hasUnitBasedPricing: hasUnitBasedPricing,
    getServiceItemUnits: getServiceItemUnits,
    setEditingServiceDateAndMaxCustomerCount,
    canSetServiceScheduled,
    revertToActive,
    revertToPending,
    onSubmitDrawer,
    onSubmitDrawerAsPending,
    onSubmitConvertCustomerToSubscriber,
    onConfirm,
    refresh,
    removeCustomer,
    holdCustomer,
    showPendingServiceScheduled,
    refreshAvailableNsoisToMove,
    clearNsoisWithSameNso,
    convertForHappeningSoonCard,
    getSubOptions,
    isSelectedOptionOneTime,
    updatePriceSuffix,
    onShowOperations,
    onHideOperations,
    onSubmitOperations,
  };
}