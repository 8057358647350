/* eslint-disable @typescript-eslint/no-shadow */
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { IDropdownOption } from 'model/dropdown';
import { Controller, useFormContext } from 'react-hook-form';
import { generateGuid } from 'util/guidUtil';

const filter = createFilterOptions<IDropdownOption>();
export interface IHookFreeSoloProps {
  required?:boolean;
  dropdownOptions: IDropdownOption[];
  name: string;
  label:string;
  loading:boolean;
  rules?:any;
  postOnChange?:Function;
  postOnTextChange?:Function;
  postOnBlur?:Function;
  extraProps?:any;
  helperTextDefault?:string;
  preventAdd?:boolean;
  onTextFieldKeyUp?:any;
  /**
   * If this is supplied then the default function of MUI enter will be disabled
   */
  onEnterPressed?:any;
}

export default function HookFreeSolo({
  required,
  dropdownOptions,
  name,
  label,
  loading,
  rules,
  postOnChange,
  postOnTextChange,
  postOnBlur,
  extraProps,
  helperTextDefault,
  preventAdd,
  onTextFieldKeyUp,
  onEnterPressed,
}:IHookFreeSoloProps) {
  const formContext = useFormContext();
  const { control, setValue, getValues } = formContext;
  const { isError, getErrorMessage } = useErrorState(formContext);
  const _rules:any = {
    required: {
      value: required || false, message: 'Required',
    },
    ...rules,
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={_rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <Autocomplete
            freeSolo
            loading={loading}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (onEnterPressed) {
                  (e as any).defaultMuiPrevented = true;
                  onEnterPressed(e);
                }
              }
            }}
            id={`${name}-freesolo`}
            onChange={(e, value:any, reason, details) => {
              if (reason === 'selectOption') {
                if (Array.isArray(value)) {
                  let nextValues = value.map(x => x.optionValue ?? x);
                  onChange(nextValues);
                  if (postOnChange) {
                    postOnChange(formContext, nextValues, reason, details);
                  }
                } else {
                  onChange(value);
                  if (postOnChange) {
                    postOnChange(formContext, value, reason, details);
                  }
                }
              } else if (reason === 'createOption') {

              } else if (reason === 'removeOption') {
                if (Array.isArray(value)) {
                  let nextValues = value.filter(x => x !== x.optionValue).map(x => x.optionValue ?? x);
                  onChange(nextValues);
                  if (postOnChange) {
                    postOnChange(formContext, nextValues, reason, details);
                  }
                } else {
                  onChange(value);
                  if (postOnChange) {
                    postOnChange(formContext, value, reason, details);
                  }
                }
              } else if (reason === 'blur') {

              } else if (reason === 'clear') {
                if (Array.isArray(value)) {
                  onChange([]);
                  if (postOnChange) {
                    postOnChange(formContext, [], reason, details);
                  }
                } else {
                  onChange(null);
                  if (postOnChange) {
                    postOnChange(formContext, null, reason, details);
                  }
                }
              }

            }}
            isOptionEqualToValue={(option, value) => {
              return option && option.optionValue === value;
            }}
            getOptionDisabled={(option)=>{
              return option.optionValue === 'PLACEHOLDER';
            }}
            options={dropdownOptions}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option.optionValue);
              if (inputValue !== '' && !isExisting && !preventAdd) {
                filtered.push({
                  optionValue: inputValue,
                  optionText: inputValue,
                  key: `FREESOLO_ADDED_${generateGuid()}`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              return option?.optionText ?? '';
            }}
            renderOption={(props, option, { selected }) => {
              if (option.key.includes('FREESOLO_ADDED_')) {
                return (
                  <li {...props}>
                    {!loading ? `Add: ${option.optionText}` : 'Loading'}
                  </li>
                );
              }
              return (
                <li {...props}>
                  {!loading ? option.optionText : 'Loading'}
                </li>
              );
            }}
            value={value}
            fullWidth
            selectOnFocus
            handleHomeEndKeys

            renderInput={(params) => (
              <TextField
                {...params}
                error={isError(name)}
                inputRef={ref}
                helperText={(isError(name) && getErrorMessage(name)) || (helperTextDefault !== undefined ? helperTextDefault : ' ')}
                label={label}
                variant='standard'
                onChange={(e) => {
                  if (postOnTextChange) {
                    postOnTextChange(e);
                  }
                }}
                onKeyUp={onTextFieldKeyUp}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            {...extraProps}
          />
        );
      }
      }
    />
  );
}