import ReactGA from 'react-ga4';

export default function logPageView(serviceProviderId? : String) {
  console.log('Firing page view for google analytics');
  var shouldExcludeFromAnalytics = localStorage.getItem('shouldExcludeFromAnalytics');
  if (shouldExcludeFromAnalytics && shouldExcludeFromAnalytics === 'true') {
    console.log('Skipping page view due to shouldExcludeFromAnalytics cookie being set.');
  } else {
    if (window.location.origin === 'https://provider.streetfair.app') {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    } else {
      console.log('Skipping page view for google analytics due to test environment. ' + window.location.origin);
    }
  }
}