import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './footer.module.css';

export default function Footer({ className }:any) {
  return (
    <Grid container className={className} style={{ backgroundColor: 'transparent' }}>
      <Grid item>
        <Typography variant='caption' textAlign='left'>Made by your neighbors in Charlotte, NC &copy; Streetfair 2022</Typography>
      </Grid>
      <Grid container item xs={12} >
        <Grid item xs='auto' style={{ fontSize: '14px' }}>
          <a target="#" href="https://app.termly.io/document/privacy-policy/bfb3cd20-dee0-4605-bc85-e65a1abc777f" className={styles.link}>Privacy</a>
          <a target="#" href="https://app.termly.io/document/terms-of-use-for-online-marketplace/6a0b182e-5b34-4435-9473-cbcf54393c6f" className={styles.link}>Terms</a>
        </Grid>
      </Grid>
    </Grid>
  );
}