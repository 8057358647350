import { Alert, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { useFormContext } from 'react-hook-form';
import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './formComponents.module.css';

export interface IServiceSelectProps {
  suppressMargin?:boolean;
}

export default function ServiceSelect({ suppressMargin }:IServiceSelectProps) {
  const formCtx = useFormContext();
  const { isError, getErrorMessage } = useErrorState(formCtx);
  const { serviceOfferingToServiceTypeOptions, isEditing } = useGroupDeal();
  return (
    <>
      {!isEditing &&
      <FormControl
        variant='standard'
        fullWidth
        error={isError('serviceOfferingId')}
        className={suppressMargin ? styles.createGroupDealServiceSelect : styles.nsoSelect}
      >
        <InputLabel htmlFor="service-offering-select">Service</InputLabel>
        <HookSelect
          control={formCtx.control}
          rules={{
            required: { value: true, message: 'Required' },
          }}
          name='serviceOfferingId'
          id='service-offering-select'
          menuItems={serviceOfferingToServiceTypeOptions}
          menuItemKeys={{
            displayValue: 'optionText',
            key: 'key',
            value: 'optionValue',
          }}
          extraProps={{
            disabled: isEditing || serviceOfferingToServiceTypeOptions.length === 0,
          }}
        />
        <FormHelperText>{getErrorMessage('serviceOfferingId') || ' '}</FormHelperText>
        {serviceOfferingToServiceTypeOptions.length === 0 && (
          <Alert severity="error">There are no available service offerings. Please contact StreetFair support.</Alert>
        )}
      </FormControl>
      }
    </>
  );
}