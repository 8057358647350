import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import BasicFields from '../formComponents/BasicFields';
import CustomerFields from '../formComponents/CustomerFields';
import useGroupDeal from '../hookStore/useGroupDeal';
import styles from './groupDeal.module.css';
import Shake, { useShake } from 'components/layout/Shake';
import { ServiceInstanceType } from 'model/ancillary';
import { createEmptyGroupDealForm, IGroupDealForm } from 'model/groupDeal';


export interface IGroupDealFormInternalProps {
  initialFormData: IGroupDealForm;
}
const shakeStoreKey = 'saveGroupDeal';

function OldGroupDealFormInternal({ initialFormData }: IGroupDealFormInternalProps) {
  const theme = useTheme();
  const { setAnimate } = useShake(shakeStoreKey);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const formContext = useForm<IGroupDealForm>({ mode: 'onTouched', defaultValues: createEmptyGroupDealForm() });
  const { save, cancel, nsoi } = useGroupDeal();

  useEffect(() => {
    formContext.reset(initialFormData);
  }, [initialFormData]);

  const onSubmit : SubmitHandler<IGroupDealForm> = data => {
    void save(data);
  };
  const onInvalid : any = data => {
    setAnimate(true);
  };
  const watchedCustomers = useWatch({ control: formContext.control, name: 'customers' });

  const { isSubmitting, isValid } = formContext.formState;
  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit, onInvalid)} className={styles.formElement}>
        <Grid container>
          <Grid container item xs={12} sm={12} md={4} className={!mdDown ? styles.desktopSection : styles.section}>
            <BasicFields/>
          </Grid>
          <Grid container item xs={12} sm={12} md={8} className={`${!mdDown ? styles.desktopCustomerWrapper: styles.customerWrapper}`}>
            <CustomerFields/>
            <Grid container item xs={12} justifyContent={{ sm: 'space-between' }} alignItems='flex-end'>
              <Grid item xs={12} sm={5} md={4} order={{ xs: 0, sm: 1 }}>
                <Shake
                  storeKey={shakeStoreKey}
                  timeout={900}
                  renderProps={(animatedClassName) => (
                    <Button
                      disabled={isSubmitting || watchedCustomers.length === 0 || nsoi.serviceInstanceType === ServiceInstanceType.PLACEHOLDER}
                      className={`${styles.saveButton} ${animatedClassName}`}
                      variant='contained'
                      type='submit'
                    >
                      Save Group Deal
                    </Button>
                  )}/>
              </Grid>
              <Grid item xs={12} sm={5} md={4}>
                <Button
                  className={smDown ? styles.mobileCancelButton : styles.cancelButton}
                  type='button'
                  variant='outlined'
                  onClick={() => cancel()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default function OldGroupDealForm() {
  const { formData } = useGroupDeal();
  return (
    <OldGroupDealFormInternal initialFormData={formData}/>
  );
}