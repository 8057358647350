
export const CustomFieldGroupTypes = {
  ADDITIONAL_SERVICES: 'ADDITIONAL_SERVICES',
};

export interface IServiceOffering{
  id?: string;
  providerId?: string;
  name?: string;
  serviceTypeId?: string;
  serviceType: string;
  serviceCategory: string;
  frequency?: string;
  invitationDescription?: string;
  cadenceType?: string;
  subscriptionOptions?: IServiceSubscriptionOptions | any;
  serviceDetailSections?: IServiceDetailSectionDTO[];
  customFields?: ICustomFieldDTO[];
  defaultDiscountScheduleId?: string;
  defaultForServiceType: boolean;
  territoryServiceOfferingEnabled: boolean;
  hasDisabledMaxCustomerCount:boolean;

}


export interface IServiceSubscriptionOptions {
  subscriptionTermInMonths?: number;
  label: string;
  options?: ISubscriptionOption[];
}

export interface ISubscriptionOption {
  optionName: string;
  optionValue: string;
  isOneTime:boolean;
  priceSuffix?: string;
}

export interface IServiceDetailSectionDTO {
  sectionHeaderName?: string;
  sectionItems?: string[];
  sectionListItems?: string[];
}
export interface ICustomFieldDTO {
  fieldTypeCode?: string;
  fieldLabel?: string;
  fieldName?: string;
  fieldPlaceholderText?: string;
  required: boolean;
  customFieldOptions?: ICustomFieldDetailDTO[];
  customFieldGroupType: string | null;
}

export interface ICustomFieldDetailDTO {
  optionValue: string;
  optionText: string;
}