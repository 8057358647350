import Userfront from '@userfront/react';
import LogoHeader from 'components/Header/logoHeader';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';


const LoginForm = Userfront.build({
  toolId: window.REACT_APP_PROVIDER_USERFRONT_LOGIN,
});

export default function SignIn() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('redirect')) {
      const redirect = searchParams.get('redirect');
      if (Userfront.tokens.accessToken && redirect && redirect !== '/') {
        navigate(redirect);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const interval = setInterval(() => {
      let el = document.querySelector('input[type="password"]');
      if (el && el.parentElement) {
        el!.parentElement!.nextSibling!.textContent = 'Password must be at least 8 characters including 1 uppercase letter and 1 number.';
        clearInterval(interval);
      }
    }, 50);
    return () => clearInterval(interval);
  }, []);


  return (
    <>

      <LogoHeader hideUserButtons/>
      <LoginForm />
    </>

  );
}