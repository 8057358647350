import { Grow } from '@mui/material';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePostConfirmModal from '../hookStore/usePostConfirmModal';
import ConfirmationEmailSlide from './ConfirmationEmailSlide';
import { PostConfirmSteps } from './constants';
import HappeningSoonSlide from './HappeningSoonSlide';
import LoadingSlide from './LoadingSlide';
import { IHappeningSoonSlideProps, IModalSlideProps } from './modalSlide';
import NeighborsNotifiedSlide from './NeighborsNotifiedSlide';
import styles from './postConfirmModal.module.css';
import PriceDropAlertSlide from './PriceDropAlertSlide';
import { useLoading } from 'components/layout/Loading';
import ModalFrame from 'components/modals/ModalFrame';
import { SFConfetti } from 'components/sfConfetti';
import { getRandomInt } from 'util/randomUtil';

const overrideStyles = {
  [`& .${styles.postConfirmModal}.MuiPaper-root`]: {
    width: {
      xs: '100%',
      sm: 'auto',
    },
    height: {
      xs: '100%',
      sm: 'auto',
    },
    borderRadius: {
      xs: '0',
      sm: '12px',
    },
  },
  [`& .${styles.postConfirmModal}.MuiCardContent-root`]: {
    padding: {
      xs: '12px',
      sm: '24px',
    },
    minWidth: {
      xs: 'auto',
      sm: '600px',
    },
    maxWidth: {
      sm: '700px',
    },

  },
  [`& .${styles.postConfirmModal}.MuiButton-root`]: {
    width: {
      xs: '100%',
      sm: 'auto',
    },
  },
};


const elementMap = new Map<string, (props:IModalSlideProps) => JSX.Element>();
elementMap.set(PostConfirmSteps.HAPPENING_SOON_SLIDE, (props:IModalSlideProps) => <HappeningSoonSlide {...props as IHappeningSoonSlideProps} />);
elementMap.set(PostConfirmSteps.PRICE_DROP_ALERT_SLIDE, (props:IModalSlideProps) => <PriceDropAlertSlide {...props}/>);
elementMap.set(PostConfirmSteps.CONFIRMATION_EMAIL_SLIDE, (props:IModalSlideProps) => <ConfirmationEmailSlide {...props}/>);
elementMap.set(PostConfirmSteps.NEIGHBORS_NOTIFIED_SLIDE, (props:IModalSlideProps) => <NeighborsNotifiedSlide {...props}/>);

export default function WizardModal() {
  const { loadingKey, wizardModalKey, showConfetti, activeStep, slides, confettiCallback } = usePostConfirmModal();
  const { customer, provider } = useCustomerDetail();
  const { getLoadingState } = useLoading(loadingKey);

  const { isLoading } = getLoadingState();

  const compositeIsLoading = isLoading || !customer || !provider;
  return (
    <ModalFrame
      modalKey={wizardModalKey}
      modalClassName={styles.postConfirmModal}
      cardContentClassName={styles.postConfirmModal}
      cardClassName={styles.postConfirmModal}
      overrideStyles={overrideStyles}
    >
      {compositeIsLoading && (
        <LoadingSlide/>
      )}

      <SFConfetti
        showConfetti={showConfetti && !compositeIsLoading}
        numberOfPieces={getRandomInt(300, 400)}
        onConfettiComplete={confettiCallback}
        gravity={0.7}
      />

      {
        slides.map((x, i) => {
          if (i === activeStep && !compositeIsLoading) {
            const comp = elementMap.get(x.key);
            if (comp) {
              return (
                <Grow
                  key={i}
                  unmountOnExit
                  in={true}
                >
                  <div>
                    {comp(x)}
                  </div>
                </Grow>
              );
            }
          }
          return null;
        })
      }

    </ModalFrame>
  );
}