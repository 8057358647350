import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventIcon from '@mui/icons-material/Event';
import { Button, Grid } from '@mui/material';
import { HookCheckbox, HookTextField } from 'components/reactHookForm';
import useStreetfairAnalytics, { ButtonClickTypes } from 'hooks/useStreetfairAnalytics';
import { ParticipantStatus } from 'model/status';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { createDateIgnoreTimezone, formatDateRangeShortLocalDate, formatMonthDayYear } from 'util/dateUtil';
import { formatDecimalMinTwoDecimals } from 'util/numberUtil';
import styles from '../customer.module.css';
import useConfirmCustomerDrawer, { COMPLETION_DRAWER_VARIANT } from '../hookStore/useConfirmCustomerDrawer';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import CaptionDetail from './CaptionDetail';
import ConsulationDate from './ConsulationDate';
import PendingServiceScheduled from './PendingServiceScheduled';

const WorkflowStep = {
  CONSULTATION_SCHEDULED: 'CONSULTATION_SCHEDULED',
  QUOTE_OR_ESTIMATE_SENT: 'QUOTE_OR_ESTIMATE_SENT',
};
const editDetailsSx = {
  width: {
    xs: '100%',
  },
  marginTop: {
    xs: '10px',
    sm: '0px',
  },
  marginBottom: {
    xs: '10px',
    sm: '0px',
  },
  marginRight: {
    xs: '32px',
    sm: '0px',
  },
};
export default function ProviderWorkflow() {

  const { control, getValues, setValue } = useFormContext();
  const {
    onInitialConfirmation,
    canSetServiceScheduled,
    revertToActive,
    revertToPending,
    showPendingServiceScheduled,
    customer,
    clearNsoisWithSameNso,
  } = useCustomerDetail();
  const { customerId, subscriberId } = useParams();
  const { calculateDataDrivenTotalPrice, serverSidePriceResult } = usePriceCalculator();
  const { openDrawer, open, suppressDataDrivenCalculation } = useConfirmCustomerDrawer();
  const { trackButtonClick } = useStreetfairAnalytics();

  const shouldIncludeCurrentCustomerInDiscount:boolean = false;
  useEffect(() => {
    if (!open && !suppressDataDrivenCalculation) {
      void calculateDataDrivenTotalPrice();
    }
  }, [open, suppressDataDrivenCalculation]);

  let serverSideCalculatedTotalPrice = serverSidePriceResult.totalPriceAfterDiscount;

  const wasCustomerContacted = useWatch({ control, name: 'wasCustomerContacted' });
  const watchedWasConsultationScheduled = useWatch({ control, name: 'wasConsultationScheduled' });
  const watchedWasQuoteSent = useWatch({ control, name: 'wasQuoteSent' });
  const watchedWasServiceScheduled = useWatch({ control, name: 'wasServiceScheduled' });

  const shouldShowField = (currentCustomer, workflowStep) => {
    var shouldShow = false;
    if (currentCustomer && currentCustomer.workflowSteps) {
      currentCustomer.workflowSteps.forEach(x => {
        if (x.stepName === workflowStep && x.shouldShow) {
          shouldShow = true;
        }
      });
    }
    return shouldShow;
  };
  const shouldShowQuoteSent = shouldShowField(customer, WorkflowStep.QUOTE_OR_ESTIMATE_SENT);
  return (
    <Grid container >
      <Grid item xs={12}>
        <HookCheckbox
          defaultValue={false}
          name='wasCustomerContacted'
          label='Reached out to customer'
          onChecked={() => onInitialConfirmation(getValues)}/>
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={10}>
        {wasCustomerContacted && (
          <HookTextField
            name='customerContactComment'
            label='Notes'
            postOnChange={(e) =>{
              void onInitialConfirmation(getValues, true);
            }}
            onBlur={() => {void onInitialConfirmation(getValues, false);}}
            multiline
          />
        )}
      </Grid>
      {shouldShowField(customer, WorkflowStep.CONSULTATION_SCHEDULED) &&
            <>
              <Grid item xs={12}>
                <HookCheckbox
                  defaultValue={false}
                  name='wasConsultationScheduled'
                  label='Consultation Scheduled'
                  onChecked={() => void onInitialConfirmation(getValues)}
                  extraProps={{
                    disabled: !wasCustomerContacted,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={10}>
                {watchedWasConsultationScheduled && (
                  <ConsulationDate/>
                )}
              </Grid>
            </>
      }
      {shouldShowQuoteSent && (
        <>
          <Grid item xs={12}>
            <HookCheckbox
              defaultValue={false}
              name='wasQuoteSent'
              label='Quote/Pricing Estimate provided'
              onChecked={() => onInitialConfirmation(getValues)}
              extraProps={{
                disabled: !wasCustomerContacted,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={10}>
            {watchedWasQuoteSent && (
              <HookTextField
                name='customerQuoteComment'
                label='Notes'
                postOnChange={(e) =>{
                  void onInitialConfirmation(getValues, true);
                }}
                onBlur={() => {void onInitialConfirmation(getValues, false);}}
                multiline
              />
            )}
          </Grid>
        </>
      )}
      <PendingServiceScheduled/>

      {!showPendingServiceScheduled() && (
        <Grid item xs={12}>
          <HookCheckbox
            defaultValue={false}
            name='wasServiceScheduled'
            label='Service Scheduled'
            onChecked={(val) => {
              if (val) {
                clearNsoisWithSameNso();
                openDrawer();
              } else {
                void revertToPending();
              }
            }}
            extraProps={{
              disabled: (shouldShowQuoteSent && !watchedWasQuoteSent) || (!shouldShowQuoteSent && !wasCustomerContacted),
            }}
          />
        </Grid>
      )}

      {watchedWasServiceScheduled && !showPendingServiceScheduled() && (
        <Grid container item xs={12} sx={{ marginLeft: '30px' }}>
          <div style={{ width: '300px' }}>
            <CaptionDetail
              icon={<AttachMoneyIcon/>}
              value={formatDecimalMinTwoDecimals(serverSideCalculatedTotalPrice, 'Not Set')}
              label='Total Price'
            />
            {customer.recurring && (
              <CaptionDetail
                icon={<EventIcon className={styles.calendarIcon}/>}
                value={formatMonthDayYear(createDateIgnoreTimezone(customer.subscriptionStartDate)) || 'Not Set'}
                label={'Service Start Date'}
              />
            )}
            {!customer.recurring && !customer.endServiceDate && (
              <CaptionDetail
                icon={<div className={styles.calendarIcon}><EventIcon/></div>}
                value={formatMonthDayYear(customer.serviceDate) || formatMonthDayYear(customer.neighborhoodServiceOfferingInstanceServiceDate)}
                label={`${customer.serviceDate ? 'Service Date' : 'Tentative Service Date'}`}
              />
            )}
            {!customer.recurring && customer.endServiceDate && (
              <CaptionDetail
                icon={<div className={styles.calendarIcon}><EventIcon/></div>}
                value={formatDateRangeShortLocalDate(customer.serviceDate, customer.endServiceDate)}
                label={`${customer.serviceDate ? 'Service Date' : 'Tentative Service Date'}`}
                sx={{ fontSize: '12px' }}
              />
            )}
          </div>
          <Grid container item xs={12} sm='auto' alignItems='center'>
            <Button
              variant='outlined'
              onClick={() => {
                void trackButtonClick(ButtonClickTypes.CUSTOMER_DETAIL_EDIT_DETAILS);
                clearNsoisWithSameNso();
                openDrawer();
              }}
              sx={editDetailsSx}
            >
              <span>Edit Details</span>
            </Button>
          </Grid>

        </Grid>
      )}
      {!customer.recurring && (
        <Grid item xs={12}>
          <HookCheckbox
            defaultValue={false}
            name='wasMarkedComplete'
            label='Completed Service'
            onChecked={(val) => {
              if (val) {
                openDrawer(COMPLETION_DRAWER_VARIANT);
              } else {
                void revertToActive();
              }
            }}
            extraProps={{
              disabled: !watchedWasServiceScheduled || (customer.status !== ParticipantStatus.ACTIVE && customer.status !== ParticipantStatus.COMPLETE),
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

