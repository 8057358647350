
import { Alert, Button, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import CreateNewButton from './CreateNewButton';
import styles from './customerAccordion.module.css';
import CustomerAccordionFields from './CustomerAccordionFields';
import OtherGroupDeal from './OtherGroupDeal';
import { ServiceInstanceStatus, ServiceInstanceType } from 'model/ancillary';
import { IGroupDealCustomer } from 'model/customer';
import { createEmptyMoveToGroupDealForm, IMoveGroupDealError, IMoveToGroupDealForm } from 'model/groupDeal';
import { INeighborhoodServiceOfferingInstance } from 'model/neighborhoodServiceOfferingInstance';
import useGroupDeal from 'pages/groupDeal/hookStore/useGroupDeal';
import useMoveGroupDealStore from 'pages/groupDeal/hookStore/useMoveGroupDeal';

export interface IMoveToGroupDealProps {
  onlyOneCustomer:boolean;
  shouldShowMaxCustomerCount:boolean;
  customer:IGroupDealCustomer;
  onRemove: () => void;
}


export default function MoveToGroupDeal({ shouldShowMaxCustomerCount, customer, onlyOneCustomer, onRemove }: IMoveToGroupDealProps) {
  const { nsoi, nsoisWithSameNso } = useGroupDeal();
  const [showCreateGroupDeal, setShowCreateGroupDeal] = useState<boolean>(false);
  const [data, setData] = useState<IMoveToGroupDealForm>(createEmptyMoveToGroupDealForm(customer.id));
  const [error, setError] = useState<IMoveGroupDealError>({
    serviceDatesError: null,
    maxCustomerCountError: null,
  });
  const [selectedNsoiId, setSelectedNsoiId] = useState<string>('');
  const [createNewSelected, setCreateNewSelected] = useState<boolean>(false);
  const theme = useTheme();
  const secondaryColor = theme.palette.secondary.main;

  const { warningMessage, save, loadingKey, selectGroupDeal, createGroupDeal } = useMoveGroupDealStore();

  return (
    <>
      <Grid container>
        <Grid item xs={12} textAlign='left' sx={{ marginBottom: 2 }}>
          <Typography variant='subtitle2'>Select {!onlyOneCustomer && <span>or create</span>} the group deal to move this customer to.</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid container
            className={styles.otherGroupDealWrapper}
            sx={{ border: `1px solid ${secondaryColor}` }}
          >
            {nsoisWithSameNso && nsoisWithSameNso.filter(x => {
              return (
                x.serviceInstanceType !== ServiceInstanceType.PLACEHOLDER &&
                (x.status === ServiceInstanceStatus.ACTIVE || x.status === ServiceInstanceStatus.COMPLETED)
              );
            }).map((x, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <OtherGroupDeal
                    nsoi={x}
                    isSelected={selectedNsoiId === x.id}
                    onClick={() => {
                      selectGroupDeal(x, customer.id, setData, setShowCreateGroupDeal, setSelectedNsoiId, setCreateNewSelected);
                    }}
                  />
                </Grid>
              );
            })}
            {!onlyOneCustomer && (
              <Grid item xs={12}>
                <CreateNewButton
                  isSelected={createNewSelected}
                  onClick={() => {
                    createGroupDeal(customer.id, setData, setShowCreateGroupDeal, setSelectedNsoiId, setCreateNewSelected);
                  }}
                  text={'Create New'}
                />
              </Grid>
            )}
          </Grid>
          {
            showCreateGroupDeal && (
              <>
                <CustomerAccordionFields shouldShowMaxCustomerCount={shouldShowMaxCustomerCount} data={data} setData={setData} error={error} setError={setError}/>
              </>
            )
          }
          {warningMessage != null && (
            <Grid item xs={12}>
              <Alert severity='warning'>{warningMessage}</Alert>
            </Grid>
          )}
          <Grid container justifyContent='space-between' className={styles.groupDealButtonWrapper}>
            <Grid item xs={12}>
              <Button
                variant='contained'
                type='button'
                onClick={async () => {
                  await save(data, customer, onlyOneCustomer, shouldShowMaxCustomerCount, onRemove, setError);
                }}
                className={styles.saveButton}
              >
                Save
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
}