import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Modal, Radio, Typography, useTheme } from '@mui/material';
import { CloseButton } from 'components/buttons';
import { HookTextField } from 'components/reactHookForm';
import HookRadioField from 'components/reactHookForm/HookRadioField';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { IPaymentRequest } from 'model/paymentRequest';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useCustomerDetail from '../hookStore/useCustomerDetail';
import usePriceCalculator from '../hookStore/usePriceCalculator';
import styles from './requestPaymentModal.module.css';

export default function RequestPaymentModal () {
  const theme = useTheme();
  const handleClose = () => setRequestPaymentModalOpen(false);
  const {
    getRequestPaymentModalOpen,
    setRequestPaymentModalOpen,
    onSubmitError,
    onSendPaymentRequest,
    onSendTestPaymentRequest,
    customer,
    provider,
  } = useCustomerDetail();
  const {
    clientSidePriceResult,
    serverSidePriceResult,
  } = usePriceCalculator();
  const priceResult = clientSidePriceResult.priceResult.discountTotal !== -1 ? clientSidePriceResult.priceResult : serverSidePriceResult;
  const requestPaymentDetails = { message: '', paymentAmount: undefined, paymentRequestType: '', customerId: undefined, serviceType: '' };
  const [paymentType, setPaymentType] = useState<string>('');
  const [paymentTypeHelpText, setPaymentTypeHelpText] = useState<string>('');
  const formContext = useForm<IPaymentRequest>({ defaultValues: requestPaymentDetails });
  const { serviceProviderId, customerId, subscriberId } = useParams();
  const { isError, getErrorMessage } = useErrorState(formContext);
  const [radioButtonValue, setRadioButtonValue] = useState<string>('venmo');
  const [cashAppSelected, setCashAppSelected] = useState<boolean>(false);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px',
    p: 4,
  };

  useEffect(() => {
    const calculatedTotalPrice = priceResult.totalPriceAfterDiscount;
    if (calculatedTotalPrice) {

      formContext.setValue('paymentAmount', calculatedTotalPrice);
    }
    if (customerId) {
      formContext.setValue('customerId', customerId);
    }
    if (subscriberId) {
      formContext.setValue('subscriberId', subscriberId);
    }
    if (serviceProviderId) {
      formContext.setValue('serviceProviderId', serviceProviderId);
    }
    formContext.setValue('serviceType', encodeURI(customer.serviceType));
    if (provider) {
      formContext.setValue('message', `Hi ${customer.firstName} - ${provider.name} has requested payment for your recent service.  You can securely complete the payment using the link here:`);
      if (provider.venmoId) {
        const type = 'venmo';
        updatePaymentTypeHelperText(type);
        formContext.setValue('paymentRequestType', type);
      } else if (provider.cashAppId) {
        const type = 'cashapp';
        updatePaymentTypeHelperText(type);
        formContext.setValue('paymentRequestType', type);
      }
    }
  }, [customer]);

  const paymentTypeOnChange = (e) => {
    updatePaymentTypeHelperText(e.target.value);
  };

  const updatePaymentTypeHelperText = (pmtType) => {
    setPaymentType(pmtType);
    switch (pmtType) {
      case 'credit-card':
        setPaymentTypeHelpText('Credit card helper text here');
        break;
      case 'venmo':
        setPaymentTypeHelpText('The customer will receive a text with a pre-populated Venmo link to complete payment');
        break;
      case 'cashapp':
        setPaymentTypeHelpText('The customer will receive a text with a pre-populated Cash App link to complete payment');
        break;
      default:
        setPaymentTypeHelpText('');
    }
  };

  return (

    <Modal
      open={getRequestPaymentModalOpen()}
      onClose={handleClose}
      aria-labelledby="request-a-payment-modal-title"
    >
      <>
        <FormProvider {...formContext}>
          <form>
            <Box sx={style}>
              <Grid container style={{ margin: '0px 10px' }}>
                <Grid container item justifyContent='end' xs={12}>
                  <CloseButton
                    tooltipTitle="close pane"
                    onClick={handleClose}
                    className={styles.closeDrawerButton}
                    iconButtonProps={{
                      color: 'primary',
                    }}
                  />
                </Grid>
                <Grid container item justifyContent='center' xs={12}>
                  <Grid item>
                    <Typography id="request-a-payment-modal-title" variant="h6" component="h2">
                                        Request Payment
                    </Typography>
                  </Grid>
                </Grid>
                {provider &&
                                <Grid container item justifyContent='start' xs={12} className={styles.section}>
                                  <FormControl fullWidth error={isError('paymentRequestType')}>
                                    <FormLabel id="payment-request-type-radio-buttons-group-label">Payment Request Type</FormLabel>
                                    {/* <Grid container item xs={12}>
                                            <Grid item>
                                                <InfoIcon style={{fontSize: '14px', color: '#666666'}}/>
                                            </Grid>
                                            <Grid item>
                                                <Typography style={{fontSize: '12px', marginLeft: '7px'}}>Add / edit payment types in settings.</Typography>
                                            </Grid>
                                        </Grid> */}

                                    <HookRadioField
                                      horizontal
                                      name='paymentRequestType'
                                      required
                                      defaultValue={radioButtonValue}
                                    >
                                      {/* {provider &&
                                                <FormControlLabel value="credit-card" control={<Radio onChange={paymentTypeOnChange}/>} label="Credit Card" />
                                            } */}
                                      {provider && provider.venmoId &&
                                                <FormControlLabel value="venmo" control={<Radio onChange={paymentTypeOnChange}/>} label="Venmo" />
                                      }
                                      {provider && provider.cashAppId &&
                                                <FormControlLabel value="cashapp" control={<Radio onChange={paymentTypeOnChange}/>} label="Cash App" />
                                      }
                                    </HookRadioField>
                                    <FormHelperText>{getErrorMessage('paymentRequestType')}</FormHelperText>
                                  </FormControl>
                                  {paymentType && paymentTypeHelpText &&
                                        <Grid container item xs={12}>
                                          <Typography className={styles.paymentTypeHelptext}>{paymentTypeHelpText}</Typography>
                                        </Grid>
                                  }
                                </Grid>
                }
                <Grid container item justifyContent='start' xs={6} className={styles.section}>
                  <FormControl fullWidth>

                    <FormLabel id="payment-request-type-radio-buttons-group-label">Payment Amount</FormLabel>
                    <Grid container item xs={12}>
                      <Grid item style={{ marginTop: '3px' }}>
                                            $
                      </Grid>
                      <Grid item style={{ marginLeft: '3px' }}>
                        <HookTextField
                          required
                          name={'paymentAmount'}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid container item justifyContent='start' xs={11} className={styles.section}>
                  <FormControl fullWidth>
                    <FormLabel id="payment-request-type-radio-buttons-group-label">Customized Message</FormLabel>
                    <HookTextField
                      required
                      multiline
                      rows={4}
                      name={'message'}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                      variant='contained'
                      type='submit'
                      className={styles.confirmButton}
                      onClick={(e) => {
                        void formContext.handleSubmit(onSendPaymentRequest, onSubmitError)(e);
                      }}
                    >
                      <Typography variant='body1' style={{ color: theme.palette.tertiary.altExtraLight, fontWeight: '600' }}>Send Payment Request to Customer</Typography>
                    </Button>
                  </Grid>
                  <Grid container item xs={12} justifyContent='center'>
                    <Button
                      variant='outlined'
                      type='button'
                      onClick={(e) => {
                        void formContext.handleSubmit(onSendTestPaymentRequest, onSubmitError)(e);
                      }}
                      className={styles.testPaymentButton}
                    >
                      <span>Send me a test payment request</span>
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                    <Button
                      variant='text'
                      type='button'
                      onClick={handleClose}
                      className={styles.requestPaymentButton}
                    >
                      <span>Cancel</span>
                    </Button>
                  </Grid>
                </Grid>


              </Grid>
            </Box>
          </form>
        </FormProvider>
      </>
    </Modal>

  );
}
