import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import StatusBall from 'components/Status/StatusBall';
import Footer from 'components/footer';
import Loading from 'components/layout/Loading';
import useStreetfairAnalytics from 'hooks/useStreetfairAnalytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DemoUtil } from 'util/demoUtil';
import CopyButton from './common/CopyButton';
import ConfirmCustomerDrawer from './confirmCustomerDrawer';
import ConvertCustomerModal from './convertCustomerModal/ConvertCustomerModal';
import styles from './customer.module.css';
import Detail from './detail';
import HistoricalParticipants from './historicalParticipants/HistoricalParticipants';
import useCustomerDetail from './hookStore/useCustomerDetail';
import InitialConfirmation from './initialConfirmation';
import { MarkCustomerRewardsAsPaidModal } from './markRewardsAsPaid/MarkCustomerRewardsAsPaidModal';
import WizardModal from './postConfirmModal/WizardModal';

function CustomDivider() {
  return (
    <div className={styles.divider}></div>
  );
}

function normalizeName(firstName: string, lastName: string):string {
  if (firstName && lastName) {
    return `${firstName.trim()} ${lastName.trim()}`;
  } else if (firstName && !lastName) {
    const regex = /[A-Z]/g;
    let match;
    let capitalIndices:any[] = [];
    while ((match = regex.exec(firstName.trim())) !== null) {
      capitalIndices.push(match.index);
    }
    if (capitalIndices.length > 1) {
      return `${firstName.trim().substring(0, capitalIndices[1])} ${firstName.trim().substring(capitalIndices[1])}`;
    } else {
      return firstName.trim();
    }
  } else {
    return '';
  }
}

export default function CustomerDetail() {
  const { customerId, serviceProviderId } = useParams();
  const { postCustomerConfirmationModal } = useFlags();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { init, customer, loadingKey, isProviderAdmin, additionalServiceItems } = useCustomerDetail();
  const { trackPageView } = useStreetfairAnalytics();
  useEffect(() => {
    void init();
    window.scrollTo(0, 0);
    void trackPageView('Customer_Detail');
  }, []);

  const breadcrumbClass = smDown ? styles.mobileBreadcrumb : styles.breadcrumb;
  const breadcrumbLinkClass = smDown ? styles.mobileBreadcrumbLink : '';
  const customerHeaderClass = smDown ? styles.mobileCustomerHeader : '';
  const contactPageIconClass = smDown ? styles.mobileContactPageIcon : styles.contactPageIcon;
  return (
    <Loading loadingKey={loadingKey} size={100}>
      <Grid container justifyContent='center' >
        <Grid container item xs={11} sm={10} md={11} lg={11} xl={10} >
          <Grid className={styles.breadcrumb} container item xs={12} sm={8} alignItems='center' flexWrap="nowrap">
            <HomeIcon fontSize="small" className={styles.breadcrumbIcon} style={{ color: theme.palette.primary.main }}/>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
              sx={{
                '& .MuiBreadcrumbs-ol': {
                  flexWrap: 'nowrap',
                },
                '& .MuiBreadcrumbs-li': {
                  flexWrap: 'nowrap',
                },
                'xs': {
                  fontSize: '8px',
                },
              }}
            >
              <Link to={`/v1/${serviceProviderId}/`} className={breadcrumbLinkClass}>Home</Link>
              <Link to={`/v1/${serviceProviderId}/customers`} className={breadcrumbLinkClass}>Customers</Link>
              <Link to={`/v1/${serviceProviderId}/customers/${customerId}`} className={`${styles.lastLink} ${breadcrumbLinkClass}`}>{customer.firstName} {customer.lastName}</Link>
            </Breadcrumbs>
          </Grid>
          <Grid container className={`${styles.section} ${styles.customerNameTitle}`}>
            <Grid container item xs={'auto'} alignItems='center' flexWrap={'nowrap'}>
              <StatusBall
                status={customer.status}
              />
              <Typography variant='h5' component='div' className={customerHeaderClass}>
                <>{customer.firstName} {customer.lastName}</>
                <CopyButton message={normalizeName(customer.firstName, customer.lastName)}/>
              </Typography>
            </Grid>
            <HistoricalParticipants/>
          </Grid>
          {/* <Grid container>
            {additionalServiceItems.length > 0 &&
              (!customer.additionalServicesFollowUp || customer.additionalServicesFollowUp === ADDITIONAL_SERVICES_LATER) && (
              <Alert severity='warning' sx={{ marginBottom: '20px', textAlign: 'left', width: '100%' }}>
                {customer.firstName} {customer.lastName} has also requested additional services. See the Additional Services section for more details.
              </Alert>
            )}
          </Grid> */}
          <Grid container item xs={12} sm={12} md={5} lg={4} alignContent={'flex-start'} className={!mdDown ? styles.desktopSection : styles.section} >
            <Detail/>
            {mdDown && <CustomDivider/>}
          </Grid>
          <Grid container item xs={12} sm={12} md={7} lg={8} className={`${!mdDown ? styles.desktopInitialConfirmationWrapper: styles.initialConfirmationWrapper}`}>
            <InitialConfirmation/>
          </Grid>
          <Footer className={styles.footer}/>
        </Grid>

        <ConfirmCustomerDrawer/>
        {postCustomerConfirmationModal && <WizardModal />}
        {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && <ConvertCustomerModal/>}
        {DemoUtil.shouldShowProviderAdminFeature(isProviderAdmin) && <MarkCustomerRewardsAsPaidModal/>}
      </Grid>
    </Loading>
  );
}