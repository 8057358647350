import { ICustomerTableRow } from './customerTable';
import { IDropdownOption } from './dropdown';

export class CustomerActionItems {
  static NEEDS_CONTACTED = 'NEEDS_CONTACTED';
  static NEEDS_CONFIRMATION ='NEEDS_CONFIRMATION';
  static NEEDS_COMPLETED = 'NEEDS_COMPLETED';
  static NEEDS_QUOTE = 'NEEDS_QUOTE';


  public static needsContacted(actionItem:string):boolean {
    return actionItem.toLocaleUpperCase() === CustomerActionItems.NEEDS_CONTACTED;
  }
  public static needsConfirmation(actionItem:string):boolean {
    return actionItem.toLocaleUpperCase() === CustomerActionItems.NEEDS_CONFIRMATION;
  }
  public static needsCompleted(actionItem:string):boolean {
    return actionItem.toLocaleUpperCase() === CustomerActionItems.NEEDS_COMPLETED;
  }
  public static needsQuote(actionItem:string):boolean {
    return actionItem.toLocaleUpperCase() === CustomerActionItems.NEEDS_QUOTE;
  }

  //use the action item to determine what logic to execute.
  public static matchesActionItem(customer:ICustomerTableRow, actionItem:string):boolean {
    if (this.needsContacted(actionItem)) {
      return customer.needsContact;
    }
    if (this.needsQuote(actionItem)) {
      return customer.needsQuote && !customer.needsContact;
    }
    if (this.needsConfirmation(actionItem)) {
      return customer.needsConfirmation;
    }
    if (this.needsCompleted(actionItem)) {
      return customer.needsCompleted;
    }
    return false;
  }

  public static actionItems():IDropdownOption[] {
    return [
      {
        key: CustomerActionItems.NEEDS_CONTACTED,
        optionValue: CustomerActionItems.NEEDS_CONTACTED,
        optionText: 'Customers to Contact',
      },
      {
        key: CustomerActionItems.NEEDS_QUOTE,
        optionValue: CustomerActionItems.NEEDS_QUOTE,
        optionText: 'Customers to Quote/Estimate',
      },
      {
        key: CustomerActionItems.NEEDS_CONFIRMATION,
        optionValue: CustomerActionItems.NEEDS_CONFIRMATION,
        optionText: 'Customers to Confirm',
      },
      {
        key: CustomerActionItems.NEEDS_COMPLETED,
        optionValue: CustomerActionItems.NEEDS_COMPLETED,
        optionText: 'Customers to Mark Complete',
      },
    ];
  }
}
